import { Button } from 'src/common/primitives/Button';

type MultiSelectActionsProps = {
  onSelectAll: () => void;
  disabled?: boolean;
};

export function MultiSelectActions({
  onSelectAll,
  disabled,
}: MultiSelectActionsProps) {
  return (
    <div className="flex justify-end">
      <Button
        dataTestId="multi-select-select-all"
        onClick={onSelectAll}
        className="mb-1 py-0 text-sm"
        variant="ghost"
        disabled={disabled}
      >
        Select All
      </Button>
    </div>
  );
}
