import { useRef } from 'react';

import { isArray, uniqBy } from 'lodash-es';

import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import {
  FormContextField,
  FormContextFieldRulesProps,
} from 'src/common/FormField/FormContextField';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import {
  SelectOption,
  SelectOptionWithColor,
} from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect/MultiSelect';

import { isSelectOption } from '../primitives/Select/SelectOption/isSelectOption';
import { HelperTextPopover } from './FormFieldDefault';

export interface MultiSelectContextFieldProps {
  fieldPath: string;
  label: string;
  options: SelectOption[] | SelectOptionWithColor[];
  disabled?: boolean;
  searchable?: boolean;
  query?: string;
  setQuery?: (query: string) => void;
  rules?: FormContextFieldRulesProps;
  inline?: boolean;
  helperText?: string;
  onSelectAll?: () => void;
}

export function MultiSelectContextField({
  fieldPath,
  label,
  options,
  disabled,
  searchable,
  query,
  setQuery,
  rules,
  inline,
  helperText,
  onSelectAll,
}: MultiSelectContextFieldProps) {
  const selectedRef = useRef<SelectOption[] | SelectOptionWithColor[]>([]);
  return (
    <FormContextField name={fieldPath} rules={rules}>
      {({ field, fieldState }) => {
        const inputProps = {
          id: fieldPath,
          ...field,
        };
        // In the case where the user has selected some options and then
        // types in the search box, options might not have all the selected
        // options, so we keep the resolved selected options and merge them
        // Keeping this outside of MultiSelect since it's an uncontrolled component
        selectedRef.current =
          isArray(field.value) &&
          field.value.length > 0 &&
          field.value.some(isSelectOption)
            ? field.value
            : uniqBy([...options, ...selectedRef.current], 'value').filter(
                (option) =>
                  isArray(field.value) && field.value.includes(option.value)
              );
        return (
          <FormFieldContainer>
            <DefaultFormFieldLayout
              label={label}
              error={fieldState.error?.message?.toString()}
              inputProps={inputProps}
              inline={inline}
            >
              <div className="flex items-center gap-2">
                <MultiSelect
                  disabled={disabled}
                  options={options}
                  selected={selectedRef.current}
                  onChange={(selected) => {
                    const newValue =
                      isArray(field.value) &&
                      field.value.length > 0 &&
                      field.value.some(isSelectOption)
                        ? selected
                        : selected.map((option) => option.value);
                    field.onChange(newValue);
                  }}
                  searchable={searchable}
                  query={query}
                  setQuery={setQuery}
                  onSelectAll={onSelectAll}
                />
                {helperText && <HelperTextPopover text={helperText} isStatic />}
              </div>
            </DefaultFormFieldLayout>
          </FormFieldContainer>
        );
      }}
    </FormContextField>
  );
}
