import { useAttendantsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect/MultiSelect';
import { useAuth } from 'src/features/auth/useAuth';

export function AttendantSelectionField({
  onlySchedulable,
}: {
  onlySchedulable?: boolean;
}) {
  const auth = useAuth();
  const { data: attendantsData } = useAttendantsListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
    canBeScheduled: onlySchedulable,
  });
  return (
    <div>
      <FormContextField name="attendants">
        {({ field, fieldState }) => {
          return (
            <DefaultFormFieldLayout
              label="Attendants"
              error={fieldState.error?.message?.toString()}
              inputProps={{ id: 'attendants-id' }}
            >
              <MultiSelect
                placeholder="Select attendants"
                selected={field.value}
                options={
                  attendantsData?.results.map((attendant) => {
                    return {
                      label: `${attendant.first_name} ${attendant.last_name}`,
                      value: attendant.id || '',
                    };
                  }) || []
                }
                onChange={field.onChange}
              />
            </DefaultFormFieldLayout>
          );
        }}
      </FormContextField>
    </div>
  );
}
