import { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SelectOption } from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect/MultiSelect';
import { setSelectedMatchFields } from 'src/features/passenger/passenger.slice';

import { MatchFields } from '../types';

const matchFields: SelectOption<MatchFields>[] = [
  {
    label: 'Date of Birth',
    value: 'dob',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'First name',
    value: 'first_name',
  },
  {
    label: 'Last name',
    value: 'last_name',
  },
  {
    label: 'Sex',
    value: 'gender',
  },
  {
    label: 'Phone number',
    value: 'phone_number',
  },
  {
    label: 'Weight',
    value: 'weight',
  },
];

type SelectMatchFieldsForm = {
  matchFields: SelectOption<MatchFields>[];
};

interface SelectPassengerMatchFieldsStepProps {
  next: () => void;
  prev: () => void;
}

export function SelectPassengerMatchFieldsStep({
  next,
  prev,
}: SelectPassengerMatchFieldsStepProps) {
  const methods = useForm<SelectMatchFieldsForm>({
    defaultValues: {
      matchFields: matchFields.filter(({ value }) => {
        return ['first_name', 'last_name', 'phone_number', 'email'].includes(
          value
        );
      }),
    },
  });
  const dispatch = useAppDispatch();
  const selectedMatchFields = methods.watch('matchFields');
  useEffect(() => {
    dispatch(
      setSelectedMatchFields(selectedMatchFields.map((field) => field.value))
    );
  }, [dispatch, selectedMatchFields]);

  return (
    <div className="mt-2 flex max-w-md flex-col gap-2">
      <p>
        Bambi can search for duplicate passengers based on a selection of 3
        fields below. Passengers must have at least 2 matches between email,
        phone, Date of Birth, first and last name in order to merge.
      </p>
      <Controller
        control={methods.control}
        name="matchFields"
        render={({ field }) => {
          return (
            <>
              <MultiSelect
                selected={field.value}
                options={matchFields}
                onChange={field.onChange}
              />
              {field.value.length < 3 && (
                <span className="text-red-500">
                  Please select at least 3 fields
                </span>
              )}
            </>
          );
        }}
      />
      <div className="flex flex-row justify-evenly gap-2">
        <Button variant="secondary-outline" className="w-full" onClick={prev}>
          Back
        </Button>
        <Button
          variant="primary"
          className="w-full"
          disabled={selectedMatchFields.length < 3}
          onClick={next}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
