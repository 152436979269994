import { faker } from '@faker-js/faker';

/**
 * Call this in a test suite's beforeEach.
 * Call only when needed to keep tests efficient, i.e. not in setupTests.ts.
 */
export const seedFaker = () => {
  faker.seed(8675309);
};

// Trying to get some more unique values to avoid flaky tests due to multiple elements found errors
export const fakeNoun = () =>
  faker.word.noun() + new Date().getTime() + Math.floor(Math.random() * 1000);
