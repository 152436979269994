import { emptySplitApi as api } from './emptyApi';
export const addTagTypes = [
  'assignments',
  'attendants',
  'billing',
  'Authentication',
  'brokers',
  'credentials',
  'die',
  'dispatch',
  'tags',
  'drivers',
  'filters',
  'flags',
  'invoices',
  'locations',
  'members',
  'messages',
  'user',
  'organization-billing-status',
  'organizations',
  'passengers',
  'payers',
  'pricing',
  'run-bambi-run',
  'signatures',
  'tilled',
  'trip-import',
  'trips',
  'vehicles',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      assignmentsList: build.query<
        AssignmentsListApiResponse,
        AssignmentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            deleted_objects: queryArg.deletedObjects,
            ordering: queryArg.ordering,
            page: queryArg.page,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            search: queryArg.search,
            unassigned: queryArg.unassigned,
          },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsCreate: build.mutation<
        AssignmentsCreateApiResponse,
        AssignmentsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/`,
          method: 'POST',
          body: queryArg.assignmentBulkCreate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsRetrieve: build.query<
        AssignmentsRetrieveApiResponse,
        AssignmentsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsPartialUpdate: build.mutation<
        AssignmentsPartialUpdateApiResponse,
        AssignmentsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedAssignment,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsDestroy: build.mutation<
        AssignmentsDestroyApiResponse,
        AssignmentsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsBreaksList: build.query<
        AssignmentsBreaksListApiResponse,
        AssignmentsBreaksListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/breaks/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsBreaksCreate: build.mutation<
        AssignmentsBreaksCreateApiResponse,
        AssignmentsBreaksCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/breaks/`,
          method: 'POST',
          body: queryArg.assignmentBreak,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsBreaksRetrieve: build.query<
        AssignmentsBreaksRetrieveApiResponse,
        AssignmentsBreaksRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/breaks/${queryArg.breakId}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsBreaksPartialUpdate: build.mutation<
        AssignmentsBreaksPartialUpdateApiResponse,
        AssignmentsBreaksPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/breaks/${queryArg.breakId}/`,
          method: 'PATCH',
          body: queryArg.patchedAssignmentBreak,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsBreaksDestroy: build.mutation<
        AssignmentsBreaksDestroyApiResponse,
        AssignmentsBreaksDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/breaks/${queryArg.breakId}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsBreaksActiveList: build.query<
        AssignmentsBreaksActiveListApiResponse,
        AssignmentsBreaksActiveListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/breaks/active/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsDriverCallRequestsCreate: build.mutation<
        AssignmentsDriverCallRequestsCreateApiResponse,
        AssignmentsDriverCallRequestsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/driver-call-requests/`,
          method: 'POST',
          body: queryArg.driverCallRequest,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsDriverCallRequestsCancelPartialUpdate: build.mutation<
        AssignmentsDriverCallRequestsCancelPartialUpdateApiResponse,
        AssignmentsDriverCallRequestsCancelPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/driver-call-requests/${queryArg.driverCallRequestId}/cancel/`,
          method: 'PATCH',
          body: queryArg.patchedDriverCallRequest,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsDriverCallRequestsResolvePartialUpdate: build.mutation<
        AssignmentsDriverCallRequestsResolvePartialUpdateApiResponse,
        AssignmentsDriverCallRequestsResolvePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/driver-call-requests/${queryArg.driverCallRequestId}/resolve/`,
          method: 'PATCH',
          body: queryArg.patchedDriverCallRequest,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsEventsList: build.query<
        AssignmentsEventsListApiResponse,
        AssignmentsEventsListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/events/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
          params: { page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsEventsCreate: build.mutation<
        AssignmentsEventsCreateApiResponse,
        AssignmentsEventsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/events/`,
          method: 'POST',
          body: queryArg.assignmentEvent,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsTripsList: build.query<
        AssignmentsTripsListApiResponse,
        AssignmentsTripsListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/trips/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsTripsActiveList: build.query<
        AssignmentsTripsActiveListApiResponse,
        AssignmentsTripsActiveListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/${queryArg.id}/trips/active/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsAssignedMembershipsPartialUpdate: build.mutation<
        AssignmentsAssignedMembershipsPartialUpdateApiResponse,
        AssignmentsAssignedMembershipsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/assigned-memberships/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedAssignedMembership,
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsBreaksEventsList: build.query<
        AssignmentsBreaksEventsListApiResponse,
        AssignmentsBreaksEventsListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/breaks/${queryArg.id}/events/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
          params: { page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsBreaksEventsCreate: build.mutation<
        AssignmentsBreaksEventsCreateApiResponse,
        AssignmentsBreaksEventsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/breaks/${queryArg.id}/events/`,
          method: 'POST',
          body: queryArg.assignmentBreakEvent,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['assignments'],
      }),
      assignmentsBulkDefaultVehicleDriverList: build.query<
        AssignmentsBulkDefaultVehicleDriverListApiResponse,
        AssignmentsBulkDefaultVehicleDriverListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/bulk/default/vehicle/${queryArg.vehicleId}/driver/${queryArg.driverId}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { attendant_ids: queryArg.attendantIds, page: queryArg.page },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsDriverRetrieve: build.query<
        AssignmentsDriverRetrieveApiResponse,
        AssignmentsDriverRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/driver/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsDriverListList: build.query<
        AssignmentsDriverListListApiResponse,
        AssignmentsDriverListListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/driver/list/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            page: queryArg.page,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
          },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsExportList: build.query<
        AssignmentsExportListApiResponse,
        AssignmentsExportListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/export/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            format: queryArg.format,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            unassigned: queryArg.unassigned,
          },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsLocationsList: build.query<
        AssignmentsLocationsListApiResponse,
        AssignmentsLocationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/locations/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            page: queryArg.page,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
          },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsMemberListList: build.query<
        AssignmentsMemberListListApiResponse,
        AssignmentsMemberListListApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/member/list/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            page: queryArg.page,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
          },
        }),
        providesTags: ['assignments'],
      }),
      assignmentsTimeRangeCreate: build.mutation<
        AssignmentsTimeRangeCreateApiResponse,
        AssignmentsTimeRangeCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assignments/time-range/`,
          method: 'POST',
          body: queryArg.assignmentTimeRangeBulkCreate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['assignments'],
      }),
      attendantsList: build.query<
        AttendantsListApiResponse,
        AttendantsListApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            can_be_scheduled: queryArg.canBeScheduled,
            id: queryArg.id,
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['attendants'],
      }),
      attendantsCreate: build.mutation<
        AttendantsCreateApiResponse,
        AttendantsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/`,
          method: 'POST',
          body: queryArg.attendant,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      attendantsRetrieve: build.query<
        AttendantsRetrieveApiResponse,
        AttendantsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['attendants'],
      }),
      attendantsPartialUpdate: build.mutation<
        AttendantsPartialUpdateApiResponse,
        AttendantsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedAttendant,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      attendantsDestroy: build.mutation<
        AttendantsDestroyApiResponse,
        AttendantsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      attendantsSkillsCreate: build.mutation<
        AttendantsSkillsCreateApiResponse,
        AttendantsSkillsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/skills/`,
          method: 'POST',
          body: queryArg.attendantSkills,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      attendantsSkillsRetrieve: build.query<
        AttendantsSkillsRetrieveApiResponse,
        AttendantsSkillsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/skills/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['attendants'],
      }),
      attendantsSkillsUpdate: build.mutation<
        AttendantsSkillsUpdateApiResponse,
        AttendantsSkillsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/skills/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.attendantSkills,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      attendantsSkillsPartialUpdate: build.mutation<
        AttendantsSkillsPartialUpdateApiResponse,
        AttendantsSkillsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/skills/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedAttendantSkills,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      attendantsSkillsDestroy: build.mutation<
        AttendantsSkillsDestroyApiResponse,
        AttendantsSkillsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/attendants/skills/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['attendants'],
      }),
      billingBillingSettingsRetrieve: build.query<
        BillingBillingSettingsRetrieveApiResponse,
        BillingBillingSettingsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/billing/billing-settings/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['billing'],
      }),
      billingBillingSettingsPartialUpdate: build.mutation<
        BillingBillingSettingsPartialUpdateApiResponse,
        BillingBillingSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/billing/billing-settings/`,
          method: 'PATCH',
          body: queryArg.patchedBillingSettings,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['billing'],
      }),
      billingBillingSettingsNextInvoiceNumberRetrieve: build.query<
        BillingBillingSettingsNextInvoiceNumberRetrieveApiResponse,
        BillingBillingSettingsNextInvoiceNumberRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/billing/billing-settings/next-invoice-number/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['billing'],
      }),
      billingTripsBillingList: build.query<
        BillingTripsBillingListApiResponse,
        BillingTripsBillingListApiArg
      >({
        query: (queryArg) => ({
          url: `/billing/trips-billing/`,
          params: { page: queryArg.page },
        }),
        providesTags: ['billing'],
      }),
      brokersAliviOauth2TokenCreate: build.mutation<
        BrokersAliviOauth2TokenCreateApiResponse,
        BrokersAliviOauth2TokenCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/alivi/oauth2/token/`,
          method: 'POST',
          body: queryArg.aliviOAuthToken,
        }),
        invalidatesTags: ['Authentication'],
      }),
      brokersAliviRideCancelCreate: build.mutation<
        BrokersAliviRideCancelCreateApiResponse,
        BrokersAliviRideCancelCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/alivi/ride/cancel/`,
          method: 'POST',
          body: queryArg.aliviRideCancel,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersAliviRideGetStatusCreate: build.mutation<
        BrokersAliviRideGetStatusCreateApiResponse,
        BrokersAliviRideGetStatusCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/alivi/ride/getStatus/`,
          method: 'POST',
          body: queryArg.aliviRideStatus,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersAliviRideOfferCreate: build.mutation<
        BrokersAliviRideOfferCreateApiResponse,
        BrokersAliviRideOfferCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/alivi/ride/offer/`,
          method: 'POST',
          body: queryArg.aliviRideOffer,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersAliviRideUpdateCreate: build.mutation<
        BrokersAliviRideUpdateCreateApiResponse,
        BrokersAliviRideUpdateCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/alivi/ride/update/`,
          method: 'POST',
          body: queryArg.aliviRideUpdate,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersExampleHelloWorldRetrieve: build.query<
        BrokersExampleHelloWorldRetrieveApiResponse,
        BrokersExampleHelloWorldRetrieveApiArg
      >({
        query: () => ({ url: `/brokers/example/hello_world/` }),
        providesTags: ['brokers'],
      }),
      brokersKinetikOauth2TokenCreate: build.mutation<
        BrokersKinetikOauth2TokenCreateApiResponse,
        BrokersKinetikOauth2TokenCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/oauth2/token/`,
          method: 'POST',
          body: queryArg.kinetikOAuthToken,
        }),
        invalidatesTags: ['Authentication'],
      }),
      brokersKinetikV1CancelRideCreate: build.mutation<
        BrokersKinetikV1CancelRideCreateApiResponse,
        BrokersKinetikV1CancelRideCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/v1/cancelRide`,
          method: 'POST',
          body: queryArg.kinetikRideReadOnly,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersKinetikV1CreateRidesCreate: build.mutation<
        BrokersKinetikV1CreateRidesCreateApiResponse,
        BrokersKinetikV1CreateRidesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/v1/createRides`,
          method: 'POST',
          body: queryArg.kinetikRide,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersKinetikV1GetDriverCreate: build.mutation<
        BrokersKinetikV1GetDriverCreateApiResponse,
        BrokersKinetikV1GetDriverCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/v1/getDriver`,
          method: 'POST',
          body: queryArg.kinetikDriver,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersKinetikV1GetRideCreate: build.mutation<
        BrokersKinetikV1GetRideCreateApiResponse,
        BrokersKinetikV1GetRideCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/v1/getRide`,
          method: 'POST',
          body: queryArg.kinetikRideReadOnly,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersKinetikV1GetVehicleCreate: build.mutation<
        BrokersKinetikV1GetVehicleCreateApiResponse,
        BrokersKinetikV1GetVehicleCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/v1/getVehicle/`,
          method: 'POST',
          body: queryArg.kinetikVehicle,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersKinetikV1UpdateRidesUpdate: build.mutation<
        BrokersKinetikV1UpdateRidesUpdateApiResponse,
        BrokersKinetikV1UpdateRidesUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/kinetik/v1/updateRides`,
          method: 'PUT',
          body: queryArg.kinetikRide,
        }),
        invalidatesTags: ['brokers'],
      }),
      brokersMtmOauth2TokenCreate: build.mutation<
        BrokersMtmOauth2TokenCreateApiResponse,
        BrokersMtmOauth2TokenCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/mtm/oauth2/token/`,
          method: 'POST',
          body: queryArg.mtmoAuthToken,
        }),
        invalidatesTags: ['Authentication'],
      }),
      brokersMtmWebhookCreate: build.mutation<
        BrokersMtmWebhookCreateApiResponse,
        BrokersMtmWebhookCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/brokers/mtm/webhook/${queryArg.webhookId}/`,
          method: 'POST',
          body: queryArg.mtmWebhook,
        }),
        invalidatesTags: ['brokers'],
      }),
      credentialsList: build.query<
        CredentialsListApiResponse,
        CredentialsListApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/`,
          params: {
            credential_type_category: queryArg.credentialTypeCategory,
            credential_types: queryArg.credentialTypes,
            members: queryArg.members,
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
            vehicles: queryArg.vehicles,
          },
        }),
        providesTags: ['credentials'],
      }),
      credentialsCreate: build.mutation<
        CredentialsCreateApiResponse,
        CredentialsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/`,
          method: 'POST',
          body: queryArg.credential,
        }),
        invalidatesTags: ['credentials'],
      }),
      credentialsRetrieve: build.query<
        CredentialsRetrieveApiResponse,
        CredentialsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/credentials/${queryArg.id}/` }),
        providesTags: ['credentials'],
      }),
      credentialsPartialUpdate: build.mutation<
        CredentialsPartialUpdateApiResponse,
        CredentialsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedCredential,
        }),
        invalidatesTags: ['credentials'],
      }),
      credentialsDestroy: build.mutation<
        CredentialsDestroyApiResponse,
        CredentialsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/${queryArg.id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: ['credentials'],
      }),
      credentialsTypesList: build.query<
        CredentialsTypesListApiResponse,
        CredentialsTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/types/`,
          params: {
            category: queryArg.category,
            description: queryArg.description,
            id: queryArg.id,
            name: queryArg.name,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['credentials'],
      }),
      credentialsTypesCreate: build.mutation<
        CredentialsTypesCreateApiResponse,
        CredentialsTypesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/types/`,
          method: 'POST',
          body: queryArg.credentialType,
        }),
        invalidatesTags: ['credentials'],
      }),
      credentialsTypesRetrieve: build.query<
        CredentialsTypesRetrieveApiResponse,
        CredentialsTypesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/credentials/types/${queryArg.id}/` }),
        providesTags: ['credentials'],
      }),
      credentialsTypesPartialUpdate: build.mutation<
        CredentialsTypesPartialUpdateApiResponse,
        CredentialsTypesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/types/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedCredentialType,
        }),
        invalidatesTags: ['credentials'],
      }),
      credentialsTypesDestroy: build.mutation<
        CredentialsTypesDestroyApiResponse,
        CredentialsTypesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/credentials/types/${queryArg.id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: ['credentials'],
      }),
      dieCreate: build.mutation<DieCreateApiResponse, DieCreateApiArg>({
        query: (queryArg) => ({
          url: `/die/${queryArg.code}/`,
          method: 'POST',
        }),
        invalidatesTags: ['die'],
      }),
      dispatchRetrieve: build.query<
        DispatchRetrieveApiResponse,
        DispatchRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/dispatch/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            unassigned_range_end: queryArg.unassignedRangeEnd,
            unassigned_range_start: queryArg.unassignedRangeStart,
            unassigned_search: queryArg.unassignedSearch,
          },
        }),
        providesTags: ['dispatch'],
      }),
      driverTagsList: build.query<
        DriverTagsListApiResponse,
        DriverTagsListApiArg
      >({
        query: (queryArg) => ({
          url: `/driver-tags/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['tags'],
      }),
      driverTagsCreate: build.mutation<
        DriverTagsCreateApiResponse,
        DriverTagsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/driver-tags/`,
          method: 'POST',
          body: queryArg.driverTagBulkCreate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      driverTagsRetrieve: build.query<
        DriverTagsRetrieveApiResponse,
        DriverTagsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/driver-tags/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['tags'],
      }),
      driverTagsDestroy: build.mutation<
        DriverTagsDestroyApiResponse,
        DriverTagsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/driver-tags/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      driversList: build.query<DriversListApiResponse, DriversListApiArg>({
        query: (queryArg) => ({
          url: `/drivers/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            can_be_scheduled: queryArg.canBeScheduled,
            id: queryArg.id,
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['drivers'],
      }),
      driversCreate: build.mutation<
        DriversCreateApiResponse,
        DriversCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/drivers/`,
          method: 'POST',
          body: queryArg.driver,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['drivers'],
      }),
      driversRetrieve: build.query<
        DriversRetrieveApiResponse,
        DriversRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/drivers/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['drivers'],
      }),
      driversPartialUpdate: build.mutation<
        DriversPartialUpdateApiResponse,
        DriversPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/drivers/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedDriver,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['drivers'],
      }),
      driversDestroy: build.mutation<
        DriversDestroyApiResponse,
        DriversDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/drivers/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['drivers'],
      }),
      filterGroupsList: build.query<
        FilterGroupsListApiResponse,
        FilterGroupsListApiArg
      >({
        query: (queryArg) => ({
          url: `/filter-groups/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['filters'],
      }),
      filterGroupsCreate: build.mutation<
        FilterGroupsCreateApiResponse,
        FilterGroupsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/filter-groups/`,
          method: 'POST',
          body: queryArg.filterGroup,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['filters'],
      }),
      filterGroupsRetrieve: build.query<
        FilterGroupsRetrieveApiResponse,
        FilterGroupsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/filter-groups/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['filters'],
      }),
      filterGroupsPartialUpdate: build.mutation<
        FilterGroupsPartialUpdateApiResponse,
        FilterGroupsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/filter-groups/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedFilterGroup,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['filters'],
      }),
      filterGroupsDestroy: build.mutation<
        FilterGroupsDestroyApiResponse,
        FilterGroupsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/filter-groups/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['filters'],
      }),
      filterGroupsCreateExamplesCreate: build.mutation<
        FilterGroupsCreateExamplesCreateApiResponse,
        FilterGroupsCreateExamplesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/filter-groups/create_examples/`,
          method: 'POST',
          body: queryArg.filterGroup,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['filters'],
      }),
      flagsFeatureValuesList: build.query<
        FlagsFeatureValuesListApiResponse,
        FlagsFeatureValuesListApiArg
      >({
        query: (queryArg) => ({
          url: `/flags/feature-values/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['flags'],
      }),
      flagsFeatureValuesMobileList: build.query<
        FlagsFeatureValuesMobileListApiResponse,
        FlagsFeatureValuesMobileListApiArg
      >({
        query: (queryArg) => ({
          url: `/flags/feature-values-mobile/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['flags'],
      }),
      flagsFeatureValuesWebList: build.query<
        FlagsFeatureValuesWebListApiResponse,
        FlagsFeatureValuesWebListApiArg
      >({
        query: (queryArg) => ({
          url: `/flags/feature-values-web/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['flags'],
      }),
      flagsFeaturesList: build.query<
        FlagsFeaturesListApiResponse,
        FlagsFeaturesListApiArg
      >({
        query: (queryArg) => ({
          url: `/flags/features/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['flags'],
      }),
      invoicesExportList: build.query<
        InvoicesExportListApiResponse,
        InvoicesExportListApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/export/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            created_at: queryArg.createdAt,
            format: queryArg.format,
            invoice: queryArg.invoice,
            invoice__in: queryArg.invoiceIn,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            trip__in: queryArg.tripIn,
            trip__payer: queryArg.tripPayer,
            trip__payer__in: queryArg.tripPayerIn,
          },
        }),
        providesTags: ['invoices'],
      }),
      invoicesInvoicesList: build.query<
        InvoicesInvoicesListApiResponse,
        InvoicesInvoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/invoices/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            created_at: queryArg.createdAt,
            date_issued: queryArg.dateIssued,
            date_issued_end: queryArg.dateIssuedEnd,
            date_issued_start: queryArg.dateIssuedStart,
            due_max: queryArg.dueMax,
            due_min: queryArg.dueMin,
            min_payments: queryArg.minPayments,
            ordering: queryArg.ordering,
            overall_payment_status__in: queryArg.overallPaymentStatusIn,
            page: queryArg.page,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            search: queryArg.search,
            total_amount_max: queryArg.totalAmountMax,
            total_amount_min: queryArg.totalAmountMin,
            trip_payer__in: queryArg.tripPayerIn,
          },
        }),
        providesTags: ['invoices'],
      }),
      invoicesInvoicesCreate: build.mutation<
        InvoicesInvoicesCreateApiResponse,
        InvoicesInvoicesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/invoices/`,
          method: 'POST',
          body: queryArg.invoice,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesInvoicesRetrieve: build.query<
        InvoicesInvoicesRetrieveApiResponse,
        InvoicesInvoicesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/invoices/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['invoices'],
      }),
      invoicesInvoicesPartialUpdate: build.mutation<
        InvoicesInvoicesPartialUpdateApiResponse,
        InvoicesInvoicesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/invoices/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedInvoice,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesInvoicesDestroy: build.mutation<
        InvoicesInvoicesDestroyApiResponse,
        InvoicesInvoicesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/invoices/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesLineItemsList: build.query<
        InvoicesLineItemsListApiResponse,
        InvoicesLineItemsListApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/line-items/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['invoices'],
      }),
      invoicesLineItemsCreate: build.mutation<
        InvoicesLineItemsCreateApiResponse,
        InvoicesLineItemsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/line-items/`,
          method: 'POST',
          body: queryArg.invoiceLineItem,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesLineItemsRetrieve: build.query<
        InvoicesLineItemsRetrieveApiResponse,
        InvoicesLineItemsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/line-items/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['invoices'],
      }),
      invoicesLineItemsPartialUpdate: build.mutation<
        InvoicesLineItemsPartialUpdateApiResponse,
        InvoicesLineItemsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/line-items/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedInvoiceLineItem,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesLineItemsDestroy: build.mutation<
        InvoicesLineItemsDestroyApiResponse,
        InvoicesLineItemsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/line-items/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesPaymentsList: build.query<
        InvoicesPaymentsListApiResponse,
        InvoicesPaymentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/payments/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['invoices'],
      }),
      invoicesPaymentsCreate: build.mutation<
        InvoicesPaymentsCreateApiResponse,
        InvoicesPaymentsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/payments/`,
          method: 'POST',
          body: queryArg.invoicePayment,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesPaymentsRetrieve: build.query<
        InvoicesPaymentsRetrieveApiResponse,
        InvoicesPaymentsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/payments/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['invoices'],
      }),
      invoicesRefundsList: build.query<
        InvoicesRefundsListApiResponse,
        InvoicesRefundsListApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/refunds/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['invoices'],
      }),
      invoicesRefundsCreate: build.mutation<
        InvoicesRefundsCreateApiResponse,
        InvoicesRefundsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/refunds/`,
          method: 'POST',
          body: queryArg.invoiceRefund,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['invoices'],
      }),
      invoicesRefundsRetrieve: build.query<
        InvoicesRefundsRetrieveApiResponse,
        InvoicesRefundsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/invoices/refunds/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['invoices'],
      }),
      locationsAddressesList: build.query<
        LocationsAddressesListApiResponse,
        LocationsAddressesListApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/addresses/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['locations'],
      }),
      locationsAddressesRetrieve: build.query<
        LocationsAddressesRetrieveApiResponse,
        LocationsAddressesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/addresses/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['locations'],
      }),
      locationsLogCreate: build.mutation<
        LocationsLogCreateApiResponse,
        LocationsLogCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/log/`,
          method: 'POST',
          body: queryArg.locationLogEntry,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['locations'],
      }),
      locationsMunicipalitiesRetrieve: build.query<
        LocationsMunicipalitiesRetrieveApiResponse,
        LocationsMunicipalitiesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/municipalities/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['locations'],
      }),
      locationsPassengerAddressesList: build.query<
        LocationsPassengerAddressesListApiResponse,
        LocationsPassengerAddressesListApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/passenger-addresses/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page, search: queryArg.search },
        }),
        providesTags: ['locations'],
      }),
      locationsPassengerAddressesRetrieve: build.query<
        LocationsPassengerAddressesRetrieveApiResponse,
        LocationsPassengerAddressesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/passenger-addresses/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['locations'],
      }),
      membersList: build.query<MembersListApiResponse, MembersListApiArg>({
        query: (queryArg) => ({
          url: `/members/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            deleted_objects: queryArg.deletedObjects,
            is_attendant: queryArg.isAttendant,
            is_driver: queryArg.isDriver,
            ordering: queryArg.ordering,
            page: queryArg.page,
            role: queryArg.role,
            search: queryArg.search,
          },
        }),
        providesTags: ['members'],
      }),
      membersCreate: build.mutation<
        MembersCreateApiResponse,
        MembersCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/members/`,
          method: 'POST',
          body: queryArg.membership,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['members'],
      }),
      membersRetrieve: build.query<
        MembersRetrieveApiResponse,
        MembersRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/members/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        providesTags: ['members'],
      }),
      membersPartialUpdate: build.mutation<
        MembersPartialUpdateApiResponse,
        MembersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/members/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedMembership,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['members'],
      }),
      membersDestroy: build.mutation<
        MembersDestroyApiResponse,
        MembersDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/members/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['members'],
      }),
      membersUndeleteCreate: build.mutation<
        MembersUndeleteCreateApiResponse,
        MembersUndeleteCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/members/${queryArg.id}/undelete/`,
          method: 'POST',
          body: queryArg.membership,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['members'],
      }),
      messagesList: build.query<MessagesListApiResponse, MessagesListApiArg>({
        query: (queryArg) => ({
          url: `/messages/`,
          params: { page: queryArg.page },
        }),
        providesTags: ['messages'],
      }),
      messagesCreate: build.mutation<
        MessagesCreateApiResponse,
        MessagesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/messages/`,
          method: 'POST',
          body: queryArg.message,
        }),
        invalidatesTags: ['messages'],
      }),
      messagesRetrieve: build.query<
        MessagesRetrieveApiResponse,
        MessagesRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/messages/${queryArg.id}/` }),
        providesTags: ['messages'],
      }),
      messagesTripMessagesList: build.query<
        MessagesTripMessagesListApiResponse,
        MessagesTripMessagesListApiArg
      >({
        query: (queryArg) => ({
          url: `/messages/trip-messages/`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            trip_id: queryArg.tripId,
          },
        }),
        providesTags: ['messages'],
      }),
      messagesTripMessagesCreate: build.mutation<
        MessagesTripMessagesCreateApiResponse,
        MessagesTripMessagesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/messages/trip-messages/`,
          method: 'POST',
          body: queryArg.tripMessage,
        }),
        invalidatesTags: ['messages'],
      }),
      messagesTripMessagesRetrieve: build.query<
        MessagesTripMessagesRetrieveApiResponse,
        MessagesTripMessagesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/messages/trip-messages/${queryArg.tripId}/`,
        }),
        providesTags: ['messages'],
      }),
      messagesWebHookMessageUpdateCreate: build.mutation<
        MessagesWebHookMessageUpdateCreateApiResponse,
        MessagesWebHookMessageUpdateCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/messages/web-hook/message-update/`,
          method: 'POST',
          body: queryArg.message,
        }),
        invalidatesTags: ['messages'],
      }),
      mobileAppSettingsRetrieve: build.query<
        MobileAppSettingsRetrieveApiResponse,
        MobileAppSettingsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/mobile-app-settings/`,
          headers: { 'User-Agent': queryArg['User-Agent'] },
        }),
        providesTags: ['user'],
      }),
      mobileAppSettingsPartialUpdate: build.mutation<
        MobileAppSettingsPartialUpdateApiResponse,
        MobileAppSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/mobile-app-settings/`,
          method: 'PATCH',
          body: queryArg.patchedMobileAppSettings,
          headers: { 'User-Agent': queryArg['User-Agent'] },
        }),
        invalidatesTags: ['user'],
      }),
      organizationBillingStatusRetrieve: build.query<
        OrganizationBillingStatusRetrieveApiResponse,
        OrganizationBillingStatusRetrieveApiArg
      >({
        query: () => ({ url: `/organization-billing-status/` }),
        providesTags: ['organization-billing-status'],
      }),
      organizationSettingsRetrieve: build.query<
        OrganizationSettingsRetrieveApiResponse,
        OrganizationSettingsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/organization-settings/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['organizations'],
      }),
      organizationSettingsPartialUpdate: build.mutation<
        OrganizationSettingsPartialUpdateApiResponse,
        OrganizationSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/organization-settings/`,
          method: 'PATCH',
          body: queryArg.patchedOrganizationSettings,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['organizations'],
      }),
      organizationSettingsConnectMerchantAccountCreate: build.mutation<
        OrganizationSettingsConnectMerchantAccountCreateApiResponse,
        OrganizationSettingsConnectMerchantAccountCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/organization-settings/connect-merchant-account/`,
          method: 'POST',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['organizations'],
      }),
      passengersList: build.query<
        PassengersListApiResponse,
        PassengersListApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['passengers'],
      }),
      passengersCreate: build.mutation<
        PassengersCreateApiResponse,
        PassengersCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/`,
          method: 'POST',
          body: queryArg.passenger,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['passengers'],
      }),
      passengersRetrieve: build.query<
        PassengersRetrieveApiResponse,
        PassengersRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['passengers'],
      }),
      passengersPartialUpdate: build.mutation<
        PassengersPartialUpdateApiResponse,
        PassengersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPassenger,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['passengers'],
      }),
      passengersDestroy: build.mutation<
        PassengersDestroyApiResponse,
        PassengersDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['passengers'],
      }),
      passengersRecentAddressesList: build.query<
        PassengersRecentAddressesListApiResponse,
        PassengersRecentAddressesListApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/${queryArg.id}/recent-addresses/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { max_results: queryArg.maxResults, page: queryArg.page },
        }),
        providesTags: ['passengers'],
      }),
      passengersRecentPayersList: build.query<
        PassengersRecentPayersListApiResponse,
        PassengersRecentPayersListApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/${queryArg.id}/recent-payers/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { max_results: queryArg.maxResults, page: queryArg.page },
        }),
        providesTags: ['passengers'],
      }),
      passengersDuplicatesList: build.query<
        PassengersDuplicatesListApiResponse,
        PassengersDuplicatesListApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/duplicates/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            created_at_end: queryArg.createdAtEnd,
            created_at_start: queryArg.createdAtStart,
            first_name_starts_with: queryArg.firstNameStartsWith,
            last_name_starts_with: queryArg.lastNameStartsWith,
            match_fields: queryArg.matchFields,
            page: queryArg.page,
            passenger__in: queryArg.passengerIn,
          },
        }),
        providesTags: ['passengers'],
      }),
      passengersMergeCreate: build.mutation<
        PassengersMergeCreateApiResponse,
        PassengersMergeCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/passengers/merge/`,
          method: 'POST',
          body: queryArg.passengerMergeRequest,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['passengers'],
      }),
      payerSettingsList: build.query<
        PayerSettingsListApiResponse,
        PayerSettingsListApiArg
      >({
        query: (queryArg) => ({
          url: `/payer-settings/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['payers'],
      }),
      payerSettingsRetrieve: build.query<
        PayerSettingsRetrieveApiResponse,
        PayerSettingsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payer-settings/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      payerSettingsPartialUpdate: build.mutation<
        PayerSettingsPartialUpdateApiResponse,
        PayerSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/payer-settings/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPayerOrganizationSettings,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payerinfoList: build.query<PayerinfoListApiResponse, PayerinfoListApiArg>(
        {
          query: (queryArg) => ({
            url: `/payerinfo/`,
            headers: { 'Organization-ID': queryArg['Organization-ID'] },
            params: {
              ordering: queryArg.ordering,
              page: queryArg.page,
              search: queryArg.search,
            },
          }),
          providesTags: ['payers'],
        }
      ),
      payerinfoRetrieve: build.query<
        PayerinfoRetrieveApiResponse,
        PayerinfoRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payerinfo/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      payerinfooneschematemplateList: build.query<
        PayerinfooneschematemplateListApiResponse,
        PayerinfooneschematemplateListApiArg
      >({
        query: (queryArg) => ({
          url: `/payerinfooneschematemplate/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['payers'],
      }),
      payersList: build.query<PayersListApiResponse, PayersListApiArg>({
        query: (queryArg) => ({
          url: `/payers/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            can_multiload: queryArg.canMultiload,
            id: queryArg.id,
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['payers'],
      }),
      payersCreate: build.mutation<PayersCreateApiResponse, PayersCreateApiArg>(
        {
          query: (queryArg) => ({
            url: `/payers/`,
            method: 'POST',
            body: queryArg.payerOrganization,
            headers: { 'Organization-ID': queryArg['Organization-ID'] },
          }),
          invalidatesTags: ['payers'],
        }
      ),
      payersPassengersRetrieve: build.query<
        PayersPassengersRetrieveApiResponse,
        PayersPassengersRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.payerOrganizationId}/passengers/${queryArg.passengerId}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      payersPassengersCreate: build.mutation<
        PayersPassengersCreateApiResponse,
        PayersPassengersCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.payerOrganizationId}/passengers/${queryArg.passengerId}/`,
          method: 'POST',
          body: queryArg.payerPassenger,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payersPassengersPartialUpdate: build.mutation<
        PayersPassengersPartialUpdateApiResponse,
        PayersPassengersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.payerOrganizationId}/passengers/${queryArg.passengerId}/`,
          method: 'PATCH',
          body: queryArg.patchedPayerPassenger,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payersPassengersDestroy: build.mutation<
        PayersPassengersDestroyApiResponse,
        PayersPassengersDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.payerOrganizationId}/passengers/${queryArg.passengerId}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payersSettingsRetrieve: build.query<
        PayersSettingsRetrieveApiResponse,
        PayersSettingsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.payerOrganizationId}/settings/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      payersSettingsPartialUpdate: build.mutation<
        PayersSettingsPartialUpdateApiResponse,
        PayersSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.payerOrganizationId}/settings/`,
          method: 'PATCH',
          body: queryArg.patchedPayerOrganizationSettings,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payersRetrieve: build.query<
        PayersRetrieveApiResponse,
        PayersRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      payersPartialUpdate: build.mutation<
        PayersPartialUpdateApiResponse,
        PayersPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPayerOrganization,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payersDestroy: build.mutation<
        PayersDestroyApiResponse,
        PayersDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['payers'],
      }),
      payersListSelected: build.query<
        PayersListSelectedApiResponse,
        PayersListSelectedApiArg
      >({
        query: (queryArg) => ({
          url: `/payers/list-selected/`,
          method: 'POST',
          body: queryArg.payerFilters,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            can_multiload: queryArg.canMultiload,
            id: queryArg.id,
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['payers'],
      }),
      payersandtemplatesRetrieve: build.query<
        PayersandtemplatesRetrieveApiResponse,
        PayersandtemplatesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payersandtemplates/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      payerstemplatesList: build.query<
        PayerstemplatesListApiResponse,
        PayerstemplatesListApiArg
      >({
        query: (queryArg) => ({
          url: `/payerstemplates/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['payers'],
      }),
      payerstemplatesRetrieve: build.query<
        PayerstemplatesRetrieveApiResponse,
        PayerstemplatesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/payerstemplates/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['payers'],
      }),
      pricingPriceEstimateCreate: build.mutation<
        PricingPriceEstimateCreateApiResponse,
        PricingPriceEstimateCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/price-estimate/`,
          method: 'POST',
          body: queryArg.pricing,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPriceQuoteCreate: build.mutation<
        PricingPriceQuoteCreateApiResponse,
        PricingPriceQuoteCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/price-quote/`,
          method: 'POST',
          body: queryArg.priceQuote,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingModelSelectionCreate: build.mutation<
        PricingPricingModelSelectionCreateApiResponse,
        PricingPricingModelSelectionCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-model-selection/`,
          method: 'POST',
          body: queryArg.pricingModelSelection,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingModelsList: build.query<
        PricingPricingModelsListApiResponse,
        PricingPricingModelsListApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-models/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page, search: queryArg.search },
        }),
        providesTags: ['pricing'],
      }),
      pricingPricingModelsCreate: build.mutation<
        PricingPricingModelsCreateApiResponse,
        PricingPricingModelsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-models/`,
          method: 'POST',
          body: queryArg.pricingModel,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingModelsRetrieve: build.query<
        PricingPricingModelsRetrieveApiResponse,
        PricingPricingModelsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-models/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['pricing'],
      }),
      pricingPricingModelsPartialUpdate: build.mutation<
        PricingPricingModelsPartialUpdateApiResponse,
        PricingPricingModelsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-models/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPricingModel,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingModelsDestroy: build.mutation<
        PricingPricingModelsDestroyApiResponse,
        PricingPricingModelsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-models/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingRulesPartialUpdate: build.mutation<
        PricingPricingRulesPartialUpdateApiResponse,
        PricingPricingRulesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-rules/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPricingRuleSelection,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingRulesDestroy: build.mutation<
        PricingPricingRulesDestroyApiResponse,
        PricingPricingRulesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-rules/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingSchemeConflictsCreate: build.mutation<
        PricingPricingSchemeConflictsCreateApiResponse,
        PricingPricingSchemeConflictsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-scheme-conflicts/`,
          method: 'POST',
          body: queryArg.priceSchemeConflict,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingSchemesList: build.query<
        PricingPricingSchemesListApiResponse,
        PricingPricingSchemesListApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-schemes/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            payer__in: queryArg.payerIn,
            pricing_scheme_payers__payer_id:
              queryArg.pricingSchemePayersPayerId,
            search: queryArg.search,
          },
        }),
        providesTags: ['pricing'],
      }),
      pricingPricingSchemesCreate: build.mutation<
        PricingPricingSchemesCreateApiResponse,
        PricingPricingSchemesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-schemes/`,
          method: 'POST',
          body: queryArg.pricingScheme,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingSchemesRetrieve: build.query<
        PricingPricingSchemesRetrieveApiResponse,
        PricingPricingSchemesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-schemes/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['pricing'],
      }),
      pricingPricingSchemesPartialUpdate: build.mutation<
        PricingPricingSchemesPartialUpdateApiResponse,
        PricingPricingSchemesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-schemes/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPricingScheme,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingSchemesDestroy: build.mutation<
        PricingPricingSchemesDestroyApiResponse,
        PricingPricingSchemesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-schemes/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingTypesList: build.query<
        PricingPricingTypesListApiResponse,
        PricingPricingTypesListApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-types/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['pricing'],
      }),
      pricingPricingTypesCreate: build.mutation<
        PricingPricingTypesCreateApiResponse,
        PricingPricingTypesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-types/`,
          method: 'POST',
          body: queryArg.pricingTypeSelection,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingTypesRetrieve: build.query<
        PricingPricingTypesRetrieveApiResponse,
        PricingPricingTypesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-types/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['pricing'],
      }),
      pricingPricingTypesPartialUpdate: build.mutation<
        PricingPricingTypesPartialUpdateApiResponse,
        PricingPricingTypesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-types/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedPricingTypeSelection,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      pricingPricingTypesDestroy: build.mutation<
        PricingPricingTypesDestroyApiResponse,
        PricingPricingTypesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/pricing/pricing-types/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['pricing'],
      }),
      profileRetrieve: build.query<
        ProfileRetrieveApiResponse,
        ProfileRetrieveApiArg
      >({
        query: () => ({ url: `/profile/` }),
        providesTags: ['user'],
      }),
      runBambiRunCreate: build.mutation<
        RunBambiRunCreateApiResponse,
        RunBambiRunCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/run-bambi-run/`,
          method: 'POST',
          body: queryArg.runBambiRun,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      runBambiRunRetrieve: build.query<
        RunBambiRunRetrieveApiResponse,
        RunBambiRunRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/run-bambi-run/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['run-bambi-run'],
      }),
      runBambiRunApplyCreate: build.mutation<
        RunBambiRunApplyCreateApiResponse,
        RunBambiRunApplyCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/run-bambi-run/${queryArg.id}/apply/`,
          method: 'POST',
          body: queryArg.runBambiRunApply,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      signaturesList: build.query<
        SignaturesListApiResponse,
        SignaturesListApiArg
      >({
        query: (queryArg) => ({
          url: `/signatures/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['signatures'],
      }),
      signaturesCreate: build.mutation<
        SignaturesCreateApiResponse,
        SignaturesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/signatures/`,
          method: 'POST',
          body: queryArg.signature,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['signatures'],
      }),
      signaturesRetrieve: build.query<
        SignaturesRetrieveApiResponse,
        SignaturesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/signatures/${queryArg.id}/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        providesTags: ['signatures'],
      }),
      signaturesDestroy: build.mutation<
        SignaturesDestroyApiResponse,
        SignaturesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/signatures/${queryArg.id}/`,
          method: 'DELETE',
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['signatures'],
      }),
      tagsList: build.query<TagsListApiResponse, TagsListApiArg>({
        query: (queryArg) => ({
          url: `/tags/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['tags'],
      }),
      tagsCreate: build.mutation<TagsCreateApiResponse, TagsCreateApiArg>({
        query: (queryArg) => ({
          url: `/tags/`,
          method: 'POST',
          body: queryArg.tag,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      tagsRetrieve: build.query<TagsRetrieveApiResponse, TagsRetrieveApiArg>({
        query: (queryArg) => ({
          url: `/tags/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['tags'],
      }),
      tagsPartialUpdate: build.mutation<
        TagsPartialUpdateApiResponse,
        TagsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/tags/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedTag,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      tagsDestroy: build.mutation<TagsDestroyApiResponse, TagsDestroyApiArg>({
        query: (queryArg) => ({
          url: `/tags/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      tilledPaymentMethodRetrieve: build.query<
        TilledPaymentMethodRetrieveApiResponse,
        TilledPaymentMethodRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/tilled/payment-method/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            payer_id: queryArg.payerId,
            payer_type: queryArg.payerType,
          },
        }),
        providesTags: ['tilled'],
      }),
      tilledPaymentMethodCreate: build.mutation<
        TilledPaymentMethodCreateApiResponse,
        TilledPaymentMethodCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/tilled/payment-method/`,
          method: 'POST',
          body: queryArg.tilledPaymentMethodInput,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tilled'],
      }),
      tilledPaymentMethodDestroy: build.mutation<
        TilledPaymentMethodDestroyApiResponse,
        TilledPaymentMethodDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/tilled/payment-method/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { payment_method_id: queryArg.paymentMethodId },
        }),
        invalidatesTags: ['tilled'],
      }),
      tilledPayoutsList: build.query<
        TilledPayoutsListApiResponse,
        TilledPayoutsListApiArg
      >({
        query: (queryArg) => ({
          url: `/tilled/payouts/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['tilled'],
      }),
      tilledWebhookCreate: build.mutation<
        TilledWebhookCreateApiResponse,
        TilledWebhookCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/tilled/webhook/`,
          method: 'POST',
          body: queryArg.tilledWebHookEnvelope,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tilled'],
      }),
      tokensChatIssueRetrieve: build.query<
        TokensChatIssueRetrieveApiResponse,
        TokensChatIssueRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/tokens/chat/issue/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['members'],
      }),
      tokensPushRegisterCreate: build.mutation<
        TokensPushRegisterCreateApiResponse,
        TokensPushRegisterCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/tokens/push/register/`,
          method: 'POST',
          body: queryArg.pushTokens,
          headers: {
            'Organization-ID': queryArg['Organization-ID'],
            'User-Agent': queryArg['User-Agent'],
          },
        }),
        invalidatesTags: ['members'],
      }),
      tokensPushRevokeCreate: build.mutation<
        TokensPushRevokeCreateApiResponse,
        TokensPushRevokeCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/tokens/push/revoke/`,
          method: 'POST',
          body: queryArg.pushTokens,
          headers: {
            'Organization-ID': queryArg['Organization-ID'],
            'User-Agent': queryArg['User-Agent'],
          },
        }),
        invalidatesTags: ['members'],
      }),
      tripImportCreate: build.mutation<
        TripImportCreateApiResponse,
        TripImportCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trip-import/`,
          method: 'POST',
          body: queryArg.tripImportAsyncRequest,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trip-import'],
      }),
      tripImportRetrieve: build.query<
        TripImportRetrieveApiResponse,
        TripImportRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trip-import/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['trip-import'],
      }),
      tripTagsList: build.query<TripTagsListApiResponse, TripTagsListApiArg>({
        query: (queryArg) => ({
          url: `/trip-tags/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['tags'],
      }),
      tripTagsCreate: build.mutation<
        TripTagsCreateApiResponse,
        TripTagsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trip-tags/`,
          method: 'POST',
          body: queryArg.tripTagBulkCreate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      tripTagsRetrieve: build.query<
        TripTagsRetrieveApiResponse,
        TripTagsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trip-tags/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['tags'],
      }),
      tripTagsDestroy: build.mutation<
        TripTagsDestroyApiResponse,
        TripTagsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/trip-tags/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      tripsList: build.query<TripsListApiResponse, TripsListApiArg>({
        query: (queryArg) => ({
          url: `/trips/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            driver__in: queryArg.driverIn,
            dropoff_municipality__in: queryArg.dropoffMunicipalityIn,
            external_trip_id__in: queryArg.externalTripIdIn,
            has_invoice: queryArg.hasInvoice,
            id__in: queryArg.idIn,
            not_tag__in: queryArg.notTagIn,
            ordering: queryArg.ordering,
            page: queryArg.page,
            passenger__in: queryArg.passengerIn,
            passengers: queryArg.passengers,
            payer__in: queryArg.payerIn,
            payers: queryArg.payers,
            pickup_municipality__in: queryArg.pickupMunicipalityIn,
            price_max: queryArg.priceMax,
            price_min: queryArg.priceMin,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            search: queryArg.search,
            status: queryArg.status,
            status__in: queryArg.statusIn,
            tag__in: queryArg.tagIn,
            tags: queryArg.tags,
            vehicle_id__in: queryArg.vehicleIdIn,
            vehicles: queryArg.vehicles,
          },
        }),
        providesTags: ['trips'],
      }),
      tripsCreate: build.mutation<TripsCreateApiResponse, TripsCreateApiArg>({
        query: (queryArg) => ({
          url: `/trips/`,
          method: 'POST',
          body: queryArg.tripsBulkCreate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsRetrieve: build.query<TripsRetrieveApiResponse, TripsRetrieveApiArg>(
        {
          query: (queryArg) => ({
            url: `/trips/${queryArg.id}/`,
            headers: { 'Organization-ID': queryArg['Organization-ID'] },
          }),
          providesTags: ['trips'],
        }
      ),
      tripsPartialUpdate: build.mutation<
        TripsPartialUpdateApiResponse,
        TripsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedTrip,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsDestroy: build.mutation<TripsDestroyApiResponse, TripsDestroyApiArg>(
        {
          query: (queryArg) => ({
            url: `/trips/${queryArg.id}/`,
            method: 'DELETE',
            headers: { 'Organization-ID': queryArg['Organization-ID'] },
          }),
          invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
        }
      ),
      tripsAcceptPartialUpdate: build.mutation<
        TripsAcceptPartialUpdateApiResponse,
        TripsAcceptPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/accept/`,
          method: 'PATCH',
          body: queryArg.patchedTripAccept,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsActivateWillCallPartialUpdate: build.mutation<
        TripsActivateWillCallPartialUpdateApiResponse,
        TripsActivateWillCallPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/activate-will-call/`,
          method: 'PATCH',
          body: queryArg.patchedTripActivateWillCall,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsAssignPartialUpdate: build.mutation<
        TripsAssignPartialUpdateApiResponse,
        TripsAssignPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/assign/`,
          method: 'PATCH',
          body: queryArg.patchedTripAssign,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsCancelPartialUpdate: build.mutation<
        TripsCancelPartialUpdateApiResponse,
        TripsCancelPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/cancel/`,
          method: 'PATCH',
          body: queryArg.patchedTripCancel,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsCancelCodesRetrieve: build.query<
        TripsCancelCodesRetrieveApiResponse,
        TripsCancelCodesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/cancel-codes/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        providesTags: ['trips'],
      }),
      tripsCancelReasonsRetrieve: build.query<
        TripsCancelReasonsRetrieveApiResponse,
        TripsCancelReasonsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/cancel-reasons/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        providesTags: ['trips'],
      }),
      tripsCompletePartialUpdate: build.mutation<
        TripsCompletePartialUpdateApiResponse,
        TripsCompletePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/complete/`,
          method: 'PATCH',
          body: queryArg.patchedTripComplete,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsCorrelatedList: build.query<
        TripsCorrelatedListApiResponse,
        TripsCorrelatedListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/correlated/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsCreatesList: build.query<
        TripsCreatesListApiResponse,
        TripsCreatesListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/creates/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsDriverEnteredNotesPartialUpdate: build.mutation<
        TripsDriverEnteredNotesPartialUpdateApiResponse,
        TripsDriverEnteredNotesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/driver-entered-notes/`,
          method: 'PATCH',
          body: queryArg.patchedTripUpdateDriverEnteredNotes,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsEventsList: build.query<
        TripsEventsListApiResponse,
        TripsEventsListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/events/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsLocationsList: build.query<
        TripsLocationsListApiResponse,
        TripsLocationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/locations/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsLockPartialUpdate: build.mutation<
        TripsLockPartialUpdateApiResponse,
        TripsLockPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/lock/`,
          method: 'PATCH',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsOverrideStatusPartialUpdate: build.mutation<
        TripsOverrideStatusPartialUpdateApiResponse,
        TripsOverrideStatusPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/override-status/`,
          method: 'PATCH',
          body: queryArg.patchedTripOverrideStatusChange,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsPriceSummaryRetrieve: build.query<
        TripsPriceSummaryRetrieveApiResponse,
        TripsPriceSummaryRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/trips/${queryArg.id}/price-summary/` }),
        providesTags: ['trips'],
      }),
      tripsReassignPartialUpdate: build.mutation<
        TripsReassignPartialUpdateApiResponse,
        TripsReassignPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/reassign/`,
          method: 'PATCH',
          body: queryArg.patchedTripReassign,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsRejectPartialUpdate: build.mutation<
        TripsRejectPartialUpdateApiResponse,
        TripsRejectPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/reject/`,
          method: 'PATCH',
          body: queryArg.patchedTripReject,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsRejectCodesRetrieve: build.query<
        TripsRejectCodesRetrieveApiResponse,
        TripsRejectCodesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/reject-codes/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        providesTags: ['trips'],
      }),
      tripsRejectReasonsRetrieve: build.query<
        TripsRejectReasonsRetrieveApiResponse,
        TripsRejectReasonsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/reject-reasons/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        providesTags: ['trips'],
      }),
      tripsRelatedList: build.query<
        TripsRelatedListApiResponse,
        TripsRelatedListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/related/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsSignaturesList: build.query<
        TripsSignaturesListApiResponse,
        TripsSignaturesListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/signatures/`,
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsStatusPartialUpdate: build.mutation<
        TripsStatusPartialUpdateApiResponse,
        TripsStatusPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/status/`,
          method: 'PATCH',
          body: queryArg.patchedTripStatusChange,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsSummaryRetrieve: build.query<
        TripsSummaryRetrieveApiResponse,
        TripsSummaryRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/trips/${queryArg.id}/summary/` }),
        providesTags: ['trips'],
      }),
      tripsTurnbackReasonsRetrieve: build.query<
        TripsTurnbackReasonsRetrieveApiResponse,
        TripsTurnbackReasonsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/turnback-reasons/`,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        providesTags: ['trips'],
      }),
      tripsUnassignPartialUpdate: build.mutation<
        TripsUnassignPartialUpdateApiResponse,
        TripsUnassignPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/unassign/`,
          method: 'PATCH',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsUncancelPartialUpdate: build.mutation<
        TripsUncancelPartialUpdateApiResponse,
        TripsUncancelPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/uncancel/`,
          method: 'PATCH',
          body: queryArg.patchedTripUncancel,
          headers: {
            'Device-Requested-At': queryArg['Device-Requested-At'],
            Latitude: queryArg.latitude,
            'Location-Queried-At': queryArg['Location-Queried-At'],
            Longitude: queryArg.longitude,
            'Organization-ID': queryArg['Organization-ID'],
          },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsUnlockPartialUpdate: build.mutation<
        TripsUnlockPartialUpdateApiResponse,
        TripsUnlockPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/unlock/`,
          method: 'PATCH',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsUnrejectPartialUpdate: build.mutation<
        TripsUnrejectPartialUpdateApiResponse,
        TripsUnrejectPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/unreject/`,
          method: 'PATCH',
          body: queryArg.patchedTripUnreject,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsUpdatePickupAtPartialUpdate: build.mutation<
        TripsUpdatePickupAtPartialUpdateApiResponse,
        TripsUpdatePickupAtPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/update-pickup-at/`,
          method: 'PATCH',
          body: queryArg.patchedTripUpdatePickupAt,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsUpdatesList: build.query<
        TripsUpdatesListApiResponse,
        TripsUpdatesListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/updates/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsValidatePartialUpdate: build.mutation<
        TripsValidatePartialUpdateApiResponse,
        TripsValidatePartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/${queryArg.id}/validate/`,
          method: 'PATCH',
          body: queryArg.patchedTripValidate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsAllList: build.query<TripsAllListApiResponse, TripsAllListApiArg>({
        query: (queryArg) => ({
          url: `/trips/all/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            driver__in: queryArg.driverIn,
            dropoff_municipality__in: queryArg.dropoffMunicipalityIn,
            external_trip_id__in: queryArg.externalTripIdIn,
            has_invoice: queryArg.hasInvoice,
            id__in: queryArg.idIn,
            not_tag__in: queryArg.notTagIn,
            ordering: queryArg.ordering,
            page: queryArg.page,
            passenger__in: queryArg.passengerIn,
            passengers: queryArg.passengers,
            payer__in: queryArg.payerIn,
            payers: queryArg.payers,
            pickup_municipality__in: queryArg.pickupMunicipalityIn,
            price_max: queryArg.priceMax,
            price_min: queryArg.priceMin,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            search: queryArg.search,
            status: queryArg.status,
            status__in: queryArg.statusIn,
            tag__in: queryArg.tagIn,
            tags: queryArg.tags,
            vehicle_id__in: queryArg.vehicleIdIn,
            vehicles: queryArg.vehicles,
          },
        }),
        providesTags: ['trips'],
      }),
      tripsBulkAcceptCreate: build.mutation<
        TripsBulkAcceptCreateApiResponse,
        TripsBulkAcceptCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-accept/`,
          method: 'POST',
          body: queryArg.bulkTripAccept,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsBulkAssignCreate: build.mutation<
        TripsBulkAssignCreateApiResponse,
        TripsBulkAssignCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-assign/`,
          method: 'POST',
          body: queryArg.bulkTripAssign,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsBulkCancelCreate: build.mutation<
        TripsBulkCancelCreateApiResponse,
        TripsBulkCancelCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-cancel/`,
          method: 'POST',
          body: queryArg.bulkTripCancel,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsBulkCancelReasonsCreate: build.query<
        TripsBulkCancelReasonsCreateApiResponse,
        TripsBulkCancelReasonsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-cancel-reasons/`,
          method: 'POST',
          body: queryArg.bulkTripCancelReasons,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['trips'],
      }),
      tripsBulkRejectCreate: build.mutation<
        TripsBulkRejectCreateApiResponse,
        TripsBulkRejectCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-reject/`,
          method: 'POST',
          body: queryArg.bulkTripReject,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsBulkUnassignCreate: build.mutation<
        TripsBulkUnassignCreateApiResponse,
        TripsBulkUnassignCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-unassign/`,
          method: 'POST',
          body: queryArg.bulkTripUnassign,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsBulkValidateCreate: build.mutation<
        TripsBulkValidateCreateApiResponse,
        TripsBulkValidateCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/bulk-validate/`,
          method: 'POST',
          body: queryArg.bulkTripValidate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsExportList: build.query<
        TripsExportListApiResponse,
        TripsExportListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/export/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            driver__in: queryArg.driverIn,
            dropoff_municipality__in: queryArg.dropoffMunicipalityIn,
            external_trip_id__in: queryArg.externalTripIdIn,
            format: queryArg.format,
            has_invoice: queryArg.hasInvoice,
            id__in: queryArg.idIn,
            not_tag__in: queryArg.notTagIn,
            output_set: queryArg.outputSet,
            passenger__in: queryArg.passengerIn,
            passengers: queryArg.passengers,
            payer__in: queryArg.payerIn,
            payers: queryArg.payers,
            pickup_municipality__in: queryArg.pickupMunicipalityIn,
            price_max: queryArg.priceMax,
            price_min: queryArg.priceMin,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            status: queryArg.status,
            status__in: queryArg.statusIn,
            tag__in: queryArg.tagIn,
            tags: queryArg.tags,
            vehicle_id__in: queryArg.vehicleIdIn,
            vehicles: queryArg.vehicles,
          },
        }),
        providesTags: ['trips'],
      }),
      tripsImportCreate: build.mutation<
        TripsImportCreateApiResponse,
        TripsImportCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/import/`,
          method: 'POST',
          body: queryArg.tripImportViewSet,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsMultiloadList: build.query<
        TripsMultiloadListApiResponse,
        TripsMultiloadListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/multiload/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['trips'],
      }),
      tripsMultiloadCreate: build.mutation<
        TripsMultiloadCreateApiResponse,
        TripsMultiloadCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/multiload/`,
          method: 'POST',
          body: queryArg.multiload,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsMultiloadRetrieve: build.query<
        TripsMultiloadRetrieveApiResponse,
        TripsMultiloadRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/multiload/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['trips'],
      }),
      tripsMultiloadPartialUpdate: build.mutation<
        TripsMultiloadPartialUpdateApiResponse,
        TripsMultiloadPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/multiload/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedMultiload,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsMultiloadDestroy: build.mutation<
        TripsMultiloadDestroyApiResponse,
        TripsMultiloadDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/multiload/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsMultiloadMobileRetrieve: build.query<
        TripsMultiloadMobileRetrieveApiResponse,
        TripsMultiloadMobileRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/multiload/${queryArg.id}/mobile/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['trips'],
      }),
      tripsRequestedCountRetrieve: build.query<
        TripsRequestedCountRetrieveApiResponse,
        TripsRequestedCountRetrieveApiArg
      >({
        query: () => ({ url: `/trips/requested-count/` }),
        providesTags: ['trips'],
      }),
      tripsSubscriptionsList: build.query<
        TripsSubscriptionsListApiResponse,
        TripsSubscriptionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/subscriptions/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            payers: queryArg.payers,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            search: queryArg.search,
          },
        }),
        providesTags: ['trips'],
      }),
      tripsSubscriptionsCreate: build.mutation<
        TripsSubscriptionsCreateApiResponse,
        TripsSubscriptionsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/subscriptions/`,
          method: 'POST',
          body: queryArg.tripSubscription,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsSubscriptionsPartialUpdate: build.mutation<
        TripsSubscriptionsPartialUpdateApiResponse,
        TripsSubscriptionsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/subscriptions/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedTripSubscription,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsSubscriptionsDestroy: build.mutation<
        TripsSubscriptionsDestroyApiResponse,
        TripsSubscriptionsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/subscriptions/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { delete: queryArg['delete'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      tripsSuggestedPickupAtCreate: build.mutation<
        TripsSuggestedPickupAtCreateApiResponse,
        TripsSuggestedPickupAtCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/trips/suggested-pickup-at/`,
          method: 'POST',
          body: queryArg.tripSuggestedPickupTime,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['trips', 'dispatch', 'run-bambi-run'],
      }),
      vehicleTagsList: build.query<
        VehicleTagsListApiResponse,
        VehicleTagsListApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicle-tags/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['tags'],
      }),
      vehicleTagsCreate: build.mutation<
        VehicleTagsCreateApiResponse,
        VehicleTagsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicle-tags/`,
          method: 'POST',
          body: queryArg.vehicleTagBulkCreate,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      vehicleTagsRetrieve: build.query<
        VehicleTagsRetrieveApiResponse,
        VehicleTagsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicle-tags/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['tags'],
      }),
      vehicleTagsDestroy: build.mutation<
        VehicleTagsDestroyApiResponse,
        VehicleTagsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicle-tags/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['tags'],
      }),
      vehiclesList: build.query<VehiclesListApiResponse, VehiclesListApiArg>({
        query: (queryArg) => ({
          url: `/vehicles/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            can_be_scheduled: queryArg.canBeScheduled,
            category: queryArg.category,
            deleted_objects: queryArg.deletedObjects,
            ordering: queryArg.ordering,
            page: queryArg.page,
            search: queryArg.search,
          },
        }),
        providesTags: ['vehicles'],
      }),
      vehiclesCreate: build.mutation<
        VehiclesCreateApiResponse,
        VehiclesCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/`,
          method: 'POST',
          body: queryArg.vehicle,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['vehicles'],
      }),
      vehiclesRetrieve: build.query<
        VehiclesRetrieveApiResponse,
        VehiclesRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        providesTags: ['vehicles'],
      }),
      vehiclesPartialUpdate: build.mutation<
        VehiclesPartialUpdateApiResponse,
        VehiclesPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/${queryArg.id}/`,
          method: 'PATCH',
          body: queryArg.patchedVehicle,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['vehicles'],
      }),
      vehiclesDestroy: build.mutation<
        VehiclesDestroyApiResponse,
        VehiclesDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/${queryArg.id}/`,
          method: 'DELETE',
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { deleted_objects: queryArg.deletedObjects },
        }),
        invalidatesTags: ['vehicles'],
      }),
      vehiclesInspectionCreate: build.mutation<
        VehiclesInspectionCreateApiResponse,
        VehiclesInspectionCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/inspection/`,
          method: 'POST',
          body: queryArg.vehicleInspectionReport,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        invalidatesTags: ['vehicles'],
      }),
      vehiclesInspectionFieldsList: build.query<
        VehiclesInspectionFieldsListApiResponse,
        VehiclesInspectionFieldsListApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/inspection-fields/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: { page: queryArg.page },
        }),
        providesTags: ['vehicles'],
      }),
      vehiclesInspectionsList: build.query<
        VehiclesInspectionsListApiResponse,
        VehiclesInspectionsListApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/inspections/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
          params: {
            assignment: queryArg.assignment,
            created_at: queryArg.createdAt,
            has_fail: queryArg.hasFail,
            membership: queryArg.membership,
            membership__in: queryArg.membershipIn,
            page: queryArg.page,
            range_end: queryArg.rangeEnd,
            range_start: queryArg.rangeStart,
            vehicle: queryArg.vehicle,
            vehicle__in: queryArg.vehicleIn,
          },
        }),
        providesTags: ['vehicles'],
      }),
      vehiclesInspectionsRetrieve: build.query<
        VehiclesInspectionsRetrieveApiResponse,
        VehiclesInspectionsRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/vehicles/inspections/${queryArg.id}/`,
          headers: { 'Organization-ID': queryArg['Organization-ID'] },
        }),
        providesTags: ['vehicles'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as bambiApi };
export type AssignmentsListApiResponse =
  /** status 200  */ PaginatedAssignmentListRead;
export type AssignmentsListApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  rangeEnd?: string;
  rangeStart?: string;
  /** A search term. */
  search?: string;
  unassigned?: boolean;
};
export type AssignmentsCreateApiResponse =
  /** status 201  */ AssignmentBulkCreateRead;
export type AssignmentsCreateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  assignmentBulkCreate: AssignmentBulkCreate;
};
export type AssignmentsRetrieveApiResponse = /** status 200  */ AssignmentRead;
export type AssignmentsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this assignment. */
  id: string;
};
export type AssignmentsPartialUpdateApiResponse =
  /** status 200  */ AssignmentRead;
export type AssignmentsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this assignment. */
  id: string;
  patchedAssignment: PatchedAssignment;
};
export type AssignmentsDestroyApiResponse =
  /** status 204 No response body */ void;
export type AssignmentsDestroyApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this assignment. */
  id: string;
};
export type AssignmentsBreaksListApiResponse =
  /** status 200  */ PaginatedAssignmentBreakListRead;
export type AssignmentsBreaksListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type AssignmentsBreaksCreateApiResponse =
  /** status 201  */ AssignmentBreakRead;
export type AssignmentsBreaksCreateApiArg = {
  'Organization-ID'?: string;
  id: string;
  assignmentBreak: AssignmentBreak;
};
export type AssignmentsBreaksRetrieveApiResponse =
  /** status 200  */ AssignmentBreakRead;
export type AssignmentsBreaksRetrieveApiArg = {
  'Organization-ID'?: string;
  breakId: string;
  id: string;
};
export type AssignmentsBreaksPartialUpdateApiResponse =
  /** status 200  */ AssignmentBreakRead;
export type AssignmentsBreaksPartialUpdateApiArg = {
  'Organization-ID'?: string;
  breakId: string;
  id: string;
  patchedAssignmentBreak: PatchedAssignmentBreak;
};
export type AssignmentsBreaksDestroyApiResponse =
  /** status 204 No response body */ void;
export type AssignmentsBreaksDestroyApiArg = {
  'Organization-ID'?: string;
  breakId: string;
  id: string;
};
export type AssignmentsBreaksActiveListApiResponse =
  /** status 200  */ PaginatedAssignmentBreakListRead;
export type AssignmentsBreaksActiveListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type AssignmentsDriverCallRequestsCreateApiResponse =
  /** status 201  */ DriverCallRequestRead;
export type AssignmentsDriverCallRequestsCreateApiArg = {
  'Organization-ID'?: string;
  id: string;
  driverCallRequest: DriverCallRequest;
};
export type AssignmentsDriverCallRequestsCancelPartialUpdateApiResponse =
  /** status 200  */ DriverCallRequestRead;
export type AssignmentsDriverCallRequestsCancelPartialUpdateApiArg = {
  'Organization-ID'?: string;
  driverCallRequestId: string;
  id: string;
  patchedDriverCallRequest: PatchedDriverCallRequest;
};
export type AssignmentsDriverCallRequestsResolvePartialUpdateApiResponse =
  /** status 200  */ DriverCallRequestRead;
export type AssignmentsDriverCallRequestsResolvePartialUpdateApiArg = {
  'Organization-ID'?: string;
  driverCallRequestId: string;
  id: string;
  patchedDriverCallRequest: PatchedDriverCallRequest;
};
export type AssignmentsEventsListApiResponse =
  /** status 200  */ PaginatedAssignmentEventListRead;
export type AssignmentsEventsListApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type AssignmentsEventsCreateApiResponse =
  /** status 201  */ AssignmentEventRead;
export type AssignmentsEventsCreateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  assignmentEvent: AssignmentEvent;
};
export type AssignmentsTripsListApiResponse =
  /** status 200  */ PaginatedAssignmentTripListRead;
export type AssignmentsTripsListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type AssignmentsTripsActiveListApiResponse =
  /** status 200  */ PaginatedAssignmentTripListRead;
export type AssignmentsTripsActiveListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type AssignmentsAssignedMembershipsPartialUpdateApiResponse =
  /** status 200  */ AssignedMembershipRead;
export type AssignmentsAssignedMembershipsPartialUpdateApiArg = {
  id: string;
  patchedAssignedMembership: PatchedAssignedMembership;
};
export type AssignmentsBreaksEventsListApiResponse =
  /** status 200  */ PaginatedAssignmentBreakEventListRead;
export type AssignmentsBreaksEventsListApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type AssignmentsBreaksEventsCreateApiResponse =
  /** status 201  */ AssignmentBreakEventRead;
export type AssignmentsBreaksEventsCreateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  assignmentBreakEvent: AssignmentBreakEvent;
};
export type AssignmentsBulkDefaultVehicleDriverListApiResponse =
  /** status 200  */ PaginatedBulkAssignmentDefaultListRead;
export type AssignmentsBulkDefaultVehicleDriverListApiArg = {
  'Organization-ID'?: string;
  attendantIds?: string;
  driverId: string;
  /** A page number within the paginated result set. */
  page?: number;
  vehicleId: string;
};
export type AssignmentsDriverRetrieveApiResponse =
  /** status 200  */ AssignmentRead;
export type AssignmentsDriverRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type AssignmentsDriverListListApiResponse =
  /** status 200  */ PaginatedAssignmentListRead;
export type AssignmentsDriverListListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
  rangeEnd?: string;
  rangeStart?: string;
};
export type AssignmentsExportListApiResponse = unknown;
export type AssignmentsExportListApiArg = {
  'Organization-ID'?: string;
  format?: 'csv' | 'xlsx';
  rangeEnd?: string;
  rangeStart?: string;
  unassigned?: boolean;
};
export type AssignmentsLocationsListApiResponse =
  /** status 200  */ PaginatedAssignmentLocationListRead;
export type AssignmentsLocationsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
  rangeEnd?: string;
  rangeStart?: string;
};
export type AssignmentsMemberListListApiResponse =
  /** status 200  */ PaginatedAssignmentListRead;
export type AssignmentsMemberListListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
  rangeEnd?: string;
  rangeStart?: string;
};
export type AssignmentsTimeRangeCreateApiResponse =
  /** status 201  */ AssignmentTimeRangeBulkCreateRead;
export type AssignmentsTimeRangeCreateApiArg = {
  'Organization-ID'?: string;
  assignmentTimeRangeBulkCreate: AssignmentTimeRangeBulkCreateWrite;
};
export type AttendantsListApiResponse =
  /** status 200  */ PaginatedAttendantListRead;
export type AttendantsListApiArg = {
  'Organization-ID'?: string;
  canBeScheduled?: boolean;
  id?: string;
  /** Sort attendants by an attribute */
  ordering?: 'can_be_scheduled' | 'first_name';
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type AttendantsCreateApiResponse = /** status 201  */ AttendantRead;
export type AttendantsCreateApiArg = {
  'Organization-ID'?: string;
  attendant: AttendantWrite;
};
export type AttendantsRetrieveApiResponse = /** status 200  */ AttendantRead;
export type AttendantsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this attendant. */
  id: string;
};
export type AttendantsPartialUpdateApiResponse =
  /** status 200  */ AttendantRead;
export type AttendantsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this attendant. */
  id: string;
  patchedAttendant: PatchedAttendantWrite;
};
export type AttendantsDestroyApiResponse =
  /** status 204 No response body */ void;
export type AttendantsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this attendant. */
  id: string;
};
export type AttendantsSkillsCreateApiResponse =
  /** status 201  */ AttendantSkills;
export type AttendantsSkillsCreateApiArg = {
  'Organization-ID'?: string;
  attendantSkills: AttendantSkillsWrite;
};
export type AttendantsSkillsRetrieveApiResponse =
  /** status 200  */ AttendantSkills;
export type AttendantsSkillsRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type AttendantsSkillsUpdateApiResponse =
  /** status 200  */ AttendantSkills;
export type AttendantsSkillsUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  attendantSkills: AttendantSkillsWrite;
};
export type AttendantsSkillsPartialUpdateApiResponse =
  /** status 200  */ AttendantSkills;
export type AttendantsSkillsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedAttendantSkills: PatchedAttendantSkillsWrite;
};
export type AttendantsSkillsDestroyApiResponse =
  /** status 204 No response body */ void;
export type AttendantsSkillsDestroyApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type BillingBillingSettingsRetrieveApiResponse =
  /** status 200  */ BillingSettingsRead;
export type BillingBillingSettingsRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type BillingBillingSettingsPartialUpdateApiResponse =
  /** status 200  */ BillingSettingsRead;
export type BillingBillingSettingsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  patchedBillingSettings: PatchedBillingSettings;
};
export type BillingBillingSettingsNextInvoiceNumberRetrieveApiResponse =
  /** status 200  */ InvoiceNumberGeneratorRead;
export type BillingBillingSettingsNextInvoiceNumberRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type BillingTripsBillingListApiResponse =
  /** status 200  */ PaginatedTripBillingListRead;
export type BillingTripsBillingListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type BrokersAliviOauth2TokenCreateApiResponse =
  /** status 200  */ AliviOAuthTokenRead;
export type BrokersAliviOauth2TokenCreateApiArg = {
  aliviOAuthToken: AliviOAuthTokenWrite;
};
export type BrokersAliviRideCancelCreateApiResponse =
  /** status 200  */ AliviRideCancel;
export type BrokersAliviRideCancelCreateApiArg = {
  aliviRideCancel: AliviRideCancelWrite;
};
export type BrokersAliviRideGetStatusCreateApiResponse =
  /** status 200  */ AliviRideStatusRead;
export type BrokersAliviRideGetStatusCreateApiArg = {
  aliviRideStatus: AliviRideStatus;
};
export type BrokersAliviRideOfferCreateApiResponse =
  /** status 200  */ AliviRideOfferRead;
export type BrokersAliviRideOfferCreateApiArg = {
  aliviRideOffer: AliviRideOfferWrite;
};
export type BrokersAliviRideUpdateCreateApiResponse =
  /** status 200  */ AliviRideUpdate;
export type BrokersAliviRideUpdateCreateApiArg = {
  aliviRideUpdate: AliviRideUpdateWrite;
};
export type BrokersExampleHelloWorldRetrieveApiResponse =
  /** status 200 No response body */ void;
export type BrokersExampleHelloWorldRetrieveApiArg = void;
export type BrokersKinetikOauth2TokenCreateApiResponse =
  /** status 200  */ KinetikOAuthTokenRead;
export type BrokersKinetikOauth2TokenCreateApiArg = {
  kinetikOAuthToken: KinetikOAuthToken;
};
export type BrokersKinetikV1CancelRideCreateApiResponse =
  /** status 200  */ KinetikRideReadOnly;
export type BrokersKinetikV1CancelRideCreateApiArg = {
  kinetikRideReadOnly: KinetikRideReadOnly;
};
export type BrokersKinetikV1CreateRidesCreateApiResponse =
  /** status 200  */ KinetikRide;
export type BrokersKinetikV1CreateRidesCreateApiArg = {
  kinetikRide: KinetikRide;
};
export type BrokersKinetikV1GetDriverCreateApiResponse =
  /** status 200  */ KinetikDriverRead;
export type BrokersKinetikV1GetDriverCreateApiArg = {
  kinetikDriver: KinetikDriver;
};
export type BrokersKinetikV1GetRideCreateApiResponse =
  /** status 200  */ KinetikRideReadOnly;
export type BrokersKinetikV1GetRideCreateApiArg = {
  kinetikRideReadOnly: KinetikRideReadOnly;
};
export type BrokersKinetikV1GetVehicleCreateApiResponse =
  /** status 200  */ KinetikVehicleRead;
export type BrokersKinetikV1GetVehicleCreateApiArg = {
  kinetikVehicle: KinetikVehicle;
};
export type BrokersKinetikV1UpdateRidesUpdateApiResponse =
  /** status 200  */ KinetikRide;
export type BrokersKinetikV1UpdateRidesUpdateApiArg = {
  kinetikRide: KinetikRide;
};
export type BrokersMtmOauth2TokenCreateApiResponse =
  /** status 200  */ MtmoAuthTokenRead;
export type BrokersMtmOauth2TokenCreateApiArg = {
  mtmoAuthToken: MtmoAuthTokenWrite;
};
export type BrokersMtmWebhookCreateApiResponse = /** status 200  */ MtmWebhook;
export type BrokersMtmWebhookCreateApiArg = {
  webhookId: string;
  mtmWebhook: MtmWebhook;
};
export type CredentialsListApiResponse =
  /** status 200  */ PaginatedCredentialListRead;
export type CredentialsListApiArg = {
  credentialTypeCategory?: string;
  credentialTypes?: string;
  members?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
  vehicles?: string;
};
export type CredentialsCreateApiResponse = /** status 201  */ CredentialRead;
export type CredentialsCreateApiArg = {
  credential: CredentialWrite;
};
export type CredentialsRetrieveApiResponse = /** status 200  */ CredentialRead;
export type CredentialsRetrieveApiArg = {
  /** A UUID string identifying this credential. */
  id: string;
};
export type CredentialsPartialUpdateApiResponse =
  /** status 200  */ CredentialRead;
export type CredentialsPartialUpdateApiArg = {
  /** A UUID string identifying this credential. */
  id: string;
  patchedCredential: PatchedCredentialWrite;
};
export type CredentialsDestroyApiResponse =
  /** status 204 No response body */ void;
export type CredentialsDestroyApiArg = {
  /** A UUID string identifying this credential. */
  id: string;
};
export type CredentialsTypesListApiResponse =
  /** status 200  */ PaginatedCredentialTypeListRead;
export type CredentialsTypesListApiArg = {
  /** The category of the credential type. */
  category?: 'Misc' | 'Team' | 'Vehicle';
  description?: string;
  id?: string;
  name?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type CredentialsTypesCreateApiResponse =
  /** status 201  */ CredentialTypeRead;
export type CredentialsTypesCreateApiArg = {
  credentialType: CredentialType;
};
export type CredentialsTypesRetrieveApiResponse =
  /** status 200  */ CredentialTypeRead;
export type CredentialsTypesRetrieveApiArg = {
  /** A UUID string identifying this credential type. */
  id: string;
};
export type CredentialsTypesPartialUpdateApiResponse =
  /** status 200  */ CredentialTypeRead;
export type CredentialsTypesPartialUpdateApiArg = {
  /** A UUID string identifying this credential type. */
  id: string;
  patchedCredentialType: PatchedCredentialType;
};
export type CredentialsTypesDestroyApiResponse =
  /** status 204 No response body */ void;
export type CredentialsTypesDestroyApiArg = {
  /** A UUID string identifying this credential type. */
  id: string;
};
export type DieCreateApiResponse = unknown;
export type DieCreateApiArg = {
  code: number;
};
export type DispatchRetrieveApiResponse = /** status 200  */ DispatchRead;
export type DispatchRetrieveApiArg = {
  'Organization-ID'?: string;
  rangeEnd?: string;
  rangeStart?: string;
  unassignedRangeEnd?: string;
  unassignedRangeStart?: string;
  unassignedSearch?: string;
};
export type DriverTagsListApiResponse =
  /** status 200  */ PaginatedDriverTagListRead;
export type DriverTagsListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type DriverTagsCreateApiResponse =
  /** status 201  */ DriverTagBulkCreateRead;
export type DriverTagsCreateApiArg = {
  'Organization-ID'?: string;
  driverTagBulkCreate: DriverTagBulkCreate;
};
export type DriverTagsRetrieveApiResponse = /** status 200  */ DriverTagRead;
export type DriverTagsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this driver tag. */
  id: string;
};
export type DriverTagsDestroyApiResponse =
  /** status 204 No response body */ void;
export type DriverTagsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this driver tag. */
  id: string;
};
export type DriversListApiResponse = /** status 200  */ PaginatedDriverListRead;
export type DriversListApiArg = {
  'Organization-ID'?: string;
  canBeScheduled?: boolean;
  id?: string;
  /** Sort drivers by an attribute */
  ordering?:
    | 'can_be_scheduled'
    | 'first_name'
    | 'license_expiration_date'
    | 'license_number'
    | 'license_state';
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type DriversCreateApiResponse = /** status 201  */ DriverRead;
export type DriversCreateApiArg = {
  'Organization-ID'?: string;
  driver: DriverWrite;
};
export type DriversRetrieveApiResponse = /** status 200  */ DriverRead;
export type DriversRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this driver. */
  id: string;
};
export type DriversPartialUpdateApiResponse = /** status 200  */ DriverRead;
export type DriversPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this driver. */
  id: string;
  patchedDriver: PatchedDriverWrite;
};
export type DriversDestroyApiResponse = /** status 204 No response body */ void;
export type DriversDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this driver. */
  id: string;
};
export type FilterGroupsListApiResponse =
  /** status 200  */ PaginatedFilterGroupListRead;
export type FilterGroupsListApiArg = {
  'Organization-ID'?: string;
  /** Sort tags by an attribute */
  ordering?: 'created_at' | 'group_name' | 'updated_at';
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type FilterGroupsCreateApiResponse = /** status 201  */ FilterGroupRead;
export type FilterGroupsCreateApiArg = {
  'Organization-ID'?: string;
  filterGroup: FilterGroup;
};
export type FilterGroupsRetrieveApiResponse =
  /** status 200  */ FilterGroupRead;
export type FilterGroupsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this filter group. */
  id: string;
};
export type FilterGroupsPartialUpdateApiResponse =
  /** status 200  */ FilterGroupRead;
export type FilterGroupsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this filter group. */
  id: string;
  patchedFilterGroup: PatchedFilterGroup;
};
export type FilterGroupsDestroyApiResponse =
  /** status 204 No response body */ void;
export type FilterGroupsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this filter group. */
  id: string;
};
export type FilterGroupsCreateExamplesCreateApiResponse =
  /** status 200  */ FilterGroupRead;
export type FilterGroupsCreateExamplesCreateApiArg = {
  'Organization-ID'?: string;
  filterGroup: FilterGroup;
};
export type FlagsFeatureValuesListApiResponse =
  /** status 200  */ PaginatedFeatureFlagListRead;
export type FlagsFeatureValuesListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type FlagsFeatureValuesMobileListApiResponse =
  /** status 200  */ PaginatedFeatureFlagListRead;
export type FlagsFeatureValuesMobileListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type FlagsFeatureValuesWebListApiResponse =
  /** status 200  */ PaginatedFeatureFlagListRead;
export type FlagsFeatureValuesWebListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type FlagsFeaturesListApiResponse =
  /** status 200  */ PaginatedFeatureListRead;
export type FlagsFeaturesListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type InvoicesExportListApiResponse = unknown;
export type InvoicesExportListApiArg = {
  'Organization-ID'?: string;
  createdAt?: string;
  format?: 'csv' | 'xlsx';
  invoice?: string;
  /** Multiple values may be separated by commas. */
  invoiceIn?: string[];
  rangeEnd?: string;
  rangeStart?: string;
  /** Multiple values may be separated by commas. */
  tripIn?: string[];
  tripPayer?: string;
  /** Multiple values may be separated by commas. */
  tripPayerIn?: string[];
};
export type InvoicesInvoicesListApiResponse =
  /** status 200  */ PaginatedInvoiceListRead;
export type InvoicesInvoicesListApiArg = {
  'Organization-ID'?: string;
  createdAt?: string;
  dateIssued?: string;
  dateIssuedEnd?: string;
  dateIssuedStart?: string;
  dueMax?: number;
  dueMin?: number;
  minPayments?: number;
  /** Sort invoices by an attribute */
  ordering?: 'created_at' | 'date_issued' | 'number';
  /** Filter by overall payment status */
  overallPaymentStatusIn?: (
    | 'overpaid'
    | 'paid'
    | 'partially_paid'
    | 'unknown'
    | 'unpaid'
  )[];
  /** A page number within the paginated result set. */
  page?: number;
  rangeEnd?: string;
  rangeStart?: string;
  /** A search term. */
  search?: string;
  totalAmountMax?: number;
  totalAmountMin?: number;
  /** Filter by the payers on the trips for the invoices */
  tripPayerIn?: string[];
};
export type InvoicesInvoicesCreateApiResponse = /** status 201  */ InvoiceRead;
export type InvoicesInvoicesCreateApiArg = {
  'Organization-ID'?: string;
  invoice: Invoice;
};
export type InvoicesInvoicesRetrieveApiResponse =
  /** status 200  */ InvoiceRead;
export type InvoicesInvoicesRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice. */
  id: string;
};
export type InvoicesInvoicesPartialUpdateApiResponse =
  /** status 200  */ InvoiceRead;
export type InvoicesInvoicesPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice. */
  id: string;
  patchedInvoice: PatchedInvoice;
};
export type InvoicesInvoicesDestroyApiResponse =
  /** status 204 No response body */ void;
export type InvoicesInvoicesDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice. */
  id: string;
};
export type InvoicesLineItemsListApiResponse =
  /** status 200  */ PaginatedInvoiceLineItemListRead;
export type InvoicesLineItemsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type InvoicesLineItemsCreateApiResponse =
  /** status 201  */ InvoiceLineItemRead;
export type InvoicesLineItemsCreateApiArg = {
  'Organization-ID'?: string;
  invoiceLineItem: InvoiceLineItem;
};
export type InvoicesLineItemsRetrieveApiResponse =
  /** status 200  */ InvoiceLineItemRead;
export type InvoicesLineItemsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice line item. */
  id: string;
};
export type InvoicesLineItemsPartialUpdateApiResponse =
  /** status 200  */ InvoiceLineItemRead;
export type InvoicesLineItemsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice line item. */
  id: string;
  patchedInvoiceLineItem: PatchedInvoiceLineItem;
};
export type InvoicesLineItemsDestroyApiResponse =
  /** status 204 No response body */ void;
export type InvoicesLineItemsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice line item. */
  id: string;
};
export type InvoicesPaymentsListApiResponse =
  /** status 200  */ PaginatedInvoicePaymentListRead;
export type InvoicesPaymentsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type InvoicesPaymentsCreateApiResponse =
  /** status 201  */ InvoicePaymentRead;
export type InvoicesPaymentsCreateApiArg = {
  'Organization-ID'?: string;
  invoicePayment: InvoicePayment;
};
export type InvoicesPaymentsRetrieveApiResponse =
  /** status 200  */ InvoicePaymentRead;
export type InvoicesPaymentsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice payment. */
  id: string;
};
export type InvoicesRefundsListApiResponse =
  /** status 200  */ PaginatedInvoiceRefundListRead;
export type InvoicesRefundsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type InvoicesRefundsCreateApiResponse =
  /** status 201  */ InvoiceRefundRead;
export type InvoicesRefundsCreateApiArg = {
  'Organization-ID'?: string;
  invoiceRefund: InvoiceRefund;
};
export type InvoicesRefundsRetrieveApiResponse =
  /** status 200  */ InvoiceRefundRead;
export type InvoicesRefundsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this invoice refund. */
  id: string;
};
export type LocationsAddressesListApiResponse =
  /** status 200  */ PaginatedAddressListRead;
export type LocationsAddressesListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type LocationsAddressesRetrieveApiResponse =
  /** status 200  */ AddressRead;
export type LocationsAddressesRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this address. */
  id: string;
};
export type LocationsLogCreateApiResponse = /** status 201  */ LocationLogEntry;
export type LocationsLogCreateApiArg = {
  'Organization-ID'?: string;
  locationLogEntry: LocationLogEntryWrite;
};
export type LocationsMunicipalitiesRetrieveApiResponse =
  /** status 200  */ MunicipalityList;
export type LocationsMunicipalitiesRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type LocationsPassengerAddressesListApiResponse =
  /** status 200  */ PaginatedPassengerAddressListRead;
export type LocationsPassengerAddressesListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type LocationsPassengerAddressesRetrieveApiResponse =
  /** status 200  */ PassengerAddressRead;
export type LocationsPassengerAddressesRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this passenger address. */
  id: string;
};
export type MembersListApiResponse =
  /** status 200  */ PaginatedMembershipListRead;
export type MembersListApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  isAttendant?: boolean;
  isDriver?: boolean;
  /** Sort members by an attribute */
  ordering?: 'email' | 'first_name' | 'phone_number' | 'role';
  /** A page number within the paginated result set. */
  page?: number;
  role?:
    | 'admin'
    | 'associate'
    | 'bambi'
    | 'dispatcher'
    | 'facility admin'
    | 'facility user'
    | 'integration'
    | 'owner'
    | 'restricted support';
  /** A search term. */
  search?: string;
};
export type MembersCreateApiResponse = /** status 201  */ MembershipRead;
export type MembersCreateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  membership: MembershipWrite;
};
export type MembersRetrieveApiResponse = /** status 200  */ MembershipRead;
export type MembersRetrieveApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this membership. */
  id: string;
};
export type MembersPartialUpdateApiResponse = /** status 200  */ MembershipRead;
export type MembersPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this membership. */
  id: string;
  patchedMembership: PatchedMembershipWrite;
};
export type MembersDestroyApiResponse = /** status 204 No response body */ void;
export type MembersDestroyApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this membership. */
  id: string;
};
export type MembersUndeleteCreateApiResponse =
  /** status 200  */ MembershipRead;
export type MembersUndeleteCreateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this membership. */
  id: string;
  membership: MembershipWrite;
};
export type MessagesListApiResponse =
  /** status 200  */ PaginatedMessageListRead;
export type MessagesListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type MessagesCreateApiResponse = /** status 201  */ MessageRead;
export type MessagesCreateApiArg = {
  message: Message;
};
export type MessagesRetrieveApiResponse = /** status 200  */ MessageRead;
export type MessagesRetrieveApiArg = {
  /** A UUID string identifying this message. */
  id: string;
};
export type MessagesTripMessagesListApiResponse =
  /** status 200  */ PaginatedTripMessageListRead;
export type MessagesTripMessagesListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
  tripId?: string;
};
export type MessagesTripMessagesCreateApiResponse =
  /** status 201  */ TripMessageRead;
export type MessagesTripMessagesCreateApiArg = {
  tripMessage: TripMessageWrite;
};
export type MessagesTripMessagesRetrieveApiResponse =
  /** status 200  */ TripMessageRead;
export type MessagesTripMessagesRetrieveApiArg = {
  tripId: string;
};
export type MessagesWebHookMessageUpdateCreateApiResponse =
  /** status 200  */ MessageRead;
export type MessagesWebHookMessageUpdateCreateApiArg = {
  message: Message;
};
export type MobileAppSettingsRetrieveApiResponse =
  /** status 200  */ MobileAppSettings;
export type MobileAppSettingsRetrieveApiArg = {
  'User-Agent': string;
};
export type MobileAppSettingsPartialUpdateApiResponse =
  /** status 200  */ MobileAppSettings;
export type MobileAppSettingsPartialUpdateApiArg = {
  'User-Agent': string;
  patchedMobileAppSettings: PatchedMobileAppSettings;
};
export type OrganizationBillingStatusRetrieveApiResponse =
  /** status 200  */ OrganizationBillingStatusRead;
export type OrganizationBillingStatusRetrieveApiArg = void;
export type OrganizationSettingsRetrieveApiResponse =
  /** status 200  */ OrganizationSettingsRead;
export type OrganizationSettingsRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type OrganizationSettingsPartialUpdateApiResponse =
  /** status 200  */ OrganizationSettingsRead;
export type OrganizationSettingsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  patchedOrganizationSettings: PatchedOrganizationSettings;
};
export type OrganizationSettingsConnectMerchantAccountCreateApiResponse =
  /** status 200  */ OrganizationMerchantAccountOnboarding;
export type OrganizationSettingsConnectMerchantAccountCreateApiArg = {
  'Organization-ID'?: string;
};
export type PassengersListApiResponse =
  /** status 200  */ PaginatedPassengerListRead;
export type PassengersListApiArg = {
  'Organization-ID'?: string;
  /** Sort passengers by an attribute */
  ordering?: 'dob' | 'email' | 'full_name' | 'phone_number';
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type PassengersCreateApiResponse = /** status 201  */ PassengerRead;
export type PassengersCreateApiArg = {
  'Organization-ID'?: string;
  passenger: Passenger;
};
export type PassengersRetrieveApiResponse = /** status 200  */ PassengerRead;
export type PassengersRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this passenger. */
  id: string;
};
export type PassengersPartialUpdateApiResponse =
  /** status 200  */ PassengerRead;
export type PassengersPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this passenger. */
  id: string;
  patchedPassenger: PatchedPassenger;
};
export type PassengersDestroyApiResponse =
  /** status 204 No response body */ void;
export type PassengersDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this passenger. */
  id: string;
};
export type PassengersRecentAddressesListApiResponse =
  /** status 200  */ PaginatedPassengerAddressListRead;
export type PassengersRecentAddressesListApiArg = {
  'Organization-ID'?: string;
  id: string;
  maxResults?: number;
  /** A page number within the paginated result set. */
  page?: number;
};
export type PassengersRecentPayersListApiResponse =
  /** status 200  */ PaginatedPayerPassengerListRead;
export type PassengersRecentPayersListApiArg = {
  'Organization-ID'?: string;
  id: string;
  maxResults?: number;
  /** A page number within the paginated result set. */
  page?: number;
};
export type PassengersDuplicatesListApiResponse =
  /** status 200  */ PaginatedPassengerDuplicateListRead;
export type PassengersDuplicatesListApiArg = {
  'Organization-ID'?: string;
  createdAtEnd?: string;
  createdAtStart?: string;
  firstNameStartsWith?: string;
  lastNameStartsWith?: string;
  /** fields to exactly match on when looking for duplicates */
  matchFields?: (
    | 'dob'
    | 'email'
    | 'first_name'
    | 'gender'
    | 'last_name'
    | 'phone_number'
    | 'weight'
  )[];
  /** A page number within the paginated result set. */
  page?: number;
  /** Multiple values may be separated by commas. */
  passengerIn?: string[];
};
export type PassengersMergeCreateApiResponse =
  /** status 200  */ PassengerMergeResultRead;
export type PassengersMergeCreateApiArg = {
  'Organization-ID'?: string;
  passengerMergeRequest: PassengerMergeRequest;
};
export type PayerSettingsListApiResponse =
  /** status 200  */ PaginatedPayerOrganizationSettingsListRead;
export type PayerSettingsListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type PayerSettingsRetrieveApiResponse =
  /** status 200  */ PayerOrganizationSettingsRead;
export type PayerSettingsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this Payer Organization Settings. */
  id: string;
};
export type PayerSettingsPartialUpdateApiResponse =
  /** status 200  */ PayerOrganizationSettingsRead;
export type PayerSettingsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this Payer Organization Settings. */
  id: string;
  patchedPayerOrganizationSettings: PatchedPayerOrganizationSettings;
};
export type PayerinfoListApiResponse =
  /** status 200  */ PaginatedPayerInfoListRead;
export type PayerinfoListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type PayerinfoRetrieveApiResponse = /** status 200  */ PayerInfoRead;
export type PayerinfoRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this payer info. */
  id: string;
};
export type PayerinfooneschematemplateListApiResponse =
  /** status 200  */ PaginatedPayerInfoOneSchemaTemplateListRead;
export type PayerinfooneschematemplateListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type PayersListApiResponse =
  /** status 200  */ PaginatedPayerOrganizationListRead;
export type PayersListApiArg = {
  'Organization-ID'?: string;
  canMultiload?: boolean;
  id?: string;
  /** Sort payers by an attribute */
  ordering?:
    | 'billing_email'
    | 'billing_phone_number'
    | 'display_name'
    | 'email'
    | 'phone_number'
    | 'website';
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type PayersCreateApiResponse = /** status 201  */ PayerOrganizationRead;
export type PayersCreateApiArg = {
  'Organization-ID'?: string;
  payerOrganization: PayerOrganization;
};
export type PayersPassengersRetrieveApiResponse =
  /** status 200  */ PayerPassengerRead;
export type PayersPassengersRetrieveApiArg = {
  'Organization-ID'?: string;
  passengerId: string;
  payerOrganizationId: string;
};
export type PayersPassengersCreateApiResponse =
  /** status 201  */ PayerPassengerRead;
export type PayersPassengersCreateApiArg = {
  'Organization-ID'?: string;
  passengerId: string;
  payerOrganizationId: string;
  payerPassenger: PayerPassenger;
};
export type PayersPassengersPartialUpdateApiResponse =
  /** status 200  */ PayerPassengerRead;
export type PayersPassengersPartialUpdateApiArg = {
  'Organization-ID'?: string;
  passengerId: string;
  payerOrganizationId: string;
  patchedPayerPassenger: PatchedPayerPassenger;
};
export type PayersPassengersDestroyApiResponse =
  /** status 204 No response body */ void;
export type PayersPassengersDestroyApiArg = {
  'Organization-ID'?: string;
  passengerId: string;
  payerOrganizationId: string;
};
export type PayersSettingsRetrieveApiResponse =
  /** status 200  */ PayerOrganizationSettingsRead;
export type PayersSettingsRetrieveApiArg = {
  'Organization-ID'?: string;
  payerOrganizationId: string;
};
export type PayersSettingsPartialUpdateApiResponse =
  /** status 200  */ PayerOrganizationSettingsRead;
export type PayersSettingsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  payerOrganizationId: string;
  patchedPayerOrganizationSettings: PatchedPayerOrganizationSettings;
};
export type PayersRetrieveApiResponse =
  /** status 200  */ PayerOrganizationRead;
export type PayersRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this payer organization. */
  id: string;
};
export type PayersPartialUpdateApiResponse =
  /** status 200  */ PayerOrganizationRead;
export type PayersPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this payer organization. */
  id: string;
  patchedPayerOrganization: PatchedPayerOrganization;
};
export type PayersDestroyApiResponse = /** status 204 No response body */ void;
export type PayersDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this payer organization. */
  id: string;
};
export type PayersListSelectedApiResponse =
  /** status 200  */ PaginatedPayerOrganizationListRead;
export type PayersListSelectedApiArg = {
  'Organization-ID'?: string;
  canMultiload?: boolean;
  id?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
  payerFilters: PayerFilters;
};
export type PayersandtemplatesRetrieveApiResponse =
  /** status 200  */ PayerWithTemplatesResponseRead;
export type PayersandtemplatesRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type PayerstemplatesListApiResponse =
  /** status 200  */ PaginatedPayerWithTemplatesListRead;
export type PayerstemplatesListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type PayerstemplatesRetrieveApiResponse =
  /** status 200  */ PayerWithTemplatesRead;
export type PayerstemplatesRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this payer organization. */
  id: string;
};
export type PricingPriceEstimateCreateApiResponse =
  /** status 201  */ PricingRead;
export type PricingPriceEstimateCreateApiArg = {
  'Organization-ID'?: string;
  pricing: PricingWrite;
};
export type PricingPriceQuoteCreateApiResponse =
  /** status 201  */ PriceQuoteRead;
export type PricingPriceQuoteCreateApiArg = {
  'Organization-ID'?: string;
  priceQuote: PriceQuote;
};
export type PricingPricingModelSelectionCreateApiResponse =
  /** status 201  */ PricingModelSelectionRead;
export type PricingPricingModelSelectionCreateApiArg = {
  'Organization-ID'?: string;
  pricingModelSelection: PricingModelSelectionWrite;
};
export type PricingPricingModelsListApiResponse =
  /** status 200  */ PaginatedPricingModelListRead;
export type PricingPricingModelsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type PricingPricingModelsCreateApiResponse =
  /** status 201  */ PricingModelRead;
export type PricingPricingModelsCreateApiArg = {
  'Organization-ID'?: string;
  pricingModel: PricingModel;
};
export type PricingPricingModelsRetrieveApiResponse =
  /** status 200  */ PricingModelRead;
export type PricingPricingModelsRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type PricingPricingModelsPartialUpdateApiResponse =
  /** status 200  */ PricingModelRead;
export type PricingPricingModelsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedPricingModel: PatchedPricingModel;
};
export type PricingPricingModelsDestroyApiResponse =
  /** status 204 No response body */ void;
export type PricingPricingModelsDestroyApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type PricingPricingRulesPartialUpdateApiResponse =
  /** status 200  */ PricingRuleSelectionRead;
export type PricingPricingRulesPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this pricing rule. */
  id: string;
  patchedPricingRuleSelection: PatchedPricingRuleSelection;
};
export type PricingPricingRulesDestroyApiResponse =
  /** status 204 No response body */ void;
export type PricingPricingRulesDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this pricing rule. */
  id: string;
};
export type PricingPricingSchemeConflictsCreateApiResponse =
  /** status 200  */ PriceSchemeConflictResults;
export type PricingPricingSchemeConflictsCreateApiArg = {
  'Organization-ID'?: string;
  priceSchemeConflict: PriceSchemeConflictWrite;
};
export type PricingPricingSchemesListApiResponse =
  /** status 200  */ PaginatedPricingSchemeListRead;
export type PricingPricingSchemesListApiArg = {
  'Organization-ID'?: string;
  /** Sort pricing schemes by an attribute */
  ordering?:
    | 'base_fee_cents'
    | 'created_at'
    | 'description'
    | 'end_date'
    | 'loaded_mileage_rate_cents'
    | 'name'
    | 'start_date'
    | 'updated_at';
  /** A page number within the paginated result set. */
  page?: number;
  /** Multiple values may be separated by commas. */
  payerIn?: string[];
  pricingSchemePayersPayerId?: string;
  /** A search term. */
  search?: string;
};
export type PricingPricingSchemesCreateApiResponse =
  /** status 201  */ PricingSchemeRead;
export type PricingPricingSchemesCreateApiArg = {
  'Organization-ID'?: string;
  pricingScheme: PricingSchemeWrite;
};
export type PricingPricingSchemesRetrieveApiResponse =
  /** status 200  */ PricingSchemeRead;
export type PricingPricingSchemesRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type PricingPricingSchemesPartialUpdateApiResponse =
  /** status 200  */ PricingSchemeRead;
export type PricingPricingSchemesPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedPricingScheme: PatchedPricingSchemeWrite;
};
export type PricingPricingSchemesDestroyApiResponse =
  /** status 204 No response body */ void;
export type PricingPricingSchemesDestroyApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type PricingPricingTypesListApiResponse =
  /** status 200  */ PaginatedPricingTypeSelectionListRead;
export type PricingPricingTypesListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type PricingPricingTypesCreateApiResponse =
  /** status 201  */ PricingTypeSelectionRead;
export type PricingPricingTypesCreateApiArg = {
  'Organization-ID'?: string;
  pricingTypeSelection: PricingTypeSelectionWrite;
};
export type PricingPricingTypesRetrieveApiResponse =
  /** status 200  */ PricingTypeSelectionRead;
export type PricingPricingTypesRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this pricing type. */
  id: string;
};
export type PricingPricingTypesPartialUpdateApiResponse =
  /** status 200  */ PricingTypeSelectionRead;
export type PricingPricingTypesPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this pricing type. */
  id: string;
  patchedPricingTypeSelection: PatchedPricingTypeSelectionWrite;
};
export type PricingPricingTypesDestroyApiResponse =
  /** status 204 No response body */ void;
export type PricingPricingTypesDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this pricing type. */
  id: string;
};
export type ProfileRetrieveApiResponse = /** status 200  */ UserProfileRead;
export type ProfileRetrieveApiArg = void;
export type RunBambiRunCreateApiResponse = /** status 201  */ RunBambiRunRead;
export type RunBambiRunCreateApiArg = {
  'Organization-ID'?: string;
  runBambiRun: RunBambiRunWrite;
};
export type RunBambiRunRetrieveApiResponse =
  /** status 200  */ RunBambiRunTaskRead;
export type RunBambiRunRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type RunBambiRunApplyCreateApiResponse =
  /** status 200  */ RunBambiRunApply;
export type RunBambiRunApplyCreateApiArg = {
  'Organization-ID'?: string;
  id: string;
  runBambiRunApply: RunBambiRunApplyWrite;
};
export type SignaturesListApiResponse =
  /** status 200  */ PaginatedSignatureListRead;
export type SignaturesListApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type SignaturesCreateApiResponse = /** status 201  */ SignatureRead;
export type SignaturesCreateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  signature: Signature;
};
export type SignaturesRetrieveApiResponse = /** status 200  */ SignatureRead;
export type SignaturesRetrieveApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  /** A UUID string identifying this signature. */
  id: string;
};
export type SignaturesDestroyApiResponse =
  /** status 204 No response body */ void;
export type SignaturesDestroyApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  /** A UUID string identifying this signature. */
  id: string;
};
export type TagsListApiResponse = /** status 200  */ PaginatedTagListRead;
export type TagsListApiArg = {
  'Organization-ID'?: string;
  /** Sort tags by an attribute */
  ordering?: 'created_at' | 'tag_name' | 'updated_at';
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type TagsCreateApiResponse = /** status 201  */ TagRead;
export type TagsCreateApiArg = {
  'Organization-ID'?: string;
  tag: Tag;
};
export type TagsRetrieveApiResponse = /** status 200  */ TagRead;
export type TagsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this tag. */
  id: string;
};
export type TagsPartialUpdateApiResponse = /** status 200  */ TagRead;
export type TagsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this tag. */
  id: string;
  patchedTag: PatchedTag;
};
export type TagsDestroyApiResponse = /** status 204 No response body */ void;
export type TagsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this tag. */
  id: string;
};
export type TilledPaymentMethodRetrieveApiResponse =
  /** status 200  */ TilledListPaymentMethodsTilledResponse;
export type TilledPaymentMethodRetrieveApiArg = {
  'Organization-ID'?: string;
  payerId: string;
  payerType: 'passenger' | 'payer_organization';
};
export type TilledPaymentMethodCreateApiResponse =
  /** status 200  */ TilledCustomer;
export type TilledPaymentMethodCreateApiArg = {
  'Organization-ID'?: string;
  tilledPaymentMethodInput: TilledPaymentMethodInput;
};
export type TilledPaymentMethodDestroyApiResponse =
  /** status 204 No response body */ void;
export type TilledPaymentMethodDestroyApiArg = {
  'Organization-ID'?: string;
  paymentMethodId: string;
};
export type TilledPayoutsListApiResponse =
  /** status 200  */ PaginatedTilledPayoutItemList;
export type TilledPayoutsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TilledWebhookCreateApiResponse =
  /** status 200  */ TilledWebHookEnvelope;
export type TilledWebhookCreateApiArg = {
  'Organization-ID'?: string;
  tilledWebHookEnvelope: TilledWebHookEnvelope;
};
export type TokensChatIssueRetrieveApiResponse = /** status 200  */ ChatTokens;
export type TokensChatIssueRetrieveApiArg = {
  'Organization-ID'?: string;
};
export type TokensPushRegisterCreateApiResponse =
  /** status 200  */ PushTokensRead;
export type TokensPushRegisterCreateApiArg = {
  'Organization-ID'?: string;
  'User-Agent': string;
  pushTokens: PushTokens;
};
export type TokensPushRevokeCreateApiResponse =
  /** status 200  */ PushTokensRead;
export type TokensPushRevokeCreateApiArg = {
  'Organization-ID'?: string;
  'User-Agent': string;
  pushTokens: PushTokens;
};
export type TripImportCreateApiResponse =
  /** status 201  */ TripImportAsyncRead;
export type TripImportCreateApiArg = {
  'Organization-ID'?: string;
  tripImportAsyncRequest: TripImportAsyncRequestWrite;
};
export type TripImportRetrieveApiResponse =
  /** status 200  */ TripImportTaskRead;
export type TripImportRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripTagsListApiResponse =
  /** status 200  */ PaginatedTripTagListRead;
export type TripTagsListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type TripTagsCreateApiResponse =
  /** status 201  */ TripTagBulkCreateRead;
export type TripTagsCreateApiArg = {
  'Organization-ID'?: string;
  tripTagBulkCreate: TripTagBulkCreate;
};
export type TripTagsRetrieveApiResponse = /** status 200  */ TripTagRead;
export type TripTagsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this trip tag. */
  id: string;
};
export type TripTagsDestroyApiResponse =
  /** status 204 No response body */ void;
export type TripTagsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this trip tag. */
  id: string;
};
export type TripsListApiResponse = /** status 200  */ PaginatedTripListRead;
export type TripsListApiArg = {
  'Organization-ID'?: string;
  /** Multiple values may be separated by commas. */
  driverIn?: (string | null)[];
  /** Multiple values may be separated by commas. */
  dropoffMunicipalityIn?: string[];
  /** Multiple values may be separated by commas. */
  externalTripIdIn?: string[];
  hasInvoice?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: string[];
  /** Multiple values may be separated by commas. */
  notTagIn?: string[];
  /** Sort trips by an attribute */
  ordering?:
    | 'dropoff_address'
    | 'name'
    | 'payer_name'
    | 'pickup_address'
    | 'scheduled_pickup_at'
    | 'status';
  /** A page number within the paginated result set. */
  page?: number;
  /** Multiple values may be separated by commas. */
  passengerIn?: string[];
  passengers?: string;
  /** Multiple values may be separated by commas. */
  payerIn?: string[];
  payers?: string;
  /** Multiple values may be separated by commas. */
  pickupMunicipalityIn?: string[];
  /** The manually inputted price of the trip */
  priceMax?: number | null;
  /** The manually inputted price of the trip */
  priceMin?: number | null;
  rangeEnd?: string;
  rangeStart?: string;
  /** A search term. */
  search?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  statusIn?: (
    | 'assigned'
    | 'at dropoff'
    | 'at pickup'
    | 'canceled'
    | 'completed'
    | 'en route'
    | 'pending'
    | 'rejected'
    | 'requested'
    | 'transporting'
  )[];
  /** Multiple values may be separated by commas. */
  tagIn?: string[];
  tags?: string;
  /** Multiple values may be separated by commas. */
  vehicleIdIn?: string[];
  vehicles?: string;
};
export type TripsCreateApiResponse = /** status 201  */ TripsBulkCreateRead;
export type TripsCreateApiArg = {
  'Organization-ID'?: string;
  tripsBulkCreate: TripsBulkCreateWrite;
};
export type TripsRetrieveApiResponse = /** status 200  */ TripRead;
export type TripsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this trip. */
  id: string;
};
export type TripsPartialUpdateApiResponse = /** status 200  */ TripRead;
export type TripsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this trip. */
  id: string;
  patchedTrip: PatchedTripWrite;
};
export type TripsDestroyApiResponse = /** status 204 No response body */ void;
export type TripsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this trip. */
  id: string;
};
export type TripsAcceptPartialUpdateApiResponse = /** status 200  */ TripAccept;
export type TripsAcceptPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripAccept: PatchedTripAcceptWrite;
};
export type TripsActivateWillCallPartialUpdateApiResponse =
  /** status 200  */ TripActivateWillCall;
export type TripsActivateWillCallPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripActivateWillCall: PatchedTripActivateWillCallWrite;
};
export type TripsAssignPartialUpdateApiResponse = /** status 200  */ TripAssign;
export type TripsAssignPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripAssign: PatchedTripAssignWrite;
};
export type TripsCancelPartialUpdateApiResponse = /** status 200  */ TripCancel;
export type TripsCancelPartialUpdateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  patchedTripCancel: PatchedTripCancelWrite;
};
export type TripsCancelCodesRetrieveApiResponse =
  /** status 200  */ TripCancelReasonCodeRead;
export type TripsCancelCodesRetrieveApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
};
export type TripsCancelReasonsRetrieveApiResponse =
  /** status 200  */ TripCancelReasonsRead;
export type TripsCancelReasonsRetrieveApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
};
export type TripsCompletePartialUpdateApiResponse =
  /** status 200  */ TripComplete;
export type TripsCompletePartialUpdateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  patchedTripComplete: PatchedTripCompleteWrite;
};
export type TripsCorrelatedListApiResponse =
  /** status 200  */ PaginatedTripListRead;
export type TripsCorrelatedListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsCreatesListApiResponse =
  /** status 200  */ PaginatedTripCreateListRead;
export type TripsCreatesListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsDriverEnteredNotesPartialUpdateApiResponse =
  /** status 200  */ TripUpdateDriverEnteredNotes;
export type TripsDriverEnteredNotesPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripUpdateDriverEnteredNotes: PatchedTripUpdateDriverEnteredNotesWrite;
};
export type TripsEventsListApiResponse =
  /** status 200  */ PaginatedTripEventListRead;
export type TripsEventsListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsLocationsListApiResponse =
  /** status 200  */ PaginatedLocationLogEntryMinimalReadOnlyListRead;
export type TripsLocationsListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsLockPartialUpdateApiResponse =
  /** status 200 No response body */ void;
export type TripsLockPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripsOverrideStatusPartialUpdateApiResponse =
  /** status 200  */ TripOverrideStatusChange;
export type TripsOverrideStatusPartialUpdateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  patchedTripOverrideStatusChange: PatchedTripOverrideStatusChangeWrite;
};
export type TripsPriceSummaryRetrieveApiResponse =
  /** status 200  */ TripPriceSummaryRead;
export type TripsPriceSummaryRetrieveApiArg = {
  id: string;
};
export type TripsReassignPartialUpdateApiResponse =
  /** status 200  */ TripReassign;
export type TripsReassignPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripReassign: PatchedTripReassignWrite;
};
export type TripsRejectPartialUpdateApiResponse = /** status 200  */ TripReject;
export type TripsRejectPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripReject: PatchedTripRejectWrite;
};
export type TripsRejectCodesRetrieveApiResponse =
  /** status 200  */ TripRejectionReasonsRead;
export type TripsRejectCodesRetrieveApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
};
export type TripsRejectReasonsRetrieveApiResponse =
  /** status 200  */ TripRejectReasonsRead;
export type TripsRejectReasonsRetrieveApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
};
export type TripsRelatedListApiResponse =
  /** status 200  */ PaginatedTripListRead;
export type TripsRelatedListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsSignaturesListApiResponse =
  /** status 200  */ PaginatedSignatureListRead;
export type TripsSignaturesListApiArg = {
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsStatusPartialUpdateApiResponse =
  /** status 200  */ TripStatusChange;
export type TripsStatusPartialUpdateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  patchedTripStatusChange: PatchedTripStatusChangeWrite;
};
export type TripsSummaryRetrieveApiResponse =
  /** status 200  */ TripSummaryRead;
export type TripsSummaryRetrieveApiArg = {
  id: string;
};
export type TripsTurnbackReasonsRetrieveApiResponse =
  /** status 200  */ TripTurnbackReasonRead;
export type TripsTurnbackReasonsRetrieveApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
};
export type TripsUnassignPartialUpdateApiResponse =
  /** status 200 No response body */ void;
export type TripsUnassignPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripsUncancelPartialUpdateApiResponse =
  /** status 200  */ TripUncancel;
export type TripsUncancelPartialUpdateApiArg = {
  'Device-Requested-At'?: string;
  latitude?: string;
  'Location-Queried-At'?: string;
  longitude?: string;
  'Organization-ID'?: string;
  id: string;
  patchedTripUncancel: PatchedTripUncancelWrite;
};
export type TripsUnlockPartialUpdateApiResponse =
  /** status 200 No response body */ void;
export type TripsUnlockPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripsUnrejectPartialUpdateApiResponse =
  /** status 200  */ TripUnreject;
export type TripsUnrejectPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripUnreject: PatchedTripUnrejectWrite;
};
export type TripsUpdatePickupAtPartialUpdateApiResponse =
  /** status 200  */ TripUpdatePickupAt;
export type TripsUpdatePickupAtPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripUpdatePickupAt: PatchedTripUpdatePickupAtWrite;
};
export type TripsUpdatesListApiResponse =
  /** status 200  */ PaginatedTripUpdateListRead;
export type TripsUpdatesListApiArg = {
  'Organization-ID'?: string;
  id: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsValidatePartialUpdateApiResponse =
  /** status 200  */ TripValidateRead;
export type TripsValidatePartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripValidate: PatchedTripValidateWrite;
};
export type TripsAllListApiResponse = /** status 200  */ PaginatedTripListRead;
export type TripsAllListApiArg = {
  'Organization-ID'?: string;
  /** Multiple values may be separated by commas. */
  driverIn?: (string | null)[];
  /** Multiple values may be separated by commas. */
  dropoffMunicipalityIn?: string[];
  /** Multiple values may be separated by commas. */
  externalTripIdIn?: string[];
  hasInvoice?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: string[];
  /** Multiple values may be separated by commas. */
  notTagIn?: string[];
  /** Sort trips by an attribute */
  ordering?:
    | 'dropoff_address'
    | 'name'
    | 'payer_name'
    | 'pickup_address'
    | 'scheduled_pickup_at'
    | 'status';
  /** A page number within the paginated result set. */
  page?: number;
  /** Multiple values may be separated by commas. */
  passengerIn?: string[];
  passengers?: string;
  /** Multiple values may be separated by commas. */
  payerIn?: string[];
  payers?: string;
  /** Multiple values may be separated by commas. */
  pickupMunicipalityIn?: string[];
  /** The manually inputted price of the trip */
  priceMax?: number | null;
  /** The manually inputted price of the trip */
  priceMin?: number | null;
  rangeEnd?: string;
  rangeStart?: string;
  /** A search term. */
  search?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  statusIn?: (
    | 'assigned'
    | 'at dropoff'
    | 'at pickup'
    | 'canceled'
    | 'completed'
    | 'en route'
    | 'pending'
    | 'rejected'
    | 'requested'
    | 'transporting'
  )[];
  /** Multiple values may be separated by commas. */
  tagIn?: string[];
  tags?: string;
  /** Multiple values may be separated by commas. */
  vehicleIdIn?: string[];
  vehicles?: string;
};
export type TripsBulkAcceptCreateApiResponse =
  /** status 200  */ BulkTripAcceptResults;
export type TripsBulkAcceptCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripAccept: BulkTripAcceptWrite;
};
export type TripsBulkAssignCreateApiResponse =
  /** status 201  */ BulkTripAssign;
export type TripsBulkAssignCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripAssign: BulkTripAssignWrite;
};
export type TripsBulkCancelCreateApiResponse =
  /** status 200  */ BulkTripCancelResults;
export type TripsBulkCancelCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripCancel: BulkTripCancelWrite;
};
export type TripsBulkCancelReasonsCreateApiResponse =
  /** status 200  */ BulkTripCancelReasonsRead;
export type TripsBulkCancelReasonsCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripCancelReasons: BulkTripCancelReasonsWrite;
};
export type TripsBulkRejectCreateApiResponse =
  /** status 200  */ BulkTripRejectResults;
export type TripsBulkRejectCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripReject: BulkTripRejectWrite;
};
export type TripsBulkUnassignCreateApiResponse =
  /** status 200  */ BulkTripUnassignResults;
export type TripsBulkUnassignCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripUnassign: BulkTripUnassignWrite;
};
export type TripsBulkValidateCreateApiResponse =
  /** status 200  */ BulkTripValidateResults;
export type TripsBulkValidateCreateApiArg = {
  'Organization-ID'?: string;
  bulkTripValidate: BulkTripValidateWrite;
};
export type TripsExportListApiResponse = unknown;
export type TripsExportListApiArg = {
  'Organization-ID'?: string;
  /** Multiple values may be separated by commas. */
  driverIn?: (string | null)[];
  /** Multiple values may be separated by commas. */
  dropoffMunicipalityIn?: string[];
  /** Multiple values may be separated by commas. */
  externalTripIdIn?: string[];
  format?: 'csv' | 'xlsx';
  hasInvoice?: boolean;
  /** Multiple values may be separated by commas. */
  idIn?: string[];
  /** Multiple values may be separated by commas. */
  notTagIn?: string[];
  /** format for export */
  outputSet?:
    | 'call_the_car'
    | 'evertransit'
    | 'kinetik'
    | 'mini'
    | 'modivcare'
    | 'saferide'
    | 'welltrans';
  /** Multiple values may be separated by commas. */
  passengerIn?: string[];
  passengers?: string;
  /** Multiple values may be separated by commas. */
  payerIn?: string[];
  payers?: string;
  /** Multiple values may be separated by commas. */
  pickupMunicipalityIn?: string[];
  /** The manually inputted price of the trip */
  priceMax?: number | null;
  /** The manually inputted price of the trip */
  priceMin?: number | null;
  rangeEnd?: string;
  rangeStart?: string;
  status?: string;
  /** Multiple values may be separated by commas. */
  statusIn?: (
    | 'assigned'
    | 'at dropoff'
    | 'at pickup'
    | 'canceled'
    | 'completed'
    | 'en route'
    | 'pending'
    | 'rejected'
    | 'requested'
    | 'transporting'
  )[];
  /** Multiple values may be separated by commas. */
  tagIn?: string[];
  tags?: string;
  /** Multiple values may be separated by commas. */
  vehicleIdIn?: string[];
  vehicles?: string;
};
export type TripsImportCreateApiResponse = /** status 201  */ TripImportRead;
export type TripsImportCreateApiArg = {
  'Organization-ID'?: string;
  tripImportViewSet: TripImportViewSetWrite;
};
export type TripsMultiloadListApiResponse =
  /** status 200  */ PaginatedMultiloadListRead;
export type TripsMultiloadListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type TripsMultiloadCreateApiResponse = /** status 201  */ MultiloadRead;
export type TripsMultiloadCreateApiArg = {
  'Organization-ID'?: string;
  multiload: Multiload;
};
export type TripsMultiloadRetrieveApiResponse =
  /** status 200  */ MultiloadRead;
export type TripsMultiloadRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripsMultiloadPartialUpdateApiResponse =
  /** status 200  */ MultiloadRead;
export type TripsMultiloadPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedMultiload: PatchedMultiload;
};
export type TripsMultiloadDestroyApiResponse =
  /** status 204 No response body */ void;
export type TripsMultiloadDestroyApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripsMultiloadMobileRetrieveApiResponse =
  /** status 200  */ MultiloadMobileRead;
export type TripsMultiloadMobileRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type TripsRequestedCountRetrieveApiResponse =
  /** status 200  */ TripRequestedCount;
export type TripsRequestedCountRetrieveApiArg = void;
export type TripsSubscriptionsListApiResponse =
  /** status 200  */ PaginatedTripSubscriptionListRead;
export type TripsSubscriptionsListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  payers?: string;
  rangeEnd?: string;
  rangeStart?: string;
  /** A search term. */
  search?: string;
};
export type TripsSubscriptionsCreateApiResponse =
  /** status 201  */ TripSubscriptionRead;
export type TripsSubscriptionsCreateApiArg = {
  'Organization-ID'?: string;
  tripSubscription: TripSubscriptionWrite;
};
export type TripsSubscriptionsPartialUpdateApiResponse =
  /** status 200  */ TripSubscriptionRead;
export type TripsSubscriptionsPartialUpdateApiArg = {
  'Organization-ID'?: string;
  id: string;
  patchedTripSubscription: PatchedTripSubscriptionWrite;
};
export type TripsSubscriptionsDestroyApiResponse =
  /** status 204 No response body */ void;
export type TripsSubscriptionsDestroyApiArg = {
  'Organization-ID'?: string;
  delete?: boolean;
  id: string;
};
export type TripsSuggestedPickupAtCreateApiResponse =
  /** status 200  */ TripSuggestedPickupTimeRead;
export type TripsSuggestedPickupAtCreateApiArg = {
  'Organization-ID'?: string;
  tripSuggestedPickupTime: TripSuggestedPickupTimeWrite;
};
export type VehicleTagsListApiResponse =
  /** status 200  */ PaginatedVehicleTagListRead;
export type VehicleTagsListApiArg = {
  'Organization-ID'?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type VehicleTagsCreateApiResponse =
  /** status 201  */ VehicleTagBulkCreateRead;
export type VehicleTagsCreateApiArg = {
  'Organization-ID'?: string;
  vehicleTagBulkCreate: VehicleTagBulkCreate;
};
export type VehicleTagsRetrieveApiResponse = /** status 200  */ VehicleTagRead;
export type VehicleTagsRetrieveApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this vehicle tag. */
  id: string;
};
export type VehicleTagsDestroyApiResponse =
  /** status 204 No response body */ void;
export type VehicleTagsDestroyApiArg = {
  'Organization-ID'?: string;
  /** A UUID string identifying this vehicle tag. */
  id: string;
};
export type VehiclesListApiResponse =
  /** status 200  */ PaginatedVehicleListRead;
export type VehiclesListApiArg = {
  'Organization-ID'?: string;
  canBeScheduled?: boolean;
  category?: 'ambulatory' | 'stretcher' | 'wheelchair';
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type VehiclesCreateApiResponse = /** status 201  */ VehicleRead;
export type VehiclesCreateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  vehicle: VehicleWrite;
};
export type VehiclesRetrieveApiResponse = /** status 200  */ VehicleRead;
export type VehiclesRetrieveApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this vehicle. */
  id: string;
};
export type VehiclesPartialUpdateApiResponse = /** status 200  */ VehicleRead;
export type VehiclesPartialUpdateApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this vehicle. */
  id: string;
  patchedVehicle: PatchedVehicleWrite;
};
export type VehiclesDestroyApiResponse =
  /** status 204 No response body */ void;
export type VehiclesDestroyApiArg = {
  'Organization-ID'?: string;
  /** Include deleted objects in the response, or only deleted objects in the response. */
  deletedObjects?: 'include' | 'only';
  /** A UUID string identifying this vehicle. */
  id: string;
};
export type VehiclesInspectionCreateApiResponse =
  /** status 201  */ VehicleInspectionReportRead;
export type VehiclesInspectionCreateApiArg = {
  'Organization-ID'?: string;
  vehicleInspectionReport: VehicleInspectionReportWrite;
};
export type VehiclesInspectionFieldsListApiResponse =
  /** status 200  */ PaginatedVehicleInspectionFieldList;
export type VehiclesInspectionFieldsListApiArg = {
  'Organization-ID'?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type VehiclesInspectionsListApiResponse =
  /** status 200  */ PaginatedVehicleInspectionReportDetailsListRead;
export type VehiclesInspectionsListApiArg = {
  'Organization-ID'?: string;
  assignment?: string;
  createdAt?: string;
  hasFail?: boolean;
  membership?: string;
  /** Multiple values may be separated by commas. */
  membershipIn?: string[];
  /** A page number within the paginated result set. */
  page?: number;
  rangeEnd?: string;
  rangeStart?: string;
  vehicle?: string;
  /** Multiple values may be separated by commas. */
  vehicleIn?: string[];
};
export type VehiclesInspectionsRetrieveApiResponse =
  /** status 200  */ VehicleInspectionReportDetailsRead;
export type VehiclesInspectionsRetrieveApiArg = {
  'Organization-ID'?: string;
  id: string;
};
export type AssignedVehicle = {
  vehicle: string;
};
export type VehicleCategoryEnum = 'wheelchair' | 'stretcher' | 'ambulatory';
export type AssignedVehicleRead = {
  vehicle: string;
  nickname: string;
  category: VehicleCategoryEnum;
  color: string;
  make: string;
  model: string;
  year: number;
  license_plate: string;
};
export type AssignedDriver = {
  driver?: string | null;
};
export type AssignedDriverRead = {
  membership: string;
  first_name: string;
  last_name: string;
  driver?: string | null;
  /** The time when this team member clocked in for their crew assignment */
  clocked_in_at: string | null;
  /** The time when this team member clocked out for their crew assignment */
  clocked_out_at: string | null;
  is_currently_clocked_in: boolean;
};
export type AssignedAttendant = {
  attendant?: string | null;
};
export type AssignedAttendantRead = {
  membership: string;
  first_name: string;
  last_name: string;
  attendant?: string | null;
};
export type Assignment = {
  start: string;
  end: string;
  vehicle: AssignedVehicle;
  driver: AssignedDriver;
  attendants: AssignedAttendant[];
};
export type DriverCallRequest = {
  /** Comment from driver on reason for the call */
  comment?: string;
};
export type DriverCallRequestRead = {
  id: string;
  assignment: string;
  created_at: string;
  /** Membership of driver that created the call request */
  created_by: string;
  /** Membership of non-associate that resolved the call request */
  resolved_by: string | null;
  canceled_at: string | null;
  resolved_at: string | null;
  /** Comment from driver on reason for the call */
  comment?: string;
};
export type AssignmentVehicleInspection = {};
export type AssignmentVehicleInspectionRead = {
  id: string;
};
export type AssignmentBreak = {
  id?: string;
  start: string;
  end: string;
  break_notes?: string;
};
export type AssignmentBreakStatusEnum = 'complete' | 'in progress' | 'upcoming';
export type AssignmentBreakRead = {
  id?: string;
  start: string;
  end: string;
  assignment_id: string;
  break_notes?: string;
  status: AssignmentBreakStatusEnum;
  started_at: string | null;
  ended_at: string | null;
};
export type AssignmentRead = {
  id: string;
  start: string;
  end: string;
  vehicle: AssignedVehicleRead;
  driver: AssignedDriverRead;
  driver_call_requests: DriverCallRequestRead[];
  attendants: AssignedAttendantRead[];
  trip_count: number;
  vehicle_inspections: AssignmentVehicleInspectionRead[];
  breaks: AssignmentBreakRead[];
  will_call_count: number;
};
export type PaginatedAssignmentList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Assignment[];
};
export type PaginatedAssignmentListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentRead[];
};
export type ValidationErrorEnum = 'validation_error';
export type AssignmentsListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type AssignmentsListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type AssignmentsListError =
  | ({
      attr: 'range_start';
    } & AssignmentsListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & AssignmentsListRangeEndErrorComponent);
export type AssignmentsListValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsListError[];
};
export type ClientErrorEnum = 'client_error';
export type ParseErrorCodeEnum = 'parse_error';
export type ParseError = {
  code: ParseErrorCodeEnum;
  detail: string;
  attr: string | null;
};
export type ParseErrorResponse = {
  type: ClientErrorEnum;
  errors: ParseError[];
};
export type AssignmentsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type ErrorCode403Enum = 'permission_denied';
export type Error403 = {
  code: ErrorCode403Enum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse403 = {
  type: ClientErrorEnum;
  errors: Error403[];
};
export type ErrorCode404Enum = 'not_found';
export type Error404 = {
  code: ErrorCode404Enum;
  detail: string;
  attr: string | null;
};
export type ErrorResponse404 = {
  type: ClientErrorEnum;
  errors: Error404[];
};
export type AssignmentBulkCreate = {
  assignments: Assignment[];
};
export type AssignmentBulkCreateRead = {
  assignments: AssignmentRead[];
};
export type AssignmentsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsCreateAssignmentsNonFieldErrorsErrorComponent = {
  attr: 'assignments.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexNonFieldErrorsErrorComponent = {
  attr: 'assignments.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexStartErrorComponent = {
  attr: 'assignments.INDEX.start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexEndErrorComponent = {
  attr: 'assignments.INDEX.end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexVehicleNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.vehicle.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsCreateAssignmentsIndexVehicleVehicleErrorComponent = {
  attr: 'assignments.INDEX.vehicle.vehicle';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexDriverNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.driver.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsCreateAssignmentsIndexDriverDriverErrorComponent = {
  attr: 'assignments.INDEX.driver.driver';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexDriverCallRequestsIndexCommentErrorComponent =
  {
    attr: 'assignments.INDEX.driver_call_requests.INDEX.comment';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsCreateAssignmentsIndexAttendantsNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.attendants.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsCreateAssignmentsIndexAttendantsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.attendants.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsCreateAssignmentsIndexAttendantsIndexAttendantErrorComponent =
  {
    attr: 'assignments.INDEX.attendants.INDEX.attendant';
    code: 'does_not_exist' | 'incorrect_type';
    detail: string;
  };
export type AssignmentsCreateAssignmentsIndexBreaksIndexIdErrorComponent = {
  attr: 'assignments.INDEX.breaks.INDEX.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexBreaksIndexStartErrorComponent = {
  attr: 'assignments.INDEX.breaks.INDEX.start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexBreaksIndexEndErrorComponent = {
  attr: 'assignments.INDEX.breaks.INDEX.end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsCreateAssignmentsIndexBreaksIndexBreakNotesErrorComponent =
  {
    attr: 'assignments.INDEX.breaks.INDEX.break_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsCreateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.non_field_errors';
    } & AssignmentsCreateAssignmentsNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.non_field_errors';
    } & AssignmentsCreateAssignmentsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.start';
    } & AssignmentsCreateAssignmentsIndexStartErrorComponent)
  | ({
      attr: 'assignments.INDEX.end';
    } & AssignmentsCreateAssignmentsIndexEndErrorComponent)
  | ({
      attr: 'assignments.INDEX.vehicle.non_field_errors';
    } & AssignmentsCreateAssignmentsIndexVehicleNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.vehicle.vehicle';
    } & AssignmentsCreateAssignmentsIndexVehicleVehicleErrorComponent)
  | ({
      attr: 'assignments.INDEX.driver.non_field_errors';
    } & AssignmentsCreateAssignmentsIndexDriverNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.driver.driver';
    } & AssignmentsCreateAssignmentsIndexDriverDriverErrorComponent)
  | ({
      attr: 'assignments.INDEX.driver_call_requests.INDEX.comment';
    } & AssignmentsCreateAssignmentsIndexDriverCallRequestsIndexCommentErrorComponent)
  | ({
      attr: 'assignments.INDEX.attendants.non_field_errors';
    } & AssignmentsCreateAssignmentsIndexAttendantsNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.attendants.INDEX.non_field_errors';
    } & AssignmentsCreateAssignmentsIndexAttendantsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.attendants.INDEX.attendant';
    } & AssignmentsCreateAssignmentsIndexAttendantsIndexAttendantErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.id';
    } & AssignmentsCreateAssignmentsIndexBreaksIndexIdErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.start';
    } & AssignmentsCreateAssignmentsIndexBreaksIndexStartErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.end';
    } & AssignmentsCreateAssignmentsIndexBreaksIndexEndErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.break_notes';
    } & AssignmentsCreateAssignmentsIndexBreaksIndexBreakNotesErrorComponent);
export type AssignmentsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsCreateError[];
};
export type AssignmentsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsPartialUpdateStartErrorComponent = {
  attr: 'start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateEndErrorComponent = {
  attr: 'end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateVehicleNonFieldErrorsErrorComponent = {
  attr: 'vehicle.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateVehicleVehicleErrorComponent = {
  attr: 'vehicle.vehicle';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateDriverNonFieldErrorsErrorComponent = {
  attr: 'driver.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateDriverDriverErrorComponent = {
  attr: 'driver.driver';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type AssignmentsPartialUpdateDriverCallRequestsIndexCommentErrorComponent =
  {
    attr: 'driver_call_requests.INDEX.comment';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsPartialUpdateAttendantsNonFieldErrorsErrorComponent = {
  attr: 'attendants.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateAttendantsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'attendants.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsPartialUpdateAttendantsIndexAttendantErrorComponent = {
  attr: 'attendants.INDEX.attendant';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type AssignmentsPartialUpdateBreaksIndexIdErrorComponent = {
  attr: 'breaks.INDEX.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AssignmentsPartialUpdateBreaksIndexStartErrorComponent = {
  attr: 'breaks.INDEX.start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateBreaksIndexEndErrorComponent = {
  attr: 'breaks.INDEX.end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsPartialUpdateBreaksIndexBreakNotesErrorComponent = {
  attr: 'breaks.INDEX.break_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type AssignmentsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'start';
    } & AssignmentsPartialUpdateStartErrorComponent)
  | ({
      attr: 'end';
    } & AssignmentsPartialUpdateEndErrorComponent)
  | ({
      attr: 'vehicle.non_field_errors';
    } & AssignmentsPartialUpdateVehicleNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.vehicle';
    } & AssignmentsPartialUpdateVehicleVehicleErrorComponent)
  | ({
      attr: 'driver.non_field_errors';
    } & AssignmentsPartialUpdateDriverNonFieldErrorsErrorComponent)
  | ({
      attr: 'driver.driver';
    } & AssignmentsPartialUpdateDriverDriverErrorComponent)
  | ({
      attr: 'driver_call_requests.INDEX.comment';
    } & AssignmentsPartialUpdateDriverCallRequestsIndexCommentErrorComponent)
  | ({
      attr: 'attendants.non_field_errors';
    } & AssignmentsPartialUpdateAttendantsNonFieldErrorsErrorComponent)
  | ({
      attr: 'attendants.INDEX.non_field_errors';
    } & AssignmentsPartialUpdateAttendantsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'attendants.INDEX.attendant';
    } & AssignmentsPartialUpdateAttendantsIndexAttendantErrorComponent)
  | ({
      attr: 'breaks.INDEX.id';
    } & AssignmentsPartialUpdateBreaksIndexIdErrorComponent)
  | ({
      attr: 'breaks.INDEX.start';
    } & AssignmentsPartialUpdateBreaksIndexStartErrorComponent)
  | ({
      attr: 'breaks.INDEX.end';
    } & AssignmentsPartialUpdateBreaksIndexEndErrorComponent)
  | ({
      attr: 'breaks.INDEX.break_notes';
    } & AssignmentsPartialUpdateBreaksIndexBreakNotesErrorComponent);
export type AssignmentsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsPartialUpdateError[];
};
export type AssignmentsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedAssignment = {
  start?: string;
  end?: string;
  vehicle?: AssignedVehicle;
  driver?: AssignedDriver;
  attendants?: AssignedAttendant[];
};
export type PatchedAssignmentRead = {
  id?: string;
  start?: string;
  end?: string;
  vehicle?: AssignedVehicleRead;
  driver?: AssignedDriverRead;
  driver_call_requests?: DriverCallRequestRead[];
  attendants?: AssignedAttendantRead[];
  trip_count?: number;
  vehicle_inspections?: AssignmentVehicleInspectionRead[];
  breaks?: AssignmentBreakRead[];
  will_call_count?: number;
};
export type AssignmentsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedAssignmentBreakList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentBreak[];
};
export type PaginatedAssignmentBreakListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentBreakRead[];
};
export type AssignmentsBreaksListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsBreaksCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsBreaksCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AssignmentsBreaksCreateStartErrorComponent = {
  attr: 'start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsBreaksCreateEndErrorComponent = {
  attr: 'end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsBreaksCreateBreakNotesErrorComponent = {
  attr: 'break_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type AssignmentsBreaksCreateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsBreaksCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & AssignmentsBreaksCreateIdErrorComponent)
  | ({
      attr: 'start';
    } & AssignmentsBreaksCreateStartErrorComponent)
  | ({
      attr: 'end';
    } & AssignmentsBreaksCreateEndErrorComponent)
  | ({
      attr: 'break_notes';
    } & AssignmentsBreaksCreateBreakNotesErrorComponent);
export type AssignmentsBreaksCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsBreaksCreateError[];
};
export type AssignmentsBreaksCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsBreaksCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentsBreaksRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsBreaksPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsBreaksPartialUpdateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AssignmentsBreaksPartialUpdateStartErrorComponent = {
  attr: 'start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsBreaksPartialUpdateEndErrorComponent = {
  attr: 'end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsBreaksPartialUpdateBreakNotesErrorComponent = {
  attr: 'break_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type AssignmentsBreaksPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsBreaksPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & AssignmentsBreaksPartialUpdateIdErrorComponent)
  | ({
      attr: 'start';
    } & AssignmentsBreaksPartialUpdateStartErrorComponent)
  | ({
      attr: 'end';
    } & AssignmentsBreaksPartialUpdateEndErrorComponent)
  | ({
      attr: 'break_notes';
    } & AssignmentsBreaksPartialUpdateBreakNotesErrorComponent);
export type AssignmentsBreaksPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsBreaksPartialUpdateError[];
};
export type AssignmentsBreaksPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsBreaksPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedAssignmentBreak = {
  id?: string;
  start?: string;
  end?: string;
  break_notes?: string;
};
export type PatchedAssignmentBreakRead = {
  id?: string;
  start?: string;
  end?: string;
  assignment_id?: string;
  break_notes?: string;
  status?: AssignmentBreakStatusEnum;
  started_at?: string | null;
  ended_at?: string | null;
};
export type AssignmentsBreaksDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsBreaksActiveListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsDriverCallRequestsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsDriverCallRequestsCreateCommentErrorComponent = {
  attr: 'comment';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type AssignmentsDriverCallRequestsCreateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsDriverCallRequestsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'comment';
    } & AssignmentsDriverCallRequestsCreateCommentErrorComponent);
export type AssignmentsDriverCallRequestsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsDriverCallRequestsCreateError[];
};
export type AssignmentsDriverCallRequestsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsDriverCallRequestsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentsDriverCallRequestsCancelPartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: 'non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type AssignmentsDriverCallRequestsCancelPartialUpdateCommentErrorComponent =
  {
    attr: 'comment';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsDriverCallRequestsCancelPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsDriverCallRequestsCancelPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'comment';
    } & AssignmentsDriverCallRequestsCancelPartialUpdateCommentErrorComponent);
export type AssignmentsDriverCallRequestsCancelPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsDriverCallRequestsCancelPartialUpdateError[];
};
export type AssignmentsDriverCallRequestsCancelPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsDriverCallRequestsCancelPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedDriverCallRequest = {
  /** Comment from driver on reason for the call */
  comment?: string;
};
export type PatchedDriverCallRequestRead = {
  id?: string;
  assignment?: string;
  created_at?: string;
  /** Membership of driver that created the call request */
  created_by?: string;
  /** Membership of non-associate that resolved the call request */
  resolved_by?: string | null;
  canceled_at?: string | null;
  resolved_at?: string | null;
  /** Comment from driver on reason for the call */
  comment?: string;
};
export type AssignmentsDriverCallRequestsResolvePartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: 'non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type AssignmentsDriverCallRequestsResolvePartialUpdateCommentErrorComponent =
  {
    attr: 'comment';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsDriverCallRequestsResolvePartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsDriverCallRequestsResolvePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'comment';
    } & AssignmentsDriverCallRequestsResolvePartialUpdateCommentErrorComponent);
export type AssignmentsDriverCallRequestsResolvePartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsDriverCallRequestsResolvePartialUpdateError[];
};
export type AssignmentsDriverCallRequestsResolvePartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsDriverCallRequestsResolvePartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentEventEventTypeEnum =
  | 'assignment_event_start'
  | 'assignment_event_end';
export type AssignmentEvent = {
  event_type: AssignmentEventEventTypeEnum;
};
export type AssignmentEventRead = {
  id: string;
  assignment: string;
  created_at: string;
  event_type: AssignmentEventEventTypeEnum;
  membership: string;
  device_requested_at: string | null;
  longitude: number;
  latitude: number;
  location_queried_at: string | null;
  odometer: number | null;
};
export type PaginatedAssignmentEventList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentEvent[];
};
export type PaginatedAssignmentEventListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentEventRead[];
};
export type AssignmentsEventsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsEventsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsEventsCreateEventTypeErrorComponent = {
  attr: 'event_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type AssignmentsEventsCreateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsEventsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'event_type';
    } & AssignmentsEventsCreateEventTypeErrorComponent);
export type AssignmentsEventsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsEventsCreateError[];
};
export type AssignmentsEventsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsEventsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type OverrideStatusEnum =
  | 'requested'
  | 'pending'
  | 'assigned'
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff'
  | 'completed'
  | 'canceled'
  | 'rejected';
export type SpaceTypeEnum =
  | 'wheelchair'
  | 'wheelchair xl'
  | 'broda chair'
  | 'geri chair'
  | 'stretcher'
  | 'ambulatory';
export type ServiceTypeEnum =
  | 'curb-to-curb'
  | 'door-to-door'
  | 'door-through-door';
export type AssignmentTrip = {
  id?: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** Current status of this trip */
  status?: OverrideStatusEnum;
  scheduled_pickup_at: string;
  estimated_dropoff_at: string;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** The time the trip was canceled */
  canceled_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** Whether the passenger has called in that they are ready to be picked up */
  is_will_call_activated?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
};
export type PassengerMinimalReadOnly = {};
export type PronounEnum = 'she' | 'he' | 'they';
export type GenderEnum = 'female' | 'male' | 'other';
export type PassengerMinimalReadOnlyRead = {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  preferred_name: string;
  pronoun: PronounEnum;
  /** Patient gender according to their primary insurance */
  gender: GenderEnum;
  dob: string | null;
  weight: number | null;
  /** Passenger phone number (unique in organization) */
  phone_number: string | null;
  /** email address (unique in organization) */
  email: string | null;
  notes: string;
};
export type PurposeEnum = 'pickup' | 'dropoff';
export type StairEquipmentEnum = 'ramp' | 'stair-chair';
export type BlankEnum = '';
export type AssignmentTripLocationDetail = {
  id?: string;
  /** Purpose of this location, pickup, dropoff, etc. */
  purpose: PurposeEnum;
  /** The number of stairs at this location */
  num_stairs?: number;
  /** Stair equipment required for this location */
  stair_equipment?: StairEquipmentEnum | BlankEnum;
  /** Name of contact for this location, if not passenger */
  contact_name?: string;
  /** Phone number of contact for this location, if not passenger */
  contact_phone_number?: string | null;
  /** Notes visible to the driver for this location */
  driver_notes?: string;
};
export type AssignmentTripLocation = {
  /** numerical portion of address, ex. 5C */
  address_number?: string;
  /** ISO 3166 3-digit country/region code, ex. CAN */
  country?: string;
  /** full address */
  label?: string;
  /** local area, ex. Toronto */
  municipality?: string;
  /** community/district, ex. downtown */
  neighborhood?: string;
  /** letters/numbers in country-specific format */
  postal_code?: string;
  region?: string;
  sub_region?: string;
  street?: string;
  unit_number?: string;
  /** ex., apartment */
  unit_type?: string;
  latitude: number;
  longitude: number;
};
export type AssignmentTripLocationRead = {
  /** numerical portion of address, ex. 5C */
  address_number?: string;
  /** ISO 3166 3-digit country/region code, ex. CAN */
  country?: string;
  /** full address */
  label?: string;
  detail: string;
  /** local area, ex. Toronto */
  municipality?: string;
  /** community/district, ex. downtown */
  neighborhood?: string;
  /** letters/numbers in country-specific format */
  postal_code?: string;
  region?: string;
  sub_region?: string;
  street?: string;
  unit_number?: string;
  /** ex., apartment */
  unit_type?: string;
  latitude: number;
  longitude: number;
};
export type AssignmentTripLocationDetailRead = {
  id?: string;
  /** Purpose of this location, pickup, dropoff, etc. */
  purpose: PurposeEnum;
  location: AssignmentTripLocationRead;
  /** The number of stairs at this location */
  num_stairs?: number;
  /** Stair equipment required for this location */
  stair_equipment?: StairEquipmentEnum | BlankEnum;
  /** Name of contact for this location, if not passenger */
  contact_name?: string;
  /** Phone number of contact for this location, if not passenger */
  contact_phone_number?: string | null;
  /** Notes visible to the driver for this location */
  driver_notes?: string;
};
export type MissingRequiredSignaturesEnum =
  | 'pickup_passenger'
  | 'dropoff_passenger'
  | 'pickup_driver'
  | 'dropoff_driver';
export type AssignmentTripPayer = {};
export type PayerTypeEnum = 'insurance' | 'broker' | 'private';
export type AssignmentTripPayerSettings = {};
export type AssignmentTripPayerSettingsRead = {
  requires_signature_pickup_passenger: boolean;
  requires_signature_dropoff_passenger: boolean;
  requires_signature_pickup_driver: boolean;
  requires_signature_dropoff_driver: boolean;
};
export type AssignmentTripPayerRead = {
  id: string;
  /** Name the organization uses to refer to this payer across the app */
  display_name: string;
  payer_type: PayerTypeEnum;
  settings: AssignmentTripPayerSettingsRead;
};
export type AssignmentTripTiming = {};
export type AssignmentTripTimingRead = {
  /** Estimated arrival at pickup location per vehicle's last location log */
  estimated_pickup_arrival_time: string | null;
  is_on_time: boolean | null;
  delayed_by_seconds: number | null;
  /** Last time this timing was calculated */
  last_calculated_at: string | null;
  /** Estimated time this trip will be completed per vehicle's last location log */
  estimated_completion_time: string | null;
};
export type AssignmentTripRead = {
  id?: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** Current status of this trip */
  status?: OverrideStatusEnum;
  passenger: PassengerMinimalReadOnlyRead;
  scheduled_pickup_at: string;
  estimated_dropoff_at: string;
  current_task: PurposeEnum;
  current_task_at: string;
  pickup: AssignmentTripLocationDetailRead;
  dropoff: AssignmentTripLocationDetailRead;
  /** List of required signatures considered missing for trip status. */
  missing_required_signatures: MissingRequiredSignaturesEnum[];
  multiload_id: string | null;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** The time the trip was canceled */
  canceled_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** Whether the passenger has called in that they are ready to be picked up */
  is_will_call_activated?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min: number | null;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  payer: AssignmentTripPayerRead;
  timing: AssignmentTripTimingRead | null;
};
export type PaginatedAssignmentTripList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentTrip[];
};
export type PaginatedAssignmentTripListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentTripRead[];
};
export type AssignmentsTripsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsTripsActiveListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignedMembership = {
  start: string;
  end: string;
  /** The time when this team member clocked out for their crew assignment */
  clocked_out_at?: string | null;
};
export type AssignedMembershipRead = {
  id: string;
  assignment: string;
  start: string;
  end: string;
  membership: string;
  driver: string | null;
  attendant: string | null;
  /** The time when this team member clocked in for their crew assignment */
  clocked_in_at: string | null;
  /** The time when this team member clocked out for their crew assignment */
  clocked_out_at?: string | null;
};
export type AssignmentsAssignedMembershipsPartialUpdateNonFieldErrorsErrorComponent =
  {
    attr: 'non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type AssignmentsAssignedMembershipsPartialUpdateStartErrorComponent = {
  attr: 'start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsAssignedMembershipsPartialUpdateEndErrorComponent = {
  attr: 'end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsAssignedMembershipsPartialUpdateClockedOutAtErrorComponent =
  {
    attr: 'clocked_out_at';
    code: 'date' | 'invalid' | 'make_aware' | 'overflow';
    detail: string;
  };
export type AssignmentsAssignedMembershipsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsAssignedMembershipsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'start';
    } & AssignmentsAssignedMembershipsPartialUpdateStartErrorComponent)
  | ({
      attr: 'end';
    } & AssignmentsAssignedMembershipsPartialUpdateEndErrorComponent)
  | ({
      attr: 'clocked_out_at';
    } & AssignmentsAssignedMembershipsPartialUpdateClockedOutAtErrorComponent);
export type AssignmentsAssignedMembershipsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsAssignedMembershipsPartialUpdateError[];
};
export type AssignmentsAssignedMembershipsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsAssignedMembershipsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedAssignedMembership = {
  start?: string;
  end?: string;
  /** The time when this team member clocked out for their crew assignment */
  clocked_out_at?: string | null;
};
export type PatchedAssignedMembershipRead = {
  id?: string;
  assignment?: string;
  start?: string;
  end?: string;
  membership?: string;
  driver?: string | null;
  attendant?: string | null;
  /** The time when this team member clocked in for their crew assignment */
  clocked_in_at?: string | null;
  /** The time when this team member clocked out for their crew assignment */
  clocked_out_at?: string | null;
};
export type AssignmentBreakEventEventTypeEnum =
  | 'assignment_break_event_start'
  | 'assignment_break_event_end';
export type AssignmentBreakEvent = {
  event_type: AssignmentBreakEventEventTypeEnum;
};
export type AssignmentBreakEventRead = {
  id: string;
  assignment_break: string;
  created_at: string;
  event_type: AssignmentBreakEventEventTypeEnum;
  membership: string;
  device_requested_at: string | null;
  longitude: number;
  latitude: number;
  location_queried_at: string | null;
  odometer: number | null;
};
export type PaginatedAssignmentBreakEventList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentBreakEvent[];
};
export type PaginatedAssignmentBreakEventListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentBreakEventRead[];
};
export type AssignmentsBreaksEventsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsBreaksEventsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsBreaksEventsCreateEventTypeErrorComponent = {
  attr: 'event_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type AssignmentsBreaksEventsCreateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsBreaksEventsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'event_type';
    } & AssignmentsBreaksEventsCreateEventTypeErrorComponent);
export type AssignmentsBreaksEventsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsBreaksEventsCreateError[];
};
export type AssignmentsBreaksEventsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsBreaksEventsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkAssignmentDefault = {
  id?: string;
  /** a (sorted) list of attendant ids */
  attendant_ids?: string[];
  sunday_start?: string | null;
  sunday_duration_minutes?: number | null;
  monday_start?: string | null;
  monday_duration_minutes?: number | null;
  tuesday_start?: string | null;
  tuesday_duration_minutes?: number | null;
  wednesday_start?: string | null;
  wednesday_duration_minutes?: number | null;
  thursday_start?: string | null;
  thursday_duration_minutes?: number | null;
  friday_start?: string | null;
  friday_duration_minutes?: number | null;
  saturday_start?: string | null;
  saturday_duration_minutes?: number | null;
};
export type BulkAssignmentDefaultRead = {
  id?: string;
  vehicle_id: string;
  driver_id: string;
  /** a (sorted) list of attendant ids */
  attendant_ids?: string[];
  sunday_start?: string | null;
  sunday_duration_minutes?: number | null;
  monday_start?: string | null;
  monday_duration_minutes?: number | null;
  tuesday_start?: string | null;
  tuesday_duration_minutes?: number | null;
  wednesday_start?: string | null;
  wednesday_duration_minutes?: number | null;
  thursday_start?: string | null;
  thursday_duration_minutes?: number | null;
  friday_start?: string | null;
  friday_duration_minutes?: number | null;
  saturday_start?: string | null;
  saturday_duration_minutes?: number | null;
};
export type PaginatedBulkAssignmentDefaultList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: BulkAssignmentDefault[];
};
export type PaginatedBulkAssignmentDefaultListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: BulkAssignmentDefaultRead[];
};
export type AssignmentsBulkDefaultVehicleDriverListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsDriverRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AssignmentsDriverListListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type AssignmentsDriverListListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type AssignmentsDriverListListError =
  | ({
      attr: 'range_start';
    } & AssignmentsDriverListListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & AssignmentsDriverListListRangeEndErrorComponent);
export type AssignmentsDriverListListValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsDriverListListError[];
};
export type AssignmentsDriverListListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsDriverListListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentLocation = {
  start: string;
  end: string;
  vehicle: AssignedVehicle;
  driver: AssignedDriver;
  attendants: AssignedAttendant[];
};
export type LocationLogEntryRead = {
  uuid: string;
  location_log_entry: string;
  timestamp: string;
  latitude: number;
  longitude: number;
  odometer: number;
  heading: number;
};
export type AssignmentLocationRead = {
  id: string;
  start: string;
  end: string;
  latest_location: LocationLogEntryRead;
  vehicle: AssignedVehicleRead;
  driver: AssignedDriverRead;
  attendants: AssignedAttendantRead[];
  trip_count: number;
};
export type PaginatedAssignmentLocationList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentLocation[];
};
export type PaginatedAssignmentLocationListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AssignmentLocationRead[];
};
export type AssignmentsLocationsListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type AssignmentsLocationsListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type AssignmentsLocationsListError =
  | ({
      attr: 'range_start';
    } & AssignmentsLocationsListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & AssignmentsLocationsListRangeEndErrorComponent);
export type AssignmentsLocationsListValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsLocationsListError[];
};
export type AssignmentsLocationsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsLocationsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentsMemberListListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type AssignmentsMemberListListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type AssignmentsMemberListListError =
  | ({
      attr: 'range_start';
    } & AssignmentsMemberListListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & AssignmentsMemberListListRangeEndErrorComponent);
export type AssignmentsMemberListListValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsMemberListListError[];
};
export type AssignmentsMemberListListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsMemberListListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AssignmentTimeRangeBulkCreate = {};
export type AssignmentTimeRangeBulkCreateRead = {
  assignments: AssignmentRead[];
};
export type AssignedDays = {};
export type DayEnum =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
export type AssignedDaysWrite = {
  day: DayEnum;
  start: string;
  duration_minutes: number;
};
export type AssignmentTimeRangeBulkCreateWrite = {
  range_start: string;
  range_end: string;
  vehicle: AssignedVehicle;
  driver: AssignedDriver;
  attendants: AssignedAttendant[];
  save_as_default: boolean;
  assigned_days: AssignedDaysWrite[];
};
export type AssignmentsTimeRangeCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AssignmentsTimeRangeCreateAssignmentsIndexStartErrorComponent = {
  attr: 'assignments.INDEX.start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateAssignmentsIndexEndErrorComponent = {
  attr: 'assignments.INDEX.end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateAssignmentsIndexVehicleNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.vehicle.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexVehicleVehicleErrorComponent =
  {
    attr: 'assignments.INDEX.vehicle.vehicle';
    code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexDriverNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.driver.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexDriverDriverErrorComponent =
  {
    attr: 'assignments.INDEX.driver.driver';
    code: 'does_not_exist' | 'incorrect_type';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexDriverCallRequestsIndexCommentErrorComponent =
  {
    attr: 'assignments.INDEX.driver_call_requests.INDEX.comment';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexAttendantsNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.attendants.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexAttendantsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'assignments.INDEX.attendants.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexAttendantsIndexAttendantErrorComponent =
  {
    attr: 'assignments.INDEX.attendants.INDEX.attendant';
    code: 'does_not_exist' | 'incorrect_type';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexIdErrorComponent =
  {
    attr: 'assignments.INDEX.breaks.INDEX.id';
    code: 'invalid' | 'null' | 'unique';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexStartErrorComponent =
  {
    attr: 'assignments.INDEX.breaks.INDEX.start';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexEndErrorComponent =
  {
    attr: 'assignments.INDEX.breaks.INDEX.end';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexBreakNotesErrorComponent =
  {
    attr: 'assignments.INDEX.breaks.INDEX.break_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type AssignmentsTimeRangeCreateRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateVehicleNonFieldErrorsErrorComponent = {
  attr: 'vehicle.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateVehicleVehicleErrorComponent = {
  attr: 'vehicle.vehicle';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateDriverNonFieldErrorsErrorComponent = {
  attr: 'driver.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateDriverDriverErrorComponent = {
  attr: 'driver.driver';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type AssignmentsTimeRangeCreateAttendantsNonFieldErrorsErrorComponent = {
  attr: 'attendants.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateAttendantsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'attendants.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAttendantsIndexAttendantErrorComponent = {
  attr: 'attendants.INDEX.attendant';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type AssignmentsTimeRangeCreateSaveAsDefaultErrorComponent = {
  attr: 'save_as_default';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateAssignedDaysNonFieldErrorsErrorComponent =
  {
    attr: 'assigned_days.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignedDaysIndexNonFieldErrorsErrorComponent =
  {
    attr: 'assigned_days.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateAssignedDaysIndexDayErrorComponent = {
  attr: 'assigned_days.INDEX.day';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateAssignedDaysIndexStartErrorComponent = {
  attr: 'assigned_days.INDEX.start';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AssignmentsTimeRangeCreateAssignedDaysIndexDurationMinutesErrorComponent =
  {
    attr: 'assigned_days.INDEX.duration_minutes';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type AssignmentsTimeRangeCreateError =
  | ({
      attr: 'non_field_errors';
    } & AssignmentsTimeRangeCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.start';
    } & AssignmentsTimeRangeCreateAssignmentsIndexStartErrorComponent)
  | ({
      attr: 'assignments.INDEX.end';
    } & AssignmentsTimeRangeCreateAssignmentsIndexEndErrorComponent)
  | ({
      attr: 'assignments.INDEX.vehicle.non_field_errors';
    } & AssignmentsTimeRangeCreateAssignmentsIndexVehicleNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.vehicle.vehicle';
    } & AssignmentsTimeRangeCreateAssignmentsIndexVehicleVehicleErrorComponent)
  | ({
      attr: 'assignments.INDEX.driver.non_field_errors';
    } & AssignmentsTimeRangeCreateAssignmentsIndexDriverNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.driver.driver';
    } & AssignmentsTimeRangeCreateAssignmentsIndexDriverDriverErrorComponent)
  | ({
      attr: 'assignments.INDEX.driver_call_requests.INDEX.comment';
    } & AssignmentsTimeRangeCreateAssignmentsIndexDriverCallRequestsIndexCommentErrorComponent)
  | ({
      attr: 'assignments.INDEX.attendants.non_field_errors';
    } & AssignmentsTimeRangeCreateAssignmentsIndexAttendantsNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.attendants.INDEX.non_field_errors';
    } & AssignmentsTimeRangeCreateAssignmentsIndexAttendantsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignments.INDEX.attendants.INDEX.attendant';
    } & AssignmentsTimeRangeCreateAssignmentsIndexAttendantsIndexAttendantErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.id';
    } & AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexIdErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.start';
    } & AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexStartErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.end';
    } & AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexEndErrorComponent)
  | ({
      attr: 'assignments.INDEX.breaks.INDEX.break_notes';
    } & AssignmentsTimeRangeCreateAssignmentsIndexBreaksIndexBreakNotesErrorComponent)
  | ({
      attr: 'range_start';
    } & AssignmentsTimeRangeCreateRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & AssignmentsTimeRangeCreateRangeEndErrorComponent)
  | ({
      attr: 'vehicle.non_field_errors';
    } & AssignmentsTimeRangeCreateVehicleNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.vehicle';
    } & AssignmentsTimeRangeCreateVehicleVehicleErrorComponent)
  | ({
      attr: 'driver.non_field_errors';
    } & AssignmentsTimeRangeCreateDriverNonFieldErrorsErrorComponent)
  | ({
      attr: 'driver.driver';
    } & AssignmentsTimeRangeCreateDriverDriverErrorComponent)
  | ({
      attr: 'attendants.non_field_errors';
    } & AssignmentsTimeRangeCreateAttendantsNonFieldErrorsErrorComponent)
  | ({
      attr: 'attendants.INDEX.non_field_errors';
    } & AssignmentsTimeRangeCreateAttendantsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'attendants.INDEX.attendant';
    } & AssignmentsTimeRangeCreateAttendantsIndexAttendantErrorComponent)
  | ({
      attr: 'save_as_default';
    } & AssignmentsTimeRangeCreateSaveAsDefaultErrorComponent)
  | ({
      attr: 'assigned_days.non_field_errors';
    } & AssignmentsTimeRangeCreateAssignedDaysNonFieldErrorsErrorComponent)
  | ({
      attr: 'assigned_days.INDEX.non_field_errors';
    } & AssignmentsTimeRangeCreateAssignedDaysIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'assigned_days.INDEX.day';
    } & AssignmentsTimeRangeCreateAssignedDaysIndexDayErrorComponent)
  | ({
      attr: 'assigned_days.INDEX.start';
    } & AssignmentsTimeRangeCreateAssignedDaysIndexStartErrorComponent)
  | ({
      attr: 'assigned_days.INDEX.duration_minutes';
    } & AssignmentsTimeRangeCreateAssignedDaysIndexDurationMinutesErrorComponent);
export type AssignmentsTimeRangeCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AssignmentsTimeRangeCreateError[];
};
export type AssignmentsTimeRangeCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AssignmentsTimeRangeCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type Attendant = {
  can_be_scheduled?: boolean;
  membership: string;
};
export type AttendantSkills = {
  id?: string;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
};
export type AttendantSkillsWrite = {
  id?: string;
  attendant: string;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
};
export type AttendantRead = {
  id: string;
  can_be_scheduled?: boolean;
  membership: string;
  organization_id: string;
  first_name: string;
  last_name: string;
  role: string;
  skills: AttendantSkills;
};
export type AttendantWrite = {
  can_be_scheduled?: boolean;
  membership: string;
};
export type PaginatedAttendantList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Attendant[];
};
export type PaginatedAttendantListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AttendantRead[];
};
export type PaginatedAttendantListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AttendantWrite[];
};
export type AttendantsListIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null_characters_not_allowed';
  detail: string;
};
export type AttendantsListError = {
  attr: 'id';
} & AttendantsListIdErrorComponent;
export type AttendantsListValidationError = {
  type: ValidationErrorEnum;
  errors: AttendantsListError[];
};
export type AttendantsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AttendantsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AttendantsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AttendantsCreateCanBeScheduledErrorComponent = {
  attr: 'can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateMembershipErrorComponent = {
  attr: 'membership';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required' | 'unique';
  detail: string;
};
export type AttendantsCreateSkillsIdErrorComponent = {
  attr: 'skills.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AttendantsCreateSkillsAttendantErrorComponent = {
  attr: 'skills.attendant';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AttendantsCreateSkillsCanDoWheelchairServiceErrorComponent = {
  attr: 'skills.can_do_wheelchair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoWheelchairXlServiceErrorComponent = {
  attr: 'skills.can_do_wheelchair_xl_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoBrodaChairServiceErrorComponent = {
  attr: 'skills.can_do_broda_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoGeriChairServiceErrorComponent = {
  attr: 'skills.can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoStretcherServiceErrorComponent = {
  attr: 'skills.can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoAmbulatoryServiceErrorComponent = {
  attr: 'skills.can_do_ambulatory_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoCurbToCurbErrorComponent = {
  attr: 'skills.can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoDoorToDoorErrorComponent = {
  attr: 'skills.can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoDoorThroughDoorErrorComponent = {
  attr: 'skills.can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoBariatricErrorComponent = {
  attr: 'skills.can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateSkillsCanDoStairsErrorComponent = {
  attr: 'skills.can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsCreateError =
  | ({
      attr: 'non_field_errors';
    } & AttendantsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'can_be_scheduled';
    } & AttendantsCreateCanBeScheduledErrorComponent)
  | ({
      attr: 'membership';
    } & AttendantsCreateMembershipErrorComponent)
  | ({
      attr: 'skills.id';
    } & AttendantsCreateSkillsIdErrorComponent)
  | ({
      attr: 'skills.attendant';
    } & AttendantsCreateSkillsAttendantErrorComponent)
  | ({
      attr: 'skills.can_do_wheelchair_service';
    } & AttendantsCreateSkillsCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'skills.can_do_wheelchair_xl_service';
    } & AttendantsCreateSkillsCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'skills.can_do_broda_chair_service';
    } & AttendantsCreateSkillsCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'skills.can_do_geri_chair_service';
    } & AttendantsCreateSkillsCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'skills.can_do_stretcher_service';
    } & AttendantsCreateSkillsCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'skills.can_do_ambulatory_service';
    } & AttendantsCreateSkillsCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'skills.can_do_curb_to_curb';
    } & AttendantsCreateSkillsCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'skills.can_do_door_to_door';
    } & AttendantsCreateSkillsCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'skills.can_do_door_through_door';
    } & AttendantsCreateSkillsCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'skills.can_do_bariatric';
    } & AttendantsCreateSkillsCanDoBariatricErrorComponent)
  | ({
      attr: 'skills.can_do_stairs';
    } & AttendantsCreateSkillsCanDoStairsErrorComponent);
export type AttendantsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AttendantsCreateError[];
};
export type AttendantsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AttendantsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AttendantsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AttendantsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AttendantsPartialUpdateCanBeScheduledErrorComponent = {
  attr: 'can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateMembershipErrorComponent = {
  attr: 'membership';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required' | 'unique';
  detail: string;
};
export type AttendantsPartialUpdateSkillsIdErrorComponent = {
  attr: 'skills.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AttendantsPartialUpdateSkillsAttendantErrorComponent = {
  attr: 'skills.attendant';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoWheelchairServiceErrorComponent =
  {
    attr: 'skills.can_do_wheelchair_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsPartialUpdateSkillsCanDoWheelchairXlServiceErrorComponent =
  {
    attr: 'skills.can_do_wheelchair_xl_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsPartialUpdateSkillsCanDoBrodaChairServiceErrorComponent =
  {
    attr: 'skills.can_do_broda_chair_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsPartialUpdateSkillsCanDoGeriChairServiceErrorComponent = {
  attr: 'skills.can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoStretcherServiceErrorComponent = {
  attr: 'skills.can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoAmbulatoryServiceErrorComponent =
  {
    attr: 'skills.can_do_ambulatory_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsPartialUpdateSkillsCanDoCurbToCurbErrorComponent = {
  attr: 'skills.can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoDoorToDoorErrorComponent = {
  attr: 'skills.can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoDoorThroughDoorErrorComponent = {
  attr: 'skills.can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoBariatricErrorComponent = {
  attr: 'skills.can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateSkillsCanDoStairsErrorComponent = {
  attr: 'skills.can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AttendantsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'can_be_scheduled';
    } & AttendantsPartialUpdateCanBeScheduledErrorComponent)
  | ({
      attr: 'membership';
    } & AttendantsPartialUpdateMembershipErrorComponent)
  | ({
      attr: 'skills.id';
    } & AttendantsPartialUpdateSkillsIdErrorComponent)
  | ({
      attr: 'skills.attendant';
    } & AttendantsPartialUpdateSkillsAttendantErrorComponent)
  | ({
      attr: 'skills.can_do_wheelchair_service';
    } & AttendantsPartialUpdateSkillsCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'skills.can_do_wheelchair_xl_service';
    } & AttendantsPartialUpdateSkillsCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'skills.can_do_broda_chair_service';
    } & AttendantsPartialUpdateSkillsCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'skills.can_do_geri_chair_service';
    } & AttendantsPartialUpdateSkillsCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'skills.can_do_stretcher_service';
    } & AttendantsPartialUpdateSkillsCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'skills.can_do_ambulatory_service';
    } & AttendantsPartialUpdateSkillsCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'skills.can_do_curb_to_curb';
    } & AttendantsPartialUpdateSkillsCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'skills.can_do_door_to_door';
    } & AttendantsPartialUpdateSkillsCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'skills.can_do_door_through_door';
    } & AttendantsPartialUpdateSkillsCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'skills.can_do_bariatric';
    } & AttendantsPartialUpdateSkillsCanDoBariatricErrorComponent)
  | ({
      attr: 'skills.can_do_stairs';
    } & AttendantsPartialUpdateSkillsCanDoStairsErrorComponent);
export type AttendantsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AttendantsPartialUpdateError[];
};
export type AttendantsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AttendantsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedAttendant = {
  can_be_scheduled?: boolean;
  membership?: string;
};
export type PatchedAttendantRead = {
  id?: string;
  can_be_scheduled?: boolean;
  membership?: string;
  organization_id?: string;
  first_name?: string;
  last_name?: string;
  role?: string;
  skills?: AttendantSkills;
};
export type PatchedAttendantWrite = {
  can_be_scheduled?: boolean;
  membership?: string;
};
export type AttendantsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AttendantsSkillsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AttendantsSkillsCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AttendantsSkillsCreateAttendantErrorComponent = {
  attr: 'attendant';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AttendantsSkillsCreateCanDoWheelchairServiceErrorComponent = {
  attr: 'can_do_wheelchair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoWheelchairXlServiceErrorComponent = {
  attr: 'can_do_wheelchair_xl_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoBrodaChairServiceErrorComponent = {
  attr: 'can_do_broda_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoGeriChairServiceErrorComponent = {
  attr: 'can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoStretcherServiceErrorComponent = {
  attr: 'can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoAmbulatoryServiceErrorComponent = {
  attr: 'can_do_ambulatory_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoCurbToCurbErrorComponent = {
  attr: 'can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoDoorToDoorErrorComponent = {
  attr: 'can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoDoorThroughDoorErrorComponent = {
  attr: 'can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoBariatricErrorComponent = {
  attr: 'can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateCanDoStairsErrorComponent = {
  attr: 'can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsCreateError =
  | ({
      attr: 'non_field_errors';
    } & AttendantsSkillsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & AttendantsSkillsCreateIdErrorComponent)
  | ({
      attr: 'attendant';
    } & AttendantsSkillsCreateAttendantErrorComponent)
  | ({
      attr: 'can_do_wheelchair_service';
    } & AttendantsSkillsCreateCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'can_do_wheelchair_xl_service';
    } & AttendantsSkillsCreateCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'can_do_broda_chair_service';
    } & AttendantsSkillsCreateCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'can_do_geri_chair_service';
    } & AttendantsSkillsCreateCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'can_do_stretcher_service';
    } & AttendantsSkillsCreateCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'can_do_ambulatory_service';
    } & AttendantsSkillsCreateCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'can_do_curb_to_curb';
    } & AttendantsSkillsCreateCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'can_do_door_to_door';
    } & AttendantsSkillsCreateCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'can_do_door_through_door';
    } & AttendantsSkillsCreateCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'can_do_bariatric';
    } & AttendantsSkillsCreateCanDoBariatricErrorComponent)
  | ({
      attr: 'can_do_stairs';
    } & AttendantsSkillsCreateCanDoStairsErrorComponent);
export type AttendantsSkillsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: AttendantsSkillsCreateError[];
};
export type AttendantsSkillsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AttendantsSkillsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AttendantsSkillsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AttendantsSkillsUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AttendantsSkillsUpdateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AttendantsSkillsUpdateAttendantErrorComponent = {
  attr: 'attendant';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoWheelchairServiceErrorComponent = {
  attr: 'can_do_wheelchair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoWheelchairXlServiceErrorComponent = {
  attr: 'can_do_wheelchair_xl_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoBrodaChairServiceErrorComponent = {
  attr: 'can_do_broda_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoGeriChairServiceErrorComponent = {
  attr: 'can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoStretcherServiceErrorComponent = {
  attr: 'can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoAmbulatoryServiceErrorComponent = {
  attr: 'can_do_ambulatory_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoCurbToCurbErrorComponent = {
  attr: 'can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoDoorToDoorErrorComponent = {
  attr: 'can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoDoorThroughDoorErrorComponent = {
  attr: 'can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoBariatricErrorComponent = {
  attr: 'can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateCanDoStairsErrorComponent = {
  attr: 'can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AttendantsSkillsUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & AttendantsSkillsUpdateIdErrorComponent)
  | ({
      attr: 'attendant';
    } & AttendantsSkillsUpdateAttendantErrorComponent)
  | ({
      attr: 'can_do_wheelchair_service';
    } & AttendantsSkillsUpdateCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'can_do_wheelchair_xl_service';
    } & AttendantsSkillsUpdateCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'can_do_broda_chair_service';
    } & AttendantsSkillsUpdateCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'can_do_geri_chair_service';
    } & AttendantsSkillsUpdateCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'can_do_stretcher_service';
    } & AttendantsSkillsUpdateCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'can_do_ambulatory_service';
    } & AttendantsSkillsUpdateCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'can_do_curb_to_curb';
    } & AttendantsSkillsUpdateCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'can_do_door_to_door';
    } & AttendantsSkillsUpdateCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'can_do_door_through_door';
    } & AttendantsSkillsUpdateCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'can_do_bariatric';
    } & AttendantsSkillsUpdateCanDoBariatricErrorComponent)
  | ({
      attr: 'can_do_stairs';
    } & AttendantsSkillsUpdateCanDoStairsErrorComponent);
export type AttendantsSkillsUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AttendantsSkillsUpdateError[];
};
export type AttendantsSkillsUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AttendantsSkillsUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AttendantsSkillsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type AttendantsSkillsPartialUpdateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type AttendantsSkillsPartialUpdateAttendantErrorComponent = {
  attr: 'attendant';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoWheelchairServiceErrorComponent =
  {
    attr: 'can_do_wheelchair_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsSkillsPartialUpdateCanDoWheelchairXlServiceErrorComponent =
  {
    attr: 'can_do_wheelchair_xl_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsSkillsPartialUpdateCanDoBrodaChairServiceErrorComponent =
  {
    attr: 'can_do_broda_chair_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsSkillsPartialUpdateCanDoGeriChairServiceErrorComponent = {
  attr: 'can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoStretcherServiceErrorComponent = {
  attr: 'can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoAmbulatoryServiceErrorComponent =
  {
    attr: 'can_do_ambulatory_service';
    code: 'invalid' | 'null';
    detail: string;
  };
export type AttendantsSkillsPartialUpdateCanDoCurbToCurbErrorComponent = {
  attr: 'can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoDoorToDoorErrorComponent = {
  attr: 'can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoDoorThroughDoorErrorComponent = {
  attr: 'can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoBariatricErrorComponent = {
  attr: 'can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateCanDoStairsErrorComponent = {
  attr: 'can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type AttendantsSkillsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & AttendantsSkillsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & AttendantsSkillsPartialUpdateIdErrorComponent)
  | ({
      attr: 'attendant';
    } & AttendantsSkillsPartialUpdateAttendantErrorComponent)
  | ({
      attr: 'can_do_wheelchair_service';
    } & AttendantsSkillsPartialUpdateCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'can_do_wheelchair_xl_service';
    } & AttendantsSkillsPartialUpdateCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'can_do_broda_chair_service';
    } & AttendantsSkillsPartialUpdateCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'can_do_geri_chair_service';
    } & AttendantsSkillsPartialUpdateCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'can_do_stretcher_service';
    } & AttendantsSkillsPartialUpdateCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'can_do_ambulatory_service';
    } & AttendantsSkillsPartialUpdateCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'can_do_curb_to_curb';
    } & AttendantsSkillsPartialUpdateCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'can_do_door_to_door';
    } & AttendantsSkillsPartialUpdateCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'can_do_door_through_door';
    } & AttendantsSkillsPartialUpdateCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'can_do_bariatric';
    } & AttendantsSkillsPartialUpdateCanDoBariatricErrorComponent)
  | ({
      attr: 'can_do_stairs';
    } & AttendantsSkillsPartialUpdateCanDoStairsErrorComponent);
export type AttendantsSkillsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: AttendantsSkillsPartialUpdateError[];
};
export type AttendantsSkillsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & AttendantsSkillsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedAttendantSkills = {
  id?: string;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
};
export type PatchedAttendantSkillsWrite = {
  id?: string;
  attendant?: string;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
};
export type AttendantsSkillsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type BillingSettings = {
  invoice_seed_number?: number;
  invoice_prefix?: string;
  phone_number?: string;
  email?: string;
};
export type BillingSettingsRead = {
  id: string;
  invoice_seed_number?: number;
  invoice_prefix?: string;
  phone_number?: string;
  email?: string;
};
export type BillingBillingSettingsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type BillingBillingSettingsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BillingBillingSettingsPartialUpdateInvoiceSeedNumberErrorComponent =
  {
    attr: 'invoice_seed_number';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type BillingBillingSettingsPartialUpdateInvoicePrefixErrorComponent = {
  attr: 'invoice_prefix';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BillingBillingSettingsPartialUpdatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BillingBillingSettingsPartialUpdateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BillingBillingSettingsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & BillingBillingSettingsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'invoice_seed_number';
    } & BillingBillingSettingsPartialUpdateInvoiceSeedNumberErrorComponent)
  | ({
      attr: 'invoice_prefix';
    } & BillingBillingSettingsPartialUpdateInvoicePrefixErrorComponent)
  | ({
      attr: 'phone_number';
    } & BillingBillingSettingsPartialUpdatePhoneNumberErrorComponent)
  | ({
      attr: 'email';
    } & BillingBillingSettingsPartialUpdateEmailErrorComponent);
export type BillingBillingSettingsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: BillingBillingSettingsPartialUpdateError[];
};
export type BillingBillingSettingsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BillingBillingSettingsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedBillingSettings = {
  invoice_seed_number?: number;
  invoice_prefix?: string;
  phone_number?: string;
  email?: string;
};
export type PatchedBillingSettingsRead = {
  id?: string;
  invoice_seed_number?: number;
  invoice_prefix?: string;
  phone_number?: string;
  email?: string;
};
export type InvoiceNumberGenerator = {};
export type InvoiceNumberGeneratorRead = {
  invoice_prefix: string;
  next_invoice_number: number;
};
export type BillingBillingSettingsNextInvoiceNumberRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PatchedTripBillingPassenger = {
  id?: string;
  first_name?: string;
  last_name?: string;
  preferred_name?: string;
  pronoun?: string;
  gender?: string;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  phone_number?: string;
  email?: string;
  notes?: string;
};
export type PatchedTripBillingPassengerRead = {
  id?: string;
  organization_id?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  preferred_name?: string;
  pronoun?: string;
  gender?: string;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  phone_number?: string;
  email?: string;
  notes?: string;
};
export type TripBilling = {
  passenger: PatchedTripBillingPassenger;
  scheduled_pickup_at: string;
  oxygen_liters_per_min?: string;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  confirmation_code?: string;
};
export type AddressLocation = {
  place_id: string;
};
export type AddressLocationRead = {
  /** full address */
  label: string;
  place_id: string;
};
export type AddressTypeEnum = 'medical' | 'organization' | 'residential';
export type Address = {
  location?: AddressLocation;
  /** Address as sent to aws to get location */
  label: string;
  label2?: string;
  address_notes?: string;
  address_type?: AddressTypeEnum;
};
export type AddressRead = {
  id: string;
  organization_id: string;
  location?: AddressLocationRead;
  /** Address as sent to aws to get location */
  label: string;
  label2?: string;
  address_notes?: string;
  address_type?: AddressTypeEnum;
  created_at: string;
  updated_at: string;
};
export type PayerOrganization = {
  payer?: string | null;
  /** Name the organization uses to refer to this payer across the app */
  display_name: string;
  /** Any alternate name the organization uses (ex., full legal name) */
  alternate_name?: string;
  type?: PayerTypeEnum;
  /** if this payer could be used for current or future trips */
  is_active?: boolean;
  /** Whether a payer organization allows any kind of multiloading */
  can_multiload?: boolean;
  /** ID of the organization in the payer's system */
  external_id?: string;
  website?: string;
  /** Employer Identification Number */
  ein?: string;
  /** Default contact email, ex. help@bambi.health */
  email?: string;
  /** Default contact phone number */
  phone_number?: string | null;
  /** Name of person to contact for billing/invoicing help or questions */
  billing_name?: string;
  /** Phone number for billing-specific purposes */
  billing_phone_number?: string | null;
  /** Email for billing/invoicing, ex. nirav@bambi.healh */
  billing_email?: string;
  /** Any additional information to display to dispatchers */
  dispatcher_notes?: string;
  /** Any additional information to display to admins */
  admin_notes?: string;
  deleted_at?: string | null;
  billing_address?: Address | null;
  default_trip_address?: Address | null;
  multiloading_partners?: string[] | null;
};
export type PayerOrganizationRead = {
  id: string;
  organization_id: string;
  payer?: string | null;
  /** Name the organization uses to refer to this payer across the app */
  display_name: string;
  /** Any alternate name the organization uses (ex., full legal name) */
  alternate_name?: string;
  type?: PayerTypeEnum;
  /** if this payer could be used for current or future trips */
  is_active?: boolean;
  /** Whether a payer organization allows any kind of multiloading */
  can_multiload?: boolean;
  /** ID of the organization in the payer's system */
  external_id?: string;
  website?: string;
  /** Employer Identification Number */
  ein?: string;
  /** Default contact email, ex. help@bambi.health */
  email?: string;
  /** Default contact phone number */
  phone_number?: string | null;
  /** Name of person to contact for billing/invoicing help or questions */
  billing_name?: string;
  /** Phone number for billing-specific purposes */
  billing_phone_number?: string | null;
  /** Email for billing/invoicing, ex. nirav@bambi.healh */
  billing_email?: string;
  /** Any additional information to display to dispatchers */
  dispatcher_notes?: string;
  /** Any additional information to display to admins */
  admin_notes?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  billing_address?: AddressRead | null;
  default_trip_address?: AddressRead | null;
  pickup_default_early_seconds: number;
  pickup_default_late_seconds: number;
  pickup_appointment_early_seconds: number;
  pickup_appointment_late_seconds: number;
  pickup_will_call_early_seconds: number;
  pickup_will_call_late_seconds: number;
  extra_scheduled_pickup_at_seconds: number;
  invoice_due_after_days: number;
  requires_signature_pickup_passenger: boolean;
  requires_signature_dropoff_passenger: boolean;
  requires_signature_pickup_driver: boolean;
  requires_signature_dropoff_driver: boolean;
  payer_name: string | null;
  payer_website: string | null;
  payer_type: PayerTypeEnum;
  multiloading_partners?: string[] | null;
  has_kinetik_account: boolean;
};
export type PaymentMethodEnum = 'cash' | 'credit-card' | 'invoice';
export type CanceledReasonEnum =
  | 'no show'
  | 'passenger not ready'
  | 'passenger refused'
  | 'passenger unable'
  | 'scheduling conflict'
  | 'appointment canceled'
  | 'other';
export type TripBillingRead = {
  id: string;
  /** Current status of this trip */
  status: OverrideStatusEnum;
  /** Locked assignment cannot be changed */
  is_locked: boolean | null;
  passenger: PatchedTripBillingPassengerRead;
  payer: PayerOrganizationRead;
  early_pickup_at: string;
  late_pickup_at: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id: string;
  /** The initial price of the trip calculated using a pricing model */
  initial_calculated_price_cents: number | null;
  /** The final price of the trip calculated using a pricing model */
  final_calculated_price_cents: number | null;
  /** The manually inputted price of the trip */
  input_price_cents: number | null;
  payment_method: PaymentMethodEnum;
  scheduled_pickup_at: string;
  current_task: string | null;
  current_task_at: string | null;
  /** The time of the passenger's appointment */
  appointment_at: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call: boolean;
  /** Whether the passenger has called in that they are ready to be picked up */
  is_will_call_activated: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter: boolean;
  service_type: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes: string;
  /** Notes entered by the driver */
  driver_entered_notes: string;
  is_oxygen_required: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers: number;
  estimated_distance_miles: number | null;
  /** The time the trip was canceled */
  canceled_at: string | null;
  /** The main reason a trip was canceled */
  canceled_reason: CanceledReasonEnum;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension: string;
  timing: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  tags: string[];
  /** The suggested pickup time based off of run_bambi_run results */
  suggested_pickup_time: string | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound: boolean;
  /** The authorization ID for this trip */
  authorization_id: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked: boolean;
  confirmation_code?: string;
  invoice_numbers: string | null;
  invoice_id: string | null;
};
export type TripBillingWrite = {
  passenger: PatchedTripBillingPassenger;
  payer_id: string;
  scheduled_pickup_at: string;
  oxygen_liters_per_min?: string;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  tag_ids?: string[] | null;
  confirmation_code?: string;
};
export type PaginatedTripBillingList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripBilling[];
};
export type PaginatedTripBillingListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripBillingRead[];
};
export type PaginatedTripBillingListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripBillingWrite[];
};
export type BillingTripsBillingListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type AliviOAuthToken = {};
export type TokenTypeEnum = 'Bearer';
export type AliviOAuthTokenRead = {
  access_token: string;
  expires_in: number;
  token_type: TokenTypeEnum;
};
export type GrantTypeEnum = 'client_credentials';
export type AliviOAuthTokenWrite = {
  client_id: string;
  client_secret: string;
  grant_type: GrantTypeEnum;
};
export type BrokersAliviOauth2TokenCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersAliviOauth2TokenCreateClientIdErrorComponent = {
  attr: 'client_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviOauth2TokenCreateClientSecretErrorComponent = {
  attr: 'client_secret';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviOauth2TokenCreateGrantTypeErrorComponent = {
  attr: 'grant_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviOauth2TokenCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersAliviOauth2TokenCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'client_id';
    } & BrokersAliviOauth2TokenCreateClientIdErrorComponent)
  | ({
      attr: 'client_secret';
    } & BrokersAliviOauth2TokenCreateClientSecretErrorComponent)
  | ({
      attr: 'grant_type';
    } & BrokersAliviOauth2TokenCreateGrantTypeErrorComponent);
export type BrokersAliviOauth2TokenCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersAliviOauth2TokenCreateError[];
};
export type BrokersAliviOauth2TokenCreateErrorResponse400 = {
  type: 'validation_error';
} & BrokersAliviOauth2TokenCreateValidationError;
export type AliviRideCancel = {};
export type AliviRideCancelWrite = {
  id: string;
};
export type BrokersAliviRideCancelCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersAliviRideCancelCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideCancelCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersAliviRideCancelCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersAliviRideCancelCreateIdErrorComponent);
export type BrokersAliviRideCancelCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersAliviRideCancelCreateError[];
};
export type BrokersAliviRideCancelCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersAliviRideCancelCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AliviRideStatus = {
  id: string;
};
export type AliviRideStatusStatusEnum =
  | 'accept'
  | 'reject'
  | 'pending'
  | 'scheduled'
  | 'unscheduled'
  | 'pickupEnRoute'
  | 'pickupArrived'
  | 'dropoffEnroute'
  | 'dropoffArrived'
  | 'dropoffCompleted'
  | 'canceled';
export type LevelOfServiceEnum =
  | 'C2C'
  | 'D2D'
  | 'WCH'
  | 'BWCH'
  | 'STR'
  | 'BSTR';
export type AliviPassengerReadOnly = {};
export type AliviAddressReadOnly = {};
export type AliviAddressReadOnlyRead = {
  name: string;
  phone: string;
  addressLine: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
};
export type AliviPassengerReadOnlyRead = {
  id: string;
  firstname: string;
  lastname: string;
  gender: string;
  weight: number;
  phone: string;
  homeAddress: AliviAddressReadOnlyRead;
};
export type AliviCompanionsReadOnly = {};
export type AliviCompanionsReadOnlyRead = {
  adults: number;
  childrens: number;
  carSeats: number;
};
export type AliviRideStatusRead = {
  id: string;
  status: AliviRideStatusStatusEnum;
  transportationProviderId: string;
  levelOfService: LevelOfServiceEnum;
  passenger: AliviPassengerReadOnlyRead;
  pickupAddress: AliviAddressReadOnlyRead;
  dropoffAddress: AliviAddressReadOnlyRead;
  appointmentTime: string;
  pickupTime: string;
  specialRequest: any[];
  driverNotes: string;
  alternateRideId: string;
  willcall: boolean;
  mileage: number;
  companions: AliviCompanionsReadOnlyRead;
};
export type BrokersAliviRideGetStatusCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersAliviRideGetStatusCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideGetStatusCreateSpecialRequestIndexErrorComponent = {
  attr: 'specialRequest.INDEX';
  code: 'required';
  detail: string;
};
export type BrokersAliviRideGetStatusCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersAliviRideGetStatusCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersAliviRideGetStatusCreateIdErrorComponent)
  | ({
      attr: 'specialRequest.INDEX';
    } & BrokersAliviRideGetStatusCreateSpecialRequestIndexErrorComponent);
export type BrokersAliviRideGetStatusCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersAliviRideGetStatusCreateError[];
};
export type BrokersAliviRideGetStatusCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersAliviRideGetStatusCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AliviRideOffer = {};
export type AliviRideOfferRead = {
  rideId: string;
};
export type AliviPassenger = {};
export type AliviAddress = {};
export type AliviAddressWrite = {
  name: string;
  phone: string;
  addressLine: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
};
export type AliviPassengerWrite = {
  id: string;
  firstname: string;
  lastname: string;
  gender: string;
  weight?: number;
  phone: string;
  homeAddress: AliviAddressWrite;
};
export type AliviCompanions = {};
export type AliviCompanionsWrite = {
  adults?: number;
  childrens?: number;
  carSeats?: number;
};
export type AliviRideOfferWrite = {
  transportationProviderId: string;
  levelOfService: LevelOfServiceEnum;
  passenger: AliviPassengerWrite;
  pickupAddress: AliviAddressWrite;
  dropoffAddress: AliviAddressWrite;
  appointmentTime?: string;
  pickupTime: string;
  specialRequest?: any[];
  driverNotes?: string;
  alternateRideId?: string;
  willcall?: boolean;
  mileage?: number;
  webhookUrl?: string;
  companions?: AliviCompanionsWrite;
};
export type BrokersAliviRideOfferCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersAliviRideOfferCreateTransportationProviderIdErrorComponent =
  {
    attr: 'transportationProviderId';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideOfferCreateLevelOfServiceErrorComponent = {
  attr: 'levelOfService';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerNonFieldErrorsErrorComponent = {
  attr: 'passenger.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerIdErrorComponent = {
  attr: 'passenger.id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerFirstnameErrorComponent = {
  attr: 'passenger.firstname';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerLastnameErrorComponent = {
  attr: 'passenger.lastname';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerGenderErrorComponent = {
  attr: 'passenger.gender';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerWeightErrorComponent = {
  attr: 'passenger.weight';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerPhoneErrorComponent = {
  attr: 'passenger.phone';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePassengerHomeAddressNonFieldErrorsErrorComponent =
  {
    attr: 'passenger.homeAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressNameErrorComponent =
  {
    attr: 'passenger.homeAddress.name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressPhoneErrorComponent =
  {
    attr: 'passenger.homeAddress.phone';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressAddressLineErrorComponent =
  {
    attr: 'passenger.homeAddress.addressLine';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressAddressLine2ErrorComponent =
  {
    attr: 'passenger.homeAddress.addressLine2';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressCityErrorComponent =
  {
    attr: 'passenger.homeAddress.city';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressStateErrorComponent =
  {
    attr: 'passenger.homeAddress.state';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressZipCodeErrorComponent =
  {
    attr: 'passenger.homeAddress.zipCode';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressLatitudeErrorComponent =
  {
    attr: 'passenger.homeAddress.latitude';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePassengerHomeAddressLongitudeErrorComponent =
  {
    attr: 'passenger.homeAddress.longitude';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePickupAddressNonFieldErrorsErrorComponent =
  {
    attr: 'pickupAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePickupAddressNameErrorComponent = {
  attr: 'pickupAddress.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupAddressPhoneErrorComponent = {
  attr: 'pickupAddress.phone';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupAddressAddressLineErrorComponent =
  {
    attr: 'pickupAddress.addressLine';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePickupAddressAddressLine2ErrorComponent =
  {
    attr: 'pickupAddress.addressLine2';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreatePickupAddressCityErrorComponent = {
  attr: 'pickupAddress.city';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupAddressStateErrorComponent = {
  attr: 'pickupAddress.state';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupAddressZipCodeErrorComponent = {
  attr: 'pickupAddress.zipCode';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupAddressLatitudeErrorComponent = {
  attr: 'pickupAddress.latitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupAddressLongitudeErrorComponent = {
  attr: 'pickupAddress.longitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressNonFieldErrorsErrorComponent =
  {
    attr: 'dropoffAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideOfferCreateDropoffAddressNameErrorComponent = {
  attr: 'dropoffAddress.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressPhoneErrorComponent = {
  attr: 'dropoffAddress.phone';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressAddressLineErrorComponent =
  {
    attr: 'dropoffAddress.addressLine';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreateDropoffAddressAddressLine2ErrorComponent =
  {
    attr: 'dropoffAddress.addressLine2';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideOfferCreateDropoffAddressCityErrorComponent = {
  attr: 'dropoffAddress.city';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressStateErrorComponent = {
  attr: 'dropoffAddress.state';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressZipCodeErrorComponent = {
  attr: 'dropoffAddress.zipCode';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressLatitudeErrorComponent = {
  attr: 'dropoffAddress.latitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreateDropoffAddressLongitudeErrorComponent = {
  attr: 'dropoffAddress.longitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreateAppointmentTimeErrorComponent = {
  attr: 'appointmentTime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow';
  detail: string;
};
export type BrokersAliviRideOfferCreatePickupTimeErrorComponent = {
  attr: 'pickupTime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreateSpecialRequestErrorComponent = {
  attr: 'specialRequest';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreateSpecialRequestIndexErrorComponent = {
  attr: 'specialRequest.INDEX';
  code: 'required';
  detail: string;
};
export type BrokersAliviRideOfferCreateDriverNotesErrorComponent = {
  attr: 'driverNotes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateAlternateRideIdErrorComponent = {
  attr: 'alternateRideId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateWillcallErrorComponent = {
  attr: 'willcall';
  code: 'invalid' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreateMileageErrorComponent = {
  attr: 'mileage';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreateWebhookUrlErrorComponent = {
  attr: 'webhookUrl';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideOfferCreateCompanionsNonFieldErrorsErrorComponent =
  {
    attr: 'companions.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type BrokersAliviRideOfferCreateCompanionsAdultsErrorComponent = {
  attr: 'companions.adults';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreateCompanionsChildrensErrorComponent = {
  attr: 'companions.childrens';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreateCompanionsCarSeatsErrorComponent = {
  attr: 'companions.carSeats';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideOfferCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersAliviRideOfferCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'transportationProviderId';
    } & BrokersAliviRideOfferCreateTransportationProviderIdErrorComponent)
  | ({
      attr: 'levelOfService';
    } & BrokersAliviRideOfferCreateLevelOfServiceErrorComponent)
  | ({
      attr: 'passenger.non_field_errors';
    } & BrokersAliviRideOfferCreatePassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.id';
    } & BrokersAliviRideOfferCreatePassengerIdErrorComponent)
  | ({
      attr: 'passenger.firstname';
    } & BrokersAliviRideOfferCreatePassengerFirstnameErrorComponent)
  | ({
      attr: 'passenger.lastname';
    } & BrokersAliviRideOfferCreatePassengerLastnameErrorComponent)
  | ({
      attr: 'passenger.gender';
    } & BrokersAliviRideOfferCreatePassengerGenderErrorComponent)
  | ({
      attr: 'passenger.weight';
    } & BrokersAliviRideOfferCreatePassengerWeightErrorComponent)
  | ({
      attr: 'passenger.phone';
    } & BrokersAliviRideOfferCreatePassengerPhoneErrorComponent)
  | ({
      attr: 'passenger.homeAddress.non_field_errors';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.homeAddress.name';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressNameErrorComponent)
  | ({
      attr: 'passenger.homeAddress.phone';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressPhoneErrorComponent)
  | ({
      attr: 'passenger.homeAddress.addressLine';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressAddressLineErrorComponent)
  | ({
      attr: 'passenger.homeAddress.addressLine2';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressAddressLine2ErrorComponent)
  | ({
      attr: 'passenger.homeAddress.city';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressCityErrorComponent)
  | ({
      attr: 'passenger.homeAddress.state';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressStateErrorComponent)
  | ({
      attr: 'passenger.homeAddress.zipCode';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressZipCodeErrorComponent)
  | ({
      attr: 'passenger.homeAddress.latitude';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressLatitudeErrorComponent)
  | ({
      attr: 'passenger.homeAddress.longitude';
    } & BrokersAliviRideOfferCreatePassengerHomeAddressLongitudeErrorComponent)
  | ({
      attr: 'pickupAddress.non_field_errors';
    } & BrokersAliviRideOfferCreatePickupAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickupAddress.name';
    } & BrokersAliviRideOfferCreatePickupAddressNameErrorComponent)
  | ({
      attr: 'pickupAddress.phone';
    } & BrokersAliviRideOfferCreatePickupAddressPhoneErrorComponent)
  | ({
      attr: 'pickupAddress.addressLine';
    } & BrokersAliviRideOfferCreatePickupAddressAddressLineErrorComponent)
  | ({
      attr: 'pickupAddress.addressLine2';
    } & BrokersAliviRideOfferCreatePickupAddressAddressLine2ErrorComponent)
  | ({
      attr: 'pickupAddress.city';
    } & BrokersAliviRideOfferCreatePickupAddressCityErrorComponent)
  | ({
      attr: 'pickupAddress.state';
    } & BrokersAliviRideOfferCreatePickupAddressStateErrorComponent)
  | ({
      attr: 'pickupAddress.zipCode';
    } & BrokersAliviRideOfferCreatePickupAddressZipCodeErrorComponent)
  | ({
      attr: 'pickupAddress.latitude';
    } & BrokersAliviRideOfferCreatePickupAddressLatitudeErrorComponent)
  | ({
      attr: 'pickupAddress.longitude';
    } & BrokersAliviRideOfferCreatePickupAddressLongitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.non_field_errors';
    } & BrokersAliviRideOfferCreateDropoffAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoffAddress.name';
    } & BrokersAliviRideOfferCreateDropoffAddressNameErrorComponent)
  | ({
      attr: 'dropoffAddress.phone';
    } & BrokersAliviRideOfferCreateDropoffAddressPhoneErrorComponent)
  | ({
      attr: 'dropoffAddress.addressLine';
    } & BrokersAliviRideOfferCreateDropoffAddressAddressLineErrorComponent)
  | ({
      attr: 'dropoffAddress.addressLine2';
    } & BrokersAliviRideOfferCreateDropoffAddressAddressLine2ErrorComponent)
  | ({
      attr: 'dropoffAddress.city';
    } & BrokersAliviRideOfferCreateDropoffAddressCityErrorComponent)
  | ({
      attr: 'dropoffAddress.state';
    } & BrokersAliviRideOfferCreateDropoffAddressStateErrorComponent)
  | ({
      attr: 'dropoffAddress.zipCode';
    } & BrokersAliviRideOfferCreateDropoffAddressZipCodeErrorComponent)
  | ({
      attr: 'dropoffAddress.latitude';
    } & BrokersAliviRideOfferCreateDropoffAddressLatitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.longitude';
    } & BrokersAliviRideOfferCreateDropoffAddressLongitudeErrorComponent)
  | ({
      attr: 'appointmentTime';
    } & BrokersAliviRideOfferCreateAppointmentTimeErrorComponent)
  | ({
      attr: 'pickupTime';
    } & BrokersAliviRideOfferCreatePickupTimeErrorComponent)
  | ({
      attr: 'specialRequest';
    } & BrokersAliviRideOfferCreateSpecialRequestErrorComponent)
  | ({
      attr: 'specialRequest.INDEX';
    } & BrokersAliviRideOfferCreateSpecialRequestIndexErrorComponent)
  | ({
      attr: 'driverNotes';
    } & BrokersAliviRideOfferCreateDriverNotesErrorComponent)
  | ({
      attr: 'alternateRideId';
    } & BrokersAliviRideOfferCreateAlternateRideIdErrorComponent)
  | ({
      attr: 'willcall';
    } & BrokersAliviRideOfferCreateWillcallErrorComponent)
  | ({
      attr: 'mileage';
    } & BrokersAliviRideOfferCreateMileageErrorComponent)
  | ({
      attr: 'webhookUrl';
    } & BrokersAliviRideOfferCreateWebhookUrlErrorComponent)
  | ({
      attr: 'companions.non_field_errors';
    } & BrokersAliviRideOfferCreateCompanionsNonFieldErrorsErrorComponent)
  | ({
      attr: 'companions.adults';
    } & BrokersAliviRideOfferCreateCompanionsAdultsErrorComponent)
  | ({
      attr: 'companions.childrens';
    } & BrokersAliviRideOfferCreateCompanionsChildrensErrorComponent)
  | ({
      attr: 'companions.carSeats';
    } & BrokersAliviRideOfferCreateCompanionsCarSeatsErrorComponent);
export type BrokersAliviRideOfferCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersAliviRideOfferCreateError[];
};
export type BrokersAliviRideOfferCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersAliviRideOfferCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type AliviRideUpdate = {};
export type AliviRideUpdateWrite = {
  id: string;
  transportationProviderId: string;
  levelOfService: LevelOfServiceEnum;
  appointmentTime?: string;
  pickupTime?: string;
  specialRequest?: any[];
  driverNotes?: string;
  alternateRideId?: string;
  willcall?: boolean;
  mileage?: number;
  companions?: AliviCompanionsWrite;
  passenger?: AliviPassengerWrite;
  pickupAddress?: AliviAddressWrite;
  dropoffAddress?: AliviAddressWrite;
};
export type BrokersAliviRideUpdateCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersAliviRideUpdateCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreateTransportationProviderIdErrorComponent =
  {
    attr: 'transportationProviderId';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideUpdateCreateLevelOfServiceErrorComponent = {
  attr: 'levelOfService';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreateAppointmentTimeErrorComponent = {
  attr: 'appointmentTime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupTimeErrorComponent = {
  attr: 'pickupTime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow';
  detail: string;
};
export type BrokersAliviRideUpdateCreateSpecialRequestErrorComponent = {
  attr: 'specialRequest';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreateSpecialRequestIndexErrorComponent = {
  attr: 'specialRequest.INDEX';
  code: 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDriverNotesErrorComponent = {
  attr: 'driverNotes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateAlternateRideIdErrorComponent = {
  attr: 'alternateRideId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateWillcallErrorComponent = {
  attr: 'willcall';
  code: 'invalid' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreateMileageErrorComponent = {
  attr: 'mileage';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreateCompanionsNonFieldErrorsErrorComponent =
  {
    attr: 'companions.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type BrokersAliviRideUpdateCreateCompanionsAdultsErrorComponent = {
  attr: 'companions.adults';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreateCompanionsChildrensErrorComponent = {
  attr: 'companions.childrens';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreateCompanionsCarSeatsErrorComponent = {
  attr: 'companions.carSeats';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerNonFieldErrorsErrorComponent =
  {
    attr: 'passenger.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerIdErrorComponent = {
  attr: 'passenger.id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerFirstnameErrorComponent = {
  attr: 'passenger.firstname';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerLastnameErrorComponent = {
  attr: 'passenger.lastname';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerGenderErrorComponent = {
  attr: 'passenger.gender';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerWeightErrorComponent = {
  attr: 'passenger.weight';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerPhoneErrorComponent = {
  attr: 'passenger.phone';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePassengerHomeAddressNonFieldErrorsErrorComponent =
  {
    attr: 'passenger.homeAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressNameErrorComponent =
  {
    attr: 'passenger.homeAddress.name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressPhoneErrorComponent =
  {
    attr: 'passenger.homeAddress.phone';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressAddressLineErrorComponent =
  {
    attr: 'passenger.homeAddress.addressLine';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressAddressLine2ErrorComponent =
  {
    attr: 'passenger.homeAddress.addressLine2';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressCityErrorComponent =
  {
    attr: 'passenger.homeAddress.city';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressStateErrorComponent =
  {
    attr: 'passenger.homeAddress.state';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressZipCodeErrorComponent =
  {
    attr: 'passenger.homeAddress.zipCode';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressLatitudeErrorComponent =
  {
    attr: 'passenger.homeAddress.latitude';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePassengerHomeAddressLongitudeErrorComponent =
  {
    attr: 'passenger.homeAddress.longitude';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePickupAddressNonFieldErrorsErrorComponent =
  {
    attr: 'pickupAddress.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePickupAddressNameErrorComponent = {
  attr: 'pickupAddress.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupAddressPhoneErrorComponent = {
  attr: 'pickupAddress.phone';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupAddressAddressLineErrorComponent =
  {
    attr: 'pickupAddress.addressLine';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePickupAddressAddressLine2ErrorComponent =
  {
    attr: 'pickupAddress.addressLine2';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreatePickupAddressCityErrorComponent = {
  attr: 'pickupAddress.city';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupAddressStateErrorComponent = {
  attr: 'pickupAddress.state';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupAddressZipCodeErrorComponent = {
  attr: 'pickupAddress.zipCode';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupAddressLatitudeErrorComponent = {
  attr: 'pickupAddress.latitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreatePickupAddressLongitudeErrorComponent = {
  attr: 'pickupAddress.longitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressNonFieldErrorsErrorComponent =
  {
    attr: 'dropoffAddress.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type BrokersAliviRideUpdateCreateDropoffAddressNameErrorComponent = {
  attr: 'dropoffAddress.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressPhoneErrorComponent = {
  attr: 'dropoffAddress.phone';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressAddressLineErrorComponent =
  {
    attr: 'dropoffAddress.addressLine';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreateDropoffAddressAddressLine2ErrorComponent =
  {
    attr: 'dropoffAddress.addressLine2';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersAliviRideUpdateCreateDropoffAddressCityErrorComponent = {
  attr: 'dropoffAddress.city';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressStateErrorComponent = {
  attr: 'dropoffAddress.state';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressZipCodeErrorComponent = {
  attr: 'dropoffAddress.zipCode';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressLatitudeErrorComponent = {
  attr: 'dropoffAddress.latitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type BrokersAliviRideUpdateCreateDropoffAddressLongitudeErrorComponent =
  {
    attr: 'dropoffAddress.longitude';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type BrokersAliviRideUpdateCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersAliviRideUpdateCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersAliviRideUpdateCreateIdErrorComponent)
  | ({
      attr: 'transportationProviderId';
    } & BrokersAliviRideUpdateCreateTransportationProviderIdErrorComponent)
  | ({
      attr: 'levelOfService';
    } & BrokersAliviRideUpdateCreateLevelOfServiceErrorComponent)
  | ({
      attr: 'appointmentTime';
    } & BrokersAliviRideUpdateCreateAppointmentTimeErrorComponent)
  | ({
      attr: 'pickupTime';
    } & BrokersAliviRideUpdateCreatePickupTimeErrorComponent)
  | ({
      attr: 'specialRequest';
    } & BrokersAliviRideUpdateCreateSpecialRequestErrorComponent)
  | ({
      attr: 'specialRequest.INDEX';
    } & BrokersAliviRideUpdateCreateSpecialRequestIndexErrorComponent)
  | ({
      attr: 'driverNotes';
    } & BrokersAliviRideUpdateCreateDriverNotesErrorComponent)
  | ({
      attr: 'alternateRideId';
    } & BrokersAliviRideUpdateCreateAlternateRideIdErrorComponent)
  | ({
      attr: 'willcall';
    } & BrokersAliviRideUpdateCreateWillcallErrorComponent)
  | ({
      attr: 'mileage';
    } & BrokersAliviRideUpdateCreateMileageErrorComponent)
  | ({
      attr: 'companions.non_field_errors';
    } & BrokersAliviRideUpdateCreateCompanionsNonFieldErrorsErrorComponent)
  | ({
      attr: 'companions.adults';
    } & BrokersAliviRideUpdateCreateCompanionsAdultsErrorComponent)
  | ({
      attr: 'companions.childrens';
    } & BrokersAliviRideUpdateCreateCompanionsChildrensErrorComponent)
  | ({
      attr: 'companions.carSeats';
    } & BrokersAliviRideUpdateCreateCompanionsCarSeatsErrorComponent)
  | ({
      attr: 'passenger.non_field_errors';
    } & BrokersAliviRideUpdateCreatePassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.id';
    } & BrokersAliviRideUpdateCreatePassengerIdErrorComponent)
  | ({
      attr: 'passenger.firstname';
    } & BrokersAliviRideUpdateCreatePassengerFirstnameErrorComponent)
  | ({
      attr: 'passenger.lastname';
    } & BrokersAliviRideUpdateCreatePassengerLastnameErrorComponent)
  | ({
      attr: 'passenger.gender';
    } & BrokersAliviRideUpdateCreatePassengerGenderErrorComponent)
  | ({
      attr: 'passenger.weight';
    } & BrokersAliviRideUpdateCreatePassengerWeightErrorComponent)
  | ({
      attr: 'passenger.phone';
    } & BrokersAliviRideUpdateCreatePassengerPhoneErrorComponent)
  | ({
      attr: 'passenger.homeAddress.non_field_errors';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.homeAddress.name';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressNameErrorComponent)
  | ({
      attr: 'passenger.homeAddress.phone';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressPhoneErrorComponent)
  | ({
      attr: 'passenger.homeAddress.addressLine';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressAddressLineErrorComponent)
  | ({
      attr: 'passenger.homeAddress.addressLine2';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressAddressLine2ErrorComponent)
  | ({
      attr: 'passenger.homeAddress.city';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressCityErrorComponent)
  | ({
      attr: 'passenger.homeAddress.state';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressStateErrorComponent)
  | ({
      attr: 'passenger.homeAddress.zipCode';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressZipCodeErrorComponent)
  | ({
      attr: 'passenger.homeAddress.latitude';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressLatitudeErrorComponent)
  | ({
      attr: 'passenger.homeAddress.longitude';
    } & BrokersAliviRideUpdateCreatePassengerHomeAddressLongitudeErrorComponent)
  | ({
      attr: 'pickupAddress.non_field_errors';
    } & BrokersAliviRideUpdateCreatePickupAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickupAddress.name';
    } & BrokersAliviRideUpdateCreatePickupAddressNameErrorComponent)
  | ({
      attr: 'pickupAddress.phone';
    } & BrokersAliviRideUpdateCreatePickupAddressPhoneErrorComponent)
  | ({
      attr: 'pickupAddress.addressLine';
    } & BrokersAliviRideUpdateCreatePickupAddressAddressLineErrorComponent)
  | ({
      attr: 'pickupAddress.addressLine2';
    } & BrokersAliviRideUpdateCreatePickupAddressAddressLine2ErrorComponent)
  | ({
      attr: 'pickupAddress.city';
    } & BrokersAliviRideUpdateCreatePickupAddressCityErrorComponent)
  | ({
      attr: 'pickupAddress.state';
    } & BrokersAliviRideUpdateCreatePickupAddressStateErrorComponent)
  | ({
      attr: 'pickupAddress.zipCode';
    } & BrokersAliviRideUpdateCreatePickupAddressZipCodeErrorComponent)
  | ({
      attr: 'pickupAddress.latitude';
    } & BrokersAliviRideUpdateCreatePickupAddressLatitudeErrorComponent)
  | ({
      attr: 'pickupAddress.longitude';
    } & BrokersAliviRideUpdateCreatePickupAddressLongitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.non_field_errors';
    } & BrokersAliviRideUpdateCreateDropoffAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoffAddress.name';
    } & BrokersAliviRideUpdateCreateDropoffAddressNameErrorComponent)
  | ({
      attr: 'dropoffAddress.phone';
    } & BrokersAliviRideUpdateCreateDropoffAddressPhoneErrorComponent)
  | ({
      attr: 'dropoffAddress.addressLine';
    } & BrokersAliviRideUpdateCreateDropoffAddressAddressLineErrorComponent)
  | ({
      attr: 'dropoffAddress.addressLine2';
    } & BrokersAliviRideUpdateCreateDropoffAddressAddressLine2ErrorComponent)
  | ({
      attr: 'dropoffAddress.city';
    } & BrokersAliviRideUpdateCreateDropoffAddressCityErrorComponent)
  | ({
      attr: 'dropoffAddress.state';
    } & BrokersAliviRideUpdateCreateDropoffAddressStateErrorComponent)
  | ({
      attr: 'dropoffAddress.zipCode';
    } & BrokersAliviRideUpdateCreateDropoffAddressZipCodeErrorComponent)
  | ({
      attr: 'dropoffAddress.latitude';
    } & BrokersAliviRideUpdateCreateDropoffAddressLatitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.longitude';
    } & BrokersAliviRideUpdateCreateDropoffAddressLongitudeErrorComponent);
export type BrokersAliviRideUpdateCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersAliviRideUpdateCreateError[];
};
export type BrokersAliviRideUpdateCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersAliviRideUpdateCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BrokersExampleHelloWorldRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type KinetikOAuthToken = {};
export type KinetikOAuthTokenRead = {
  apiKey: string;
  apiSecret: string;
};
export type BrokersKinetikOauth2TokenCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikOauth2TokenCreateError = {
  attr: 'non_field_errors';
} & BrokersKinetikOauth2TokenCreateNonFieldErrorsErrorComponent;
export type BrokersKinetikOauth2TokenCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikOauth2TokenCreateError[];
};
export type BrokersKinetikOauth2TokenCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikOauth2TokenCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type KinetikRideReadOnly = {
  /** Unique ID for the trip from Kinetik */
  id: string;
  /** Unique ID for the transport provider from Kinetik */
  transportationProviderId: string;
};
export type BrokersKinetikV1CancelRideCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikV1CancelRideCreateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CancelRideCreateTransportationProviderIdErrorComponent =
  {
    attr: 'transportationProviderId';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CancelRideCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersKinetikV1CancelRideCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersKinetikV1CancelRideCreateIdErrorComponent)
  | ({
      attr: 'transportationProviderId';
    } & BrokersKinetikV1CancelRideCreateTransportationProviderIdErrorComponent);
export type BrokersKinetikV1CancelRideCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikV1CancelRideCreateError[];
};
export type BrokersKinetikV1CancelRideCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikV1CancelRideCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripTypeEnum = 'WillCall' | 'Appointment';
export type KinetikAddress = {
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zip: string;
  country?: string | null;
  longitude?: number | null;
  latitude?: number | null;
};
export type KinetikAdditionalPassengers = {
  adultCount?: number | null;
  childCount?: number | null;
};
export type VehicleTypeEnum =
  | 'Ambulance'
  | 'Wheelchair'
  | 'Sedan'
  | 'Bus'
  | 'Minivan'
  | 'Stretcher'
  | 'Taxi'
  | 'Other';
export type KinetikProcedureCode = {
  procedureCode?: string | null;
  mod?: string | null;
};
export type DesignationEnum = 'facility' | 'patient' | 'pickup' | 'dropoff';
export type KinetikPhoneNumber = {
  number: string;
  extension?: string | null;
  designation: DesignationEnum;
  phonetype?: string | null;
};
export type KinetikRide = {
  patientName: string;
  patientPhone?: string | null;
  /** Kinetik's passenger ID */
  patientId: string;
  /** Kinetik's payer ID */
  accountId: string;
  /** Unique ID for the trip from Kinetik */
  id: string;
  /** Unique ID for the trip from the broker */
  referenceId: string;
  /** Unique ID for the transport provider from Kinetik */
  transportationProviderId: string;
  tripType: TripTypeEnum;
  pickupDateTime: string;
  dropoffDateTime?: string | null;
  pickupAddress: KinetikAddress;
  dropoffAddress: KinetikAddress;
  mileage: number;
  additionalPassengers?: KinetikAdditionalPassengers | null;
  chargeAmount?: number | null;
  vehicleType: VehicleTypeEnum;
  /** Trip instructions if any */
  instructions?: string | null;
  preferredDriverId?: string | null;
  procedureCodes?: KinetikProcedureCode[] | null;
  phoneNumbers: KinetikPhoneNumber[];
};
export type BrokersKinetikV1CreateRidesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePatientNameErrorComponent = {
  attr: 'patientName';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePatientPhoneErrorComponent = {
  attr: 'patientPhone';
  code:
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePatientIdErrorComponent = {
  attr: 'patientId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateAccountIdErrorComponent = {
  attr: 'accountId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateReferenceIdErrorComponent = {
  attr: 'referenceId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateTransportationProviderIdErrorComponent =
  {
    attr: 'transportationProviderId';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateTripTypeErrorComponent = {
  attr: 'tripType';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePickupDateTimeErrorComponent = {
  attr: 'pickupDateTime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateDropoffDateTimeErrorComponent = {
  attr: 'dropoffDateTime';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePickupAddressNonFieldErrorsErrorComponent =
  {
    attr: 'pickupAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePickupAddressAddress1ErrorComponent =
  {
    attr: 'pickupAddress.address1';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePickupAddressAddress2ErrorComponent =
  {
    attr: 'pickupAddress.address2';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePickupAddressCityErrorComponent = {
  attr: 'pickupAddress.city';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePickupAddressStateErrorComponent =
  {
    attr: 'pickupAddress.state';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePickupAddressZipErrorComponent = {
  attr: 'pickupAddress.zip';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePickupAddressCountryErrorComponent =
  {
    attr: 'pickupAddress.country';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePickupAddressLongitudeErrorComponent =
  {
    attr: 'pickupAddress.longitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePickupAddressLatitudeErrorComponent =
  {
    attr: 'pickupAddress.latitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressNonFieldErrorsErrorComponent =
  {
    attr: 'dropoffAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressAddress1ErrorComponent =
  {
    attr: 'dropoffAddress.address1';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressAddress2ErrorComponent =
  {
    attr: 'dropoffAddress.address2';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressCityErrorComponent =
  {
    attr: 'dropoffAddress.city';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressStateErrorComponent =
  {
    attr: 'dropoffAddress.state';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressZipErrorComponent = {
  attr: 'dropoffAddress.zip';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateDropoffAddressCountryErrorComponent =
  {
    attr: 'dropoffAddress.country';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressLongitudeErrorComponent =
  {
    attr: 'dropoffAddress.longitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateDropoffAddressLatitudeErrorComponent =
  {
    attr: 'dropoffAddress.latitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateMileageErrorComponent = {
  attr: 'mileage';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null' | 'required';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateAdditionalPassengersNonFieldErrorsErrorComponent =
  {
    attr: 'additionalPassengers.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateAdditionalPassengersAdultCountErrorComponent =
  {
    attr: 'additionalPassengers.adultCount';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateAdditionalPassengersChildCountErrorComponent =
  {
    attr: 'additionalPassengers.childCount';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateChargeAmountErrorComponent = {
  attr: 'chargeAmount';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateVehicleTypeErrorComponent = {
  attr: 'vehicleType';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateInstructionsErrorComponent = {
  attr: 'instructions';
  code:
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreatePreferredDriverIdErrorComponent = {
  attr: 'preferredDriverId';
  code:
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1CreateRidesCreateProcedureCodesNonFieldErrorsErrorComponent =
  {
    attr: 'procedureCodes.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateProcedureCodesIndexNonFieldErrorsErrorComponent =
  {
    attr: 'procedureCodes.INDEX.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateProcedureCodesIndexProcedureCodeErrorComponent =
  {
    attr: 'procedureCodes.INDEX.procedureCode';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateProcedureCodesIndexModErrorComponent =
  {
    attr: 'procedureCodes.INDEX.mod';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePhoneNumbersNonFieldErrorsErrorComponent =
  {
    attr: 'phoneNumbers.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexNonFieldErrorsErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexNumberErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.number';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexExtensionErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.extension';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexDesignationErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.designation';
    code: 'invalid_choice' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexPhonetypeErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.phonetype';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1CreateRidesCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersKinetikV1CreateRidesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'patientName';
    } & BrokersKinetikV1CreateRidesCreatePatientNameErrorComponent)
  | ({
      attr: 'patientPhone';
    } & BrokersKinetikV1CreateRidesCreatePatientPhoneErrorComponent)
  | ({
      attr: 'patientId';
    } & BrokersKinetikV1CreateRidesCreatePatientIdErrorComponent)
  | ({
      attr: 'accountId';
    } & BrokersKinetikV1CreateRidesCreateAccountIdErrorComponent)
  | ({
      attr: 'id';
    } & BrokersKinetikV1CreateRidesCreateIdErrorComponent)
  | ({
      attr: 'referenceId';
    } & BrokersKinetikV1CreateRidesCreateReferenceIdErrorComponent)
  | ({
      attr: 'transportationProviderId';
    } & BrokersKinetikV1CreateRidesCreateTransportationProviderIdErrorComponent)
  | ({
      attr: 'tripType';
    } & BrokersKinetikV1CreateRidesCreateTripTypeErrorComponent)
  | ({
      attr: 'pickupDateTime';
    } & BrokersKinetikV1CreateRidesCreatePickupDateTimeErrorComponent)
  | ({
      attr: 'dropoffDateTime';
    } & BrokersKinetikV1CreateRidesCreateDropoffDateTimeErrorComponent)
  | ({
      attr: 'pickupAddress.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickupAddress.address1';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressAddress1ErrorComponent)
  | ({
      attr: 'pickupAddress.address2';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressAddress2ErrorComponent)
  | ({
      attr: 'pickupAddress.city';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressCityErrorComponent)
  | ({
      attr: 'pickupAddress.state';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressStateErrorComponent)
  | ({
      attr: 'pickupAddress.zip';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressZipErrorComponent)
  | ({
      attr: 'pickupAddress.country';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressCountryErrorComponent)
  | ({
      attr: 'pickupAddress.longitude';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressLongitudeErrorComponent)
  | ({
      attr: 'pickupAddress.latitude';
    } & BrokersKinetikV1CreateRidesCreatePickupAddressLatitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoffAddress.address1';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressAddress1ErrorComponent)
  | ({
      attr: 'dropoffAddress.address2';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressAddress2ErrorComponent)
  | ({
      attr: 'dropoffAddress.city';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressCityErrorComponent)
  | ({
      attr: 'dropoffAddress.state';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressStateErrorComponent)
  | ({
      attr: 'dropoffAddress.zip';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressZipErrorComponent)
  | ({
      attr: 'dropoffAddress.country';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressCountryErrorComponent)
  | ({
      attr: 'dropoffAddress.longitude';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressLongitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.latitude';
    } & BrokersKinetikV1CreateRidesCreateDropoffAddressLatitudeErrorComponent)
  | ({
      attr: 'mileage';
    } & BrokersKinetikV1CreateRidesCreateMileageErrorComponent)
  | ({
      attr: 'additionalPassengers.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreateAdditionalPassengersNonFieldErrorsErrorComponent)
  | ({
      attr: 'additionalPassengers.adultCount';
    } & BrokersKinetikV1CreateRidesCreateAdditionalPassengersAdultCountErrorComponent)
  | ({
      attr: 'additionalPassengers.childCount';
    } & BrokersKinetikV1CreateRidesCreateAdditionalPassengersChildCountErrorComponent)
  | ({
      attr: 'chargeAmount';
    } & BrokersKinetikV1CreateRidesCreateChargeAmountErrorComponent)
  | ({
      attr: 'vehicleType';
    } & BrokersKinetikV1CreateRidesCreateVehicleTypeErrorComponent)
  | ({
      attr: 'instructions';
    } & BrokersKinetikV1CreateRidesCreateInstructionsErrorComponent)
  | ({
      attr: 'preferredDriverId';
    } & BrokersKinetikV1CreateRidesCreatePreferredDriverIdErrorComponent)
  | ({
      attr: 'procedureCodes.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreateProcedureCodesNonFieldErrorsErrorComponent)
  | ({
      attr: 'procedureCodes.INDEX.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreateProcedureCodesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'procedureCodes.INDEX.procedureCode';
    } & BrokersKinetikV1CreateRidesCreateProcedureCodesIndexProcedureCodeErrorComponent)
  | ({
      attr: 'procedureCodes.INDEX.mod';
    } & BrokersKinetikV1CreateRidesCreateProcedureCodesIndexModErrorComponent)
  | ({
      attr: 'phoneNumbers.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreatePhoneNumbersNonFieldErrorsErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.non_field_errors';
    } & BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.number';
    } & BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexNumberErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.extension';
    } & BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexExtensionErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.designation';
    } & BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexDesignationErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.phonetype';
    } & BrokersKinetikV1CreateRidesCreatePhoneNumbersIndexPhonetypeErrorComponent);
export type BrokersKinetikV1CreateRidesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikV1CreateRidesCreateError[];
};
export type BrokersKinetikV1CreateRidesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikV1CreateRidesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type KinetikDriver = {
  /** Our unique identifier for the driver */
  id: string;
};
export type KinetikDriverRead = {
  /** Our unique identifier for the driver */
  id: string;
  firstName: string;
  lastName: string;
  licenseNumber: string | null;
  licenseState: string | null;
  phoneNumber: string;
};
export type BrokersKinetikV1GetDriverCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikV1GetDriverCreateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1GetDriverCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersKinetikV1GetDriverCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersKinetikV1GetDriverCreateIdErrorComponent);
export type BrokersKinetikV1GetDriverCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikV1GetDriverCreateError[];
};
export type BrokersKinetikV1GetDriverCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikV1GetDriverCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BrokersKinetikV1GetRideCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikV1GetRideCreateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1GetRideCreateTransportationProviderIdErrorComponent =
  {
    attr: 'transportationProviderId';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1GetRideCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersKinetikV1GetRideCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersKinetikV1GetRideCreateIdErrorComponent)
  | ({
      attr: 'transportationProviderId';
    } & BrokersKinetikV1GetRideCreateTransportationProviderIdErrorComponent);
export type BrokersKinetikV1GetRideCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikV1GetRideCreateError[];
};
export type BrokersKinetikV1GetRideCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikV1GetRideCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type KinetikVehicle = {
  /** Our unique identifier for the vehicle */
  id: string;
};
export type KinetikVehicleRead = {
  /** Our unique identifier for the vehicle */
  id: string;
  vin: string;
  licensePlate: string;
  licenseState: string;
};
export type BrokersKinetikV1GetVehicleCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikV1GetVehicleCreateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1GetVehicleCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersKinetikV1GetVehicleCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & BrokersKinetikV1GetVehicleCreateIdErrorComponent);
export type BrokersKinetikV1GetVehicleCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikV1GetVehicleCreateError[];
};
export type BrokersKinetikV1GetVehicleCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikV1GetVehicleCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BrokersKinetikV1UpdateRidesUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePatientNameErrorComponent = {
  attr: 'patientName';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePatientPhoneErrorComponent = {
  attr: 'patientPhone';
  code:
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePatientIdErrorComponent = {
  attr: 'patientId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateAccountIdErrorComponent = {
  attr: 'accountId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateReferenceIdErrorComponent = {
  attr: 'referenceId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateTransportationProviderIdErrorComponent =
  {
    attr: 'transportationProviderId';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateTripTypeErrorComponent = {
  attr: 'tripType';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePickupDateTimeErrorComponent = {
  attr: 'pickupDateTime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateDropoffDateTimeErrorComponent = {
  attr: 'dropoffDateTime';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressNonFieldErrorsErrorComponent =
  {
    attr: 'pickupAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressAddress1ErrorComponent =
  {
    attr: 'pickupAddress.address1';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressAddress2ErrorComponent =
  {
    attr: 'pickupAddress.address2';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressCityErrorComponent = {
  attr: 'pickupAddress.city';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressStateErrorComponent =
  {
    attr: 'pickupAddress.state';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressZipErrorComponent = {
  attr: 'pickupAddress.zip';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressCountryErrorComponent =
  {
    attr: 'pickupAddress.country';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressLongitudeErrorComponent =
  {
    attr: 'pickupAddress.longitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePickupAddressLatitudeErrorComponent =
  {
    attr: 'pickupAddress.latitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressNonFieldErrorsErrorComponent =
  {
    attr: 'dropoffAddress.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressAddress1ErrorComponent =
  {
    attr: 'dropoffAddress.address1';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressAddress2ErrorComponent =
  {
    attr: 'dropoffAddress.address2';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressCityErrorComponent =
  {
    attr: 'dropoffAddress.city';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressStateErrorComponent =
  {
    attr: 'dropoffAddress.state';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressZipErrorComponent = {
  attr: 'dropoffAddress.zip';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressCountryErrorComponent =
  {
    attr: 'dropoffAddress.country';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressLongitudeErrorComponent =
  {
    attr: 'dropoffAddress.longitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateDropoffAddressLatitudeErrorComponent =
  {
    attr: 'dropoffAddress.latitude';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateMileageErrorComponent = {
  attr: 'mileage';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null' | 'required';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateAdditionalPassengersNonFieldErrorsErrorComponent =
  {
    attr: 'additionalPassengers.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateAdditionalPassengersAdultCountErrorComponent =
  {
    attr: 'additionalPassengers.adultCount';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateAdditionalPassengersChildCountErrorComponent =
  {
    attr: 'additionalPassengers.childCount';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateChargeAmountErrorComponent = {
  attr: 'chargeAmount';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateVehicleTypeErrorComponent = {
  attr: 'vehicleType';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateInstructionsErrorComponent = {
  attr: 'instructions';
  code:
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdatePreferredDriverIdErrorComponent = {
  attr: 'preferredDriverId';
  code:
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersKinetikV1UpdateRidesUpdateProcedureCodesNonFieldErrorsErrorComponent =
  {
    attr: 'procedureCodes.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateProcedureCodesIndexNonFieldErrorsErrorComponent =
  {
    attr: 'procedureCodes.INDEX.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateProcedureCodesIndexProcedureCodeErrorComponent =
  {
    attr: 'procedureCodes.INDEX.procedureCode';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateProcedureCodesIndexModErrorComponent =
  {
    attr: 'procedureCodes.INDEX.mod';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePhoneNumbersNonFieldErrorsErrorComponent =
  {
    attr: 'phoneNumbers.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexNonFieldErrorsErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexNumberErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.number';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexExtensionErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.extension';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexDesignationErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.designation';
    code: 'invalid_choice' | 'null' | 'required';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexPhonetypeErrorComponent =
  {
    attr: 'phoneNumbers.INDEX.phonetype';
    code:
      | 'invalid'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type BrokersKinetikV1UpdateRidesUpdateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'patientName';
    } & BrokersKinetikV1UpdateRidesUpdatePatientNameErrorComponent)
  | ({
      attr: 'patientPhone';
    } & BrokersKinetikV1UpdateRidesUpdatePatientPhoneErrorComponent)
  | ({
      attr: 'patientId';
    } & BrokersKinetikV1UpdateRidesUpdatePatientIdErrorComponent)
  | ({
      attr: 'accountId';
    } & BrokersKinetikV1UpdateRidesUpdateAccountIdErrorComponent)
  | ({
      attr: 'id';
    } & BrokersKinetikV1UpdateRidesUpdateIdErrorComponent)
  | ({
      attr: 'referenceId';
    } & BrokersKinetikV1UpdateRidesUpdateReferenceIdErrorComponent)
  | ({
      attr: 'transportationProviderId';
    } & BrokersKinetikV1UpdateRidesUpdateTransportationProviderIdErrorComponent)
  | ({
      attr: 'tripType';
    } & BrokersKinetikV1UpdateRidesUpdateTripTypeErrorComponent)
  | ({
      attr: 'pickupDateTime';
    } & BrokersKinetikV1UpdateRidesUpdatePickupDateTimeErrorComponent)
  | ({
      attr: 'dropoffDateTime';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffDateTimeErrorComponent)
  | ({
      attr: 'pickupAddress.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickupAddress.address1';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressAddress1ErrorComponent)
  | ({
      attr: 'pickupAddress.address2';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressAddress2ErrorComponent)
  | ({
      attr: 'pickupAddress.city';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressCityErrorComponent)
  | ({
      attr: 'pickupAddress.state';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressStateErrorComponent)
  | ({
      attr: 'pickupAddress.zip';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressZipErrorComponent)
  | ({
      attr: 'pickupAddress.country';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressCountryErrorComponent)
  | ({
      attr: 'pickupAddress.longitude';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressLongitudeErrorComponent)
  | ({
      attr: 'pickupAddress.latitude';
    } & BrokersKinetikV1UpdateRidesUpdatePickupAddressLatitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoffAddress.address1';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressAddress1ErrorComponent)
  | ({
      attr: 'dropoffAddress.address2';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressAddress2ErrorComponent)
  | ({
      attr: 'dropoffAddress.city';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressCityErrorComponent)
  | ({
      attr: 'dropoffAddress.state';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressStateErrorComponent)
  | ({
      attr: 'dropoffAddress.zip';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressZipErrorComponent)
  | ({
      attr: 'dropoffAddress.country';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressCountryErrorComponent)
  | ({
      attr: 'dropoffAddress.longitude';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressLongitudeErrorComponent)
  | ({
      attr: 'dropoffAddress.latitude';
    } & BrokersKinetikV1UpdateRidesUpdateDropoffAddressLatitudeErrorComponent)
  | ({
      attr: 'mileage';
    } & BrokersKinetikV1UpdateRidesUpdateMileageErrorComponent)
  | ({
      attr: 'additionalPassengers.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdateAdditionalPassengersNonFieldErrorsErrorComponent)
  | ({
      attr: 'additionalPassengers.adultCount';
    } & BrokersKinetikV1UpdateRidesUpdateAdditionalPassengersAdultCountErrorComponent)
  | ({
      attr: 'additionalPassengers.childCount';
    } & BrokersKinetikV1UpdateRidesUpdateAdditionalPassengersChildCountErrorComponent)
  | ({
      attr: 'chargeAmount';
    } & BrokersKinetikV1UpdateRidesUpdateChargeAmountErrorComponent)
  | ({
      attr: 'vehicleType';
    } & BrokersKinetikV1UpdateRidesUpdateVehicleTypeErrorComponent)
  | ({
      attr: 'instructions';
    } & BrokersKinetikV1UpdateRidesUpdateInstructionsErrorComponent)
  | ({
      attr: 'preferredDriverId';
    } & BrokersKinetikV1UpdateRidesUpdatePreferredDriverIdErrorComponent)
  | ({
      attr: 'procedureCodes.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdateProcedureCodesNonFieldErrorsErrorComponent)
  | ({
      attr: 'procedureCodes.INDEX.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdateProcedureCodesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'procedureCodes.INDEX.procedureCode';
    } & BrokersKinetikV1UpdateRidesUpdateProcedureCodesIndexProcedureCodeErrorComponent)
  | ({
      attr: 'procedureCodes.INDEX.mod';
    } & BrokersKinetikV1UpdateRidesUpdateProcedureCodesIndexModErrorComponent)
  | ({
      attr: 'phoneNumbers.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdatePhoneNumbersNonFieldErrorsErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.non_field_errors';
    } & BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.number';
    } & BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexNumberErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.extension';
    } & BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexExtensionErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.designation';
    } & BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexDesignationErrorComponent)
  | ({
      attr: 'phoneNumbers.INDEX.phonetype';
    } & BrokersKinetikV1UpdateRidesUpdatePhoneNumbersIndexPhonetypeErrorComponent);
export type BrokersKinetikV1UpdateRidesUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersKinetikV1UpdateRidesUpdateError[];
};
export type BrokersKinetikV1UpdateRidesUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersKinetikV1UpdateRidesUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MtmoAuthToken = {};
export type MtmoAuthTokenRead = {
  access_token: string;
  expires_in: number;
  token_type: TokenTypeEnum;
};
export type MtmoAuthTokenWrite = {
  client_id: string;
  client_secret: string;
  grant_type: GrantTypeEnum;
};
export type BrokersMtmOauth2TokenCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersMtmOauth2TokenCreateClientIdErrorComponent = {
  attr: 'client_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersMtmOauth2TokenCreateClientSecretErrorComponent = {
  attr: 'client_secret';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersMtmOauth2TokenCreateGrantTypeErrorComponent = {
  attr: 'grant_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersMtmOauth2TokenCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersMtmOauth2TokenCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'client_id';
    } & BrokersMtmOauth2TokenCreateClientIdErrorComponent)
  | ({
      attr: 'client_secret';
    } & BrokersMtmOauth2TokenCreateClientSecretErrorComponent)
  | ({
      attr: 'grant_type';
    } & BrokersMtmOauth2TokenCreateGrantTypeErrorComponent);
export type BrokersMtmOauth2TokenCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersMtmOauth2TokenCreateError[];
};
export type BrokersMtmOauth2TokenCreateErrorResponse400 = {
  type: 'validation_error';
} & BrokersMtmOauth2TokenCreateValidationError;
export type EventTypeIdEnum = 10 | 11 | 12 | 13;
export type MtmWebhook = {
  eventTypeId: EventTypeIdEnum;
  providerTripId: string;
  eventData?: {
    [key: string]: any;
  };
  organizationCode?: string;
};
export type BrokersMtmWebhookCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type BrokersMtmWebhookCreateEventTypeIdErrorComponent = {
  attr: 'eventTypeId';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type BrokersMtmWebhookCreateProviderTripIdErrorComponent = {
  attr: 'providerTripId';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersMtmWebhookCreateEventDataErrorComponent = {
  attr: 'eventData';
  code: 'not_a_dict' | 'null';
  detail: string;
};
export type BrokersMtmWebhookCreateEventDataKeyErrorComponent = {
  attr: 'eventData.KEY';
  code: 'required';
  detail: string;
};
export type BrokersMtmWebhookCreateOrganizationCodeErrorComponent = {
  attr: 'organizationCode';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type BrokersMtmWebhookCreateError =
  | ({
      attr: 'non_field_errors';
    } & BrokersMtmWebhookCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'eventTypeId';
    } & BrokersMtmWebhookCreateEventTypeIdErrorComponent)
  | ({
      attr: 'providerTripId';
    } & BrokersMtmWebhookCreateProviderTripIdErrorComponent)
  | ({
      attr: 'eventData';
    } & BrokersMtmWebhookCreateEventDataErrorComponent)
  | ({
      attr: 'eventData.KEY';
    } & BrokersMtmWebhookCreateEventDataKeyErrorComponent)
  | ({
      attr: 'organizationCode';
    } & BrokersMtmWebhookCreateOrganizationCodeErrorComponent);
export type BrokersMtmWebhookCreateValidationError = {
  type: ValidationErrorEnum;
  errors: BrokersMtmWebhookCreateError[];
};
export type BrokersMtmWebhookCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & BrokersMtmWebhookCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type Credential = {
  credential_type_id: string;
  expiration_date: string;
  notes?: string;
};
export type CredentialCategoryEnum = 'Team' | 'Vehicle' | 'Misc';
export type CredentialType = {
  name: string;
  description?: string;
  category: CredentialCategoryEnum;
};
export type CredentialTypeRead = {
  id: string;
  name: string;
  description?: string;
  category: CredentialCategoryEnum;
};
export type RoleEnum =
  | 'owner'
  | 'admin'
  | 'dispatcher'
  | 'associate'
  | 'restricted support'
  | 'facility admin'
  | 'facility user'
  | 'bambi'
  | 'integration';
export type Membership = {
  role?: RoleEnum;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
};
export type MembershipRead = {
  id: string;
  organization_id: string;
  role?: RoleEnum;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  status: string;
  active_payers: any[] | null;
  shared_payer_count: number | null;
  deleted_at: string | null;
};
export type MembershipWrite = {
  role?: RoleEnum;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  payer_ids?: string[] | null;
};
export type UsStateEnum =
  | 'AL'
  | 'AK'
  | 'AS'
  | 'AZ'
  | 'AR'
  | 'AA'
  | 'AE'
  | 'AP'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'DC'
  | 'FL'
  | 'GA'
  | 'GU'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'MP'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'PR'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VI'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY';
export type NullEnum = null;
export type VehicleStorageLocation = {
  place_id: string;
};
export type VehicleStorageLocationRead = {
  /** full address */
  label: string;
  place_id: string;
};
export type VehicleCapacityConfiguration = {
  wheelchair_capacity?: number;
  wheelchair_xl_capacity?: number;
  broda_chair_capacity?: number;
  geri_chair_capacity?: number;
  stretcher_capacity?: number;
  ambulatory_capacity?: number;
  /** how many wheelchairs without passengers the vehicle can safely transport */
  equipment_wheelchair_capacity?: number;
};
export type Vehicle = {
  nickname?: string;
  vin?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number | null;
  can_be_scheduled?: boolean;
  license_plate?: string;
  license_plate_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  category: VehicleCategoryEnum;
  notes?: string;
  storage_location: VehicleStorageLocation;
  capacity_configurations: VehicleCapacityConfiguration[];
  deleted_at?: string | null;
};
export type VehicleVehicleTag = {
  tag_name: string;
  color_hex: string;
};
export type VehicleVehicleTagRead = {
  id: string;
  tag: string;
  tag_name: string;
  color_hex: string;
  created_at: string;
  updated_at: string;
};
export type VehicleRead = {
  id: string;
  nickname?: string;
  vin?: string;
  organization_id: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number | null;
  can_be_scheduled?: boolean;
  license_plate?: string;
  license_plate_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  category: VehicleCategoryEnum;
  notes?: string;
  storage_location: VehicleStorageLocationRead;
  capacity_configurations: VehicleCapacityConfiguration[];
  updated_at: string;
  created_at: string;
  deleted_at?: string | null;
  tags: VehicleVehicleTagRead[] | null;
};
export type VehicleWrite = {
  nickname?: string;
  vin?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number | null;
  can_be_scheduled?: boolean;
  license_plate?: string;
  license_plate_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  category: VehicleCategoryEnum;
  notes?: string;
  storage_location: VehicleStorageLocation;
  capacity_configurations: VehicleCapacityConfiguration[];
  deleted_at?: string | null;
  tag_ids?: string[] | null;
};
export type CredentialRead = {
  id: string;
  credential_type: CredentialTypeRead;
  credential_type_id: string;
  membership: MembershipRead;
  vehicle: VehicleRead;
  expiration_date: string;
  notes?: string;
};
export type CredentialWrite = {
  credential_type_id: string;
  membership_id?: string;
  vehicle_id?: string;
  expiration_date: string;
  notes?: string;
};
export type PaginatedCredentialList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Credential[];
};
export type PaginatedCredentialListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CredentialRead[];
};
export type PaginatedCredentialListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CredentialWrite[];
};
export type CredentialsListCredentialTypeCategoryErrorComponent = {
  attr: 'credential_type_category';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsListCredentialTypesErrorComponent = {
  attr: 'credential_types';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsListMembersErrorComponent = {
  attr: 'members';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsListVehiclesErrorComponent = {
  attr: 'vehicles';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsListError =
  | ({
      attr: 'credential_type_category';
    } & CredentialsListCredentialTypeCategoryErrorComponent)
  | ({
      attr: 'credential_types';
    } & CredentialsListCredentialTypesErrorComponent)
  | ({
      attr: 'members';
    } & CredentialsListMembersErrorComponent)
  | ({
      attr: 'vehicles';
    } & CredentialsListVehiclesErrorComponent);
export type CredentialsListValidationError = {
  type: ValidationErrorEnum;
  errors: CredentialsListError[];
};
export type CredentialsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & CredentialsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type CredentialsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type CredentialsCreateCredentialTypeNameErrorComponent = {
  attr: 'credential_type.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateCredentialTypeDescriptionErrorComponent = {
  attr: 'credential_type.description';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateCredentialTypeCategoryErrorComponent = {
  attr: 'credential_type.category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type CredentialsCreateCredentialTypeIdErrorComponent = {
  attr: 'credential_type_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsCreateMembershipRoleErrorComponent = {
  attr: 'membership.role';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type CredentialsCreateMembershipFirstNameErrorComponent = {
  attr: 'membership.first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateMembershipLastNameErrorComponent = {
  attr: 'membership.last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateMembershipEmailErrorComponent = {
  attr: 'membership.email';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateMembershipPhoneNumberErrorComponent = {
  attr: 'membership.phone_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateMembershipPayerIdsErrorComponent = {
  attr: 'membership.payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type CredentialsCreateMembershipPayerIdsIndexErrorComponent = {
  attr: 'membership.payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsCreateMembershipIdErrorComponent = {
  attr: 'membership_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type CredentialsCreateVehicleNicknameErrorComponent = {
  attr: 'vehicle.nickname';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleVinErrorComponent = {
  attr: 'vehicle.vin';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleMakeErrorComponent = {
  attr: 'vehicle.make';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleModelErrorComponent = {
  attr: 'vehicle.model';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleColorErrorComponent = {
  attr: 'vehicle.color';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleYearErrorComponent = {
  attr: 'vehicle.year';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type CredentialsCreateVehicleCanBeScheduledErrorComponent = {
  attr: 'vehicle.can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type CredentialsCreateVehicleLicensePlateErrorComponent = {
  attr: 'vehicle.license_plate';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleLicensePlateStateErrorComponent = {
  attr: 'vehicle.license_plate_state';
  code: 'invalid_choice';
  detail: string;
};
export type CredentialsCreateVehicleCategoryErrorComponent = {
  attr: 'vehicle.category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type CredentialsCreateVehicleNotesErrorComponent = {
  attr: 'vehicle.notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleStorageLocationNonFieldErrorsErrorComponent =
  {
    attr: 'vehicle.storage_location.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type CredentialsCreateVehicleStorageLocationPlaceIdErrorComponent = {
  attr: 'vehicle.storage_location.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleCapacityConfigurationsNonFieldErrorsErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexWheelchairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.wheelchair_xl_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexBrodaChairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.broda_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexGeriChairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.geri_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexStretcherCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.stretcher_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.ambulatory_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.equipment_wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsCreateVehicleDeletedAtErrorComponent = {
  attr: 'vehicle.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type CredentialsCreateVehicleTagsIndexTagNameErrorComponent = {
  attr: 'vehicle.tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleTagsIndexColorHexErrorComponent = {
  attr: 'vehicle.tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateVehicleTagIdsErrorComponent = {
  attr: 'vehicle.tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type CredentialsCreateVehicleTagIdsIndexErrorComponent = {
  attr: 'vehicle.tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsCreateVehicleIdErrorComponent = {
  attr: 'vehicle_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type CredentialsCreateExpirationDateErrorComponent = {
  attr: 'expiration_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsCreateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsCreateError =
  | ({
      attr: 'non_field_errors';
    } & CredentialsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'credential_type.name';
    } & CredentialsCreateCredentialTypeNameErrorComponent)
  | ({
      attr: 'credential_type.description';
    } & CredentialsCreateCredentialTypeDescriptionErrorComponent)
  | ({
      attr: 'credential_type.category';
    } & CredentialsCreateCredentialTypeCategoryErrorComponent)
  | ({
      attr: 'credential_type_id';
    } & CredentialsCreateCredentialTypeIdErrorComponent)
  | ({
      attr: 'membership.role';
    } & CredentialsCreateMembershipRoleErrorComponent)
  | ({
      attr: 'membership.first_name';
    } & CredentialsCreateMembershipFirstNameErrorComponent)
  | ({
      attr: 'membership.last_name';
    } & CredentialsCreateMembershipLastNameErrorComponent)
  | ({
      attr: 'membership.email';
    } & CredentialsCreateMembershipEmailErrorComponent)
  | ({
      attr: 'membership.phone_number';
    } & CredentialsCreateMembershipPhoneNumberErrorComponent)
  | ({
      attr: 'membership.payer_ids';
    } & CredentialsCreateMembershipPayerIdsErrorComponent)
  | ({
      attr: 'membership.payer_ids.INDEX';
    } & CredentialsCreateMembershipPayerIdsIndexErrorComponent)
  | ({
      attr: 'membership_id';
    } & CredentialsCreateMembershipIdErrorComponent)
  | ({
      attr: 'vehicle.nickname';
    } & CredentialsCreateVehicleNicknameErrorComponent)
  | ({
      attr: 'vehicle.vin';
    } & CredentialsCreateVehicleVinErrorComponent)
  | ({
      attr: 'vehicle.make';
    } & CredentialsCreateVehicleMakeErrorComponent)
  | ({
      attr: 'vehicle.model';
    } & CredentialsCreateVehicleModelErrorComponent)
  | ({
      attr: 'vehicle.color';
    } & CredentialsCreateVehicleColorErrorComponent)
  | ({
      attr: 'vehicle.year';
    } & CredentialsCreateVehicleYearErrorComponent)
  | ({
      attr: 'vehicle.can_be_scheduled';
    } & CredentialsCreateVehicleCanBeScheduledErrorComponent)
  | ({
      attr: 'vehicle.license_plate';
    } & CredentialsCreateVehicleLicensePlateErrorComponent)
  | ({
      attr: 'vehicle.license_plate_state';
    } & CredentialsCreateVehicleLicensePlateStateErrorComponent)
  | ({
      attr: 'vehicle.category';
    } & CredentialsCreateVehicleCategoryErrorComponent)
  | ({
      attr: 'vehicle.notes';
    } & CredentialsCreateVehicleNotesErrorComponent)
  | ({
      attr: 'vehicle.storage_location.non_field_errors';
    } & CredentialsCreateVehicleStorageLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.storage_location.place_id';
    } & CredentialsCreateVehicleStorageLocationPlaceIdErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.non_field_errors';
    } & CredentialsCreateVehicleCapacityConfigurationsNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.non_field_errors';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.wheelchair_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexWheelchairCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.wheelchair_xl_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.broda_chair_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexBrodaChairCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.geri_chair_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexGeriChairCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.stretcher_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexStretcherCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.ambulatory_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.equipment_wheelchair_capacity';
    } & CredentialsCreateVehicleCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent)
  | ({
      attr: 'vehicle.deleted_at';
    } & CredentialsCreateVehicleDeletedAtErrorComponent)
  | ({
      attr: 'vehicle.tags.INDEX.tag_name';
    } & CredentialsCreateVehicleTagsIndexTagNameErrorComponent)
  | ({
      attr: 'vehicle.tags.INDEX.color_hex';
    } & CredentialsCreateVehicleTagsIndexColorHexErrorComponent)
  | ({
      attr: 'vehicle.tag_ids';
    } & CredentialsCreateVehicleTagIdsErrorComponent)
  | ({
      attr: 'vehicle.tag_ids.INDEX';
    } & CredentialsCreateVehicleTagIdsIndexErrorComponent)
  | ({
      attr: 'vehicle_id';
    } & CredentialsCreateVehicleIdErrorComponent)
  | ({
      attr: 'expiration_date';
    } & CredentialsCreateExpirationDateErrorComponent)
  | ({
      attr: 'notes';
    } & CredentialsCreateNotesErrorComponent);
export type CredentialsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: CredentialsCreateError[];
};
export type CredentialsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & CredentialsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type CredentialsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type CredentialsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type CredentialsPartialUpdateCredentialTypeNameErrorComponent = {
  attr: 'credential_type.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateCredentialTypeDescriptionErrorComponent = {
  attr: 'credential_type.description';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateCredentialTypeCategoryErrorComponent = {
  attr: 'credential_type.category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type CredentialsPartialUpdateCredentialTypeIdErrorComponent = {
  attr: 'credential_type_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsPartialUpdateMembershipRoleErrorComponent = {
  attr: 'membership.role';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type CredentialsPartialUpdateMembershipFirstNameErrorComponent = {
  attr: 'membership.first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateMembershipLastNameErrorComponent = {
  attr: 'membership.last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateMembershipEmailErrorComponent = {
  attr: 'membership.email';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateMembershipPhoneNumberErrorComponent = {
  attr: 'membership.phone_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateMembershipPayerIdsErrorComponent = {
  attr: 'membership.payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type CredentialsPartialUpdateMembershipPayerIdsIndexErrorComponent = {
  attr: 'membership.payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsPartialUpdateMembershipIdErrorComponent = {
  attr: 'membership_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type CredentialsPartialUpdateVehicleNicknameErrorComponent = {
  attr: 'vehicle.nickname';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleVinErrorComponent = {
  attr: 'vehicle.vin';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleMakeErrorComponent = {
  attr: 'vehicle.make';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleModelErrorComponent = {
  attr: 'vehicle.model';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleColorErrorComponent = {
  attr: 'vehicle.color';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleYearErrorComponent = {
  attr: 'vehicle.year';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type CredentialsPartialUpdateVehicleCanBeScheduledErrorComponent = {
  attr: 'vehicle.can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type CredentialsPartialUpdateVehicleLicensePlateErrorComponent = {
  attr: 'vehicle.license_plate';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleLicensePlateStateErrorComponent = {
  attr: 'vehicle.license_plate_state';
  code: 'invalid_choice';
  detail: string;
};
export type CredentialsPartialUpdateVehicleCategoryErrorComponent = {
  attr: 'vehicle.category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type CredentialsPartialUpdateVehicleNotesErrorComponent = {
  attr: 'vehicle.notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleStorageLocationNonFieldErrorsErrorComponent =
  {
    attr: 'vehicle.storage_location.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleStorageLocationPlaceIdErrorComponent =
  {
    attr: 'vehicle.storage_location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsNonFieldErrorsErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexWheelchairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.wheelchair_xl_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexBrodaChairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.broda_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexGeriChairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.geri_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexStretcherCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.stretcher_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.ambulatory_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent =
  {
    attr: 'vehicle.capacity_configurations.INDEX.equipment_wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type CredentialsPartialUpdateVehicleDeletedAtErrorComponent = {
  attr: 'vehicle.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type CredentialsPartialUpdateVehicleTagsIndexTagNameErrorComponent = {
  attr: 'vehicle.tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleTagsIndexColorHexErrorComponent = {
  attr: 'vehicle.tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateVehicleTagIdsErrorComponent = {
  attr: 'vehicle.tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type CredentialsPartialUpdateVehicleTagIdsIndexErrorComponent = {
  attr: 'vehicle.tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsPartialUpdateVehicleIdErrorComponent = {
  attr: 'vehicle_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type CredentialsPartialUpdateExpirationDateErrorComponent = {
  attr: 'expiration_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type CredentialsPartialUpdateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & CredentialsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'credential_type.name';
    } & CredentialsPartialUpdateCredentialTypeNameErrorComponent)
  | ({
      attr: 'credential_type.description';
    } & CredentialsPartialUpdateCredentialTypeDescriptionErrorComponent)
  | ({
      attr: 'credential_type.category';
    } & CredentialsPartialUpdateCredentialTypeCategoryErrorComponent)
  | ({
      attr: 'credential_type_id';
    } & CredentialsPartialUpdateCredentialTypeIdErrorComponent)
  | ({
      attr: 'membership.role';
    } & CredentialsPartialUpdateMembershipRoleErrorComponent)
  | ({
      attr: 'membership.first_name';
    } & CredentialsPartialUpdateMembershipFirstNameErrorComponent)
  | ({
      attr: 'membership.last_name';
    } & CredentialsPartialUpdateMembershipLastNameErrorComponent)
  | ({
      attr: 'membership.email';
    } & CredentialsPartialUpdateMembershipEmailErrorComponent)
  | ({
      attr: 'membership.phone_number';
    } & CredentialsPartialUpdateMembershipPhoneNumberErrorComponent)
  | ({
      attr: 'membership.payer_ids';
    } & CredentialsPartialUpdateMembershipPayerIdsErrorComponent)
  | ({
      attr: 'membership.payer_ids.INDEX';
    } & CredentialsPartialUpdateMembershipPayerIdsIndexErrorComponent)
  | ({
      attr: 'membership_id';
    } & CredentialsPartialUpdateMembershipIdErrorComponent)
  | ({
      attr: 'vehicle.nickname';
    } & CredentialsPartialUpdateVehicleNicknameErrorComponent)
  | ({
      attr: 'vehicle.vin';
    } & CredentialsPartialUpdateVehicleVinErrorComponent)
  | ({
      attr: 'vehicle.make';
    } & CredentialsPartialUpdateVehicleMakeErrorComponent)
  | ({
      attr: 'vehicle.model';
    } & CredentialsPartialUpdateVehicleModelErrorComponent)
  | ({
      attr: 'vehicle.color';
    } & CredentialsPartialUpdateVehicleColorErrorComponent)
  | ({
      attr: 'vehicle.year';
    } & CredentialsPartialUpdateVehicleYearErrorComponent)
  | ({
      attr: 'vehicle.can_be_scheduled';
    } & CredentialsPartialUpdateVehicleCanBeScheduledErrorComponent)
  | ({
      attr: 'vehicle.license_plate';
    } & CredentialsPartialUpdateVehicleLicensePlateErrorComponent)
  | ({
      attr: 'vehicle.license_plate_state';
    } & CredentialsPartialUpdateVehicleLicensePlateStateErrorComponent)
  | ({
      attr: 'vehicle.category';
    } & CredentialsPartialUpdateVehicleCategoryErrorComponent)
  | ({
      attr: 'vehicle.notes';
    } & CredentialsPartialUpdateVehicleNotesErrorComponent)
  | ({
      attr: 'vehicle.storage_location.non_field_errors';
    } & CredentialsPartialUpdateVehicleStorageLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.storage_location.place_id';
    } & CredentialsPartialUpdateVehicleStorageLocationPlaceIdErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.non_field_errors';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.non_field_errors';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.wheelchair_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexWheelchairCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.wheelchair_xl_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.broda_chair_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexBrodaChairCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.geri_chair_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexGeriChairCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.stretcher_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexStretcherCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.ambulatory_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent)
  | ({
      attr: 'vehicle.capacity_configurations.INDEX.equipment_wheelchair_capacity';
    } & CredentialsPartialUpdateVehicleCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent)
  | ({
      attr: 'vehicle.deleted_at';
    } & CredentialsPartialUpdateVehicleDeletedAtErrorComponent)
  | ({
      attr: 'vehicle.tags.INDEX.tag_name';
    } & CredentialsPartialUpdateVehicleTagsIndexTagNameErrorComponent)
  | ({
      attr: 'vehicle.tags.INDEX.color_hex';
    } & CredentialsPartialUpdateVehicleTagsIndexColorHexErrorComponent)
  | ({
      attr: 'vehicle.tag_ids';
    } & CredentialsPartialUpdateVehicleTagIdsErrorComponent)
  | ({
      attr: 'vehicle.tag_ids.INDEX';
    } & CredentialsPartialUpdateVehicleTagIdsIndexErrorComponent)
  | ({
      attr: 'vehicle_id';
    } & CredentialsPartialUpdateVehicleIdErrorComponent)
  | ({
      attr: 'expiration_date';
    } & CredentialsPartialUpdateExpirationDateErrorComponent)
  | ({
      attr: 'notes';
    } & CredentialsPartialUpdateNotesErrorComponent);
export type CredentialsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: CredentialsPartialUpdateError[];
};
export type CredentialsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & CredentialsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedCredential = {
  credential_type_id?: string;
  expiration_date?: string;
  notes?: string;
};
export type PatchedCredentialRead = {
  id?: string;
  credential_type?: CredentialTypeRead;
  credential_type_id?: string;
  membership?: MembershipRead;
  vehicle?: VehicleRead;
  expiration_date?: string;
  notes?: string;
};
export type PatchedCredentialWrite = {
  credential_type_id?: string;
  membership_id?: string;
  vehicle_id?: string;
  expiration_date?: string;
  notes?: string;
};
export type CredentialsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedCredentialTypeList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CredentialType[];
};
export type PaginatedCredentialTypeListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CredentialTypeRead[];
};
export type CredentialsTypesListIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesListNameErrorComponent = {
  attr: 'name';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesListDescriptionErrorComponent = {
  attr: 'description';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesListCategoryErrorComponent = {
  attr: 'category';
  code: 'invalid_choice';
  detail: string;
};
export type CredentialsTypesListError =
  | ({
      attr: 'id';
    } & CredentialsTypesListIdErrorComponent)
  | ({
      attr: 'name';
    } & CredentialsTypesListNameErrorComponent)
  | ({
      attr: 'description';
    } & CredentialsTypesListDescriptionErrorComponent)
  | ({
      attr: 'category';
    } & CredentialsTypesListCategoryErrorComponent);
export type CredentialsTypesListValidationError = {
  type: ValidationErrorEnum;
  errors: CredentialsTypesListError[];
};
export type CredentialsTypesListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & CredentialsTypesListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type CredentialsTypesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type CredentialsTypesCreateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesCreateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesCreateCategoryErrorComponent = {
  attr: 'category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type CredentialsTypesCreateError =
  | ({
      attr: 'non_field_errors';
    } & CredentialsTypesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'name';
    } & CredentialsTypesCreateNameErrorComponent)
  | ({
      attr: 'description';
    } & CredentialsTypesCreateDescriptionErrorComponent)
  | ({
      attr: 'category';
    } & CredentialsTypesCreateCategoryErrorComponent);
export type CredentialsTypesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: CredentialsTypesCreateError[];
};
export type CredentialsTypesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & CredentialsTypesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type CredentialsTypesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type CredentialsTypesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type CredentialsTypesPartialUpdateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesPartialUpdateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type CredentialsTypesPartialUpdateCategoryErrorComponent = {
  attr: 'category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type CredentialsTypesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & CredentialsTypesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'name';
    } & CredentialsTypesPartialUpdateNameErrorComponent)
  | ({
      attr: 'description';
    } & CredentialsTypesPartialUpdateDescriptionErrorComponent)
  | ({
      attr: 'category';
    } & CredentialsTypesPartialUpdateCategoryErrorComponent);
export type CredentialsTypesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: CredentialsTypesPartialUpdateError[];
};
export type CredentialsTypesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & CredentialsTypesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedCredentialType = {
  name?: string;
  description?: string;
  category?: CredentialCategoryEnum;
};
export type PatchedCredentialTypeRead = {
  id?: string;
  name?: string;
  description?: string;
  category?: CredentialCategoryEnum;
};
export type CredentialsTypesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type DispatchAssignment = {};
export type DispatchAssignedVehicle = {};
export type DispatchAssignedVehicleRead = {
  id: string;
  nickname: string;
  category: VehicleCategoryEnum;
};
export type DispatchAssignedDriver = {};
export type DispatchAssignedDriverRead = {
  id: string;
  assigned_membership: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  can_be_scheduled: boolean;
};
export type DispatchAssignmentStatusEnum = 'scheduled' | 'active' | 'complete';
export type DispatchAssignedAttendant = {};
export type DispatchAssignedAttendantRead = {
  id: string;
  first_name: string;
  last_name: string;
  can_be_scheduled: boolean;
};
export type DispatchAssignmentBreaks = {};
export type DispatchAssignmentBreaksRead = {
  id: string;
  start: string;
  end: string;
  break_notes: string;
  started_at: string | null;
  ended_at: string | null;
};
export type DispatchAssignmentRead = {
  id: string;
  start: string;
  end: string;
  vehicle: DispatchAssignedVehicleRead;
  driver: DispatchAssignedDriverRead;
  driver_call_requests: DriverCallRequestRead[];
  status: DispatchAssignmentStatusEnum;
  attendants: DispatchAssignedAttendantRead[];
  breaks: DispatchAssignmentBreaksRead[];
  clocked_in_at: string | null;
  clocked_out_at: string | null;
  estimated_revenue: number;
};
export type DispatchInboundTrip = {};
export type DispatchTripPassenger = {};
export type DispatchTripPassengerRead = {
  id: string;
  full_name: string;
};
export type DispatchInboundTripRead = {
  id: string;
  passenger: DispatchTripPassengerRead;
  scheduled_pickup_at: string;
  scheduled_dropoff_at: string;
  estimated_duration_sec: number;
  service_type: ServiceTypeEnum;
  /** Current status of this trip */
  status: OverrideStatusEnum;
  assignment_id: string;
  /** Locked assignment cannot be changed */
  is_locked: boolean | null;
  is_non_activated_will_call: boolean;
  pickup_address: string;
  dropoff_address: string;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** The time of the passenger's appointment */
  appointment_at: string | null;
  travel_time_minutes: number | null;
};
export type DispatchTrip = {
  inbound_trip: DispatchInboundTrip | null;
  /** Most recently calculated time of arrival to pickup destination */
  arrival_time: string;
  /** Most recently calculated time of trip completion */
  completion_time: string;
};
export type DispatchTripPayerOrganization = {};
export type DispatchTripPayerOrganizationRead = {
  id: string;
  /** Name the organization uses to refer to this payer across the app */
  display_name: string;
};
export type DispatchTripTiming = {};
export type DispatchTripTimingRead = {
  /** Estimated arrival at pickup location per vehicle's last location log */
  estimated_pickup_arrival_time: string | null;
  is_on_time: boolean | null;
  delayed_by_seconds: number | null;
  /** Last time this timing was calculated */
  last_calculated_at: string | null;
};
export type DispatchTripTag = {
  tag_name: string;
  color_hex: string;
};
export type DispatchTripTagRead = {
  id: string;
  tag: string;
  tag_name: string;
  color_hex: string;
};
export type IntermediaryEnum =
  | 'call_the_car'
  | 'kinetik'
  | 'momentm'
  | 'mtm'
  | 'alivi';
export type DispatchTripRead = {
  id: string;
  passenger: DispatchTripPassengerRead;
  payer: DispatchTripPayerOrganizationRead;
  scheduled_pickup_at: string;
  scheduled_dropoff_at: string;
  estimated_duration_sec: number;
  service_type: ServiceTypeEnum;
  /** Current status of this trip */
  status: OverrideStatusEnum;
  assignment_id: string;
  inbound_trip: DispatchInboundTripRead | null;
  /** Locked assignment cannot be changed */
  is_locked: boolean | null;
  is_non_activated_will_call: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound: boolean;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound: boolean;
  multiload_id: string | null;
  pickup_address: string;
  pickup_latitude: number;
  pickup_longitude: number;
  pickup_driver_notes: string;
  pickup_trip_location_detail_id: string | null;
  pickup_order: number | null;
  dropoff_address: string;
  dropoff_latitude: number;
  dropoff_longitude: number;
  dropoff_driver_notes: string;
  dropoff_trip_location_detail_id: string | null;
  dropoff_order: number | null;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** The time of the passenger's appointment */
  appointment_at: string | null;
  /** template used to create this trip, if uploaded via csv */
  one_schema_template_id: string | null;
  /** template used to create this trip, if backfilled via csv */
  one_schema_template_multi_id: string | null;
  timing: DispatchTripTimingRead | null;
  /** Optional extra time spent waiting for the pickup, provided by dispatcher */
  pickup_wait_time_seconds: number | null;
  tags: DispatchTripTagRead[] | null;
  /** Notes only visible to the dispatcher */
  dispatcher_notes: string;
  wait_time_start: string | null;
  wait_time_end: string | null;
  /** Most recently calculated time of arrival to pickup destination */
  arrival_time: string;
  /** Most recently calculated time of trip completion */
  completion_time: string;
  missing_any_required_signature: boolean | null;
  travel_time_minutes: number | null;
  /** The trip ID used by an intermediary/broker, may or may not be the originator */
  intermediary_id: string;
  /** The intermediary/broker source of this trip */
  intermediary: IntermediaryEnum;
};
export type DispatchTripSummary = {};
export type DispatchTripSummaryRead = {
  completed: number;
  will_call: number;
  canceled: number;
  on_time: number;
  delayed: number;
  assigned: number;
};
export type Dispatch = {
  assignments: DispatchAssignment[];
  unassigned_trips: DispatchTrip[];
  assigned_trips: DispatchTrip[];
  summary: DispatchTripSummary;
};
export type DispatchRead = {
  assignments: DispatchAssignmentRead[];
  unassigned_trips: DispatchTripRead[];
  assigned_trips: DispatchTripRead[];
  summary: DispatchTripSummaryRead;
};
export type DispatchRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type DriverTag = {
  driver: string;
  tag: string;
};
export type DriverTagRead = {
  id: string;
  tag_name: string;
  color_hex: string;
  driver: string;
  tag: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedDriverTagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DriverTag[];
};
export type PaginatedDriverTagListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DriverTagRead[];
};
export type DriverTagsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type DriverTagBulkCreate = {
  tags: DriverTag[];
};
export type DriverTagBulkCreateRead = {
  tags: DriverTagRead[];
};
export type DriverTagsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type DriverTagsCreateTagsNonFieldErrorsErrorComponent = {
  attr: 'tags.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type DriverTagsCreateTagsIndexNonFieldErrorsErrorComponent = {
  attr: 'tags.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required' | 'unique';
  detail: string;
};
export type DriverTagsCreateTagsIndexDriverErrorComponent = {
  attr: 'tags.INDEX.driver';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type DriverTagsCreateTagsIndexTagErrorComponent = {
  attr: 'tags.INDEX.tag';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type DriverTagsCreateError =
  | ({
      attr: 'non_field_errors';
    } & DriverTagsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.non_field_errors';
    } & DriverTagsCreateTagsNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.INDEX.non_field_errors';
    } & DriverTagsCreateTagsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.INDEX.driver';
    } & DriverTagsCreateTagsIndexDriverErrorComponent)
  | ({
      attr: 'tags.INDEX.tag';
    } & DriverTagsCreateTagsIndexTagErrorComponent);
export type DriverTagsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: DriverTagsCreateError[];
};
export type DriverTagsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & DriverTagsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type DriverTagsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type DriverTagsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type Driver = {
  can_be_scheduled?: boolean;
  license_number: string;
  license_expiration_date: string;
  license_dob?: string;
  license_state: UsStateEnum;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
  membership: string;
};
export type DriverDriverTag = {
  tag_name: string;
  color_hex: string;
};
export type DriverDriverTagRead = {
  id: string;
  tag: string;
  tag_name: string;
  color_hex: string;
  created_at: string;
  updated_at: string;
};
export type DriverRead = {
  id: string;
  first_name: string;
  last_name: string;
  app_version: string;
  can_be_scheduled?: boolean;
  license_number: string;
  license_expiration_date: string;
  license_dob?: string;
  license_state: UsStateEnum;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
  membership: string;
  organization_id: string;
  created_at: string;
  updated_at: string;
  tags: DriverDriverTagRead[] | null;
};
export type DriverWrite = {
  can_be_scheduled?: boolean;
  license_number: string;
  license_expiration_date: string;
  license_dob?: string;
  license_state: UsStateEnum;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
  membership: string;
  tag_ids?: string[] | null;
};
export type PaginatedDriverList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Driver[];
};
export type PaginatedDriverListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DriverRead[];
};
export type PaginatedDriverListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: DriverWrite[];
};
export type DriversListIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null_characters_not_allowed';
  detail: string;
};
export type DriversListError = {
  attr: 'id';
} & DriversListIdErrorComponent;
export type DriversListValidationError = {
  type: ValidationErrorEnum;
  errors: DriversListError[];
};
export type DriversListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & DriversListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type DriversCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type DriversCreateCanBeScheduledErrorComponent = {
  attr: 'can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateLicenseNumberErrorComponent = {
  attr: 'license_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type DriversCreateLicenseExpirationDateErrorComponent = {
  attr: 'license_expiration_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type DriversCreateLicenseDobErrorComponent = {
  attr: 'license_dob';
  code: 'datetime' | 'invalid' | 'null';
  detail: string;
};
export type DriversCreateLicenseStateErrorComponent = {
  attr: 'license_state';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type DriversCreateCanDoWheelchairServiceErrorComponent = {
  attr: 'can_do_wheelchair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoWheelchairXlServiceErrorComponent = {
  attr: 'can_do_wheelchair_xl_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoBrodaChairServiceErrorComponent = {
  attr: 'can_do_broda_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoGeriChairServiceErrorComponent = {
  attr: 'can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoStretcherServiceErrorComponent = {
  attr: 'can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoAmbulatoryServiceErrorComponent = {
  attr: 'can_do_ambulatory_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoCurbToCurbErrorComponent = {
  attr: 'can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoDoorToDoorErrorComponent = {
  attr: 'can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoDoorThroughDoorErrorComponent = {
  attr: 'can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoBariatricErrorComponent = {
  attr: 'can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateCanDoStairsErrorComponent = {
  attr: 'can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversCreateMembershipErrorComponent = {
  attr: 'membership';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required' | 'unique';
  detail: string;
};
export type DriversCreateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type DriversCreateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type DriversCreateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type DriversCreateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type DriversCreateError =
  | ({
      attr: 'non_field_errors';
    } & DriversCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'can_be_scheduled';
    } & DriversCreateCanBeScheduledErrorComponent)
  | ({
      attr: 'license_number';
    } & DriversCreateLicenseNumberErrorComponent)
  | ({
      attr: 'license_expiration_date';
    } & DriversCreateLicenseExpirationDateErrorComponent)
  | ({
      attr: 'license_dob';
    } & DriversCreateLicenseDobErrorComponent)
  | ({
      attr: 'license_state';
    } & DriversCreateLicenseStateErrorComponent)
  | ({
      attr: 'can_do_wheelchair_service';
    } & DriversCreateCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'can_do_wheelchair_xl_service';
    } & DriversCreateCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'can_do_broda_chair_service';
    } & DriversCreateCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'can_do_geri_chair_service';
    } & DriversCreateCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'can_do_stretcher_service';
    } & DriversCreateCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'can_do_ambulatory_service';
    } & DriversCreateCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'can_do_curb_to_curb';
    } & DriversCreateCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'can_do_door_to_door';
    } & DriversCreateCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'can_do_door_through_door';
    } & DriversCreateCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'can_do_bariatric';
    } & DriversCreateCanDoBariatricErrorComponent)
  | ({
      attr: 'can_do_stairs';
    } & DriversCreateCanDoStairsErrorComponent)
  | ({
      attr: 'membership';
    } & DriversCreateMembershipErrorComponent)
  | ({
      attr: 'tag_ids';
    } & DriversCreateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & DriversCreateTagIdsIndexErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & DriversCreateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & DriversCreateTagsIndexColorHexErrorComponent);
export type DriversCreateValidationError = {
  type: ValidationErrorEnum;
  errors: DriversCreateError[];
};
export type DriversCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & DriversCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type DriversRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type DriversPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type DriversPartialUpdateCanBeScheduledErrorComponent = {
  attr: 'can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateLicenseNumberErrorComponent = {
  attr: 'license_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type DriversPartialUpdateLicenseExpirationDateErrorComponent = {
  attr: 'license_expiration_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type DriversPartialUpdateLicenseDobErrorComponent = {
  attr: 'license_dob';
  code: 'datetime' | 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateLicenseStateErrorComponent = {
  attr: 'license_state';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type DriversPartialUpdateCanDoWheelchairServiceErrorComponent = {
  attr: 'can_do_wheelchair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoWheelchairXlServiceErrorComponent = {
  attr: 'can_do_wheelchair_xl_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoBrodaChairServiceErrorComponent = {
  attr: 'can_do_broda_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoGeriChairServiceErrorComponent = {
  attr: 'can_do_geri_chair_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoStretcherServiceErrorComponent = {
  attr: 'can_do_stretcher_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoAmbulatoryServiceErrorComponent = {
  attr: 'can_do_ambulatory_service';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoCurbToCurbErrorComponent = {
  attr: 'can_do_curb_to_curb';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoDoorToDoorErrorComponent = {
  attr: 'can_do_door_to_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoDoorThroughDoorErrorComponent = {
  attr: 'can_do_door_through_door';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoBariatricErrorComponent = {
  attr: 'can_do_bariatric';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateCanDoStairsErrorComponent = {
  attr: 'can_do_stairs';
  code: 'invalid' | 'null';
  detail: string;
};
export type DriversPartialUpdateMembershipErrorComponent = {
  attr: 'membership';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required' | 'unique';
  detail: string;
};
export type DriversPartialUpdateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type DriversPartialUpdateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type DriversPartialUpdateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type DriversPartialUpdateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type DriversPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & DriversPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'can_be_scheduled';
    } & DriversPartialUpdateCanBeScheduledErrorComponent)
  | ({
      attr: 'license_number';
    } & DriversPartialUpdateLicenseNumberErrorComponent)
  | ({
      attr: 'license_expiration_date';
    } & DriversPartialUpdateLicenseExpirationDateErrorComponent)
  | ({
      attr: 'license_dob';
    } & DriversPartialUpdateLicenseDobErrorComponent)
  | ({
      attr: 'license_state';
    } & DriversPartialUpdateLicenseStateErrorComponent)
  | ({
      attr: 'can_do_wheelchair_service';
    } & DriversPartialUpdateCanDoWheelchairServiceErrorComponent)
  | ({
      attr: 'can_do_wheelchair_xl_service';
    } & DriversPartialUpdateCanDoWheelchairXlServiceErrorComponent)
  | ({
      attr: 'can_do_broda_chair_service';
    } & DriversPartialUpdateCanDoBrodaChairServiceErrorComponent)
  | ({
      attr: 'can_do_geri_chair_service';
    } & DriversPartialUpdateCanDoGeriChairServiceErrorComponent)
  | ({
      attr: 'can_do_stretcher_service';
    } & DriversPartialUpdateCanDoStretcherServiceErrorComponent)
  | ({
      attr: 'can_do_ambulatory_service';
    } & DriversPartialUpdateCanDoAmbulatoryServiceErrorComponent)
  | ({
      attr: 'can_do_curb_to_curb';
    } & DriversPartialUpdateCanDoCurbToCurbErrorComponent)
  | ({
      attr: 'can_do_door_to_door';
    } & DriversPartialUpdateCanDoDoorToDoorErrorComponent)
  | ({
      attr: 'can_do_door_through_door';
    } & DriversPartialUpdateCanDoDoorThroughDoorErrorComponent)
  | ({
      attr: 'can_do_bariatric';
    } & DriversPartialUpdateCanDoBariatricErrorComponent)
  | ({
      attr: 'can_do_stairs';
    } & DriversPartialUpdateCanDoStairsErrorComponent)
  | ({
      attr: 'membership';
    } & DriversPartialUpdateMembershipErrorComponent)
  | ({
      attr: 'tag_ids';
    } & DriversPartialUpdateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & DriversPartialUpdateTagIdsIndexErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & DriversPartialUpdateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & DriversPartialUpdateTagsIndexColorHexErrorComponent);
export type DriversPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: DriversPartialUpdateError[];
};
export type DriversPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & DriversPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedDriver = {
  can_be_scheduled?: boolean;
  license_number?: string;
  license_expiration_date?: string;
  license_dob?: string;
  license_state?: UsStateEnum;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
  membership?: string;
};
export type PatchedDriverRead = {
  id?: string;
  first_name?: string;
  last_name?: string;
  app_version?: string;
  can_be_scheduled?: boolean;
  license_number?: string;
  license_expiration_date?: string;
  license_dob?: string;
  license_state?: UsStateEnum;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
  membership?: string;
  organization_id?: string;
  created_at?: string;
  updated_at?: string;
  tags?: DriverDriverTagRead[] | null;
};
export type PatchedDriverWrite = {
  can_be_scheduled?: boolean;
  license_number?: string;
  license_expiration_date?: string;
  license_dob?: string;
  license_state?: UsStateEnum;
  can_do_wheelchair_service?: boolean;
  can_do_wheelchair_xl_service?: boolean;
  can_do_broda_chair_service?: boolean;
  can_do_geri_chair_service?: boolean;
  can_do_stretcher_service?: boolean;
  can_do_ambulatory_service?: boolean;
  can_do_curb_to_curb?: boolean;
  can_do_door_to_door?: boolean;
  can_do_door_through_door?: boolean;
  can_do_bariatric?: boolean;
  can_do_stairs?: boolean;
  membership?: string;
  tag_ids?: string[] | null;
};
export type DriversDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type FiltersetEnum = 'InvoiceFilter' | 'TripFilter';
export type FilterGroup = {
  /** The human/computer readable name. */
  group_name: string;
  /** The URL-friendly name. */
  slug: string;
  /** Additional information for ops or admin. */
  description?: string;
  /** The associated django filterset class. */
  filterset: FiltersetEnum;
  /** Filters and their values. For example, {"overall_payment_status__in":"unpaid","payer__in":"018e7719-d16a-96b0-dac7-6d1ff142f8ef"}. */
  filters: {
    [key: string]: any;
  };
};
export type FilterGroupRead = {
  id: string;
  /** The human/computer readable name. */
  group_name: string;
  /** The URL-friendly name. */
  slug: string;
  /** Additional information for ops or admin. */
  description?: string;
  /** The associated django filterset class. */
  filterset: FiltersetEnum;
  /** Filters and their values. For example, {"overall_payment_status__in":"unpaid","payer__in":"018e7719-d16a-96b0-dac7-6d1ff142f8ef"}. */
  filters: {
    [key: string]: any;
  };
  created_at: string;
  updated_at: string;
};
export type PaginatedFilterGroupList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FilterGroup[];
};
export type PaginatedFilterGroupListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FilterGroupRead[];
};
export type FilterGroupsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type FilterGroupsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type FilterGroupsCreateGroupNameErrorComponent = {
  attr: 'group_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsCreateSlugErrorComponent = {
  attr: 'slug';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsCreateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsCreateFiltersetErrorComponent = {
  attr: 'filterset';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type FilterGroupsCreateFiltersErrorComponent = {
  attr: 'filters';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type FilterGroupsCreateError =
  | ({
      attr: 'non_field_errors';
    } & FilterGroupsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'group_name';
    } & FilterGroupsCreateGroupNameErrorComponent)
  | ({
      attr: 'slug';
    } & FilterGroupsCreateSlugErrorComponent)
  | ({
      attr: 'description';
    } & FilterGroupsCreateDescriptionErrorComponent)
  | ({
      attr: 'filterset';
    } & FilterGroupsCreateFiltersetErrorComponent)
  | ({
      attr: 'filters';
    } & FilterGroupsCreateFiltersErrorComponent);
export type FilterGroupsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: FilterGroupsCreateError[];
};
export type FilterGroupsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & FilterGroupsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type FilterGroupsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type FilterGroupsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type FilterGroupsPartialUpdateGroupNameErrorComponent = {
  attr: 'group_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsPartialUpdateSlugErrorComponent = {
  attr: 'slug';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsPartialUpdateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsPartialUpdateFiltersetErrorComponent = {
  attr: 'filterset';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type FilterGroupsPartialUpdateFiltersErrorComponent = {
  attr: 'filters';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type FilterGroupsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & FilterGroupsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'group_name';
    } & FilterGroupsPartialUpdateGroupNameErrorComponent)
  | ({
      attr: 'slug';
    } & FilterGroupsPartialUpdateSlugErrorComponent)
  | ({
      attr: 'description';
    } & FilterGroupsPartialUpdateDescriptionErrorComponent)
  | ({
      attr: 'filterset';
    } & FilterGroupsPartialUpdateFiltersetErrorComponent)
  | ({
      attr: 'filters';
    } & FilterGroupsPartialUpdateFiltersErrorComponent);
export type FilterGroupsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: FilterGroupsPartialUpdateError[];
};
export type FilterGroupsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & FilterGroupsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedFilterGroup = {
  /** The human/computer readable name. */
  group_name?: string;
  /** The URL-friendly name. */
  slug?: string;
  /** Additional information for ops or admin. */
  description?: string;
  /** The associated django filterset class. */
  filterset?: FiltersetEnum;
  /** Filters and their values. For example, {"overall_payment_status__in":"unpaid","payer__in":"018e7719-d16a-96b0-dac7-6d1ff142f8ef"}. */
  filters?: {
    [key: string]: any;
  };
};
export type PatchedFilterGroupRead = {
  id?: string;
  /** The human/computer readable name. */
  group_name?: string;
  /** The URL-friendly name. */
  slug?: string;
  /** Additional information for ops or admin. */
  description?: string;
  /** The associated django filterset class. */
  filterset?: FiltersetEnum;
  /** Filters and their values. For example, {"overall_payment_status__in":"unpaid","payer__in":"018e7719-d16a-96b0-dac7-6d1ff142f8ef"}. */
  filters?: {
    [key: string]: any;
  };
  created_at?: string;
  updated_at?: string;
};
export type FilterGroupsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type FilterGroupsCreateExamplesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type FilterGroupsCreateExamplesCreateGroupNameErrorComponent = {
  attr: 'group_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsCreateExamplesCreateSlugErrorComponent = {
  attr: 'slug';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsCreateExamplesCreateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type FilterGroupsCreateExamplesCreateFiltersetErrorComponent = {
  attr: 'filterset';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type FilterGroupsCreateExamplesCreateFiltersErrorComponent = {
  attr: 'filters';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type FilterGroupsCreateExamplesCreateError =
  | ({
      attr: 'non_field_errors';
    } & FilterGroupsCreateExamplesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'group_name';
    } & FilterGroupsCreateExamplesCreateGroupNameErrorComponent)
  | ({
      attr: 'slug';
    } & FilterGroupsCreateExamplesCreateSlugErrorComponent)
  | ({
      attr: 'description';
    } & FilterGroupsCreateExamplesCreateDescriptionErrorComponent)
  | ({
      attr: 'filterset';
    } & FilterGroupsCreateExamplesCreateFiltersetErrorComponent)
  | ({
      attr: 'filters';
    } & FilterGroupsCreateExamplesCreateFiltersErrorComponent);
export type FilterGroupsCreateExamplesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: FilterGroupsCreateExamplesCreateError[];
};
export type FilterGroupsCreateExamplesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & FilterGroupsCreateExamplesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type FeatureNameEnum =
  | 'demo'
  | 'dispatch arrival completion time'
  | 'driver call requests'
  | 'member credentials'
  | 'multi-loading'
  | 'onboarding via password reset'
  | 'passenger merge'
  | 'passenger messaging'
  | 'pricing version 1'
  | 'reporting'
  | 'ride ordering marketplace'
  | 'ride ordering portal'
  | 'tilled payment capture'
  | 'trip event history'
  | 'trip subscription update'
  | 'vehicle credentials';
export type FeatureFlag = {
  feature_name: FeatureNameEnum;
};
export type FeatureFlagRead = {
  feature_name: FeatureNameEnum;
  feature_is_on: boolean;
};
export type PaginatedFeatureFlagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FeatureFlag[];
};
export type PaginatedFeatureFlagListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FeatureFlagRead[];
};
export type FlagsFeatureValuesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type FlagsFeatureValuesMobileListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type FlagsFeatureValuesWebListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type Feature = {
  feature_name: FeatureNameEnum;
};
export type FlagTypeEnum = 'feature flag' | 'rollout toggle';
export type FeatureRead = {
  id: string;
  feature_name: FeatureNameEnum;
  /** The default state of this feature. If the flag is not set on an org, this is the state it will be in. */
  default_value: boolean;
  /** If True, this feature will always be in the default state, regardless of the flag. */
  force_default: boolean;
  /** If True, this feature is relevant to the mobile app. */
  is_mobile: boolean;
  /** If True, this feature is relevant to the web app. */
  is_web: boolean;
  /** The type of flag this feature is expected to be controlled by. Used for reporting and cleanup. Used for ops, and code warnings. */
  flag_type: FlagTypeEnum;
  created_at: string;
  updated_at: string;
};
export type PaginatedFeatureList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Feature[];
};
export type PaginatedFeatureListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: FeatureRead[];
};
export type FlagsFeaturesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type LineItemTypeEnum = 'trip' | 'modifier';
export type InvoiceNestedLineItem = {
  id?: string;
  description: string;
  amount_cents: number;
  trip?: string | null;
  line_item_type?: LineItemTypeEnum;
};
export type InvoiceLineItemPayer = {
  id: string;
  display_name: string;
};
export type InvoiceNestedLineItemRead = {
  id?: string;
  description: string;
  amount_cents: number;
  trip?: string | null;
  line_item_type?: LineItemTypeEnum;
  invoice: string;
  created_at: string;
  updated_at: string;
  trip_payer: InvoiceLineItemPayer | null;
};
export type Invoice = {
  number: string;
  date_issued: string;
  notes?: string;
  line_items: InvoiceNestedLineItem[];
};
export type InvoicePaymentStatusEnum =
  | 'canceled'
  | 'processing'
  | 'requires_action'
  | 'requires_capture'
  | 'requires_confirmation'
  | 'requires_payment_method'
  | 'succeeded'
  | 'refunded';
export type InvoicePayment = {
  id?: string;
  amount_cents: number;
  date: string;
  invoice: string;
  /** Tilled payment method id */
  external_payment_method_id: string;
  /** The status of the payment intent */
  status?: InvoicePaymentStatusEnum;
  by_passenger?: string | null;
  by_payer?: string | null;
  /** Set to true to mark payment as manually collected */
  is_manual?: boolean;
};
export type ChargeStatusEnum = 'succeeded' | 'pending' | 'failed';
export type InvoicePaymentRead = {
  id?: string;
  amount_cents: number;
  date: string;
  invoice: string;
  /** Tilled payment method id */
  external_payment_method_id: string;
  /** Tilled payment id */
  external_payment_id: string;
  /** The status of the payment intent */
  status?: InvoicePaymentStatusEnum;
  tilled_type_of_payer: string;
  id_of_payer: string | null;
  by_passenger?: string | null;
  by_payer?: string | null;
  created_at: string;
  updated_at: string;
  amount_total_cents: number;
  /** The status of the payment */
  charge_status: ChargeStatusEnum;
  refunds: string[];
  /** Tilled failure message for the charge */
  failure_message: string;
  /** Tilled failure code for the charge */
  failure_code: string;
  /** The time the charge failed at */
  failed_at: string | null;
  /** Set to true to mark payment as manually collected */
  is_manual?: boolean;
};
export type OverallPaymentStatusEnum =
  | 'paid'
  | 'partially_paid'
  | 'unpaid'
  | 'overpaid'
  | 'unknown';
export type InvoiceRead = {
  id: string;
  number: string;
  date_issued: string;
  notes?: string;
  line_items: InvoiceNestedLineItemRead[];
  total_amount_cents: number;
  total_amount_due_cents: number;
  payments: InvoicePaymentRead[] | null;
  overall_payment_status: OverallPaymentStatusEnum;
  created_at: string;
  updated_at: string;
};
export type PaginatedInvoiceList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Invoice[];
};
export type PaginatedInvoiceListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceRead[];
};
export type InvoicesInvoicesListCreatedAtErrorComponent = {
  attr: 'created_at';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesListDateIssuedErrorComponent = {
  attr: 'date_issued';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesListTotalAmountMinErrorComponent = {
  attr: 'total_amount_min';
  code: 'invalid' | 'max_value';
  detail: string;
};
export type InvoicesInvoicesListTotalAmountMaxErrorComponent = {
  attr: 'total_amount_max';
  code: 'invalid' | 'max_value';
  detail: string;
};
export type InvoicesInvoicesListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesListMinPaymentsErrorComponent = {
  attr: 'min_payments';
  code: 'invalid' | 'max_value';
  detail: string;
};
export type InvoicesInvoicesListDateIssuedStartErrorComponent = {
  attr: 'date_issued_start';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesListDateIssuedEndErrorComponent = {
  attr: 'date_issued_end';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesListOverallPaymentStatusInErrorComponent = {
  attr: 'overall_payment_status__in';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesListDueMinErrorComponent = {
  attr: 'due_min';
  code: 'invalid' | 'max_value';
  detail: string;
};
export type InvoicesInvoicesListDueMaxErrorComponent = {
  attr: 'due_max';
  code: 'invalid' | 'max_value';
  detail: string;
};
export type InvoicesInvoicesListTripPayerInErrorComponent = {
  attr: 'trip_payer__in';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesListError =
  | ({
      attr: 'created_at';
    } & InvoicesInvoicesListCreatedAtErrorComponent)
  | ({
      attr: 'date_issued';
    } & InvoicesInvoicesListDateIssuedErrorComponent)
  | ({
      attr: 'total_amount_min';
    } & InvoicesInvoicesListTotalAmountMinErrorComponent)
  | ({
      attr: 'total_amount_max';
    } & InvoicesInvoicesListTotalAmountMaxErrorComponent)
  | ({
      attr: 'range_start';
    } & InvoicesInvoicesListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & InvoicesInvoicesListRangeEndErrorComponent)
  | ({
      attr: 'min_payments';
    } & InvoicesInvoicesListMinPaymentsErrorComponent)
  | ({
      attr: 'date_issued_start';
    } & InvoicesInvoicesListDateIssuedStartErrorComponent)
  | ({
      attr: 'date_issued_end';
    } & InvoicesInvoicesListDateIssuedEndErrorComponent)
  | ({
      attr: 'overall_payment_status__in';
    } & InvoicesInvoicesListOverallPaymentStatusInErrorComponent)
  | ({
      attr: 'due_min';
    } & InvoicesInvoicesListDueMinErrorComponent)
  | ({
      attr: 'due_max';
    } & InvoicesInvoicesListDueMaxErrorComponent)
  | ({
      attr: 'trip_payer__in';
    } & InvoicesInvoicesListTripPayerInErrorComponent);
export type InvoicesInvoicesListValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesInvoicesListError[];
};
export type InvoicesInvoicesListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesInvoicesListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type InvoicesInvoicesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesCreateNumberErrorComponent = {
  attr: 'number';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesCreateDateIssuedErrorComponent = {
  attr: 'date_issued';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesCreateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsNonFieldErrorsErrorComponent = {
  attr: 'line_items.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexNonFieldErrorsErrorComponent = {
  attr: 'line_items.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexIdErrorComponent = {
  attr: 'line_items.INDEX.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexDescriptionErrorComponent = {
  attr: 'line_items.INDEX.description';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexAmountCentsErrorComponent = {
  attr: 'line_items.INDEX.amount_cents';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexTripErrorComponent = {
  attr: 'line_items.INDEX.trip';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexLineItemTypeErrorComponent = {
  attr: 'line_items.INDEX.line_item_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexTripPayerIdErrorComponent = {
  attr: 'line_items.INDEX.trip_payer.id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesCreateLineItemsIndexTripPayerDisplayNameErrorComponent =
  {
    attr: 'line_items.INDEX.trip_payer.display_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type InvoicesInvoicesCreatePaymentsIndexIdErrorComponent = {
  attr: 'payments.INDEX.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexAmountCentsErrorComponent = {
  attr: 'payments.INDEX.amount_cents';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexDateErrorComponent = {
  attr: 'payments.INDEX.date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexInvoiceErrorComponent = {
  attr: 'payments.INDEX.invoice';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexExternalPaymentMethodIdErrorComponent =
  {
    attr: 'payments.INDEX.external_payment_method_id';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type InvoicesInvoicesCreatePaymentsIndexStatusErrorComponent = {
  attr: 'payments.INDEX.status';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexByPassengerErrorComponent = {
  attr: 'payments.INDEX.by_passenger';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexByPayerErrorComponent = {
  attr: 'payments.INDEX.by_payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesInvoicesCreatePaymentsIndexIsManualErrorComponent = {
  attr: 'payments.INDEX.is_manual';
  code: 'invalid' | 'null';
  detail: string;
};
export type InvoicesInvoicesCreateError =
  | ({
      attr: 'non_field_errors';
    } & InvoicesInvoicesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'number';
    } & InvoicesInvoicesCreateNumberErrorComponent)
  | ({
      attr: 'date_issued';
    } & InvoicesInvoicesCreateDateIssuedErrorComponent)
  | ({
      attr: 'notes';
    } & InvoicesInvoicesCreateNotesErrorComponent)
  | ({
      attr: 'line_items.non_field_errors';
    } & InvoicesInvoicesCreateLineItemsNonFieldErrorsErrorComponent)
  | ({
      attr: 'line_items.INDEX.non_field_errors';
    } & InvoicesInvoicesCreateLineItemsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'line_items.INDEX.id';
    } & InvoicesInvoicesCreateLineItemsIndexIdErrorComponent)
  | ({
      attr: 'line_items.INDEX.description';
    } & InvoicesInvoicesCreateLineItemsIndexDescriptionErrorComponent)
  | ({
      attr: 'line_items.INDEX.amount_cents';
    } & InvoicesInvoicesCreateLineItemsIndexAmountCentsErrorComponent)
  | ({
      attr: 'line_items.INDEX.trip';
    } & InvoicesInvoicesCreateLineItemsIndexTripErrorComponent)
  | ({
      attr: 'line_items.INDEX.line_item_type';
    } & InvoicesInvoicesCreateLineItemsIndexLineItemTypeErrorComponent)
  | ({
      attr: 'line_items.INDEX.trip_payer.id';
    } & InvoicesInvoicesCreateLineItemsIndexTripPayerIdErrorComponent)
  | ({
      attr: 'line_items.INDEX.trip_payer.display_name';
    } & InvoicesInvoicesCreateLineItemsIndexTripPayerDisplayNameErrorComponent)
  | ({
      attr: 'payments.INDEX.id';
    } & InvoicesInvoicesCreatePaymentsIndexIdErrorComponent)
  | ({
      attr: 'payments.INDEX.amount_cents';
    } & InvoicesInvoicesCreatePaymentsIndexAmountCentsErrorComponent)
  | ({
      attr: 'payments.INDEX.date';
    } & InvoicesInvoicesCreatePaymentsIndexDateErrorComponent)
  | ({
      attr: 'payments.INDEX.invoice';
    } & InvoicesInvoicesCreatePaymentsIndexInvoiceErrorComponent)
  | ({
      attr: 'payments.INDEX.external_payment_method_id';
    } & InvoicesInvoicesCreatePaymentsIndexExternalPaymentMethodIdErrorComponent)
  | ({
      attr: 'payments.INDEX.status';
    } & InvoicesInvoicesCreatePaymentsIndexStatusErrorComponent)
  | ({
      attr: 'payments.INDEX.by_passenger';
    } & InvoicesInvoicesCreatePaymentsIndexByPassengerErrorComponent)
  | ({
      attr: 'payments.INDEX.by_payer';
    } & InvoicesInvoicesCreatePaymentsIndexByPayerErrorComponent)
  | ({
      attr: 'payments.INDEX.is_manual';
    } & InvoicesInvoicesCreatePaymentsIndexIsManualErrorComponent);
export type InvoicesInvoicesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesInvoicesCreateError[];
};
export type InvoicesInvoicesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesInvoicesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type InvoicesInvoicesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoicesInvoicesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateNumberErrorComponent = {
  attr: 'number';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateDateIssuedErrorComponent = {
  attr: 'date_issued';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateLineItemsNonFieldErrorsErrorComponent =
  {
    attr: 'line_items.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdateLineItemsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'line_items.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdateLineItemsIndexIdErrorComponent = {
  attr: 'line_items.INDEX.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateLineItemsIndexDescriptionErrorComponent =
  {
    attr: 'line_items.INDEX.description';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdateLineItemsIndexAmountCentsErrorComponent =
  {
    attr: 'line_items.INDEX.amount_cents';
    code:
      | 'invalid'
      | 'max_string_length'
      | 'max_value'
      | 'min_value'
      | 'null'
      | 'required';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdateLineItemsIndexTripErrorComponent = {
  attr: 'line_items.INDEX.trip';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateLineItemsIndexLineItemTypeErrorComponent =
  {
    attr: 'line_items.INDEX.line_item_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdateLineItemsIndexTripPayerIdErrorComponent =
  {
    attr: 'line_items.INDEX.trip_payer.id';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdateLineItemsIndexTripPayerDisplayNameErrorComponent =
  {
    attr: 'line_items.INDEX.trip_payer.display_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdatePaymentsIndexIdErrorComponent = {
  attr: 'payments.INDEX.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type InvoicesInvoicesPartialUpdatePaymentsIndexAmountCentsErrorComponent =
  {
    attr: 'payments.INDEX.amount_cents';
    code:
      | 'invalid'
      | 'max_string_length'
      | 'max_value'
      | 'min_value'
      | 'null'
      | 'required';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdatePaymentsIndexDateErrorComponent = {
  attr: 'payments.INDEX.date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesPartialUpdatePaymentsIndexInvoiceErrorComponent = {
  attr: 'payments.INDEX.invoice';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type InvoicesInvoicesPartialUpdatePaymentsIndexExternalPaymentMethodIdErrorComponent =
  {
    attr: 'payments.INDEX.external_payment_method_id';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdatePaymentsIndexStatusErrorComponent = {
  attr: 'payments.INDEX.status';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type InvoicesInvoicesPartialUpdatePaymentsIndexByPassengerErrorComponent =
  {
    attr: 'payments.INDEX.by_passenger';
    code: 'does_not_exist' | 'incorrect_type';
    detail: string;
  };
export type InvoicesInvoicesPartialUpdatePaymentsIndexByPayerErrorComponent = {
  attr: 'payments.INDEX.by_payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesInvoicesPartialUpdatePaymentsIndexIsManualErrorComponent = {
  attr: 'payments.INDEX.is_manual';
  code: 'invalid' | 'null';
  detail: string;
};
export type InvoicesInvoicesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & InvoicesInvoicesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'number';
    } & InvoicesInvoicesPartialUpdateNumberErrorComponent)
  | ({
      attr: 'date_issued';
    } & InvoicesInvoicesPartialUpdateDateIssuedErrorComponent)
  | ({
      attr: 'notes';
    } & InvoicesInvoicesPartialUpdateNotesErrorComponent)
  | ({
      attr: 'line_items.non_field_errors';
    } & InvoicesInvoicesPartialUpdateLineItemsNonFieldErrorsErrorComponent)
  | ({
      attr: 'line_items.INDEX.non_field_errors';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'line_items.INDEX.id';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexIdErrorComponent)
  | ({
      attr: 'line_items.INDEX.description';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexDescriptionErrorComponent)
  | ({
      attr: 'line_items.INDEX.amount_cents';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexAmountCentsErrorComponent)
  | ({
      attr: 'line_items.INDEX.trip';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexTripErrorComponent)
  | ({
      attr: 'line_items.INDEX.line_item_type';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexLineItemTypeErrorComponent)
  | ({
      attr: 'line_items.INDEX.trip_payer.id';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexTripPayerIdErrorComponent)
  | ({
      attr: 'line_items.INDEX.trip_payer.display_name';
    } & InvoicesInvoicesPartialUpdateLineItemsIndexTripPayerDisplayNameErrorComponent)
  | ({
      attr: 'payments.INDEX.id';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexIdErrorComponent)
  | ({
      attr: 'payments.INDEX.amount_cents';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexAmountCentsErrorComponent)
  | ({
      attr: 'payments.INDEX.date';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexDateErrorComponent)
  | ({
      attr: 'payments.INDEX.invoice';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexInvoiceErrorComponent)
  | ({
      attr: 'payments.INDEX.external_payment_method_id';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexExternalPaymentMethodIdErrorComponent)
  | ({
      attr: 'payments.INDEX.status';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexStatusErrorComponent)
  | ({
      attr: 'payments.INDEX.by_passenger';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexByPassengerErrorComponent)
  | ({
      attr: 'payments.INDEX.by_payer';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexByPayerErrorComponent)
  | ({
      attr: 'payments.INDEX.is_manual';
    } & InvoicesInvoicesPartialUpdatePaymentsIndexIsManualErrorComponent);
export type InvoicesInvoicesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesInvoicesPartialUpdateError[];
};
export type InvoicesInvoicesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesInvoicesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedInvoice = {
  number?: string;
  date_issued?: string;
  notes?: string;
  line_items?: InvoiceNestedLineItem[];
};
export type PatchedInvoiceRead = {
  id?: string;
  number?: string;
  date_issued?: string;
  notes?: string;
  line_items?: InvoiceNestedLineItemRead[];
  total_amount_cents?: number;
  total_amount_due_cents?: number;
  payments?: InvoicePaymentRead[] | null;
  overall_payment_status?: OverallPaymentStatusEnum;
  created_at?: string;
  updated_at?: string;
};
export type InvoicesInvoicesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoiceLineItem = {
  description: string;
  amount_cents: number;
  trip?: string | null;
  line_item_type?: LineItemTypeEnum;
  invoice: string;
};
export type InvoiceLineItemRead = {
  id: string;
  description: string;
  amount_cents: number;
  trip?: string | null;
  line_item_type?: LineItemTypeEnum;
  invoice: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedInvoiceLineItemList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceLineItem[];
};
export type PaginatedInvoiceLineItemListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceLineItemRead[];
};
export type InvoicesLineItemsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoicesLineItemsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type InvoicesLineItemsCreateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesLineItemsCreateAmountCentsErrorComponent = {
  attr: 'amount_cents';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type InvoicesLineItemsCreateTripErrorComponent = {
  attr: 'trip';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesLineItemsCreateLineItemTypeErrorComponent = {
  attr: 'line_item_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type InvoicesLineItemsCreateInvoiceErrorComponent = {
  attr: 'invoice';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type InvoicesLineItemsCreateError =
  | ({
      attr: 'non_field_errors';
    } & InvoicesLineItemsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'description';
    } & InvoicesLineItemsCreateDescriptionErrorComponent)
  | ({
      attr: 'amount_cents';
    } & InvoicesLineItemsCreateAmountCentsErrorComponent)
  | ({
      attr: 'trip';
    } & InvoicesLineItemsCreateTripErrorComponent)
  | ({
      attr: 'line_item_type';
    } & InvoicesLineItemsCreateLineItemTypeErrorComponent)
  | ({
      attr: 'invoice';
    } & InvoicesLineItemsCreateInvoiceErrorComponent);
export type InvoicesLineItemsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesLineItemsCreateError[];
};
export type InvoicesLineItemsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesLineItemsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type InvoicesLineItemsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoicesLineItemsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type InvoicesLineItemsPartialUpdateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesLineItemsPartialUpdateAmountCentsErrorComponent = {
  attr: 'amount_cents';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type InvoicesLineItemsPartialUpdateTripErrorComponent = {
  attr: 'trip';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesLineItemsPartialUpdateLineItemTypeErrorComponent = {
  attr: 'line_item_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type InvoicesLineItemsPartialUpdateInvoiceErrorComponent = {
  attr: 'invoice';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type InvoicesLineItemsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & InvoicesLineItemsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'description';
    } & InvoicesLineItemsPartialUpdateDescriptionErrorComponent)
  | ({
      attr: 'amount_cents';
    } & InvoicesLineItemsPartialUpdateAmountCentsErrorComponent)
  | ({
      attr: 'trip';
    } & InvoicesLineItemsPartialUpdateTripErrorComponent)
  | ({
      attr: 'line_item_type';
    } & InvoicesLineItemsPartialUpdateLineItemTypeErrorComponent)
  | ({
      attr: 'invoice';
    } & InvoicesLineItemsPartialUpdateInvoiceErrorComponent);
export type InvoicesLineItemsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesLineItemsPartialUpdateError[];
};
export type InvoicesLineItemsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesLineItemsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedInvoiceLineItem = {
  description?: string;
  amount_cents?: number;
  trip?: string | null;
  line_item_type?: LineItemTypeEnum;
  invoice?: string;
};
export type PatchedInvoiceLineItemRead = {
  id?: string;
  description?: string;
  amount_cents?: number;
  trip?: string | null;
  line_item_type?: LineItemTypeEnum;
  invoice?: string;
  created_at?: string;
  updated_at?: string;
};
export type InvoicesLineItemsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedInvoicePaymentList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoicePayment[];
};
export type PaginatedInvoicePaymentListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoicePaymentRead[];
};
export type InvoicesPaymentsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoicesPaymentsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type InvoicesPaymentsCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type InvoicesPaymentsCreateAmountCentsErrorComponent = {
  attr: 'amount_cents';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type InvoicesPaymentsCreateDateErrorComponent = {
  attr: 'date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type InvoicesPaymentsCreateInvoiceErrorComponent = {
  attr: 'invoice';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type InvoicesPaymentsCreateExternalPaymentMethodIdErrorComponent = {
  attr: 'external_payment_method_id';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesPaymentsCreateStatusErrorComponent = {
  attr: 'status';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type InvoicesPaymentsCreateByPassengerErrorComponent = {
  attr: 'by_passenger';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesPaymentsCreateByPayerErrorComponent = {
  attr: 'by_payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type InvoicesPaymentsCreateIsManualErrorComponent = {
  attr: 'is_manual';
  code: 'invalid' | 'null';
  detail: string;
};
export type InvoicesPaymentsCreateError =
  | ({
      attr: 'non_field_errors';
    } & InvoicesPaymentsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & InvoicesPaymentsCreateIdErrorComponent)
  | ({
      attr: 'amount_cents';
    } & InvoicesPaymentsCreateAmountCentsErrorComponent)
  | ({
      attr: 'date';
    } & InvoicesPaymentsCreateDateErrorComponent)
  | ({
      attr: 'invoice';
    } & InvoicesPaymentsCreateInvoiceErrorComponent)
  | ({
      attr: 'external_payment_method_id';
    } & InvoicesPaymentsCreateExternalPaymentMethodIdErrorComponent)
  | ({
      attr: 'status';
    } & InvoicesPaymentsCreateStatusErrorComponent)
  | ({
      attr: 'by_passenger';
    } & InvoicesPaymentsCreateByPassengerErrorComponent)
  | ({
      attr: 'by_payer';
    } & InvoicesPaymentsCreateByPayerErrorComponent)
  | ({
      attr: 'is_manual';
    } & InvoicesPaymentsCreateIsManualErrorComponent);
export type InvoicesPaymentsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesPaymentsCreateError[];
};
export type InvoicesPaymentsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesPaymentsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type InvoicesPaymentsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoiceRefund = {
  payment: string;
  /** Tilled payment intent id */
  payment_intent_id: string;
  amount_cents: number;
};
export type InvoiceRefundStatusEnum =
  | 'canceled'
  | 'pending'
  | 'succeeded'
  | 'failed'
  | 'requires_action';
export type InvoiceRefundRead = {
  id: string;
  payment: string;
  /** Tilled payment intent id */
  payment_intent_id: string;
  amount_cents: number;
  /** Tilled refund id */
  external_refund_id: string;
  /** Tilled payment method id */
  external_payment_method_id: string;
  /** Tilled refund-charge id */
  charge_id: string;
  status: InvoiceRefundStatusEnum;
  reason: string;
  failure_code: string;
  failure_message: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedInvoiceRefundList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceRefund[];
};
export type PaginatedInvoiceRefundListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceRefundRead[];
};
export type InvoicesRefundsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type InvoicesRefundsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type InvoicesRefundsCreatePaymentErrorComponent = {
  attr: 'payment';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type InvoicesRefundsCreatePaymentIntentIdErrorComponent = {
  attr: 'payment_intent_id';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type InvoicesRefundsCreateAmountCentsErrorComponent = {
  attr: 'amount_cents';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type InvoicesRefundsCreateError =
  | ({
      attr: 'non_field_errors';
    } & InvoicesRefundsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'payment';
    } & InvoicesRefundsCreatePaymentErrorComponent)
  | ({
      attr: 'payment_intent_id';
    } & InvoicesRefundsCreatePaymentIntentIdErrorComponent)
  | ({
      attr: 'amount_cents';
    } & InvoicesRefundsCreateAmountCentsErrorComponent);
export type InvoicesRefundsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: InvoicesRefundsCreateError[];
};
export type InvoicesRefundsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & InvoicesRefundsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type InvoicesRefundsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedAddressList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Address[];
};
export type PaginatedAddressListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: AddressRead[];
};
export type LocationsAddressesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type LocationsAddressesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type LocationLogEntry = {};
export type EventEnum =
  | 'motionchange'
  | 'providerchange'
  | 'geofence'
  | 'heartbeat';
export type LocationLogEntryLocationBattery = {
  is_charging: boolean;
  level: number;
};
export type LocationLogEntryLocationActivityTypeEnum =
  | 'still'
  | 'on_foot'
  | 'walking'
  | 'running'
  | 'in_vehicle'
  | 'on_bicycle'
  | 'unknown';
export type LocationLogEntryLocationActivity = {
  confidence: number;
  type: LocationLogEntryLocationActivityTypeEnum;
};
export type LocationLogEntryLocationCoords = {
  longitude: number;
  latitude: number;
  /** Location accuracy in meters. */
  accuracy: number;
  /** Speed in meters / second. Only present when location came from GPS. -1 otherwise. */
  speed: number;
  /** Speed accuracy in meters / second. Only present when location came from GPS. -1 otherwise. */
  speed_accuracy: number;
  /** Heading in degrees. Only present when location came from GPS. -1 otherwise. */
  heading: number;
  /** Heading accuracy in degrees. Only present when location came from GPS. -1 otherwise. */
  heading_accuracy: number;
  /** [iOS] Altitude above sea-level in meters. [Android] The altitude in meters above the WGS84 reference ellipsoid. */
  altitude: number;
  /** Altitude accuracy in meters, If this location does not have altitude_accuracy, then -1. */
  altitude_accuracy: number;
  /** The altitude of this location in meters above the WGS84 reference ellipsoid. */
  ellipsoidal_altitude: number;
  /** [iOS Only] When the env has indoor-tracking hardware, the current floor within a building. */
  floor?: number | null;
};
export type LocationLogEntryLocationExtras = {
  assignment?: string | null;
  vehicle?: string | null;
  driver?: string | null;
  trips?: string[];
};
export type LocationLogEntryLocation = {
  timestamp: string;
  uuid: string;
  /** If location was recorded while plugin is in the moving state. */
  is_moving: boolean;
  /** Distance traveled in meters. */
  odometer: number;
  /** Event responsible for generating this location. */
  event?: EventEnum | BlankEnum;
  battery: LocationLogEntryLocationBattery;
  activity: LocationLogEntryLocationActivity;
  coords: LocationLogEntryLocationCoords;
  extras: LocationLogEntryLocationExtras;
};
export type LocationLogEntryWrite = {
  location: LocationLogEntryLocation;
};
export type LocationsLogCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type LocationsLogCreateLocationNonFieldErrorsErrorComponent = {
  attr: 'location.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationTimestampErrorComponent = {
  attr: 'location.timestamp';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationUuidErrorComponent = {
  attr: 'location.uuid';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationIsMovingErrorComponent = {
  attr: 'location.is_moving';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationOdometerErrorComponent = {
  attr: 'location.odometer';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationEventErrorComponent = {
  attr: 'location.event';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type LocationsLogCreateLocationBatteryNonFieldErrorsErrorComponent = {
  attr: 'location.battery.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationBatteryIsChargingErrorComponent = {
  attr: 'location.battery.is_charging';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationBatteryLevelErrorComponent = {
  attr: 'location.battery.level';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type LocationsLogCreateLocationActivityNonFieldErrorsErrorComponent = {
  attr: 'location.activity.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationActivityConfidenceErrorComponent = {
  attr: 'location.activity.confidence';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type LocationsLogCreateLocationActivityTypeErrorComponent = {
  attr: 'location.activity.type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsNonFieldErrorsErrorComponent = {
  attr: 'location.coords.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsLongitudeErrorComponent = {
  attr: 'location.coords.longitude';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsLatitudeErrorComponent = {
  attr: 'location.coords.latitude';
  code:
    | 'invalid'
    | 'max_string_length'
    | 'max_value'
    | 'min_value'
    | 'null'
    | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsAccuracyErrorComponent = {
  attr: 'location.coords.accuracy';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsSpeedErrorComponent = {
  attr: 'location.coords.speed';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsSpeedAccuracyErrorComponent = {
  attr: 'location.coords.speed_accuracy';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsHeadingErrorComponent = {
  attr: 'location.coords.heading';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsHeadingAccuracyErrorComponent = {
  attr: 'location.coords.heading_accuracy';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsAltitudeErrorComponent = {
  attr: 'location.coords.altitude';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsAltitudeAccuracyErrorComponent = {
  attr: 'location.coords.altitude_accuracy';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationCoordsEllipsoidalAltitudeErrorComponent =
  {
    attr: 'location.coords.ellipsoidal_altitude';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type LocationsLogCreateLocationCoordsFloorErrorComponent = {
  attr: 'location.coords.floor';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type LocationsLogCreateLocationExtrasNonFieldErrorsErrorComponent = {
  attr: 'location.extras.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateLocationExtrasAssignmentErrorComponent = {
  attr: 'location.extras.assignment';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type LocationsLogCreateLocationExtrasVehicleErrorComponent = {
  attr: 'location.extras.vehicle';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type LocationsLogCreateLocationExtrasDriverErrorComponent = {
  attr: 'location.extras.driver';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type LocationsLogCreateLocationExtrasTripsErrorComponent = {
  attr: 'location.extras.trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type LocationsLogCreateLocationExtrasTripsIndexErrorComponent = {
  attr: 'location.extras.trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type LocationsLogCreateError =
  | ({
      attr: 'non_field_errors';
    } & LocationsLogCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'location.non_field_errors';
    } & LocationsLogCreateLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'location.timestamp';
    } & LocationsLogCreateLocationTimestampErrorComponent)
  | ({
      attr: 'location.uuid';
    } & LocationsLogCreateLocationUuidErrorComponent)
  | ({
      attr: 'location.is_moving';
    } & LocationsLogCreateLocationIsMovingErrorComponent)
  | ({
      attr: 'location.odometer';
    } & LocationsLogCreateLocationOdometerErrorComponent)
  | ({
      attr: 'location.event';
    } & LocationsLogCreateLocationEventErrorComponent)
  | ({
      attr: 'location.battery.non_field_errors';
    } & LocationsLogCreateLocationBatteryNonFieldErrorsErrorComponent)
  | ({
      attr: 'location.battery.is_charging';
    } & LocationsLogCreateLocationBatteryIsChargingErrorComponent)
  | ({
      attr: 'location.battery.level';
    } & LocationsLogCreateLocationBatteryLevelErrorComponent)
  | ({
      attr: 'location.activity.non_field_errors';
    } & LocationsLogCreateLocationActivityNonFieldErrorsErrorComponent)
  | ({
      attr: 'location.activity.confidence';
    } & LocationsLogCreateLocationActivityConfidenceErrorComponent)
  | ({
      attr: 'location.activity.type';
    } & LocationsLogCreateLocationActivityTypeErrorComponent)
  | ({
      attr: 'location.coords.non_field_errors';
    } & LocationsLogCreateLocationCoordsNonFieldErrorsErrorComponent)
  | ({
      attr: 'location.coords.longitude';
    } & LocationsLogCreateLocationCoordsLongitudeErrorComponent)
  | ({
      attr: 'location.coords.latitude';
    } & LocationsLogCreateLocationCoordsLatitudeErrorComponent)
  | ({
      attr: 'location.coords.accuracy';
    } & LocationsLogCreateLocationCoordsAccuracyErrorComponent)
  | ({
      attr: 'location.coords.speed';
    } & LocationsLogCreateLocationCoordsSpeedErrorComponent)
  | ({
      attr: 'location.coords.speed_accuracy';
    } & LocationsLogCreateLocationCoordsSpeedAccuracyErrorComponent)
  | ({
      attr: 'location.coords.heading';
    } & LocationsLogCreateLocationCoordsHeadingErrorComponent)
  | ({
      attr: 'location.coords.heading_accuracy';
    } & LocationsLogCreateLocationCoordsHeadingAccuracyErrorComponent)
  | ({
      attr: 'location.coords.altitude';
    } & LocationsLogCreateLocationCoordsAltitudeErrorComponent)
  | ({
      attr: 'location.coords.altitude_accuracy';
    } & LocationsLogCreateLocationCoordsAltitudeAccuracyErrorComponent)
  | ({
      attr: 'location.coords.ellipsoidal_altitude';
    } & LocationsLogCreateLocationCoordsEllipsoidalAltitudeErrorComponent)
  | ({
      attr: 'location.coords.floor';
    } & LocationsLogCreateLocationCoordsFloorErrorComponent)
  | ({
      attr: 'location.extras.non_field_errors';
    } & LocationsLogCreateLocationExtrasNonFieldErrorsErrorComponent)
  | ({
      attr: 'location.extras.assignment';
    } & LocationsLogCreateLocationExtrasAssignmentErrorComponent)
  | ({
      attr: 'location.extras.vehicle';
    } & LocationsLogCreateLocationExtrasVehicleErrorComponent)
  | ({
      attr: 'location.extras.driver';
    } & LocationsLogCreateLocationExtrasDriverErrorComponent)
  | ({
      attr: 'location.extras.trips';
    } & LocationsLogCreateLocationExtrasTripsErrorComponent)
  | ({
      attr: 'location.extras.trips.INDEX';
    } & LocationsLogCreateLocationExtrasTripsIndexErrorComponent);
export type LocationsLogCreateValidationError = {
  type: ValidationErrorEnum;
  errors: LocationsLogCreateError[];
};
export type LocationsLogCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & LocationsLogCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MunicipalityList = {
  municipality: string[];
};
export type LocationsMunicipalitiesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PassengerAddress = {};
export type PassengerAddressRead = {
  id: string;
  organization_id: string;
  passenger: string;
  address: AddressRead;
  /** If this address should be used on insurance claims (or CC processing) */
  is_billing_address: boolean;
  /** If this address is the patient's primary (likely home) address */
  is_primary_address: boolean;
  /** Additional details about this mapping; ex., what loading zone the passenger prefers */
  address_notes: string;
  /** Address details provided from trip information */
  address_details: string;
  /** Last time this address was used in a trip */
  last_used_at: string | null;
  /** The expected load time for this specific trip, provided by the customer */
  load_time_seconds: number | null;
  /** The expected unload time for this specific trip, provided by the customer */
  unload_time_seconds: number | null;
  created_at: string;
  updated_at: string;
};
export type PaginatedPassengerAddressList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PassengerAddress[];
};
export type PaginatedPassengerAddressListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PassengerAddressRead[];
};
export type LocationsPassengerAddressesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type LocationsPassengerAddressesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedMembershipList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Membership[];
};
export type PaginatedMembershipListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MembershipRead[];
};
export type PaginatedMembershipListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MembershipWrite[];
};
export type MembersListRoleErrorComponent = {
  attr: 'role';
  code: 'invalid_choice';
  detail: string;
};
export type MembersListError = {
  attr: 'role';
} & MembersListRoleErrorComponent;
export type MembersListValidationError = {
  type: ValidationErrorEnum;
  errors: MembersListError[];
};
export type MembersListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MembersListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MembersCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type MembersCreateRoleErrorComponent = {
  attr: 'role';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MembersCreateFirstNameErrorComponent = {
  attr: 'first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersCreateLastNameErrorComponent = {
  attr: 'last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersCreateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersCreatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersCreatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type MembersCreatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type MembersCreateError =
  | ({
      attr: 'non_field_errors';
    } & MembersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'role';
    } & MembersCreateRoleErrorComponent)
  | ({
      attr: 'first_name';
    } & MembersCreateFirstNameErrorComponent)
  | ({
      attr: 'last_name';
    } & MembersCreateLastNameErrorComponent)
  | ({
      attr: 'email';
    } & MembersCreateEmailErrorComponent)
  | ({
      attr: 'phone_number';
    } & MembersCreatePhoneNumberErrorComponent)
  | ({
      attr: 'payer_ids';
    } & MembersCreatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & MembersCreatePayerIdsIndexErrorComponent);
export type MembersCreateValidationError = {
  type: ValidationErrorEnum;
  errors: MembersCreateError[];
};
export type MembersCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MembersCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MembersRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type MembersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type MembersPartialUpdateRoleErrorComponent = {
  attr: 'role';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MembersPartialUpdateFirstNameErrorComponent = {
  attr: 'first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersPartialUpdateLastNameErrorComponent = {
  attr: 'last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersPartialUpdateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersPartialUpdatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersPartialUpdatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type MembersPartialUpdatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type MembersPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & MembersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'role';
    } & MembersPartialUpdateRoleErrorComponent)
  | ({
      attr: 'first_name';
    } & MembersPartialUpdateFirstNameErrorComponent)
  | ({
      attr: 'last_name';
    } & MembersPartialUpdateLastNameErrorComponent)
  | ({
      attr: 'email';
    } & MembersPartialUpdateEmailErrorComponent)
  | ({
      attr: 'phone_number';
    } & MembersPartialUpdatePhoneNumberErrorComponent)
  | ({
      attr: 'payer_ids';
    } & MembersPartialUpdatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & MembersPartialUpdatePayerIdsIndexErrorComponent);
export type MembersPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: MembersPartialUpdateError[];
};
export type MembersPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MembersPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedMembership = {
  role?: RoleEnum;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
};
export type PatchedMembershipRead = {
  id?: string;
  organization_id?: string;
  role?: RoleEnum;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  status?: string;
  active_payers?: any[] | null;
  shared_payer_count?: number | null;
  deleted_at?: string | null;
};
export type PatchedMembershipWrite = {
  role?: RoleEnum;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  payer_ids?: string[] | null;
};
export type MembersDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type MembersUndeleteCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type MembersUndeleteCreateRoleErrorComponent = {
  attr: 'role';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MembersUndeleteCreateFirstNameErrorComponent = {
  attr: 'first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersUndeleteCreateLastNameErrorComponent = {
  attr: 'last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersUndeleteCreateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersUndeleteCreatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MembersUndeleteCreatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type MembersUndeleteCreatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type MembersUndeleteCreateError =
  | ({
      attr: 'non_field_errors';
    } & MembersUndeleteCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'role';
    } & MembersUndeleteCreateRoleErrorComponent)
  | ({
      attr: 'first_name';
    } & MembersUndeleteCreateFirstNameErrorComponent)
  | ({
      attr: 'last_name';
    } & MembersUndeleteCreateLastNameErrorComponent)
  | ({
      attr: 'email';
    } & MembersUndeleteCreateEmailErrorComponent)
  | ({
      attr: 'phone_number';
    } & MembersUndeleteCreatePhoneNumberErrorComponent)
  | ({
      attr: 'payer_ids';
    } & MembersUndeleteCreatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & MembersUndeleteCreatePayerIdsIndexErrorComponent);
export type MembersUndeleteCreateValidationError = {
  type: ValidationErrorEnum;
  errors: MembersUndeleteCreateError[];
};
export type MembersUndeleteCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MembersUndeleteCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type DirectionEnum =
  | 'inbound'
  | 'outbound-api'
  | 'outbound-call'
  | 'outbound-reply';
export type MessageStatusEnum =
  | 'queued'
  | 'sending'
  | 'sent'
  | 'failed'
  | 'delivered'
  | 'undelivered'
  | 'receiving'
  | 'received'
  | 'accepted'
  | 'scheduled'
  | 'read'
  | 'partially_delivered'
  | 'canceled';
export type Message = {
  id?: string;
  deleted_at?: string | null;
  body: string;
  direction?: DirectionEnum;
  status?: MessageStatusEnum;
  from_number: string;
  to: string;
  uri?: string;
  account_sid: string;
  messaging_service_sid?: string;
  num_media?: number;
  num_segments?: number;
  price?: string;
  price_unit?: string;
  error_message?: string;
  error_code?: number | null;
  sid?: string;
  date_sent?: string | null;
  date_created?: string | null;
  date_updated?: string | null;
  api_version?: string;
  organization: string;
};
export type MessageRead = {
  id?: string;
  deleted_at?: string | null;
  created_at: string;
  updated_at: string;
  body: string;
  direction?: DirectionEnum;
  status?: MessageStatusEnum;
  from_number: string;
  to: string;
  uri?: string;
  account_sid: string;
  messaging_service_sid?: string;
  num_media?: number;
  num_segments?: number;
  price?: string;
  price_unit?: string;
  error_message?: string;
  error_code?: number | null;
  sid?: string;
  date_sent?: string | null;
  date_created?: string | null;
  date_updated?: string | null;
  api_version?: string;
  organization: string;
};
export type PaginatedMessageList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Message[];
};
export type PaginatedMessageListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MessageRead[];
};
export type MessagesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type MessagesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type MessagesCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type MessagesCreateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesCreateBodyErrorComponent = {
  attr: 'body';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateDirectionErrorComponent = {
  attr: 'direction';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MessagesCreateStatusErrorComponent = {
  attr: 'status';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MessagesCreateFromNumberErrorComponent = {
  attr: 'from_number';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateToErrorComponent = {
  attr: 'to';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateUriErrorComponent = {
  attr: 'uri';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateAccountSidErrorComponent = {
  attr: 'account_sid';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateMessagingServiceSidErrorComponent = {
  attr: 'messaging_service_sid';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateNumMediaErrorComponent = {
  attr: 'num_media';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type MessagesCreateNumSegmentsErrorComponent = {
  attr: 'num_segments';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type MessagesCreatePriceErrorComponent = {
  attr: 'price';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreatePriceUnitErrorComponent = {
  attr: 'price_unit';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateErrorMessageErrorComponent = {
  attr: 'error_message';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateErrorCodeErrorComponent = {
  attr: 'error_code';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type MessagesCreateSidErrorComponent = {
  attr: 'sid';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateDateSentErrorComponent = {
  attr: 'date_sent';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesCreateDateCreatedErrorComponent = {
  attr: 'date_created';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesCreateDateUpdatedErrorComponent = {
  attr: 'date_updated';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesCreateApiVersionErrorComponent = {
  attr: 'api_version';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesCreateOrganizationErrorComponent = {
  attr: 'organization';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type MessagesCreateError =
  | ({
      attr: 'non_field_errors';
    } & MessagesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & MessagesCreateIdErrorComponent)
  | ({
      attr: 'deleted_at';
    } & MessagesCreateDeletedAtErrorComponent)
  | ({
      attr: 'body';
    } & MessagesCreateBodyErrorComponent)
  | ({
      attr: 'direction';
    } & MessagesCreateDirectionErrorComponent)
  | ({
      attr: 'status';
    } & MessagesCreateStatusErrorComponent)
  | ({
      attr: 'from_number';
    } & MessagesCreateFromNumberErrorComponent)
  | ({
      attr: 'to';
    } & MessagesCreateToErrorComponent)
  | ({
      attr: 'uri';
    } & MessagesCreateUriErrorComponent)
  | ({
      attr: 'account_sid';
    } & MessagesCreateAccountSidErrorComponent)
  | ({
      attr: 'messaging_service_sid';
    } & MessagesCreateMessagingServiceSidErrorComponent)
  | ({
      attr: 'num_media';
    } & MessagesCreateNumMediaErrorComponent)
  | ({
      attr: 'num_segments';
    } & MessagesCreateNumSegmentsErrorComponent)
  | ({
      attr: 'price';
    } & MessagesCreatePriceErrorComponent)
  | ({
      attr: 'price_unit';
    } & MessagesCreatePriceUnitErrorComponent)
  | ({
      attr: 'error_message';
    } & MessagesCreateErrorMessageErrorComponent)
  | ({
      attr: 'error_code';
    } & MessagesCreateErrorCodeErrorComponent)
  | ({
      attr: 'sid';
    } & MessagesCreateSidErrorComponent)
  | ({
      attr: 'date_sent';
    } & MessagesCreateDateSentErrorComponent)
  | ({
      attr: 'date_created';
    } & MessagesCreateDateCreatedErrorComponent)
  | ({
      attr: 'date_updated';
    } & MessagesCreateDateUpdatedErrorComponent)
  | ({
      attr: 'api_version';
    } & MessagesCreateApiVersionErrorComponent)
  | ({
      attr: 'organization';
    } & MessagesCreateOrganizationErrorComponent);
export type MessagesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: MessagesCreateError[];
};
export type MessagesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MessagesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MessagesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripMessage = {
  message: Message;
};
export type TripMessageTrip = {
  passenger_full_name: string;
  passenger_phone_number: string;
  pickup_time: string;
};
export type TripMessageTripRead = {
  id: string;
  organization: string;
  /** Current status of this trip */
  status: OverrideStatusEnum;
  passenger_full_name: string;
  passenger_phone_number: string;
  pickup_time: string;
};
export type MessageTypeEnum =
  | 'reminder-day-before'
  | 'reminder-near-pickup'
  | 'post-trip';
export type TripMessageRead = {
  id: string;
  trip: TripMessageTripRead;
  message: MessageRead;
  message_type: MessageTypeEnum;
};
export type TripMessageWrite = {
  message: Message;
  trip_id: string;
};
export type PaginatedTripMessageList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripMessage[];
};
export type PaginatedTripMessageListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripMessageRead[];
};
export type PaginatedTripMessageListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripMessageWrite[];
};
export type MessagesTripMessagesListTripIdErrorComponent = {
  attr: 'trip_id';
  code: 'invalid' | 'null_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesListError = {
  attr: 'trip_id';
} & MessagesTripMessagesListTripIdErrorComponent;
export type MessagesTripMessagesListValidationError = {
  type: ValidationErrorEnum;
  errors: MessagesTripMessagesListError[];
};
export type MessagesTripMessagesListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MessagesTripMessagesListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MessagesTripMessagesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid' | 'unique';
  detail: string;
};
export type MessagesTripMessagesCreateTripPassengerFullNameErrorComponent = {
  attr: 'trip.passenger_full_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateTripPassengerPhoneNumberErrorComponent = {
  attr: 'trip.passenger_phone_number';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateTripPickupTimeErrorComponent = {
  attr: 'trip.pickup_time';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type MessagesTripMessagesCreateMessageNonFieldErrorsErrorComponent = {
  attr: 'message.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type MessagesTripMessagesCreateMessageIdErrorComponent = {
  attr: 'message.id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type MessagesTripMessagesCreateMessageDeletedAtErrorComponent = {
  attr: 'message.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesTripMessagesCreateMessageBodyErrorComponent = {
  attr: 'message.body';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageDirectionErrorComponent = {
  attr: 'message.direction';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MessagesTripMessagesCreateMessageStatusErrorComponent = {
  attr: 'message.status';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MessagesTripMessagesCreateMessageFromNumberErrorComponent = {
  attr: 'message.from_number';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageToErrorComponent = {
  attr: 'message.to';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageUriErrorComponent = {
  attr: 'message.uri';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageAccountSidErrorComponent = {
  attr: 'message.account_sid';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageMessagingServiceSidErrorComponent =
  {
    attr: 'message.messaging_service_sid';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type MessagesTripMessagesCreateMessageNumMediaErrorComponent = {
  attr: 'message.num_media';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type MessagesTripMessagesCreateMessageNumSegmentsErrorComponent = {
  attr: 'message.num_segments';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type MessagesTripMessagesCreateMessagePriceErrorComponent = {
  attr: 'message.price';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessagePriceUnitErrorComponent = {
  attr: 'message.price_unit';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageErrorMessageErrorComponent = {
  attr: 'message.error_message';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageErrorCodeErrorComponent = {
  attr: 'message.error_code';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type MessagesTripMessagesCreateMessageSidErrorComponent = {
  attr: 'message.sid';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageDateSentErrorComponent = {
  attr: 'message.date_sent';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesTripMessagesCreateMessageDateCreatedErrorComponent = {
  attr: 'message.date_created';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesTripMessagesCreateMessageDateUpdatedErrorComponent = {
  attr: 'message.date_updated';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesTripMessagesCreateMessageApiVersionErrorComponent = {
  attr: 'message.api_version';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesTripMessagesCreateMessageOrganizationErrorComponent = {
  attr: 'message.organization';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type MessagesTripMessagesCreateTripIdErrorComponent = {
  attr: 'trip_id';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type MessagesTripMessagesCreateError =
  | ({
      attr: 'non_field_errors';
    } & MessagesTripMessagesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip.passenger_full_name';
    } & MessagesTripMessagesCreateTripPassengerFullNameErrorComponent)
  | ({
      attr: 'trip.passenger_phone_number';
    } & MessagesTripMessagesCreateTripPassengerPhoneNumberErrorComponent)
  | ({
      attr: 'trip.pickup_time';
    } & MessagesTripMessagesCreateTripPickupTimeErrorComponent)
  | ({
      attr: 'message.non_field_errors';
    } & MessagesTripMessagesCreateMessageNonFieldErrorsErrorComponent)
  | ({
      attr: 'message.id';
    } & MessagesTripMessagesCreateMessageIdErrorComponent)
  | ({
      attr: 'message.deleted_at';
    } & MessagesTripMessagesCreateMessageDeletedAtErrorComponent)
  | ({
      attr: 'message.body';
    } & MessagesTripMessagesCreateMessageBodyErrorComponent)
  | ({
      attr: 'message.direction';
    } & MessagesTripMessagesCreateMessageDirectionErrorComponent)
  | ({
      attr: 'message.status';
    } & MessagesTripMessagesCreateMessageStatusErrorComponent)
  | ({
      attr: 'message.from_number';
    } & MessagesTripMessagesCreateMessageFromNumberErrorComponent)
  | ({
      attr: 'message.to';
    } & MessagesTripMessagesCreateMessageToErrorComponent)
  | ({
      attr: 'message.uri';
    } & MessagesTripMessagesCreateMessageUriErrorComponent)
  | ({
      attr: 'message.account_sid';
    } & MessagesTripMessagesCreateMessageAccountSidErrorComponent)
  | ({
      attr: 'message.messaging_service_sid';
    } & MessagesTripMessagesCreateMessageMessagingServiceSidErrorComponent)
  | ({
      attr: 'message.num_media';
    } & MessagesTripMessagesCreateMessageNumMediaErrorComponent)
  | ({
      attr: 'message.num_segments';
    } & MessagesTripMessagesCreateMessageNumSegmentsErrorComponent)
  | ({
      attr: 'message.price';
    } & MessagesTripMessagesCreateMessagePriceErrorComponent)
  | ({
      attr: 'message.price_unit';
    } & MessagesTripMessagesCreateMessagePriceUnitErrorComponent)
  | ({
      attr: 'message.error_message';
    } & MessagesTripMessagesCreateMessageErrorMessageErrorComponent)
  | ({
      attr: 'message.error_code';
    } & MessagesTripMessagesCreateMessageErrorCodeErrorComponent)
  | ({
      attr: 'message.sid';
    } & MessagesTripMessagesCreateMessageSidErrorComponent)
  | ({
      attr: 'message.date_sent';
    } & MessagesTripMessagesCreateMessageDateSentErrorComponent)
  | ({
      attr: 'message.date_created';
    } & MessagesTripMessagesCreateMessageDateCreatedErrorComponent)
  | ({
      attr: 'message.date_updated';
    } & MessagesTripMessagesCreateMessageDateUpdatedErrorComponent)
  | ({
      attr: 'message.api_version';
    } & MessagesTripMessagesCreateMessageApiVersionErrorComponent)
  | ({
      attr: 'message.organization';
    } & MessagesTripMessagesCreateMessageOrganizationErrorComponent)
  | ({
      attr: 'trip_id';
    } & MessagesTripMessagesCreateTripIdErrorComponent);
export type MessagesTripMessagesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: MessagesTripMessagesCreateError[];
};
export type MessagesTripMessagesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MessagesTripMessagesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MessagesTripMessagesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type MessagesWebHookMessageUpdateCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null' | 'unique';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateBodyErrorComponent = {
  attr: 'body';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateDirectionErrorComponent = {
  attr: 'direction';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateStatusErrorComponent = {
  attr: 'status';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateFromNumberErrorComponent = {
  attr: 'from_number';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateToErrorComponent = {
  attr: 'to';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateUriErrorComponent = {
  attr: 'uri';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateAccountSidErrorComponent = {
  attr: 'account_sid';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateMessagingServiceSidErrorComponent =
  {
    attr: 'messaging_service_sid';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type MessagesWebHookMessageUpdateCreateNumMediaErrorComponent = {
  attr: 'num_media';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateNumSegmentsErrorComponent = {
  attr: 'num_segments';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreatePriceErrorComponent = {
  attr: 'price';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreatePriceUnitErrorComponent = {
  attr: 'price_unit';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateErrorMessageErrorComponent = {
  attr: 'error_message';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateErrorCodeErrorComponent = {
  attr: 'error_code';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateSidErrorComponent = {
  attr: 'sid';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateDateSentErrorComponent = {
  attr: 'date_sent';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateDateCreatedErrorComponent = {
  attr: 'date_created';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateDateUpdatedErrorComponent = {
  attr: 'date_updated';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateApiVersionErrorComponent = {
  attr: 'api_version';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateOrganizationErrorComponent = {
  attr: 'organization';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type MessagesWebHookMessageUpdateCreateError =
  | ({
      attr: 'non_field_errors';
    } & MessagesWebHookMessageUpdateCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & MessagesWebHookMessageUpdateCreateIdErrorComponent)
  | ({
      attr: 'deleted_at';
    } & MessagesWebHookMessageUpdateCreateDeletedAtErrorComponent)
  | ({
      attr: 'body';
    } & MessagesWebHookMessageUpdateCreateBodyErrorComponent)
  | ({
      attr: 'direction';
    } & MessagesWebHookMessageUpdateCreateDirectionErrorComponent)
  | ({
      attr: 'status';
    } & MessagesWebHookMessageUpdateCreateStatusErrorComponent)
  | ({
      attr: 'from_number';
    } & MessagesWebHookMessageUpdateCreateFromNumberErrorComponent)
  | ({
      attr: 'to';
    } & MessagesWebHookMessageUpdateCreateToErrorComponent)
  | ({
      attr: 'uri';
    } & MessagesWebHookMessageUpdateCreateUriErrorComponent)
  | ({
      attr: 'account_sid';
    } & MessagesWebHookMessageUpdateCreateAccountSidErrorComponent)
  | ({
      attr: 'messaging_service_sid';
    } & MessagesWebHookMessageUpdateCreateMessagingServiceSidErrorComponent)
  | ({
      attr: 'num_media';
    } & MessagesWebHookMessageUpdateCreateNumMediaErrorComponent)
  | ({
      attr: 'num_segments';
    } & MessagesWebHookMessageUpdateCreateNumSegmentsErrorComponent)
  | ({
      attr: 'price';
    } & MessagesWebHookMessageUpdateCreatePriceErrorComponent)
  | ({
      attr: 'price_unit';
    } & MessagesWebHookMessageUpdateCreatePriceUnitErrorComponent)
  | ({
      attr: 'error_message';
    } & MessagesWebHookMessageUpdateCreateErrorMessageErrorComponent)
  | ({
      attr: 'error_code';
    } & MessagesWebHookMessageUpdateCreateErrorCodeErrorComponent)
  | ({
      attr: 'sid';
    } & MessagesWebHookMessageUpdateCreateSidErrorComponent)
  | ({
      attr: 'date_sent';
    } & MessagesWebHookMessageUpdateCreateDateSentErrorComponent)
  | ({
      attr: 'date_created';
    } & MessagesWebHookMessageUpdateCreateDateCreatedErrorComponent)
  | ({
      attr: 'date_updated';
    } & MessagesWebHookMessageUpdateCreateDateUpdatedErrorComponent)
  | ({
      attr: 'api_version';
    } & MessagesWebHookMessageUpdateCreateApiVersionErrorComponent)
  | ({
      attr: 'organization';
    } & MessagesWebHookMessageUpdateCreateOrganizationErrorComponent);
export type MessagesWebHookMessageUpdateCreateValidationError = {
  type: ValidationErrorEnum;
  errors: MessagesWebHookMessageUpdateCreateError[];
};
export type MessagesWebHookMessageUpdateCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MessagesWebHookMessageUpdateCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type MapsAppEnum = 'waze' | 'google' | 'apple' | 'here';
export type MobileAppSettings = {
  maps_app?: MapsAppEnum | BlankEnum;
  /** The ID of the endpoint for a device and mobile app on one of the supported push notification services */
  push_notification_endpoint_id?: string;
};
export type MobileAppSettingsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type MobileAppSettingsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type MobileAppSettingsPartialUpdateMapsAppErrorComponent = {
  attr: 'maps_app';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type MobileAppSettingsPartialUpdatePushNotificationEndpointIdErrorComponent =
  {
    attr: 'push_notification_endpoint_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type MobileAppSettingsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & MobileAppSettingsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'maps_app';
    } & MobileAppSettingsPartialUpdateMapsAppErrorComponent)
  | ({
      attr: 'push_notification_endpoint_id';
    } & MobileAppSettingsPartialUpdatePushNotificationEndpointIdErrorComponent);
export type MobileAppSettingsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: MobileAppSettingsPartialUpdateError[];
};
export type MobileAppSettingsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & MobileAppSettingsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedMobileAppSettings = {
  maps_app?: MapsAppEnum | BlankEnum;
  /** The ID of the endpoint for a device and mobile app on one of the supported push notification services */
  push_notification_endpoint_id?: string;
};
export type OrganizationBillingStatus = {};
export type OrganizationBillingStatusRead = {
  id: string;
  name: string;
  is_delinquent: boolean;
};
export type OrganizationBillingStatusRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type OrganizationHeadquarters = {
  place_id: string;
};
export type OrganizationHeadquartersRead = {
  address: string;
  place_id: string;
  latitude: number;
  longitude: number;
  time_zone_name: string;
};
export type OrganizationSettings = {
  headquarters_location?: OrganizationHeadquarters;
  logo?: string | null;
  /** Billing address for the business, appears on invoices */
  billing_address?: string;
  dispatch_phone_number?: string;
  dispatch_text_number?: string;
  /** Some payer exports require this */
  tax_id?: string;
  should_avoid_toll_roads?: boolean;
  /** The default space type when creating trips */
  default_trip_space_type?: SpaceTypeEnum | BlankEnum;
  /** The default service type when creating trips */
  default_trip_service_type?: ServiceTypeEnum | BlankEnum;
  /** Expand location details automatically on load */
  expand_trip_location_details?: boolean;
  ambulatory_load_seconds?: number;
  ambulatory_unload_seconds?: number;
  wheelchair_load_seconds?: number;
  wheelchair_unload_seconds?: number;
  wheelchair_xl_load_seconds?: number;
  wheelchair_xl_unload_seconds?: number;
  broda_chair_load_seconds?: number;
  broda_chair_unload_seconds?: number;
  geri_chair_load_seconds?: number;
  geri_chair_unload_seconds?: number;
  stretcher_load_seconds?: number;
  stretcher_unload_seconds?: number;
  /** How early a driver can arrive before a pickup time */
  pickup_default_early_seconds?: number;
  /** How late a driver can arrive after a pickup time */
  pickup_default_late_seconds?: number;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  pickup_appointment_early_seconds?: number;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  pickup_appointment_late_seconds?: number;
  /** How early a driver can arrive before a pickup time for a will call trip */
  pickup_will_call_early_seconds?: number;
  /** How late a driver can arrive after a pickup time for a will call trip */
  pickup_will_call_late_seconds?: number;
  /** How early before an appointment the passenger should arrive */
  appointment_early_arrival_window_seconds?: number;
  /** Start time of standard transport time (in local time) */
  standard_transport_time_start?: string | null;
  /** End time of standard transport time (in local time) */
  standard_transport_time_end?: string | null;
  requires_inspection_after_assignment_start?: boolean;
  requires_inspection_before_assignment_end?: boolean;
  requires_signature_pickup_passenger?: boolean;
  requires_signature_dropoff_passenger?: boolean;
  requires_signature_pickup_driver?: boolean;
  requires_signature_dropoff_driver?: boolean;
  /** Whether an associate is allowed to cancel their own trips */
  can_associate_cancel_trips?: boolean;
  /** Whether an associate is allowed to activate their own will call trips */
  can_associate_activate_will_call_trips?: boolean;
  /** The Tilled onboarding user id */
  payment_account_onboarding_user_id?: string;
  /** The lead time required for facilities to request a trip before the pickup */
  facility_trip_request_lead_time_seconds?: number;
  /** Window up to the pick up time for a customer to be charged a fee for canceling */
  cancellation_fee_window_hours?: number;
  /** Whether the organization is using pricing v1 */
  is_pricing_v1_enabled?: boolean;
};
export type OrganizationSettingsRead = {
  headquarters_location?: OrganizationHeadquartersRead;
  logo?: string | null;
  /** Billing address for the business, appears on invoices */
  billing_address?: string;
  dispatch_phone_number?: string;
  dispatch_text_number?: string;
  /** Some payer exports require this */
  tax_id?: string;
  should_avoid_toll_roads?: boolean;
  /** The default space type when creating trips */
  default_trip_space_type?: SpaceTypeEnum | BlankEnum;
  /** The default service type when creating trips */
  default_trip_service_type?: ServiceTypeEnum | BlankEnum;
  /** Expand location details automatically on load */
  expand_trip_location_details?: boolean;
  ambulatory_load_seconds?: number;
  ambulatory_unload_seconds?: number;
  wheelchair_load_seconds?: number;
  wheelchair_unload_seconds?: number;
  wheelchair_xl_load_seconds?: number;
  wheelchair_xl_unload_seconds?: number;
  broda_chair_load_seconds?: number;
  broda_chair_unload_seconds?: number;
  geri_chair_load_seconds?: number;
  geri_chair_unload_seconds?: number;
  stretcher_load_seconds?: number;
  stretcher_unload_seconds?: number;
  /** How early a driver can arrive before a pickup time */
  pickup_default_early_seconds?: number;
  /** How late a driver can arrive after a pickup time */
  pickup_default_late_seconds?: number;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  pickup_appointment_early_seconds?: number;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  pickup_appointment_late_seconds?: number;
  /** How early a driver can arrive before a pickup time for a will call trip */
  pickup_will_call_early_seconds?: number;
  /** How late a driver can arrive after a pickup time for a will call trip */
  pickup_will_call_late_seconds?: number;
  /** How early before an appointment the passenger should arrive */
  appointment_early_arrival_window_seconds?: number;
  /** Start time of standard transport time (in local time) */
  standard_transport_time_start?: string | null;
  /** End time of standard transport time (in local time) */
  standard_transport_time_end?: string | null;
  requires_inspection_after_assignment_start?: boolean;
  requires_inspection_before_assignment_end?: boolean;
  requires_signature_pickup_passenger?: boolean;
  requires_signature_dropoff_passenger?: boolean;
  requires_signature_pickup_driver?: boolean;
  requires_signature_dropoff_driver?: boolean;
  /** Whether an associate is allowed to cancel their own trips */
  can_associate_cancel_trips?: boolean;
  /** Whether an associate is allowed to activate their own will call trips */
  can_associate_activate_will_call_trips?: boolean;
  payment_account_id: string | null;
  payment_account_status: string | null;
  /** The Tilled onboarding user id */
  payment_account_onboarding_user_id?: string;
  /** The lead time required for facilities to request a trip before the pickup */
  facility_trip_request_lead_time_seconds?: number;
  /** Window up to the pick up time for a customer to be charged a fee for canceling */
  cancellation_fee_window_hours?: number;
  /** Whether the organization is using pricing v1 */
  is_pricing_v1_enabled?: boolean;
};
export type OrganizationSettingsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type OrganizationSettingsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type OrganizationSettingsPartialUpdateHeadquartersLocationNonFieldErrorsErrorComponent =
  {
    attr: 'headquarters_location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateHeadquartersLocationPlaceIdErrorComponent =
  {
    attr: 'headquarters_location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateLogoErrorComponent = {
  attr: 'logo';
  code: 'empty' | 'invalid' | 'invalid_image' | 'max_length' | 'no_name';
  detail: string;
};
export type OrganizationSettingsPartialUpdateBillingAddressErrorComponent = {
  attr: 'billing_address';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type OrganizationSettingsPartialUpdateDispatchPhoneNumberErrorComponent =
  {
    attr: 'dispatch_phone_number';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateDispatchTextNumberErrorComponent =
  {
    attr: 'dispatch_text_number';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateTaxIdErrorComponent = {
  attr: 'tax_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type OrganizationSettingsPartialUpdateShouldAvoidTollRoadsErrorComponent =
  {
    attr: 'should_avoid_toll_roads';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateDefaultTripSpaceTypeErrorComponent =
  {
    attr: 'default_trip_space_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateDefaultTripServiceTypeErrorComponent =
  {
    attr: 'default_trip_service_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateExpandTripLocationDetailsErrorComponent =
  {
    attr: 'expand_trip_location_details';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateAmbulatoryLoadSecondsErrorComponent =
  {
    attr: 'ambulatory_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateAmbulatoryUnloadSecondsErrorComponent =
  {
    attr: 'ambulatory_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateWheelchairLoadSecondsErrorComponent =
  {
    attr: 'wheelchair_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateWheelchairUnloadSecondsErrorComponent =
  {
    attr: 'wheelchair_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateWheelchairXlLoadSecondsErrorComponent =
  {
    attr: 'wheelchair_xl_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateWheelchairXlUnloadSecondsErrorComponent =
  {
    attr: 'wheelchair_xl_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateBrodaChairLoadSecondsErrorComponent =
  {
    attr: 'broda_chair_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateBrodaChairUnloadSecondsErrorComponent =
  {
    attr: 'broda_chair_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateGeriChairLoadSecondsErrorComponent =
  {
    attr: 'geri_chair_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateGeriChairUnloadSecondsErrorComponent =
  {
    attr: 'geri_chair_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateStretcherLoadSecondsErrorComponent =
  {
    attr: 'stretcher_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateStretcherUnloadSecondsErrorComponent =
  {
    attr: 'stretcher_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePickupDefaultEarlySecondsErrorComponent =
  {
    attr: 'pickup_default_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePickupDefaultLateSecondsErrorComponent =
  {
    attr: 'pickup_default_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePickupAppointmentEarlySecondsErrorComponent =
  {
    attr: 'pickup_appointment_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePickupAppointmentLateSecondsErrorComponent =
  {
    attr: 'pickup_appointment_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePickupWillCallEarlySecondsErrorComponent =
  {
    attr: 'pickup_will_call_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePickupWillCallLateSecondsErrorComponent =
  {
    attr: 'pickup_will_call_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateAppointmentEarlyArrivalWindowSecondsErrorComponent =
  {
    attr: 'appointment_early_arrival_window_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateStandardTransportTimeStartErrorComponent =
  {
    attr: 'standard_transport_time_start';
    code: 'invalid';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateStandardTransportTimeEndErrorComponent =
  {
    attr: 'standard_transport_time_end';
    code: 'invalid';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateRequiresInspectionAfterAssignmentStartErrorComponent =
  {
    attr: 'requires_inspection_after_assignment_start';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateRequiresInspectionBeforeAssignmentEndErrorComponent =
  {
    attr: 'requires_inspection_before_assignment_end';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateRequiresSignaturePickupPassengerErrorComponent =
  {
    attr: 'requires_signature_pickup_passenger';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateRequiresSignatureDropoffPassengerErrorComponent =
  {
    attr: 'requires_signature_dropoff_passenger';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateRequiresSignaturePickupDriverErrorComponent =
  {
    attr: 'requires_signature_pickup_driver';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateRequiresSignatureDropoffDriverErrorComponent =
  {
    attr: 'requires_signature_dropoff_driver';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateCanAssociateCancelTripsErrorComponent =
  {
    attr: 'can_associate_cancel_trips';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateCanAssociateActivateWillCallTripsErrorComponent =
  {
    attr: 'can_associate_activate_will_call_trips';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdatePaymentAccountOnboardingUserIdErrorComponent =
  {
    attr: 'payment_account_onboarding_user_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateFacilityTripRequestLeadTimeSecondsErrorComponent =
  {
    attr: 'facility_trip_request_lead_time_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateCancellationFeeWindowHoursErrorComponent =
  {
    attr: 'cancellation_fee_window_hours';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateIsPricingV1EnabledErrorComponent =
  {
    attr: 'is_pricing_v1_enabled';
    code: 'invalid' | 'null';
    detail: string;
  };
export type OrganizationSettingsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & OrganizationSettingsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'headquarters_location.non_field_errors';
    } & OrganizationSettingsPartialUpdateHeadquartersLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'headquarters_location.place_id';
    } & OrganizationSettingsPartialUpdateHeadquartersLocationPlaceIdErrorComponent)
  | ({
      attr: 'logo';
    } & OrganizationSettingsPartialUpdateLogoErrorComponent)
  | ({
      attr: 'billing_address';
    } & OrganizationSettingsPartialUpdateBillingAddressErrorComponent)
  | ({
      attr: 'dispatch_phone_number';
    } & OrganizationSettingsPartialUpdateDispatchPhoneNumberErrorComponent)
  | ({
      attr: 'dispatch_text_number';
    } & OrganizationSettingsPartialUpdateDispatchTextNumberErrorComponent)
  | ({
      attr: 'tax_id';
    } & OrganizationSettingsPartialUpdateTaxIdErrorComponent)
  | ({
      attr: 'should_avoid_toll_roads';
    } & OrganizationSettingsPartialUpdateShouldAvoidTollRoadsErrorComponent)
  | ({
      attr: 'default_trip_space_type';
    } & OrganizationSettingsPartialUpdateDefaultTripSpaceTypeErrorComponent)
  | ({
      attr: 'default_trip_service_type';
    } & OrganizationSettingsPartialUpdateDefaultTripServiceTypeErrorComponent)
  | ({
      attr: 'expand_trip_location_details';
    } & OrganizationSettingsPartialUpdateExpandTripLocationDetailsErrorComponent)
  | ({
      attr: 'ambulatory_load_seconds';
    } & OrganizationSettingsPartialUpdateAmbulatoryLoadSecondsErrorComponent)
  | ({
      attr: 'ambulatory_unload_seconds';
    } & OrganizationSettingsPartialUpdateAmbulatoryUnloadSecondsErrorComponent)
  | ({
      attr: 'wheelchair_load_seconds';
    } & OrganizationSettingsPartialUpdateWheelchairLoadSecondsErrorComponent)
  | ({
      attr: 'wheelchair_unload_seconds';
    } & OrganizationSettingsPartialUpdateWheelchairUnloadSecondsErrorComponent)
  | ({
      attr: 'wheelchair_xl_load_seconds';
    } & OrganizationSettingsPartialUpdateWheelchairXlLoadSecondsErrorComponent)
  | ({
      attr: 'wheelchair_xl_unload_seconds';
    } & OrganizationSettingsPartialUpdateWheelchairXlUnloadSecondsErrorComponent)
  | ({
      attr: 'broda_chair_load_seconds';
    } & OrganizationSettingsPartialUpdateBrodaChairLoadSecondsErrorComponent)
  | ({
      attr: 'broda_chair_unload_seconds';
    } & OrganizationSettingsPartialUpdateBrodaChairUnloadSecondsErrorComponent)
  | ({
      attr: 'geri_chair_load_seconds';
    } & OrganizationSettingsPartialUpdateGeriChairLoadSecondsErrorComponent)
  | ({
      attr: 'geri_chair_unload_seconds';
    } & OrganizationSettingsPartialUpdateGeriChairUnloadSecondsErrorComponent)
  | ({
      attr: 'stretcher_load_seconds';
    } & OrganizationSettingsPartialUpdateStretcherLoadSecondsErrorComponent)
  | ({
      attr: 'stretcher_unload_seconds';
    } & OrganizationSettingsPartialUpdateStretcherUnloadSecondsErrorComponent)
  | ({
      attr: 'pickup_default_early_seconds';
    } & OrganizationSettingsPartialUpdatePickupDefaultEarlySecondsErrorComponent)
  | ({
      attr: 'pickup_default_late_seconds';
    } & OrganizationSettingsPartialUpdatePickupDefaultLateSecondsErrorComponent)
  | ({
      attr: 'pickup_appointment_early_seconds';
    } & OrganizationSettingsPartialUpdatePickupAppointmentEarlySecondsErrorComponent)
  | ({
      attr: 'pickup_appointment_late_seconds';
    } & OrganizationSettingsPartialUpdatePickupAppointmentLateSecondsErrorComponent)
  | ({
      attr: 'pickup_will_call_early_seconds';
    } & OrganizationSettingsPartialUpdatePickupWillCallEarlySecondsErrorComponent)
  | ({
      attr: 'pickup_will_call_late_seconds';
    } & OrganizationSettingsPartialUpdatePickupWillCallLateSecondsErrorComponent)
  | ({
      attr: 'appointment_early_arrival_window_seconds';
    } & OrganizationSettingsPartialUpdateAppointmentEarlyArrivalWindowSecondsErrorComponent)
  | ({
      attr: 'standard_transport_time_start';
    } & OrganizationSettingsPartialUpdateStandardTransportTimeStartErrorComponent)
  | ({
      attr: 'standard_transport_time_end';
    } & OrganizationSettingsPartialUpdateStandardTransportTimeEndErrorComponent)
  | ({
      attr: 'requires_inspection_after_assignment_start';
    } & OrganizationSettingsPartialUpdateRequiresInspectionAfterAssignmentStartErrorComponent)
  | ({
      attr: 'requires_inspection_before_assignment_end';
    } & OrganizationSettingsPartialUpdateRequiresInspectionBeforeAssignmentEndErrorComponent)
  | ({
      attr: 'requires_signature_pickup_passenger';
    } & OrganizationSettingsPartialUpdateRequiresSignaturePickupPassengerErrorComponent)
  | ({
      attr: 'requires_signature_dropoff_passenger';
    } & OrganizationSettingsPartialUpdateRequiresSignatureDropoffPassengerErrorComponent)
  | ({
      attr: 'requires_signature_pickup_driver';
    } & OrganizationSettingsPartialUpdateRequiresSignaturePickupDriverErrorComponent)
  | ({
      attr: 'requires_signature_dropoff_driver';
    } & OrganizationSettingsPartialUpdateRequiresSignatureDropoffDriverErrorComponent)
  | ({
      attr: 'can_associate_cancel_trips';
    } & OrganizationSettingsPartialUpdateCanAssociateCancelTripsErrorComponent)
  | ({
      attr: 'can_associate_activate_will_call_trips';
    } & OrganizationSettingsPartialUpdateCanAssociateActivateWillCallTripsErrorComponent)
  | ({
      attr: 'payment_account_onboarding_user_id';
    } & OrganizationSettingsPartialUpdatePaymentAccountOnboardingUserIdErrorComponent)
  | ({
      attr: 'facility_trip_request_lead_time_seconds';
    } & OrganizationSettingsPartialUpdateFacilityTripRequestLeadTimeSecondsErrorComponent)
  | ({
      attr: 'cancellation_fee_window_hours';
    } & OrganizationSettingsPartialUpdateCancellationFeeWindowHoursErrorComponent)
  | ({
      attr: 'is_pricing_v1_enabled';
    } & OrganizationSettingsPartialUpdateIsPricingV1EnabledErrorComponent);
export type OrganizationSettingsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: OrganizationSettingsPartialUpdateError[];
};
export type OrganizationSettingsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & OrganizationSettingsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedOrganizationSettings = {
  headquarters_location?: OrganizationHeadquarters;
  logo?: string | null;
  /** Billing address for the business, appears on invoices */
  billing_address?: string;
  dispatch_phone_number?: string;
  dispatch_text_number?: string;
  /** Some payer exports require this */
  tax_id?: string;
  should_avoid_toll_roads?: boolean;
  /** The default space type when creating trips */
  default_trip_space_type?: SpaceTypeEnum | BlankEnum;
  /** The default service type when creating trips */
  default_trip_service_type?: ServiceTypeEnum | BlankEnum;
  /** Expand location details automatically on load */
  expand_trip_location_details?: boolean;
  ambulatory_load_seconds?: number;
  ambulatory_unload_seconds?: number;
  wheelchair_load_seconds?: number;
  wheelchair_unload_seconds?: number;
  wheelchair_xl_load_seconds?: number;
  wheelchair_xl_unload_seconds?: number;
  broda_chair_load_seconds?: number;
  broda_chair_unload_seconds?: number;
  geri_chair_load_seconds?: number;
  geri_chair_unload_seconds?: number;
  stretcher_load_seconds?: number;
  stretcher_unload_seconds?: number;
  /** How early a driver can arrive before a pickup time */
  pickup_default_early_seconds?: number;
  /** How late a driver can arrive after a pickup time */
  pickup_default_late_seconds?: number;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  pickup_appointment_early_seconds?: number;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  pickup_appointment_late_seconds?: number;
  /** How early a driver can arrive before a pickup time for a will call trip */
  pickup_will_call_early_seconds?: number;
  /** How late a driver can arrive after a pickup time for a will call trip */
  pickup_will_call_late_seconds?: number;
  /** How early before an appointment the passenger should arrive */
  appointment_early_arrival_window_seconds?: number;
  /** Start time of standard transport time (in local time) */
  standard_transport_time_start?: string | null;
  /** End time of standard transport time (in local time) */
  standard_transport_time_end?: string | null;
  requires_inspection_after_assignment_start?: boolean;
  requires_inspection_before_assignment_end?: boolean;
  requires_signature_pickup_passenger?: boolean;
  requires_signature_dropoff_passenger?: boolean;
  requires_signature_pickup_driver?: boolean;
  requires_signature_dropoff_driver?: boolean;
  /** Whether an associate is allowed to cancel their own trips */
  can_associate_cancel_trips?: boolean;
  /** Whether an associate is allowed to activate their own will call trips */
  can_associate_activate_will_call_trips?: boolean;
  /** The Tilled onboarding user id */
  payment_account_onboarding_user_id?: string;
  /** The lead time required for facilities to request a trip before the pickup */
  facility_trip_request_lead_time_seconds?: number;
  /** Window up to the pick up time for a customer to be charged a fee for canceling */
  cancellation_fee_window_hours?: number;
  /** Whether the organization is using pricing v1 */
  is_pricing_v1_enabled?: boolean;
};
export type PatchedOrganizationSettingsRead = {
  headquarters_location?: OrganizationHeadquartersRead;
  logo?: string | null;
  /** Billing address for the business, appears on invoices */
  billing_address?: string;
  dispatch_phone_number?: string;
  dispatch_text_number?: string;
  /** Some payer exports require this */
  tax_id?: string;
  should_avoid_toll_roads?: boolean;
  /** The default space type when creating trips */
  default_trip_space_type?: SpaceTypeEnum | BlankEnum;
  /** The default service type when creating trips */
  default_trip_service_type?: ServiceTypeEnum | BlankEnum;
  /** Expand location details automatically on load */
  expand_trip_location_details?: boolean;
  ambulatory_load_seconds?: number;
  ambulatory_unload_seconds?: number;
  wheelchair_load_seconds?: number;
  wheelchair_unload_seconds?: number;
  wheelchair_xl_load_seconds?: number;
  wheelchair_xl_unload_seconds?: number;
  broda_chair_load_seconds?: number;
  broda_chair_unload_seconds?: number;
  geri_chair_load_seconds?: number;
  geri_chair_unload_seconds?: number;
  stretcher_load_seconds?: number;
  stretcher_unload_seconds?: number;
  /** How early a driver can arrive before a pickup time */
  pickup_default_early_seconds?: number;
  /** How late a driver can arrive after a pickup time */
  pickup_default_late_seconds?: number;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  pickup_appointment_early_seconds?: number;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  pickup_appointment_late_seconds?: number;
  /** How early a driver can arrive before a pickup time for a will call trip */
  pickup_will_call_early_seconds?: number;
  /** How late a driver can arrive after a pickup time for a will call trip */
  pickup_will_call_late_seconds?: number;
  /** How early before an appointment the passenger should arrive */
  appointment_early_arrival_window_seconds?: number;
  /** Start time of standard transport time (in local time) */
  standard_transport_time_start?: string | null;
  /** End time of standard transport time (in local time) */
  standard_transport_time_end?: string | null;
  requires_inspection_after_assignment_start?: boolean;
  requires_inspection_before_assignment_end?: boolean;
  requires_signature_pickup_passenger?: boolean;
  requires_signature_dropoff_passenger?: boolean;
  requires_signature_pickup_driver?: boolean;
  requires_signature_dropoff_driver?: boolean;
  /** Whether an associate is allowed to cancel their own trips */
  can_associate_cancel_trips?: boolean;
  /** Whether an associate is allowed to activate their own will call trips */
  can_associate_activate_will_call_trips?: boolean;
  payment_account_id?: string | null;
  payment_account_status?: string | null;
  /** The Tilled onboarding user id */
  payment_account_onboarding_user_id?: string;
  /** The lead time required for facilities to request a trip before the pickup */
  facility_trip_request_lead_time_seconds?: number;
  /** Window up to the pick up time for a customer to be charged a fee for canceling */
  cancellation_fee_window_hours?: number;
  /** Whether the organization is using pricing v1 */
  is_pricing_v1_enabled?: boolean;
};
export type OrganizationMerchantAccountOnboardingStatusEnum =
  | 'created'
  | 'started'
  | 'submitted'
  | 'disabled'
  | 'in_review'
  | 'rejected'
  | 'withdrawn'
  | 'active';
export type OrganizationMerchantAccountOnboarding = {
  account_id: string;
  onboarding_application_url?: string | null;
  status: OrganizationMerchantAccountOnboardingStatusEnum;
  merchant_email: string;
};
export type OrganizationSettingsConnectMerchantAccountCreateNonFieldErrorsErrorComponent =
  {
    attr: 'non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type OrganizationSettingsConnectMerchantAccountCreateError = {
  attr: 'non_field_errors';
} & OrganizationSettingsConnectMerchantAccountCreateNonFieldErrorsErrorComponent;
export type OrganizationSettingsConnectMerchantAccountCreateValidationError = {
  type: ValidationErrorEnum;
  errors: OrganizationSettingsConnectMerchantAccountCreateError[];
};
export type OrganizationSettingsConnectMerchantAccountCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & OrganizationSettingsConnectMerchantAccountCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type Passenger = {
  first_name: string;
  last_name: string;
  preferred_name?: string;
  pronoun?: PronounEnum | BlankEnum;
  /** Patient gender according to their primary insurance */
  gender?: GenderEnum | BlankEnum;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  /** Passenger phone number (unique in organization) */
  phone_number?: string | null;
  /** email address (unique in organization) */
  email?: string | null;
  notes?: string;
  /** Notes, but not exposed to drivers */
  dispatcher_notes?: string;
  billing_address?: PassengerAddress | null;
  payer_ids?: string[] | null;
  /** allow sms messages to be sent to passenger (opt-in) */
  allow_sms_message?: boolean;
};
export type PassengerRead = {
  id: string;
  organization_id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  preferred_name?: string;
  pronoun?: PronounEnum | BlankEnum;
  /** Patient gender according to their primary insurance */
  gender?: GenderEnum | BlankEnum;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  /** Passenger phone number (unique in organization) */
  phone_number?: string | null;
  /** email address (unique in organization) */
  email?: string | null;
  notes?: string;
  /** Notes, but not exposed to drivers */
  dispatcher_notes?: string;
  created_at: string;
  updated_at: string;
  billing_address?: PassengerAddressRead | null;
  payer_ids?: string[] | null;
  has_tilled_account: boolean;
  /** allow sms messages to be sent to passenger (opt-in) */
  allow_sms_message?: boolean;
};
export type PaginatedPassengerList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Passenger[];
};
export type PaginatedPassengerListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PassengerRead[];
};
export type PassengersListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PassengersCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PassengersCreateFirstNameErrorComponent = {
  attr: 'first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateLastNameErrorComponent = {
  attr: 'last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreatePreferredNameErrorComponent = {
  attr: 'preferred_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreatePronounErrorComponent = {
  attr: 'pronoun';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PassengersCreateGenderErrorComponent = {
  attr: 'gender';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PassengersCreateDobErrorComponent = {
  attr: 'dob';
  code: 'datetime' | 'invalid';
  detail: string;
};
export type PassengersCreateWeightErrorComponent = {
  attr: 'weight';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PassengersCreateNeedsBariatricTransportErrorComponent = {
  attr: 'needs_bariatric_transport';
  code: 'invalid' | 'null';
  detail: string;
};
export type PassengersCreatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateBillingAddressNonFieldErrorsErrorComponent = {
  attr: 'billing_address.non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PassengersCreateBillingAddressAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'billing_address.address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PassengersCreateBillingAddressAddressLocationPlaceIdErrorComponent =
  {
    attr: 'billing_address.address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PassengersCreateBillingAddressAddressLabelErrorComponent = {
  attr: 'billing_address.address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateBillingAddressAddressLabel2ErrorComponent = {
  attr: 'billing_address.address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateBillingAddressAddressAddressNotesErrorComponent = {
  attr: 'billing_address.address.address_notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersCreateBillingAddressAddressAddressTypeErrorComponent = {
  attr: 'billing_address.address.address_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PassengersCreatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type PassengersCreatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PassengersCreateAllowSmsMessageErrorComponent = {
  attr: 'allow_sms_message';
  code: 'invalid' | 'null';
  detail: string;
};
export type PassengersCreateError =
  | ({
      attr: 'non_field_errors';
    } & PassengersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'first_name';
    } & PassengersCreateFirstNameErrorComponent)
  | ({
      attr: 'last_name';
    } & PassengersCreateLastNameErrorComponent)
  | ({
      attr: 'preferred_name';
    } & PassengersCreatePreferredNameErrorComponent)
  | ({
      attr: 'pronoun';
    } & PassengersCreatePronounErrorComponent)
  | ({
      attr: 'gender';
    } & PassengersCreateGenderErrorComponent)
  | ({
      attr: 'dob';
    } & PassengersCreateDobErrorComponent)
  | ({
      attr: 'weight';
    } & PassengersCreateWeightErrorComponent)
  | ({
      attr: 'needs_bariatric_transport';
    } & PassengersCreateNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'phone_number';
    } & PassengersCreatePhoneNumberErrorComponent)
  | ({
      attr: 'email';
    } & PassengersCreateEmailErrorComponent)
  | ({
      attr: 'notes';
    } & PassengersCreateNotesErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & PassengersCreateDispatcherNotesErrorComponent)
  | ({
      attr: 'billing_address.non_field_errors';
    } & PassengersCreateBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.address.location.non_field_errors';
    } & PassengersCreateBillingAddressAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.address.location.place_id';
    } & PassengersCreateBillingAddressAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'billing_address.address.label';
    } & PassengersCreateBillingAddressAddressLabelErrorComponent)
  | ({
      attr: 'billing_address.address.label2';
    } & PassengersCreateBillingAddressAddressLabel2ErrorComponent)
  | ({
      attr: 'billing_address.address.address_notes';
    } & PassengersCreateBillingAddressAddressAddressNotesErrorComponent)
  | ({
      attr: 'billing_address.address.address_type';
    } & PassengersCreateBillingAddressAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer_ids';
    } & PassengersCreatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & PassengersCreatePayerIdsIndexErrorComponent)
  | ({
      attr: 'allow_sms_message';
    } & PassengersCreateAllowSmsMessageErrorComponent);
export type PassengersCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PassengersCreateError[];
};
export type PassengersCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PassengersCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PassengersRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PassengersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PassengersPartialUpdateFirstNameErrorComponent = {
  attr: 'first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateLastNameErrorComponent = {
  attr: 'last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdatePreferredNameErrorComponent = {
  attr: 'preferred_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdatePronounErrorComponent = {
  attr: 'pronoun';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PassengersPartialUpdateGenderErrorComponent = {
  attr: 'gender';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PassengersPartialUpdateDobErrorComponent = {
  attr: 'dob';
  code: 'datetime' | 'invalid';
  detail: string;
};
export type PassengersPartialUpdateWeightErrorComponent = {
  attr: 'weight';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PassengersPartialUpdateNeedsBariatricTransportErrorComponent = {
  attr: 'needs_bariatric_transport';
  code: 'invalid' | 'null';
  detail: string;
};
export type PassengersPartialUpdatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type PassengersPartialUpdateBillingAddressAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'billing_address.address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PassengersPartialUpdateBillingAddressAddressLocationPlaceIdErrorComponent =
  {
    attr: 'billing_address.address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PassengersPartialUpdateBillingAddressAddressLabelErrorComponent = {
  attr: 'billing_address.address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateBillingAddressAddressLabel2ErrorComponent = {
  attr: 'billing_address.address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PassengersPartialUpdateBillingAddressAddressAddressNotesErrorComponent =
  {
    attr: 'billing_address.address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PassengersPartialUpdateBillingAddressAddressAddressTypeErrorComponent =
  {
    attr: 'billing_address.address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type PassengersPartialUpdatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'not_a_list';
  detail: string;
};
export type PassengersPartialUpdatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PassengersPartialUpdateAllowSmsMessageErrorComponent = {
  attr: 'allow_sms_message';
  code: 'invalid' | 'null';
  detail: string;
};
export type PassengersPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PassengersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'first_name';
    } & PassengersPartialUpdateFirstNameErrorComponent)
  | ({
      attr: 'last_name';
    } & PassengersPartialUpdateLastNameErrorComponent)
  | ({
      attr: 'preferred_name';
    } & PassengersPartialUpdatePreferredNameErrorComponent)
  | ({
      attr: 'pronoun';
    } & PassengersPartialUpdatePronounErrorComponent)
  | ({
      attr: 'gender';
    } & PassengersPartialUpdateGenderErrorComponent)
  | ({
      attr: 'dob';
    } & PassengersPartialUpdateDobErrorComponent)
  | ({
      attr: 'weight';
    } & PassengersPartialUpdateWeightErrorComponent)
  | ({
      attr: 'needs_bariatric_transport';
    } & PassengersPartialUpdateNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'phone_number';
    } & PassengersPartialUpdatePhoneNumberErrorComponent)
  | ({
      attr: 'email';
    } & PassengersPartialUpdateEmailErrorComponent)
  | ({
      attr: 'notes';
    } & PassengersPartialUpdateNotesErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & PassengersPartialUpdateDispatcherNotesErrorComponent)
  | ({
      attr: 'billing_address.non_field_errors';
    } & PassengersPartialUpdateBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.address.location.non_field_errors';
    } & PassengersPartialUpdateBillingAddressAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.address.location.place_id';
    } & PassengersPartialUpdateBillingAddressAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'billing_address.address.label';
    } & PassengersPartialUpdateBillingAddressAddressLabelErrorComponent)
  | ({
      attr: 'billing_address.address.label2';
    } & PassengersPartialUpdateBillingAddressAddressLabel2ErrorComponent)
  | ({
      attr: 'billing_address.address.address_notes';
    } & PassengersPartialUpdateBillingAddressAddressAddressNotesErrorComponent)
  | ({
      attr: 'billing_address.address.address_type';
    } & PassengersPartialUpdateBillingAddressAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer_ids';
    } & PassengersPartialUpdatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & PassengersPartialUpdatePayerIdsIndexErrorComponent)
  | ({
      attr: 'allow_sms_message';
    } & PassengersPartialUpdateAllowSmsMessageErrorComponent);
export type PassengersPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PassengersPartialUpdateError[];
};
export type PassengersPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PassengersPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPassenger = {
  first_name?: string;
  last_name?: string;
  preferred_name?: string;
  pronoun?: PronounEnum | BlankEnum;
  /** Patient gender according to their primary insurance */
  gender?: GenderEnum | BlankEnum;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  /** Passenger phone number (unique in organization) */
  phone_number?: string | null;
  /** email address (unique in organization) */
  email?: string | null;
  notes?: string;
  /** Notes, but not exposed to drivers */
  dispatcher_notes?: string;
  billing_address?: PassengerAddress | null;
  payer_ids?: string[] | null;
  /** allow sms messages to be sent to passenger (opt-in) */
  allow_sms_message?: boolean;
};
export type PatchedPassengerRead = {
  id?: string;
  organization_id?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  preferred_name?: string;
  pronoun?: PronounEnum | BlankEnum;
  /** Patient gender according to their primary insurance */
  gender?: GenderEnum | BlankEnum;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  /** Passenger phone number (unique in organization) */
  phone_number?: string | null;
  /** email address (unique in organization) */
  email?: string | null;
  notes?: string;
  /** Notes, but not exposed to drivers */
  dispatcher_notes?: string;
  created_at?: string;
  updated_at?: string;
  billing_address?: PassengerAddressRead | null;
  payer_ids?: string[] | null;
  has_tilled_account?: boolean;
  /** allow sms messages to be sent to passenger (opt-in) */
  allow_sms_message?: boolean;
};
export type PassengersDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PassengersRecentAddressesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayerPassenger = {
  external_id: string;
};
export type PayerPassengerRead = {
  id: string;
  payer_organization_id: string;
  passenger_id: string;
  external_id: string;
  /** Last time this payer was used in a trip */
  last_used_at: string | null;
  passenger: string;
  payer_organization: PayerOrganizationRead;
  organization_id: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedPayerPassengerList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerPassenger[];
};
export type PaginatedPayerPassengerListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerPassengerRead[];
};
export type PassengersRecentPayersListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PassengerDuplicate = {};
export type PassengerAndAddress = {
  first_name: string;
  last_name: string;
  preferred_name?: string;
  pronoun?: PronounEnum | BlankEnum;
  /** Patient gender according to their primary insurance */
  gender?: GenderEnum | BlankEnum;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  /** Passenger phone number (unique in organization) */
  phone_number?: string | null;
  /** email address (unique in organization) */
  email?: string | null;
  notes?: string;
  /** Notes, but not exposed to drivers */
  dispatcher_notes?: string;
  billing_address?: PassengerAddress | null;
  payer_ids?: string[] | null;
  /** allow sms messages to be sent to passenger (opt-in) */
  allow_sms_message?: boolean;
  passenger_addresses: PassengerAddress[];
};
export type PassengerAndAddressRead = {
  id: string;
  organization_id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  preferred_name?: string;
  pronoun?: PronounEnum | BlankEnum;
  /** Patient gender according to their primary insurance */
  gender?: GenderEnum | BlankEnum;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  /** Passenger phone number (unique in organization) */
  phone_number?: string | null;
  /** email address (unique in organization) */
  email?: string | null;
  notes?: string;
  /** Notes, but not exposed to drivers */
  dispatcher_notes?: string;
  created_at: string;
  updated_at: string;
  billing_address?: PassengerAddressRead | null;
  payer_ids?: string[] | null;
  has_tilled_account: boolean;
  /** allow sms messages to be sent to passenger (opt-in) */
  allow_sms_message?: boolean;
  passenger_addresses: PassengerAddressRead[];
};
export type PassengerDuplicateRead = {
  base_passenger: PassengerAndAddressRead;
  possible_duplicates: PassengerAndAddressRead[];
};
export type PaginatedPassengerDuplicateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PassengerDuplicate[];
};
export type PaginatedPassengerDuplicateListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PassengerDuplicateRead[];
};
export type PassengersDuplicatesListFirstNameStartsWithErrorComponent = {
  attr: 'first_name_starts_with';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type PassengersDuplicatesListLastNameStartsWithErrorComponent = {
  attr: 'last_name_starts_with';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type PassengersDuplicatesListCreatedAtStartErrorComponent = {
  attr: 'created_at_start';
  code: 'invalid';
  detail: string;
};
export type PassengersDuplicatesListCreatedAtEndErrorComponent = {
  attr: 'created_at_end';
  code: 'invalid';
  detail: string;
};
export type PassengersDuplicatesListError =
  | ({
      attr: 'first_name_starts_with';
    } & PassengersDuplicatesListFirstNameStartsWithErrorComponent)
  | ({
      attr: 'last_name_starts_with';
    } & PassengersDuplicatesListLastNameStartsWithErrorComponent)
  | ({
      attr: 'created_at_start';
    } & PassengersDuplicatesListCreatedAtStartErrorComponent)
  | ({
      attr: 'created_at_end';
    } & PassengersDuplicatesListCreatedAtEndErrorComponent);
export type PassengersDuplicatesListValidationError = {
  type: ValidationErrorEnum;
  errors: PassengersDuplicatesListError[];
};
export type PassengersDuplicatesListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PassengersDuplicatesListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PassengerMergeError = {
  passenger_id: string;
  error: string;
};
export type PassengerMergeResult = {
  base_passenger: Passenger;
  merge_success: string[];
  merge_failure: string[];
  errors?: PassengerMergeError[] | null;
};
export type PassengerMergeResultRead = {
  base_passenger: PassengerRead;
  merge_success: string[];
  merge_failure: string[];
  errors?: PassengerMergeError[] | null;
};
export type PassengersMergeCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PassengersMergeCreatePassengerIdsErrorComponent = {
  attr: 'passenger_ids';
  code: 'min_length' | 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type PassengersMergeCreatePassengerIdsIndexErrorComponent = {
  attr: 'passenger_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PassengersMergeCreateError =
  | ({
      attr: 'non_field_errors';
    } & PassengersMergeCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger_ids';
    } & PassengersMergeCreatePassengerIdsErrorComponent)
  | ({
      attr: 'passenger_ids.INDEX';
    } & PassengersMergeCreatePassengerIdsIndexErrorComponent);
export type PassengersMergeCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PassengersMergeCreateError[];
};
export type PassengersMergeCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PassengersMergeCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PassengerMergeRequest = {
  passenger_ids: string[];
};
export type PayerOrganizationSettings = {
  /** How early a driver can arrive before a pickup time */
  _pickup_default_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time */
  _pickup_default_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  _pickup_appointment_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  _pickup_appointment_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time for a will call trip */
  _pickup_will_call_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time for a will call trip */
  _pickup_will_call_late_seconds?: number | null;
  /** How many seconds extra is padded to scheduled_pickup_at during a trip import */
  _extra_scheduled_pickup_at_seconds?: number | null;
  /** How many days after an invoice is issued before it's due */
  invoice_due_after_days?: number | null;
  _requires_signature_pickup_passenger?: boolean | null;
  _requires_signature_dropoff_passenger?: boolean | null;
  _requires_signature_pickup_driver?: boolean | null;
  _requires_signature_dropoff_driver?: boolean | null;
};
export type PayerOrganizationSettingsRead = {
  id: string;
  payer_organization: string;
  /** How early a driver can arrive before a pickup time */
  _pickup_default_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time */
  _pickup_default_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  _pickup_appointment_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  _pickup_appointment_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time for a will call trip */
  _pickup_will_call_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time for a will call trip */
  _pickup_will_call_late_seconds?: number | null;
  /** How many seconds extra is padded to scheduled_pickup_at during a trip import */
  _extra_scheduled_pickup_at_seconds?: number | null;
  /** How many days after an invoice is issued before it's due */
  invoice_due_after_days?: number | null;
  _requires_signature_pickup_passenger?: boolean | null;
  _requires_signature_dropoff_passenger?: boolean | null;
  _requires_signature_pickup_driver?: boolean | null;
  _requires_signature_dropoff_driver?: boolean | null;
};
export type PaginatedPayerOrganizationSettingsList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerOrganizationSettings[];
};
export type PaginatedPayerOrganizationSettingsListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerOrganizationSettingsRead[];
};
export type PayerSettingsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayerSettingsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayerSettingsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayerSettingsPartialUpdatePickupDefaultEarlySecondsErrorComponent =
  {
    attr: '_pickup_default_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayerSettingsPartialUpdatePickupDefaultLateSecondsErrorComponent = {
  attr: '_pickup_default_late_seconds';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PayerSettingsPartialUpdatePickupAppointmentEarlySecondsErrorComponent =
  {
    attr: '_pickup_appointment_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayerSettingsPartialUpdatePickupAppointmentLateSecondsErrorComponent =
  {
    attr: '_pickup_appointment_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayerSettingsPartialUpdatePickupWillCallEarlySecondsErrorComponent =
  {
    attr: '_pickup_will_call_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayerSettingsPartialUpdatePickupWillCallLateSecondsErrorComponent =
  {
    attr: '_pickup_will_call_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayerSettingsPartialUpdateExtraScheduledPickupAtSecondsErrorComponent =
  {
    attr: '_extra_scheduled_pickup_at_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayerSettingsPartialUpdateInvoiceDueAfterDaysErrorComponent = {
  attr: 'invoice_due_after_days';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PayerSettingsPartialUpdateRequiresSignaturePickupPassengerErrorComponent =
  {
    attr: '_requires_signature_pickup_passenger';
    code: 'invalid';
    detail: string;
  };
export type PayerSettingsPartialUpdateRequiresSignatureDropoffPassengerErrorComponent =
  {
    attr: '_requires_signature_dropoff_passenger';
    code: 'invalid';
    detail: string;
  };
export type PayerSettingsPartialUpdateRequiresSignaturePickupDriverErrorComponent =
  {
    attr: '_requires_signature_pickup_driver';
    code: 'invalid';
    detail: string;
  };
export type PayerSettingsPartialUpdateRequiresSignatureDropoffDriverErrorComponent =
  {
    attr: '_requires_signature_dropoff_driver';
    code: 'invalid';
    detail: string;
  };
export type PayerSettingsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PayerSettingsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: '_pickup_default_early_seconds';
    } & PayerSettingsPartialUpdatePickupDefaultEarlySecondsErrorComponent)
  | ({
      attr: '_pickup_default_late_seconds';
    } & PayerSettingsPartialUpdatePickupDefaultLateSecondsErrorComponent)
  | ({
      attr: '_pickup_appointment_early_seconds';
    } & PayerSettingsPartialUpdatePickupAppointmentEarlySecondsErrorComponent)
  | ({
      attr: '_pickup_appointment_late_seconds';
    } & PayerSettingsPartialUpdatePickupAppointmentLateSecondsErrorComponent)
  | ({
      attr: '_pickup_will_call_early_seconds';
    } & PayerSettingsPartialUpdatePickupWillCallEarlySecondsErrorComponent)
  | ({
      attr: '_pickup_will_call_late_seconds';
    } & PayerSettingsPartialUpdatePickupWillCallLateSecondsErrorComponent)
  | ({
      attr: '_extra_scheduled_pickup_at_seconds';
    } & PayerSettingsPartialUpdateExtraScheduledPickupAtSecondsErrorComponent)
  | ({
      attr: 'invoice_due_after_days';
    } & PayerSettingsPartialUpdateInvoiceDueAfterDaysErrorComponent)
  | ({
      attr: '_requires_signature_pickup_passenger';
    } & PayerSettingsPartialUpdateRequiresSignaturePickupPassengerErrorComponent)
  | ({
      attr: '_requires_signature_dropoff_passenger';
    } & PayerSettingsPartialUpdateRequiresSignatureDropoffPassengerErrorComponent)
  | ({
      attr: '_requires_signature_pickup_driver';
    } & PayerSettingsPartialUpdateRequiresSignaturePickupDriverErrorComponent)
  | ({
      attr: '_requires_signature_dropoff_driver';
    } & PayerSettingsPartialUpdateRequiresSignatureDropoffDriverErrorComponent);
export type PayerSettingsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PayerSettingsPartialUpdateError[];
};
export type PayerSettingsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayerSettingsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPayerOrganizationSettings = {
  /** How early a driver can arrive before a pickup time */
  _pickup_default_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time */
  _pickup_default_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  _pickup_appointment_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  _pickup_appointment_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time for a will call trip */
  _pickup_will_call_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time for a will call trip */
  _pickup_will_call_late_seconds?: number | null;
  /** How many seconds extra is padded to scheduled_pickup_at during a trip import */
  _extra_scheduled_pickup_at_seconds?: number | null;
  /** How many days after an invoice is issued before it's due */
  invoice_due_after_days?: number | null;
  _requires_signature_pickup_passenger?: boolean | null;
  _requires_signature_dropoff_passenger?: boolean | null;
  _requires_signature_pickup_driver?: boolean | null;
  _requires_signature_dropoff_driver?: boolean | null;
};
export type PatchedPayerOrganizationSettingsRead = {
  id?: string;
  payer_organization?: string;
  /** How early a driver can arrive before a pickup time */
  _pickup_default_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time */
  _pickup_default_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time headed to an appointment */
  _pickup_appointment_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time headed to an appointment */
  _pickup_appointment_late_seconds?: number | null;
  /** How early a driver can arrive before a pickup time for a will call trip */
  _pickup_will_call_early_seconds?: number | null;
  /** How late a driver can arrive after a pickup time for a will call trip */
  _pickup_will_call_late_seconds?: number | null;
  /** How many seconds extra is padded to scheduled_pickup_at during a trip import */
  _extra_scheduled_pickup_at_seconds?: number | null;
  /** How many days after an invoice is issued before it's due */
  invoice_due_after_days?: number | null;
  _requires_signature_pickup_passenger?: boolean | null;
  _requires_signature_dropoff_passenger?: boolean | null;
  _requires_signature_pickup_driver?: boolean | null;
  _requires_signature_dropoff_driver?: boolean | null;
};
export type PayerInfo = {};
export type PayerInfoRead = {
  id: string;
  name: string;
  website: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedPayerInfoList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerInfo[];
};
export type PaginatedPayerInfoListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerInfoRead[];
};
export type PayerinfoListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayerinfoRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayerInfoOneSchemaTemplate = {};
export type OneSchemaTemplate = {
  id?: string;
  template_key: string;
  template_name: string;
};
export type PayerInfoOneSchemaTemplateRead = {
  payer_info: PayerInfoRead;
  one_schema_template: OneSchemaTemplate;
};
export type PaginatedPayerInfoOneSchemaTemplateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerInfoOneSchemaTemplate[];
};
export type PaginatedPayerInfoOneSchemaTemplateListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerInfoOneSchemaTemplateRead[];
};
export type PayerinfooneschematemplateListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedPayerOrganizationList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerOrganization[];
};
export type PaginatedPayerOrganizationListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerOrganizationRead[];
};
export type PayersListIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null_characters_not_allowed';
  detail: string;
};
export type PayersListError = {
  attr: 'id';
} & PayersListIdErrorComponent;
export type PayersListValidationError = {
  type: ValidationErrorEnum;
  errors: PayersListError[];
};
export type PayersListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PayersCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersCreatePayerErrorComponent = {
  attr: 'payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type PayersCreateDisplayNameErrorComponent = {
  attr: 'display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateAlternateNameErrorComponent = {
  attr: 'alternate_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateTypeErrorComponent = {
  attr: 'type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersCreateIsActiveErrorComponent = {
  attr: 'is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type PayersCreateCanMultiloadErrorComponent = {
  attr: 'can_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type PayersCreateExternalIdErrorComponent = {
  attr: 'external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateWebsiteErrorComponent = {
  attr: 'website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateEinErrorComponent = {
  attr: 'ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingNameErrorComponent = {
  attr: 'billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingPhoneNumberErrorComponent = {
  attr: 'billing_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingEmailErrorComponent = {
  attr: 'billing_email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateAdminNotesErrorComponent = {
  attr: 'admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type PayersCreateBillingAddressNonFieldErrorsErrorComponent = {
  attr: 'billing_address.non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersCreateBillingAddressLocationNonFieldErrorsErrorComponent = {
  attr: 'billing_address.location.non_field_errors';
  code: 'invalid' | 'null';
  detail: string;
};
export type PayersCreateBillingAddressLocationPlaceIdErrorComponent = {
  attr: 'billing_address.location.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingAddressLabelErrorComponent = {
  attr: 'billing_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingAddressLabel2ErrorComponent = {
  attr: 'billing_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingAddressAddressNotesErrorComponent = {
  attr: 'billing_address.address_notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateBillingAddressAddressTypeErrorComponent = {
  attr: 'billing_address.address_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersCreateDefaultTripAddressNonFieldErrorsErrorComponent = {
  attr: 'default_trip_address.non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersCreateDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersCreateDefaultTripAddressLocationPlaceIdErrorComponent = {
  attr: 'default_trip_address.location.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateDefaultTripAddressLabelErrorComponent = {
  attr: 'default_trip_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateDefaultTripAddressLabel2ErrorComponent = {
  attr: 'default_trip_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateDefaultTripAddressAddressNotesErrorComponent = {
  attr: 'default_trip_address.address_notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersCreateDefaultTripAddressAddressTypeErrorComponent = {
  attr: 'default_trip_address.address_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersCreateMultiloadingPartnersNonFieldErrorsErrorComponent = {
  attr: 'multiloading_partners.non_field_errors';
  code: 'not_a_list';
  detail: string;
};
export type PayersCreateMultiloadingPartnersIndexErrorComponent = {
  attr: 'multiloading_partners.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PayersCreateError =
  | ({
      attr: 'non_field_errors';
    } & PayersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer';
    } & PayersCreatePayerErrorComponent)
  | ({
      attr: 'display_name';
    } & PayersCreateDisplayNameErrorComponent)
  | ({
      attr: 'alternate_name';
    } & PayersCreateAlternateNameErrorComponent)
  | ({
      attr: 'type';
    } & PayersCreateTypeErrorComponent)
  | ({
      attr: 'is_active';
    } & PayersCreateIsActiveErrorComponent)
  | ({
      attr: 'can_multiload';
    } & PayersCreateCanMultiloadErrorComponent)
  | ({
      attr: 'external_id';
    } & PayersCreateExternalIdErrorComponent)
  | ({
      attr: 'website';
    } & PayersCreateWebsiteErrorComponent)
  | ({
      attr: 'ein';
    } & PayersCreateEinErrorComponent)
  | ({
      attr: 'email';
    } & PayersCreateEmailErrorComponent)
  | ({
      attr: 'phone_number';
    } & PayersCreatePhoneNumberErrorComponent)
  | ({
      attr: 'billing_name';
    } & PayersCreateBillingNameErrorComponent)
  | ({
      attr: 'billing_phone_number';
    } & PayersCreateBillingPhoneNumberErrorComponent)
  | ({
      attr: 'billing_email';
    } & PayersCreateBillingEmailErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & PayersCreateDispatcherNotesErrorComponent)
  | ({
      attr: 'admin_notes';
    } & PayersCreateAdminNotesErrorComponent)
  | ({
      attr: 'deleted_at';
    } & PayersCreateDeletedAtErrorComponent)
  | ({
      attr: 'billing_address.non_field_errors';
    } & PayersCreateBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.location.non_field_errors';
    } & PayersCreateBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.location.place_id';
    } & PayersCreateBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'billing_address.label';
    } & PayersCreateBillingAddressLabelErrorComponent)
  | ({
      attr: 'billing_address.label2';
    } & PayersCreateBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'billing_address.address_notes';
    } & PayersCreateBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'billing_address.address_type';
    } & PayersCreateBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'default_trip_address.non_field_errors';
    } & PayersCreateDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'default_trip_address.location.non_field_errors';
    } & PayersCreateDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'default_trip_address.location.place_id';
    } & PayersCreateDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'default_trip_address.label';
    } & PayersCreateDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'default_trip_address.label2';
    } & PayersCreateDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'default_trip_address.address_notes';
    } & PayersCreateDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'default_trip_address.address_type';
    } & PayersCreateDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'multiloading_partners.non_field_errors';
    } & PayersCreateMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'multiloading_partners.INDEX';
    } & PayersCreateMultiloadingPartnersIndexErrorComponent);
export type PayersCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PayersCreateError[];
};
export type PayersCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PayersPassengersRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayersPassengersCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersPassengersCreateExternalIdErrorComponent = {
  attr: 'external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationPayerErrorComponent = {
  attr: 'payer_organization.payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationDisplayNameErrorComponent = {
  attr: 'payer_organization.display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationAlternateNameErrorComponent =
  {
    attr: 'payer_organization.alternate_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationTypeErrorComponent = {
  attr: 'payer_organization.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationIsActiveErrorComponent = {
  attr: 'payer_organization.is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationCanMultiloadErrorComponent =
  {
    attr: 'payer_organization.can_multiload';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationExternalIdErrorComponent = {
  attr: 'payer_organization.external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationWebsiteErrorComponent = {
  attr: 'payer_organization.website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationEinErrorComponent = {
  attr: 'payer_organization.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationEmailErrorComponent = {
  attr: 'payer_organization.email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationPhoneNumberErrorComponent = {
  attr: 'payer_organization.phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationBillingNameErrorComponent = {
  attr: 'payer_organization.billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationBillingPhoneNumberErrorComponent =
  {
    attr: 'payer_organization.billing_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingEmailErrorComponent =
  {
    attr: 'payer_organization.billing_email';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDispatcherNotesErrorComponent =
  {
    attr: 'payer_organization.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationAdminNotesErrorComponent = {
  attr: 'payer_organization.admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationDeletedAtErrorComponent = {
  attr: 'payer_organization.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type PayersPassengersCreatePayerOrganizationBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer_organization.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingAddressLabelErrorComponent =
  {
    attr: 'payer_organization.billing_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingAddressLabel2ErrorComponent =
  {
    attr: 'payer_organization.billing_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingAddressAddressNotesErrorComponent =
  {
    attr: 'payer_organization.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationBillingAddressAddressTypeErrorComponent =
  {
    attr: 'payer_organization.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressLabelErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressLabel2ErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type PayersPassengersCreatePayerOrganizationMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'payer_organization.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type PayersPassengersCreateError =
  | ({
      attr: 'non_field_errors';
    } & PayersPassengersCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'external_id';
    } & PayersPassengersCreateExternalIdErrorComponent)
  | ({
      attr: 'payer_organization.payer';
    } & PayersPassengersCreatePayerOrganizationPayerErrorComponent)
  | ({
      attr: 'payer_organization.display_name';
    } & PayersPassengersCreatePayerOrganizationDisplayNameErrorComponent)
  | ({
      attr: 'payer_organization.alternate_name';
    } & PayersPassengersCreatePayerOrganizationAlternateNameErrorComponent)
  | ({
      attr: 'payer_organization.type';
    } & PayersPassengersCreatePayerOrganizationTypeErrorComponent)
  | ({
      attr: 'payer_organization.is_active';
    } & PayersPassengersCreatePayerOrganizationIsActiveErrorComponent)
  | ({
      attr: 'payer_organization.can_multiload';
    } & PayersPassengersCreatePayerOrganizationCanMultiloadErrorComponent)
  | ({
      attr: 'payer_organization.external_id';
    } & PayersPassengersCreatePayerOrganizationExternalIdErrorComponent)
  | ({
      attr: 'payer_organization.website';
    } & PayersPassengersCreatePayerOrganizationWebsiteErrorComponent)
  | ({
      attr: 'payer_organization.ein';
    } & PayersPassengersCreatePayerOrganizationEinErrorComponent)
  | ({
      attr: 'payer_organization.email';
    } & PayersPassengersCreatePayerOrganizationEmailErrorComponent)
  | ({
      attr: 'payer_organization.phone_number';
    } & PayersPassengersCreatePayerOrganizationPhoneNumberErrorComponent)
  | ({
      attr: 'payer_organization.billing_name';
    } & PayersPassengersCreatePayerOrganizationBillingNameErrorComponent)
  | ({
      attr: 'payer_organization.billing_phone_number';
    } & PayersPassengersCreatePayerOrganizationBillingPhoneNumberErrorComponent)
  | ({
      attr: 'payer_organization.billing_email';
    } & PayersPassengersCreatePayerOrganizationBillingEmailErrorComponent)
  | ({
      attr: 'payer_organization.dispatcher_notes';
    } & PayersPassengersCreatePayerOrganizationDispatcherNotesErrorComponent)
  | ({
      attr: 'payer_organization.admin_notes';
    } & PayersPassengersCreatePayerOrganizationAdminNotesErrorComponent)
  | ({
      attr: 'payer_organization.deleted_at';
    } & PayersPassengersCreatePayerOrganizationDeletedAtErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.non_field_errors';
    } & PayersPassengersCreatePayerOrganizationBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.location.non_field_errors';
    } & PayersPassengersCreatePayerOrganizationBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.location.place_id';
    } & PayersPassengersCreatePayerOrganizationBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.label';
    } & PayersPassengersCreatePayerOrganizationBillingAddressLabelErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.label2';
    } & PayersPassengersCreatePayerOrganizationBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.address_notes';
    } & PayersPassengersCreatePayerOrganizationBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.address_type';
    } & PayersPassengersCreatePayerOrganizationBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.non_field_errors';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.location.non_field_errors';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.location.place_id';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.label';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.label2';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.address_notes';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.address_type';
    } & PayersPassengersCreatePayerOrganizationDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer_organization.multiloading_partners.non_field_errors';
    } & PayersPassengersCreatePayerOrganizationMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.multiloading_partners.INDEX';
    } & PayersPassengersCreatePayerOrganizationMultiloadingPartnersIndexErrorComponent);
export type PayersPassengersCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PayersPassengersCreateError[];
};
export type PayersPassengersCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersPassengersCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PayersPassengersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersPassengersPartialUpdateExternalIdErrorComponent = {
  attr: 'external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersPartialUpdatePayerOrganizationPayerErrorComponent =
  {
    attr: 'payer_organization.payer';
    code: 'does_not_exist' | 'incorrect_type';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDisplayNameErrorComponent =
  {
    attr: 'payer_organization.display_name';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationAlternateNameErrorComponent =
  {
    attr: 'payer_organization.alternate_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationTypeErrorComponent = {
  attr: 'payer_organization.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersPassengersPartialUpdatePayerOrganizationIsActiveErrorComponent =
  {
    attr: 'payer_organization.is_active';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationCanMultiloadErrorComponent =
  {
    attr: 'payer_organization.can_multiload';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationExternalIdErrorComponent =
  {
    attr: 'payer_organization.external_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationWebsiteErrorComponent =
  {
    attr: 'payer_organization.website';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationEinErrorComponent = {
  attr: 'payer_organization.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPassengersPartialUpdatePayerOrganizationEmailErrorComponent =
  {
    attr: 'payer_organization.email';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationPhoneNumberErrorComponent =
  {
    attr: 'payer_organization.phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingNameErrorComponent =
  {
    attr: 'payer_organization.billing_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingPhoneNumberErrorComponent =
  {
    attr: 'payer_organization.billing_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingEmailErrorComponent =
  {
    attr: 'payer_organization.billing_email';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDispatcherNotesErrorComponent =
  {
    attr: 'payer_organization.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationAdminNotesErrorComponent =
  {
    attr: 'payer_organization.admin_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDeletedAtErrorComponent =
  {
    attr: 'payer_organization.deleted_at';
    code: 'date' | 'invalid' | 'make_aware' | 'overflow';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer_organization.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressLabelErrorComponent =
  {
    attr: 'payer_organization.billing_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressLabel2ErrorComponent =
  {
    attr: 'payer_organization.billing_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressAddressNotesErrorComponent =
  {
    attr: 'payer_organization.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationBillingAddressAddressTypeErrorComponent =
  {
    attr: 'payer_organization.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLabelErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLabel2ErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'payer_organization.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'payer_organization.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type PayersPassengersPartialUpdatePayerOrganizationMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'payer_organization.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type PayersPassengersPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PayersPassengersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'external_id';
    } & PayersPassengersPartialUpdateExternalIdErrorComponent)
  | ({
      attr: 'payer_organization.payer';
    } & PayersPassengersPartialUpdatePayerOrganizationPayerErrorComponent)
  | ({
      attr: 'payer_organization.display_name';
    } & PayersPassengersPartialUpdatePayerOrganizationDisplayNameErrorComponent)
  | ({
      attr: 'payer_organization.alternate_name';
    } & PayersPassengersPartialUpdatePayerOrganizationAlternateNameErrorComponent)
  | ({
      attr: 'payer_organization.type';
    } & PayersPassengersPartialUpdatePayerOrganizationTypeErrorComponent)
  | ({
      attr: 'payer_organization.is_active';
    } & PayersPassengersPartialUpdatePayerOrganizationIsActiveErrorComponent)
  | ({
      attr: 'payer_organization.can_multiload';
    } & PayersPassengersPartialUpdatePayerOrganizationCanMultiloadErrorComponent)
  | ({
      attr: 'payer_organization.external_id';
    } & PayersPassengersPartialUpdatePayerOrganizationExternalIdErrorComponent)
  | ({
      attr: 'payer_organization.website';
    } & PayersPassengersPartialUpdatePayerOrganizationWebsiteErrorComponent)
  | ({
      attr: 'payer_organization.ein';
    } & PayersPassengersPartialUpdatePayerOrganizationEinErrorComponent)
  | ({
      attr: 'payer_organization.email';
    } & PayersPassengersPartialUpdatePayerOrganizationEmailErrorComponent)
  | ({
      attr: 'payer_organization.phone_number';
    } & PayersPassengersPartialUpdatePayerOrganizationPhoneNumberErrorComponent)
  | ({
      attr: 'payer_organization.billing_name';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingNameErrorComponent)
  | ({
      attr: 'payer_organization.billing_phone_number';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingPhoneNumberErrorComponent)
  | ({
      attr: 'payer_organization.billing_email';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingEmailErrorComponent)
  | ({
      attr: 'payer_organization.dispatcher_notes';
    } & PayersPassengersPartialUpdatePayerOrganizationDispatcherNotesErrorComponent)
  | ({
      attr: 'payer_organization.admin_notes';
    } & PayersPassengersPartialUpdatePayerOrganizationAdminNotesErrorComponent)
  | ({
      attr: 'payer_organization.deleted_at';
    } & PayersPassengersPartialUpdatePayerOrganizationDeletedAtErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.non_field_errors';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.location.non_field_errors';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.location.place_id';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.label';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressLabelErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.label2';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.address_notes';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer_organization.billing_address.address_type';
    } & PayersPassengersPartialUpdatePayerOrganizationBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.non_field_errors';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.location.non_field_errors';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.location.place_id';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.label';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.label2';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.address_notes';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer_organization.default_trip_address.address_type';
    } & PayersPassengersPartialUpdatePayerOrganizationDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer_organization.multiloading_partners.non_field_errors';
    } & PayersPassengersPartialUpdatePayerOrganizationMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_organization.multiloading_partners.INDEX';
    } & PayersPassengersPartialUpdatePayerOrganizationMultiloadingPartnersIndexErrorComponent);
export type PayersPassengersPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PayersPassengersPartialUpdateError[];
};
export type PayersPassengersPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersPassengersPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPayerPassenger = {
  external_id?: string;
};
export type PatchedPayerPassengerRead = {
  id?: string;
  payer_organization_id?: string;
  passenger_id?: string;
  external_id?: string;
  /** Last time this payer was used in a trip */
  last_used_at?: string | null;
  passenger?: string;
  payer_organization?: PayerOrganizationRead;
  organization_id?: string;
  created_at?: string;
  updated_at?: string;
};
export type PayersPassengersDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayersSettingsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayersSettingsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersSettingsPartialUpdatePickupDefaultEarlySecondsErrorComponent =
  {
    attr: '_pickup_default_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdatePickupDefaultLateSecondsErrorComponent =
  {
    attr: '_pickup_default_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdatePickupAppointmentEarlySecondsErrorComponent =
  {
    attr: '_pickup_appointment_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdatePickupAppointmentLateSecondsErrorComponent =
  {
    attr: '_pickup_appointment_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdatePickupWillCallEarlySecondsErrorComponent =
  {
    attr: '_pickup_will_call_early_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdatePickupWillCallLateSecondsErrorComponent =
  {
    attr: '_pickup_will_call_late_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdateExtraScheduledPickupAtSecondsErrorComponent =
  {
    attr: '_extra_scheduled_pickup_at_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PayersSettingsPartialUpdateInvoiceDueAfterDaysErrorComponent = {
  attr: 'invoice_due_after_days';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PayersSettingsPartialUpdateRequiresSignaturePickupPassengerErrorComponent =
  {
    attr: '_requires_signature_pickup_passenger';
    code: 'invalid';
    detail: string;
  };
export type PayersSettingsPartialUpdateRequiresSignatureDropoffPassengerErrorComponent =
  {
    attr: '_requires_signature_dropoff_passenger';
    code: 'invalid';
    detail: string;
  };
export type PayersSettingsPartialUpdateRequiresSignaturePickupDriverErrorComponent =
  {
    attr: '_requires_signature_pickup_driver';
    code: 'invalid';
    detail: string;
  };
export type PayersSettingsPartialUpdateRequiresSignatureDropoffDriverErrorComponent =
  {
    attr: '_requires_signature_dropoff_driver';
    code: 'invalid';
    detail: string;
  };
export type PayersSettingsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PayersSettingsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: '_pickup_default_early_seconds';
    } & PayersSettingsPartialUpdatePickupDefaultEarlySecondsErrorComponent)
  | ({
      attr: '_pickup_default_late_seconds';
    } & PayersSettingsPartialUpdatePickupDefaultLateSecondsErrorComponent)
  | ({
      attr: '_pickup_appointment_early_seconds';
    } & PayersSettingsPartialUpdatePickupAppointmentEarlySecondsErrorComponent)
  | ({
      attr: '_pickup_appointment_late_seconds';
    } & PayersSettingsPartialUpdatePickupAppointmentLateSecondsErrorComponent)
  | ({
      attr: '_pickup_will_call_early_seconds';
    } & PayersSettingsPartialUpdatePickupWillCallEarlySecondsErrorComponent)
  | ({
      attr: '_pickup_will_call_late_seconds';
    } & PayersSettingsPartialUpdatePickupWillCallLateSecondsErrorComponent)
  | ({
      attr: '_extra_scheduled_pickup_at_seconds';
    } & PayersSettingsPartialUpdateExtraScheduledPickupAtSecondsErrorComponent)
  | ({
      attr: 'invoice_due_after_days';
    } & PayersSettingsPartialUpdateInvoiceDueAfterDaysErrorComponent)
  | ({
      attr: '_requires_signature_pickup_passenger';
    } & PayersSettingsPartialUpdateRequiresSignaturePickupPassengerErrorComponent)
  | ({
      attr: '_requires_signature_dropoff_passenger';
    } & PayersSettingsPartialUpdateRequiresSignatureDropoffPassengerErrorComponent)
  | ({
      attr: '_requires_signature_pickup_driver';
    } & PayersSettingsPartialUpdateRequiresSignaturePickupDriverErrorComponent)
  | ({
      attr: '_requires_signature_dropoff_driver';
    } & PayersSettingsPartialUpdateRequiresSignatureDropoffDriverErrorComponent);
export type PayersSettingsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PayersSettingsPartialUpdateError[];
};
export type PayersSettingsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersSettingsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PayersRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayersPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersPartialUpdatePayerErrorComponent = {
  attr: 'payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type PayersPartialUpdateDisplayNameErrorComponent = {
  attr: 'display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateAlternateNameErrorComponent = {
  attr: 'alternate_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateTypeErrorComponent = {
  attr: 'type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersPartialUpdateIsActiveErrorComponent = {
  attr: 'is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type PayersPartialUpdateCanMultiloadErrorComponent = {
  attr: 'can_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type PayersPartialUpdateExternalIdErrorComponent = {
  attr: 'external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateWebsiteErrorComponent = {
  attr: 'website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateEinErrorComponent = {
  attr: 'ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateEmailErrorComponent = {
  attr: 'email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdatePhoneNumberErrorComponent = {
  attr: 'phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingNameErrorComponent = {
  attr: 'billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingPhoneNumberErrorComponent = {
  attr: 'billing_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingEmailErrorComponent = {
  attr: 'billing_email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateAdminNotesErrorComponent = {
  attr: 'admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type PayersPartialUpdateBillingAddressNonFieldErrorsErrorComponent = {
  attr: 'billing_address.non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersPartialUpdateBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPartialUpdateBillingAddressLocationPlaceIdErrorComponent = {
  attr: 'billing_address.location.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingAddressLabelErrorComponent = {
  attr: 'billing_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingAddressLabel2ErrorComponent = {
  attr: 'billing_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingAddressAddressNotesErrorComponent = {
  attr: 'billing_address.address_notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateBillingAddressAddressTypeErrorComponent = {
  attr: 'billing_address.address_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersPartialUpdateDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type PayersPartialUpdateDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PayersPartialUpdateDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PayersPartialUpdateDefaultTripAddressLabelErrorComponent = {
  attr: 'default_trip_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateDefaultTripAddressLabel2ErrorComponent = {
  attr: 'default_trip_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateDefaultTripAddressAddressNotesErrorComponent = {
  attr: 'default_trip_address.address_notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PayersPartialUpdateDefaultTripAddressAddressTypeErrorComponent = {
  attr: 'default_trip_address.address_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PayersPartialUpdateMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type PayersPartialUpdateMultiloadingPartnersIndexErrorComponent = {
  attr: 'multiloading_partners.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PayersPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PayersPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer';
    } & PayersPartialUpdatePayerErrorComponent)
  | ({
      attr: 'display_name';
    } & PayersPartialUpdateDisplayNameErrorComponent)
  | ({
      attr: 'alternate_name';
    } & PayersPartialUpdateAlternateNameErrorComponent)
  | ({
      attr: 'type';
    } & PayersPartialUpdateTypeErrorComponent)
  | ({
      attr: 'is_active';
    } & PayersPartialUpdateIsActiveErrorComponent)
  | ({
      attr: 'can_multiload';
    } & PayersPartialUpdateCanMultiloadErrorComponent)
  | ({
      attr: 'external_id';
    } & PayersPartialUpdateExternalIdErrorComponent)
  | ({
      attr: 'website';
    } & PayersPartialUpdateWebsiteErrorComponent)
  | ({
      attr: 'ein';
    } & PayersPartialUpdateEinErrorComponent)
  | ({
      attr: 'email';
    } & PayersPartialUpdateEmailErrorComponent)
  | ({
      attr: 'phone_number';
    } & PayersPartialUpdatePhoneNumberErrorComponent)
  | ({
      attr: 'billing_name';
    } & PayersPartialUpdateBillingNameErrorComponent)
  | ({
      attr: 'billing_phone_number';
    } & PayersPartialUpdateBillingPhoneNumberErrorComponent)
  | ({
      attr: 'billing_email';
    } & PayersPartialUpdateBillingEmailErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & PayersPartialUpdateDispatcherNotesErrorComponent)
  | ({
      attr: 'admin_notes';
    } & PayersPartialUpdateAdminNotesErrorComponent)
  | ({
      attr: 'deleted_at';
    } & PayersPartialUpdateDeletedAtErrorComponent)
  | ({
      attr: 'billing_address.non_field_errors';
    } & PayersPartialUpdateBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.location.non_field_errors';
    } & PayersPartialUpdateBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'billing_address.location.place_id';
    } & PayersPartialUpdateBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'billing_address.label';
    } & PayersPartialUpdateBillingAddressLabelErrorComponent)
  | ({
      attr: 'billing_address.label2';
    } & PayersPartialUpdateBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'billing_address.address_notes';
    } & PayersPartialUpdateBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'billing_address.address_type';
    } & PayersPartialUpdateBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'default_trip_address.non_field_errors';
    } & PayersPartialUpdateDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'default_trip_address.location.non_field_errors';
    } & PayersPartialUpdateDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'default_trip_address.location.place_id';
    } & PayersPartialUpdateDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'default_trip_address.label';
    } & PayersPartialUpdateDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'default_trip_address.label2';
    } & PayersPartialUpdateDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'default_trip_address.address_notes';
    } & PayersPartialUpdateDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'default_trip_address.address_type';
    } & PayersPartialUpdateDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'multiloading_partners.non_field_errors';
    } & PayersPartialUpdateMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'multiloading_partners.INDEX';
    } & PayersPartialUpdateMultiloadingPartnersIndexErrorComponent);
export type PayersPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PayersPartialUpdateError[];
};
export type PayersPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPayerOrganization = {
  payer?: string | null;
  /** Name the organization uses to refer to this payer across the app */
  display_name?: string;
  /** Any alternate name the organization uses (ex., full legal name) */
  alternate_name?: string;
  type?: PayerTypeEnum;
  /** if this payer could be used for current or future trips */
  is_active?: boolean;
  /** Whether a payer organization allows any kind of multiloading */
  can_multiload?: boolean;
  /** ID of the organization in the payer's system */
  external_id?: string;
  website?: string;
  /** Employer Identification Number */
  ein?: string;
  /** Default contact email, ex. help@bambi.health */
  email?: string;
  /** Default contact phone number */
  phone_number?: string | null;
  /** Name of person to contact for billing/invoicing help or questions */
  billing_name?: string;
  /** Phone number for billing-specific purposes */
  billing_phone_number?: string | null;
  /** Email for billing/invoicing, ex. nirav@bambi.healh */
  billing_email?: string;
  /** Any additional information to display to dispatchers */
  dispatcher_notes?: string;
  /** Any additional information to display to admins */
  admin_notes?: string;
  deleted_at?: string | null;
  billing_address?: Address | null;
  default_trip_address?: Address | null;
  multiloading_partners?: string[] | null;
};
export type PatchedPayerOrganizationRead = {
  id?: string;
  organization_id?: string;
  payer?: string | null;
  /** Name the organization uses to refer to this payer across the app */
  display_name?: string;
  /** Any alternate name the organization uses (ex., full legal name) */
  alternate_name?: string;
  type?: PayerTypeEnum;
  /** if this payer could be used for current or future trips */
  is_active?: boolean;
  /** Whether a payer organization allows any kind of multiloading */
  can_multiload?: boolean;
  /** ID of the organization in the payer's system */
  external_id?: string;
  website?: string;
  /** Employer Identification Number */
  ein?: string;
  /** Default contact email, ex. help@bambi.health */
  email?: string;
  /** Default contact phone number */
  phone_number?: string | null;
  /** Name of person to contact for billing/invoicing help or questions */
  billing_name?: string;
  /** Phone number for billing-specific purposes */
  billing_phone_number?: string | null;
  /** Email for billing/invoicing, ex. nirav@bambi.healh */
  billing_email?: string;
  /** Any additional information to display to dispatchers */
  dispatcher_notes?: string;
  /** Any additional information to display to admins */
  admin_notes?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  billing_address?: AddressRead | null;
  default_trip_address?: AddressRead | null;
  pickup_default_early_seconds?: number;
  pickup_default_late_seconds?: number;
  pickup_appointment_early_seconds?: number;
  pickup_appointment_late_seconds?: number;
  pickup_will_call_early_seconds?: number;
  pickup_will_call_late_seconds?: number;
  extra_scheduled_pickup_at_seconds?: number;
  invoice_due_after_days?: number;
  requires_signature_pickup_passenger?: boolean;
  requires_signature_dropoff_passenger?: boolean;
  requires_signature_pickup_driver?: boolean;
  requires_signature_dropoff_driver?: boolean;
  payer_name?: string | null;
  payer_website?: string | null;
  payer_type?: PayerTypeEnum;
  multiloading_partners?: string[] | null;
  has_kinetik_account?: boolean;
};
export type PayersDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayersListSelectedNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PayersListSelectedSelectedPayersErrorComponent = {
  attr: 'selected_payers';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type PayersListSelectedSelectedPayersIndexErrorComponent = {
  attr: 'selected_payers.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PayersListSelectedError =
  | ({
      attr: 'non_field_errors';
    } & PayersListSelectedNonFieldErrorsErrorComponent)
  | ({
      attr: 'selected_payers';
    } & PayersListSelectedSelectedPayersErrorComponent)
  | ({
      attr: 'selected_payers.INDEX';
    } & PayersListSelectedSelectedPayersIndexErrorComponent);
export type PayersListSelectedValidationError = {
  type: ValidationErrorEnum;
  errors: PayersListSelectedError[];
};
export type PayersListSelectedErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PayersListSelectedValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PayerFilters = {
  /** List of IDs of the payers to include in response. */
  selected_payers?: string[];
};
export type PayerWithTemplates = {};
export type PayerWithTemplatesRead = {
  id: string;
  organization_id: string;
  payer: string | null;
  payer_name: string;
  payer_website: string;
  payer_type: PayerTypeEnum;
  /** ID of the organization in the payer's system */
  external_id: string;
  default_template: OneSchemaTemplate;
  active_templates: OneSchemaTemplate[];
  updated_at: string;
  created_at: string;
};
export type OneSchemaTemplateMulti = {};
export type OneSchemaTemplateMultiRead = {
  id: string;
  template_key: string;
  template_name: string;
};
export type PayerWithTemplatesResponse = {
  payer_templates: PayerWithTemplates[];
  multi_payer_templates: OneSchemaTemplateMulti[];
};
export type PayerWithTemplatesResponseRead = {
  payer_templates: PayerWithTemplatesRead[];
  multi_payer_templates: OneSchemaTemplateMultiRead[];
};
export type PayersandtemplatesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedPayerWithTemplatesList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerWithTemplates[];
};
export type PaginatedPayerWithTemplatesListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PayerWithTemplatesRead[];
};
export type PayerstemplatesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PayerstemplatesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type Pricing = {};
export type PricingUnitPrice = {
  unit_range: string;
  unit_price: number;
};
export type LocationResults = {
  id: string;
  address_number?: string | null;
  country?: string | null;
  location_label?: string | null;
  municipality?: string | null;
  neighborhood?: string | null;
  postal_code?: string | null;
  region?: string | null;
  sub_region?: string | null;
  street?: string | null;
  unit_number?: string | null;
  unit_type?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  short_label?: string | null;
};
export type PricingCondition = {
  id?: string;
  name?: string;
  match_condition?: string;
};
export type PricingModelResults = {
  id: string;
  name: string;
  details: string | null;
  is_default: boolean;
  pricing_condition?: PricingCondition | null;
};
export type PricingRuleResults = {
  id: string;
  name: string;
  additional_base: number;
  percentage: number;
  match_condition: string;
  units_covered_range_start: number | null;
  units_covered_range_end: number | null;
  text_description: string;
  order: number;
};
export type PricingTypeResults = {
  id: string;
  pricing_type: string;
  initial_rate: number;
  rounding: string;
  unit: string;
  matched_rules: PricingRuleResults[];
};
export type PricingResults = {
  original_base_price: number;
  effective_base_price: number;
  original_loaded_mileage_rate: number;
  effective_loaded_mileage_rates: PricingUnitPrice[];
  effective_loaded_mileage_price: number;
  effective_unloaded_mileage_rates: PricingUnitPrice[];
  effective_unloaded_mileage_price: number;
  total_price: number;
  effective_loaded_miles: number;
  effective_unloaded_miles: number;
  raw_calculated_loaded_miles: number;
  rounded_calculated_loaded_miles: number;
  raw_user_override_loaded_miles: number | null;
  rounded_user_override_loaded_miles: number | null;
  pickup_location: LocationResults;
  dropoff_location: LocationResults;
  pricing_model: PricingModelResults;
  pricing_types: PricingTypeResults[];
};
export type PricingResultsRead = {
  original_base_price: number;
  effective_base_price: number;
  original_loaded_mileage_rate: number;
  effective_loaded_mileage_rates: PricingUnitPrice[];
  effective_loaded_mileage_price: number;
  effective_unloaded_mileage_rates: PricingUnitPrice[];
  effective_unloaded_mileage_price: number;
  total_price: number;
  effective_loaded_miles: number;
  effective_unloaded_miles: number;
  departure_datetime: string;
  arrival_datetime: string;
  raw_calculated_loaded_miles: number;
  rounded_calculated_loaded_miles: number;
  raw_user_override_loaded_miles: number | null;
  rounded_user_override_loaded_miles: number | null;
  pickup_location: LocationResults;
  dropoff_location: LocationResults;
  pricing_model: PricingModelResults;
  pricing_types: PricingTypeResults[];
};
export type PricingRead = {
  pricing_results: PricingResultsRead;
};
export type PricingWrite = {
  space_type: SpaceTypeEnum;
  service_type: ServiceTypeEnum;
  is_will_call: boolean;
  pickup_aws_place_id: string;
  dropoff_aws_place_id: string;
  departure_datetime: string;
  is_round_trip: boolean;
  number_of_legs: number;
  correlation_id?: string | null;
  payer_id?: string | null;
  is_bariatric?: boolean | null;
  is_oxygen_required?: boolean | null;
  must_provide_wheelchair?: boolean | null;
  num_stairs?: number | null;
  trip_id?: string;
  pricing_model_id: string;
};
export type PricingPriceEstimateCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPriceEstimateCreateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreateServiceTypeErrorComponent = {
  attr: 'service_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreateIsWillCallErrorComponent = {
  attr: 'is_will_call';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreatePickupAwsPlaceIdErrorComponent = {
  attr: 'pickup_aws_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPriceEstimateCreateDropoffAwsPlaceIdErrorComponent = {
  attr: 'dropoff_aws_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPriceEstimateCreateDepartureDatetimeErrorComponent = {
  attr: 'departure_datetime';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreateIsRoundTripErrorComponent = {
  attr: 'is_round_trip';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreateNumberOfLegsErrorComponent = {
  attr: 'number_of_legs';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreateCorrelationIdErrorComponent = {
  attr: 'correlation_id';
  code: 'invalid';
  detail: string;
};
export type PricingPriceEstimateCreatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid';
  detail: string;
};
export type PricingPriceEstimateCreateIsBariatricErrorComponent = {
  attr: 'is_bariatric';
  code: 'invalid';
  detail: string;
};
export type PricingPriceEstimateCreateIsOxygenRequiredErrorComponent = {
  attr: 'is_oxygen_required';
  code: 'invalid';
  detail: string;
};
export type PricingPriceEstimateCreateMustProvideWheelchairErrorComponent = {
  attr: 'must_provide_wheelchair';
  code: 'invalid';
  detail: string;
};
export type PricingPriceEstimateCreateNumStairsErrorComponent = {
  attr: 'num_stairs';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type PricingPriceEstimateCreateTripIdErrorComponent = {
  attr: 'trip_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPriceEstimateCreatePricingModelIdErrorComponent = {
  attr: 'pricing_model_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceEstimateCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPriceEstimateCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'space_type';
    } & PricingPriceEstimateCreateSpaceTypeErrorComponent)
  | ({
      attr: 'service_type';
    } & PricingPriceEstimateCreateServiceTypeErrorComponent)
  | ({
      attr: 'is_will_call';
    } & PricingPriceEstimateCreateIsWillCallErrorComponent)
  | ({
      attr: 'pickup_aws_place_id';
    } & PricingPriceEstimateCreatePickupAwsPlaceIdErrorComponent)
  | ({
      attr: 'dropoff_aws_place_id';
    } & PricingPriceEstimateCreateDropoffAwsPlaceIdErrorComponent)
  | ({
      attr: 'departure_datetime';
    } & PricingPriceEstimateCreateDepartureDatetimeErrorComponent)
  | ({
      attr: 'is_round_trip';
    } & PricingPriceEstimateCreateIsRoundTripErrorComponent)
  | ({
      attr: 'number_of_legs';
    } & PricingPriceEstimateCreateNumberOfLegsErrorComponent)
  | ({
      attr: 'correlation_id';
    } & PricingPriceEstimateCreateCorrelationIdErrorComponent)
  | ({
      attr: 'payer_id';
    } & PricingPriceEstimateCreatePayerIdErrorComponent)
  | ({
      attr: 'is_bariatric';
    } & PricingPriceEstimateCreateIsBariatricErrorComponent)
  | ({
      attr: 'is_oxygen_required';
    } & PricingPriceEstimateCreateIsOxygenRequiredErrorComponent)
  | ({
      attr: 'must_provide_wheelchair';
    } & PricingPriceEstimateCreateMustProvideWheelchairErrorComponent)
  | ({
      attr: 'num_stairs';
    } & PricingPriceEstimateCreateNumStairsErrorComponent)
  | ({
      attr: 'trip_id';
    } & PricingPriceEstimateCreateTripIdErrorComponent)
  | ({
      attr: 'pricing_model_id';
    } & PricingPriceEstimateCreatePricingModelIdErrorComponent);
export type PricingPriceEstimateCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPriceEstimateCreateError[];
};
export type PricingPriceEstimateCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPriceEstimateCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PriceQuote = {
  is_canceled: boolean;
  cancellation_reason?: string | null;
  canceled_at?: string | null;
  pickup_at: string;
  pickup_place_id: string;
  dropoff_place_id: string;
  wait_time_minutes: number;
  steps_pickup: number;
  steps_dropoff: number;
  num_attendants: number;
  num_additional_passengers: number;
  is_bariatric: boolean;
  is_wheelchair_provided: boolean;
  is_oxygen_provided: boolean;
  space_type: SpaceTypeEnum;
  payer: string;
  is_will_call?: boolean;
  is_will_call_activated?: boolean;
  has_infectious_disease: boolean;
  is_trip_subscription?: boolean;
};
export type BaseFeeSummary = {
  sub_total: number;
};
export type LoadedMileageFeeSummary = {
  miles: number;
  free_miles: number;
  rate: number;
  sub_total: number;
};
export type LongDistanceLoadedMileageFeeSummary = {
  miles: number;
  free_miles: number;
  rate: number;
  sub_total: number;
};
export type UnloadedMileageFeeSummary = {
  miles: number;
  free_miles: number;
  rate: number;
  sub_total: number;
};
export type WaitTimeFeeSummary = {
  minutes: number;
  free_minutes: number;
  period: number;
  rate: number;
  sub_total: number;
};
export type NoShowFeeTypeEnum = 'Flat' | 'Percentage';
export type NoShowFeeSummary = {
  sub_total: number;
  cancellation_reason: string;
  canceled_at: string;
  scheduled_pickup_at: string;
  minutes_before_pickup: number;
  no_show_fee_type: NoShowFeeTypeEnum;
};
export type LateCancellationFeeSummary = {
  sub_total: number;
  canceled_at: string;
  scheduled_pickup_at: string;
  minutes_before_pickup: number;
  late_cancellation_fee_type: NoShowFeeTypeEnum;
};
export type StairFeeSummary = {
  sub_total: number;
  steps: number;
  minimum_steps: number;
  fee_type: string;
  rate: number;
};
export type AdditionalAttendantsFeeSummary = {
  sub_total: number;
  additional_attendants: number;
  rate: number;
};
export type AdditionalPassengersFeeSummary = {
  sub_total: number;
  additional_passengers: number;
  rate: number;
};
export type BariatricFeeSummary = {
  sub_total: number;
  is_bariatric: boolean;
};
export type WheelchairRentalFeeSummary = {
  sub_total: number;
  is_wheelchair_provided: boolean;
};
export type OxygenFeeSummary = {
  sub_total: number;
  is_oxygen_provided: boolean;
};
export type AfterHoursFeeSummary = {
  sub_total: number;
  pickup_time: string;
  business_hours_start?: string | null;
  business_hours_end?: string | null;
};
export type WeekendFeeSummary = {
  sub_total: number;
  day_of_week: string;
};
export type HolidayFeeSummary = {
  sub_total?: number | null;
  holiday?: string | null;
  date: string;
};
export type InfectiousDiseaseFeeSummary = {
  sub_total: number;
  has_infectious_disease: boolean;
};
export type SameDayBookingFeeSummary = {
  sub_total: number;
};
export type PriceSummary = {
  name: string;
  base_fee_summary?: BaseFeeSummary | null;
  loaded_mileage_fee_summary?: LoadedMileageFeeSummary | null;
  long_distance_loaded_mileage_fee_summary?: LongDistanceLoadedMileageFeeSummary | null;
  unloaded_mileage_fee_summary?: UnloadedMileageFeeSummary | null;
  wait_time_fee_summary?: WaitTimeFeeSummary | null;
  no_show_fee_summary?: NoShowFeeSummary | null;
  late_cancellation_fee_summary?: LateCancellationFeeSummary | null;
  pickup_stair_fee_summary?: StairFeeSummary | null;
  dropoff_stair_fee_summary?: StairFeeSummary | null;
  additional_attendants_fee_summary?: AdditionalAttendantsFeeSummary | null;
  additional_passengers_fee_summary?: AdditionalPassengersFeeSummary | null;
  bariatric_fee_summary?: BariatricFeeSummary | null;
  wheelchair_rental_fee_summary?: WheelchairRentalFeeSummary | null;
  oxygen_fee_summary?: OxygenFeeSummary | null;
  after_hours_fee_summary?: AfterHoursFeeSummary | null;
  weekend_fee_summary?: WeekendFeeSummary | null;
  holiday_fee_summary?: HolidayFeeSummary | null;
  minimum_price_cents?: number | null;
  infectious_disease_fee_summary?: InfectiousDiseaseFeeSummary | null;
  same_day_booking_fee_summary?: SameDayBookingFeeSummary | null;
  total?: number | null;
};
export type PriceQuoteRead = {
  is_canceled: boolean;
  cancellation_reason?: string | null;
  canceled_at?: string | null;
  pickup_at: string;
  pickup_place_id: string;
  dropoff_place_id: string;
  wait_time_minutes: number;
  steps_pickup: number;
  steps_dropoff: number;
  num_attendants: number;
  num_additional_passengers: number;
  is_bariatric: boolean;
  is_wheelchair_provided: boolean;
  is_oxygen_provided: boolean;
  space_type: SpaceTypeEnum;
  payer: string;
  is_will_call?: boolean;
  is_will_call_activated?: boolean;
  has_infectious_disease: boolean;
  is_trip_subscription?: boolean;
  price_summary: PriceSummary;
};
export type PricingPriceQuoteCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPriceQuoteCreateIsCanceledErrorComponent = {
  attr: 'is_canceled';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateCancellationReasonErrorComponent = {
  attr: 'cancellation_reason';
  code:
    | 'blank'
    | 'invalid'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPriceQuoteCreateCanceledAtErrorComponent = {
  attr: 'canceled_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type PricingPriceQuoteCreatePickupAtErrorComponent = {
  attr: 'pickup_at';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreatePickupPlaceIdErrorComponent = {
  attr: 'pickup_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPriceQuoteCreateDropoffPlaceIdErrorComponent = {
  attr: 'dropoff_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPriceQuoteCreateWaitTimeMinutesErrorComponent = {
  attr: 'wait_time_minutes';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateStepsPickupErrorComponent = {
  attr: 'steps_pickup';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateStepsDropoffErrorComponent = {
  attr: 'steps_dropoff';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateNumAttendantsErrorComponent = {
  attr: 'num_attendants';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateNumAdditionalPassengersErrorComponent = {
  attr: 'num_additional_passengers';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateIsBariatricErrorComponent = {
  attr: 'is_bariatric';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateIsWheelchairProvidedErrorComponent = {
  attr: 'is_wheelchair_provided';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateIsOxygenProvidedErrorComponent = {
  attr: 'is_oxygen_provided';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreatePayerErrorComponent = {
  attr: 'payer';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateIsWillCallErrorComponent = {
  attr: 'is_will_call';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPriceQuoteCreateIsWillCallActivatedErrorComponent = {
  attr: 'is_will_call_activated';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPriceQuoteCreateHasInfectiousDiseaseErrorComponent = {
  attr: 'has_infectious_disease';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPriceQuoteCreateIsTripSubscriptionErrorComponent = {
  attr: 'is_trip_subscription';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPriceQuoteCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPriceQuoteCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'is_canceled';
    } & PricingPriceQuoteCreateIsCanceledErrorComponent)
  | ({
      attr: 'cancellation_reason';
    } & PricingPriceQuoteCreateCancellationReasonErrorComponent)
  | ({
      attr: 'canceled_at';
    } & PricingPriceQuoteCreateCanceledAtErrorComponent)
  | ({
      attr: 'pickup_at';
    } & PricingPriceQuoteCreatePickupAtErrorComponent)
  | ({
      attr: 'pickup_place_id';
    } & PricingPriceQuoteCreatePickupPlaceIdErrorComponent)
  | ({
      attr: 'dropoff_place_id';
    } & PricingPriceQuoteCreateDropoffPlaceIdErrorComponent)
  | ({
      attr: 'wait_time_minutes';
    } & PricingPriceQuoteCreateWaitTimeMinutesErrorComponent)
  | ({
      attr: 'steps_pickup';
    } & PricingPriceQuoteCreateStepsPickupErrorComponent)
  | ({
      attr: 'steps_dropoff';
    } & PricingPriceQuoteCreateStepsDropoffErrorComponent)
  | ({
      attr: 'num_attendants';
    } & PricingPriceQuoteCreateNumAttendantsErrorComponent)
  | ({
      attr: 'num_additional_passengers';
    } & PricingPriceQuoteCreateNumAdditionalPassengersErrorComponent)
  | ({
      attr: 'is_bariatric';
    } & PricingPriceQuoteCreateIsBariatricErrorComponent)
  | ({
      attr: 'is_wheelchair_provided';
    } & PricingPriceQuoteCreateIsWheelchairProvidedErrorComponent)
  | ({
      attr: 'is_oxygen_provided';
    } & PricingPriceQuoteCreateIsOxygenProvidedErrorComponent)
  | ({
      attr: 'space_type';
    } & PricingPriceQuoteCreateSpaceTypeErrorComponent)
  | ({
      attr: 'payer';
    } & PricingPriceQuoteCreatePayerErrorComponent)
  | ({
      attr: 'is_will_call';
    } & PricingPriceQuoteCreateIsWillCallErrorComponent)
  | ({
      attr: 'is_will_call_activated';
    } & PricingPriceQuoteCreateIsWillCallActivatedErrorComponent)
  | ({
      attr: 'has_infectious_disease';
    } & PricingPriceQuoteCreateHasInfectiousDiseaseErrorComponent)
  | ({
      attr: 'is_trip_subscription';
    } & PricingPriceQuoteCreateIsTripSubscriptionErrorComponent);
export type PricingPriceQuoteCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPriceQuoteCreateError[];
};
export type PricingPriceQuoteCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPriceQuoteCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PricingModelSelection = {};
export type PricingModel = {
  id?: string;
  name: string;
  details: string;
  is_default: boolean;
};
export type PricingRule = {
  id?: string;
  name: string;
  /** Type of value that is affected by this pricing rule (e.g. 'base_price') */
  pricing_type: string;
  /** Flat amount to be added to selected base (in cents) */
  additional_base?: number;
  /** Percentage of price to be applied (e.g. 140 == 40% more, 60 == 40% less) */
  percentage?: number;
  match_condition: string;
  units_covered_range_start: number | null;
  units_covered_range_end: number | null;
  text_description: string;
  order?: number;
};
export type PricingType = {
  id: string;
  pricing_type: string;
  initial_rate: number;
  rounding: string;
  unit: string;
  pricing_rules: PricingRule[];
};
export type PricingModelRead = {
  id?: string;
  name: string;
  details: string;
  is_default: boolean;
  pricing_condition: PricingCondition;
  pricing_types: PricingType[];
};
export type PricingModelSelectionRead = {
  pricing_model: PricingModelRead;
};
export type PricingModelSelectionWrite = {
  space_type: SpaceTypeEnum;
  service_type: ServiceTypeEnum;
  is_will_call: boolean;
  pickup_aws_place_id: string;
  dropoff_aws_place_id: string;
  departure_datetime: string;
  is_round_trip: boolean;
  number_of_legs: number;
  correlation_id?: string | null;
  payer_id?: string | null;
  is_bariatric?: boolean | null;
  is_oxygen_required?: boolean | null;
  must_provide_wheelchair?: boolean | null;
  num_stairs?: number | null;
};
export type PricingPricingModelSelectionCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelSelectionCreateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelSelectionCreateServiceTypeErrorComponent = {
  attr: 'service_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelSelectionCreateIsWillCallErrorComponent = {
  attr: 'is_will_call';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelSelectionCreatePickupAwsPlaceIdErrorComponent = {
  attr: 'pickup_aws_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelSelectionCreateDropoffAwsPlaceIdErrorComponent =
  {
    attr: 'dropoff_aws_place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelSelectionCreateDepartureDatetimeErrorComponent =
  {
    attr: 'departure_datetime';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type PricingPricingModelSelectionCreateIsRoundTripErrorComponent = {
  attr: 'is_round_trip';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelSelectionCreateNumberOfLegsErrorComponent = {
  attr: 'number_of_legs';
  code: 'invalid' | 'max_string_length' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelSelectionCreateCorrelationIdErrorComponent = {
  attr: 'correlation_id';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelSelectionCreatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelSelectionCreateIsBariatricErrorComponent = {
  attr: 'is_bariatric';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelSelectionCreateIsOxygenRequiredErrorComponent = {
  attr: 'is_oxygen_required';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelSelectionCreateMustProvideWheelchairErrorComponent =
  {
    attr: 'must_provide_wheelchair';
    code: 'invalid';
    detail: string;
  };
export type PricingPricingModelSelectionCreateNumStairsErrorComponent = {
  attr: 'num_stairs';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type PricingPricingModelSelectionCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingModelSelectionCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'space_type';
    } & PricingPricingModelSelectionCreateSpaceTypeErrorComponent)
  | ({
      attr: 'service_type';
    } & PricingPricingModelSelectionCreateServiceTypeErrorComponent)
  | ({
      attr: 'is_will_call';
    } & PricingPricingModelSelectionCreateIsWillCallErrorComponent)
  | ({
      attr: 'pickup_aws_place_id';
    } & PricingPricingModelSelectionCreatePickupAwsPlaceIdErrorComponent)
  | ({
      attr: 'dropoff_aws_place_id';
    } & PricingPricingModelSelectionCreateDropoffAwsPlaceIdErrorComponent)
  | ({
      attr: 'departure_datetime';
    } & PricingPricingModelSelectionCreateDepartureDatetimeErrorComponent)
  | ({
      attr: 'is_round_trip';
    } & PricingPricingModelSelectionCreateIsRoundTripErrorComponent)
  | ({
      attr: 'number_of_legs';
    } & PricingPricingModelSelectionCreateNumberOfLegsErrorComponent)
  | ({
      attr: 'correlation_id';
    } & PricingPricingModelSelectionCreateCorrelationIdErrorComponent)
  | ({
      attr: 'payer_id';
    } & PricingPricingModelSelectionCreatePayerIdErrorComponent)
  | ({
      attr: 'is_bariatric';
    } & PricingPricingModelSelectionCreateIsBariatricErrorComponent)
  | ({
      attr: 'is_oxygen_required';
    } & PricingPricingModelSelectionCreateIsOxygenRequiredErrorComponent)
  | ({
      attr: 'must_provide_wheelchair';
    } & PricingPricingModelSelectionCreateMustProvideWheelchairErrorComponent)
  | ({
      attr: 'num_stairs';
    } & PricingPricingModelSelectionCreateNumStairsErrorComponent);
export type PricingPricingModelSelectionCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingModelSelectionCreateError[];
};
export type PricingPricingModelSelectionCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingModelSelectionCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PaginatedPricingModelList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingModel[];
};
export type PaginatedPricingModelListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingModelRead[];
};
export type PricingPricingModelsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingPricingModelsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelsCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPricingModelsCreateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelsCreateDetailsErrorComponent = {
  attr: 'details';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelsCreateIsDefaultErrorComponent = {
  attr: 'is_default';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelsCreatePricingConditionIdErrorComponent = {
  attr: 'pricing_condition.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPricingModelsCreatePricingConditionNameErrorComponent = {
  attr: 'pricing_condition.name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelsCreatePricingConditionMatchConditionErrorComponent =
  {
    attr: 'pricing_condition.match_condition';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexIdErrorComponent = {
  attr: 'pricing_types.INDEX.id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelsCreatePricingTypesIndexPricingTypeErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_type';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexInitialRateErrorComponent =
  {
    attr: 'pricing_types.INDEX.initial_rate';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexRoundingErrorComponent =
  {
    attr: 'pricing_types.INDEX.rounding';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexUnitErrorComponent = {
  attr: 'pricing_types.INDEX.unit';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesNonFieldErrorsErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexNonFieldErrorsErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexIdErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.id';
    code: 'invalid' | 'null' | 'unique';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexNameErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.name';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexPricingTypeErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.pricing_type';
    code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexAdditionalBaseErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.additional_base';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexPercentageErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.percentage';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexMatchConditionErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.match_condition';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeStartErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_start';
    code: 'invalid' | 'max_string_length' | 'required';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeEndErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_end';
    code: 'invalid' | 'max_string_length' | 'required';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexTextDescriptionErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.text_description';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexOrderErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.order';
    code: 'invalid' | 'max_string_length' | 'null';
    detail: string;
  };
export type PricingPricingModelsCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingModelsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & PricingPricingModelsCreateIdErrorComponent)
  | ({
      attr: 'name';
    } & PricingPricingModelsCreateNameErrorComponent)
  | ({
      attr: 'details';
    } & PricingPricingModelsCreateDetailsErrorComponent)
  | ({
      attr: 'is_default';
    } & PricingPricingModelsCreateIsDefaultErrorComponent)
  | ({
      attr: 'pricing_condition.id';
    } & PricingPricingModelsCreatePricingConditionIdErrorComponent)
  | ({
      attr: 'pricing_condition.name';
    } & PricingPricingModelsCreatePricingConditionNameErrorComponent)
  | ({
      attr: 'pricing_condition.match_condition';
    } & PricingPricingModelsCreatePricingConditionMatchConditionErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.id';
    } & PricingPricingModelsCreatePricingTypesIndexIdErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_type';
    } & PricingPricingModelsCreatePricingTypesIndexPricingTypeErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.initial_rate';
    } & PricingPricingModelsCreatePricingTypesIndexInitialRateErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.rounding';
    } & PricingPricingModelsCreatePricingTypesIndexRoundingErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.unit';
    } & PricingPricingModelsCreatePricingTypesIndexUnitErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.non_field_errors';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesNonFieldErrorsErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.non_field_errors';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.id';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexIdErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.name';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexNameErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.pricing_type';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexPricingTypeErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.additional_base';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexAdditionalBaseErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.percentage';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexPercentageErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.match_condition';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexMatchConditionErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_start';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeStartErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_end';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeEndErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.text_description';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexTextDescriptionErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.order';
    } & PricingPricingModelsCreatePricingTypesIndexPricingRulesIndexOrderErrorComponent);
export type PricingPricingModelsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingModelsCreateError[];
};
export type PricingPricingModelsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingModelsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PricingPricingModelsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingPricingModelsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingModelsPartialUpdateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPricingModelsPartialUpdateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelsPartialUpdateDetailsErrorComponent = {
  attr: 'details';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingModelsPartialUpdateIsDefaultErrorComponent = {
  attr: 'is_default';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingModelsPartialUpdatePricingConditionIdErrorComponent =
  {
    attr: 'pricing_condition.id';
    code: 'invalid' | 'null';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingConditionNameErrorComponent =
  {
    attr: 'pricing_condition.name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingConditionMatchConditionErrorComponent =
  {
    attr: 'pricing_condition.match_condition';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexIdErrorComponent =
  {
    attr: 'pricing_types.INDEX.id';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingTypeErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_type';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexInitialRateErrorComponent =
  {
    attr: 'pricing_types.INDEX.initial_rate';
    code: 'invalid' | 'max_string_length' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexRoundingErrorComponent =
  {
    attr: 'pricing_types.INDEX.rounding';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexUnitErrorComponent =
  {
    attr: 'pricing_types.INDEX.unit';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesNonFieldErrorsErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexNonFieldErrorsErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexIdErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.id';
    code: 'invalid' | 'null' | 'unique';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexNameErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.name';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexPricingTypeErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.pricing_type';
    code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexAdditionalBaseErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.additional_base';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexPercentageErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.percentage';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexMatchConditionErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.match_condition';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeStartErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_start';
    code: 'invalid' | 'max_string_length' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeEndErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_end';
    code: 'invalid' | 'max_string_length' | 'required';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexTextDescriptionErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.text_description';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexOrderErrorComponent =
  {
    attr: 'pricing_types.INDEX.pricing_rules.INDEX.order';
    code: 'invalid' | 'max_string_length' | 'null';
    detail: string;
  };
export type PricingPricingModelsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingModelsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & PricingPricingModelsPartialUpdateIdErrorComponent)
  | ({
      attr: 'name';
    } & PricingPricingModelsPartialUpdateNameErrorComponent)
  | ({
      attr: 'details';
    } & PricingPricingModelsPartialUpdateDetailsErrorComponent)
  | ({
      attr: 'is_default';
    } & PricingPricingModelsPartialUpdateIsDefaultErrorComponent)
  | ({
      attr: 'pricing_condition.id';
    } & PricingPricingModelsPartialUpdatePricingConditionIdErrorComponent)
  | ({
      attr: 'pricing_condition.name';
    } & PricingPricingModelsPartialUpdatePricingConditionNameErrorComponent)
  | ({
      attr: 'pricing_condition.match_condition';
    } & PricingPricingModelsPartialUpdatePricingConditionMatchConditionErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.id';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexIdErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_type';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingTypeErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.initial_rate';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexInitialRateErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.rounding';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexRoundingErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.unit';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexUnitErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.non_field_errors';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesNonFieldErrorsErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.non_field_errors';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.id';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexIdErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.name';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexNameErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.pricing_type';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexPricingTypeErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.additional_base';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexAdditionalBaseErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.percentage';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexPercentageErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.match_condition';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexMatchConditionErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_start';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeStartErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.units_covered_range_end';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexUnitsCoveredRangeEndErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.text_description';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexTextDescriptionErrorComponent)
  | ({
      attr: 'pricing_types.INDEX.pricing_rules.INDEX.order';
    } & PricingPricingModelsPartialUpdatePricingTypesIndexPricingRulesIndexOrderErrorComponent);
export type PricingPricingModelsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingModelsPartialUpdateError[];
};
export type PricingPricingModelsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingModelsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPricingModel = {
  id?: string;
  name?: string;
  details?: string;
  is_default?: boolean;
};
export type PatchedPricingModelRead = {
  id?: string;
  name?: string;
  details?: string;
  is_default?: boolean;
  pricing_condition?: PricingCondition;
  pricing_types?: PricingType[];
};
export type PricingPricingModelsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingRuleSelection = {
  name: string;
  /** Flat amount to be added to selected base (in cents) */
  additional_base?: number;
  /** Percentage of price to be applied (e.g. 140 == 40% more, 60 == 40% less) */
  percentage?: number;
  units_covered_range_start?: number | null;
  units_covered_range_end?: number | null;
  /** Order in which this rule is applied to the trip */
  order?: number;
};
export type PricingRuleSelectionRead = {
  id: string;
  name: string;
  /** Flat amount to be added to selected base (in cents) */
  additional_base?: number;
  /** Percentage of price to be applied (e.g. 140 == 40% more, 60 == 40% less) */
  percentage?: number;
  units_covered_range_start?: number | null;
  units_covered_range_end?: number | null;
  /** Order in which this rule is applied to the trip */
  order?: number;
};
export type PricingPricingRulesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingRulesPartialUpdateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingRulesPartialUpdateAdditionalBaseErrorComponent = {
  attr: 'additional_base';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type PricingPricingRulesPartialUpdatePercentageErrorComponent = {
  attr: 'percentage';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type PricingPricingRulesPartialUpdateUnitsCoveredRangeStartErrorComponent =
  {
    attr: 'units_covered_range_start';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type PricingPricingRulesPartialUpdateUnitsCoveredRangeEndErrorComponent =
  {
    attr: 'units_covered_range_end';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type PricingPricingRulesPartialUpdateOrderErrorComponent = {
  attr: 'order';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type PricingPricingRulesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingRulesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'name';
    } & PricingPricingRulesPartialUpdateNameErrorComponent)
  | ({
      attr: 'additional_base';
    } & PricingPricingRulesPartialUpdateAdditionalBaseErrorComponent)
  | ({
      attr: 'percentage';
    } & PricingPricingRulesPartialUpdatePercentageErrorComponent)
  | ({
      attr: 'units_covered_range_start';
    } & PricingPricingRulesPartialUpdateUnitsCoveredRangeStartErrorComponent)
  | ({
      attr: 'units_covered_range_end';
    } & PricingPricingRulesPartialUpdateUnitsCoveredRangeEndErrorComponent)
  | ({
      attr: 'order';
    } & PricingPricingRulesPartialUpdateOrderErrorComponent);
export type PricingPricingRulesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingRulesPartialUpdateError[];
};
export type PricingPricingRulesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingRulesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPricingRuleSelection = {
  name?: string;
  /** Flat amount to be added to selected base (in cents) */
  additional_base?: number;
  /** Percentage of price to be applied (e.g. 140 == 40% more, 60 == 40% less) */
  percentage?: number;
  units_covered_range_start?: number | null;
  units_covered_range_end?: number | null;
  /** Order in which this rule is applied to the trip */
  order?: number;
};
export type PatchedPricingRuleSelectionRead = {
  id?: string;
  name?: string;
  /** Flat amount to be added to selected base (in cents) */
  additional_base?: number;
  /** Percentage of price to be applied (e.g. 140 == 40% more, 60 == 40% less) */
  percentage?: number;
  units_covered_range_start?: number | null;
  units_covered_range_end?: number | null;
  /** Order in which this rule is applied to the trip */
  order?: number;
};
export type PricingPricingRulesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PriceSchemeConflictResults = {
  conflicts: string[];
};
export type PricingPricingSchemeConflictsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateIdErrorComponent = {
  attr: 'id';
  code: 'invalid' | 'null';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateStartDateErrorComponent = {
  attr: 'start_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateEndDateErrorComponent = {
  attr: 'end_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateSpaceTypesErrorComponent = {
  attr: 'space_types';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateSpaceTypesIndexErrorComponent = {
  attr: 'space_types.INDEX';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingSchemeConflictsCreatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemeConflictsCreatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemeConflictsCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingSchemeConflictsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & PricingPricingSchemeConflictsCreateIdErrorComponent)
  | ({
      attr: 'name';
    } & PricingPricingSchemeConflictsCreateNameErrorComponent)
  | ({
      attr: 'start_date';
    } & PricingPricingSchemeConflictsCreateStartDateErrorComponent)
  | ({
      attr: 'end_date';
    } & PricingPricingSchemeConflictsCreateEndDateErrorComponent)
  | ({
      attr: 'space_types';
    } & PricingPricingSchemeConflictsCreateSpaceTypesErrorComponent)
  | ({
      attr: 'space_types.INDEX';
    } & PricingPricingSchemeConflictsCreateSpaceTypesIndexErrorComponent)
  | ({
      attr: 'payer_ids';
    } & PricingPricingSchemeConflictsCreatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & PricingPricingSchemeConflictsCreatePayerIdsIndexErrorComponent);
export type PricingPricingSchemeConflictsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingSchemeConflictsCreateError[];
};
export type PricingPricingSchemeConflictsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingSchemeConflictsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PriceSchemeConflict = {};
export type PriceSchemeConflictWrite = {
  id?: string;
  name: string;
  start_date: string;
  end_date: string;
  space_types: string[];
  payer_ids: string[];
};
export type StairFeeTypeEnum = 'Flat' | 'Rate';
export type ObservedHolidaysEnum =
  | "New Year's Day"
  | 'Martin Luther King Jr. Day'
  | "Washington's Birthday"
  | 'Memorial Day'
  | 'Juneteenth'
  | 'Independence Day'
  | 'Labor Day'
  | 'Columbus Day'
  | 'Veterans Day'
  | 'Thanksgiving'
  | 'Christmas';
export type PricingScheme = {
  /** Name of the pricing scheme, this should be unique */
  name: string;
  /** Description of the pricing scheme */
  description?: string;
  /** Date the pricing scheme goes into effect */
  start_date: string;
  /** Date the pricing scheme is no longer in effect */
  end_date: string;
  /** Space types that this pricing scheme applies to */
  space_types?: SpaceTypeEnum[];
  /** Base fee in cents */
  base_fee_cents?: number | null;
  /** Rate in cents per loaded mile */
  loaded_mileage_rate_cents?: number | null;
  /** Rate in cents per mile for long distance trips */
  long_distance_mileage_rate_cents?: number | null;
  /** Minimum distance in miles for long distance trips */
  long_distance_mileage_start?: number | null;
  /** Number of free loaded miles */
  free_loaded_miles?: number | null;
  /** Rate in cents per unloaded mile */
  unloaded_mileage_rate_cents?: number | null;
  /** Number of free unloaded miles */
  free_unloaded_miles?: number | null;
  /** Rate in cents per wait time period */
  wait_time_rate_cents?: number | null;
  /** Wait time period in minutes */
  wait_time_period_minutes?: number | null;
  /** Number of free wait time minutes */
  wait_time_free_minutes?: number | null;
  /** Fee in cents for late cancellations */
  late_cancellation_fee_cents?: number | null;
  /** Minutes before scheduled pickup time for late cancellations */
  late_cancellation_period_minutes?: number | null;
  /** Fee in cents for no shows */
  no_show_fee_cents?: number | null;
  /** Fee in cents for stairs */
  stair_fee_cents?: number | null;
  /** Minimum number of steps for the stair fee to be applied */
  minimum_steps?: number | null;
  /** Type of stair fee, flat fee or per step fee */
  stair_fee_type?: StairFeeTypeEnum | BlankEnum;
  /** Fee in cents for each additional attendant */
  additional_attendant_rate_cents?: number | null;
  /** Fee in cents for each additional passenger */
  additional_passenger_rate_cents?: number | null;
  /** Fee in cents for bariatric passengers */
  bariatric_passenger_fee_cents?: number | null;
  /** Fee in cents for wheelchair rentals */
  wheelchair_rental_fee_cents?: number | null;
  /** Fee in cents for oxygen */
  oxygen_fee_cents?: number | null;
  /** Fee in cents for weekend trips */
  weekend_fee_cents?: number | null;
  /** Fee in cents for after hours trips */
  after_hours_fee_cents?: number | null;
  /** Time before which trips are considered after hours */
  business_hours_start_time?: string | null;
  /** Time after which trips are considered after hours */
  business_hours_end_time?: string | null;
  /** Fee in cents for holiday trips */
  holiday_fee_cents?: number | null;
  /** Holidays that this pricing scheme applies to */
  observed_holidays?: ObservedHolidaysEnum[] | null;
  /** Minimum price in cents for this pricing scheme */
  minimum_price_cents?: number | null;
  /** Type of cancellation fee, flat fee or as a percentage of the trip cost */
  late_cancellation_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Type of no show fee, flat fee or as a percentage of the trip cost */
  no_show_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Fee in cents for passenger with infectious disease */
  infectious_disease_fee_cents?: number | null;
  /** Fee in cents for for same day booking */
  same_day_booking_fee_cents?: number | null;
};
export type PricingSchemePayer = {
  pricing_scheme: string;
  payer: string;
};
export type PricingSchemePayerRead = {
  id: string;
  pricing_scheme: string;
  payer: string;
  display_name: string;
};
export type PricingSchemeRead = {
  id: string;
  /** Name of the pricing scheme, this should be unique */
  name: string;
  /** Description of the pricing scheme */
  description?: string;
  /** Date the pricing scheme goes into effect */
  start_date: string;
  /** Date the pricing scheme is no longer in effect */
  end_date: string;
  /** Space types that this pricing scheme applies to */
  space_types?: SpaceTypeEnum[];
  /** Base fee in cents */
  base_fee_cents?: number | null;
  /** Rate in cents per loaded mile */
  loaded_mileage_rate_cents?: number | null;
  /** Rate in cents per mile for long distance trips */
  long_distance_mileage_rate_cents?: number | null;
  /** Minimum distance in miles for long distance trips */
  long_distance_mileage_start?: number | null;
  /** Number of free loaded miles */
  free_loaded_miles?: number | null;
  /** Rate in cents per unloaded mile */
  unloaded_mileage_rate_cents?: number | null;
  /** Number of free unloaded miles */
  free_unloaded_miles?: number | null;
  /** Rate in cents per wait time period */
  wait_time_rate_cents?: number | null;
  /** Wait time period in minutes */
  wait_time_period_minutes?: number | null;
  /** Number of free wait time minutes */
  wait_time_free_minutes?: number | null;
  /** Fee in cents for late cancellations */
  late_cancellation_fee_cents?: number | null;
  /** Minutes before scheduled pickup time for late cancellations */
  late_cancellation_period_minutes?: number | null;
  /** Fee in cents for no shows */
  no_show_fee_cents?: number | null;
  /** Fee in cents for stairs */
  stair_fee_cents?: number | null;
  /** Minimum number of steps for the stair fee to be applied */
  minimum_steps?: number | null;
  /** Type of stair fee, flat fee or per step fee */
  stair_fee_type?: StairFeeTypeEnum | BlankEnum;
  /** Fee in cents for each additional attendant */
  additional_attendant_rate_cents?: number | null;
  /** Fee in cents for each additional passenger */
  additional_passenger_rate_cents?: number | null;
  /** Fee in cents for bariatric passengers */
  bariatric_passenger_fee_cents?: number | null;
  /** Fee in cents for wheelchair rentals */
  wheelchair_rental_fee_cents?: number | null;
  /** Fee in cents for oxygen */
  oxygen_fee_cents?: number | null;
  /** Fee in cents for weekend trips */
  weekend_fee_cents?: number | null;
  /** Fee in cents for after hours trips */
  after_hours_fee_cents?: number | null;
  /** Time before which trips are considered after hours */
  business_hours_start_time?: string | null;
  /** Time after which trips are considered after hours */
  business_hours_end_time?: string | null;
  /** Fee in cents for holiday trips */
  holiday_fee_cents?: number | null;
  /** Holidays that this pricing scheme applies to */
  observed_holidays?: ObservedHolidaysEnum[] | null;
  /** Minimum price in cents for this pricing scheme */
  minimum_price_cents?: number | null;
  /** Type of cancellation fee, flat fee or as a percentage of the trip cost */
  late_cancellation_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Type of no show fee, flat fee or as a percentage of the trip cost */
  no_show_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  payers: PricingSchemePayerRead[];
  /** Fee in cents for passenger with infectious disease */
  infectious_disease_fee_cents?: number | null;
  /** Fee in cents for for same day booking */
  same_day_booking_fee_cents?: number | null;
};
export type PricingSchemeWrite = {
  /** Name of the pricing scheme, this should be unique */
  name: string;
  /** Description of the pricing scheme */
  description?: string;
  /** Date the pricing scheme goes into effect */
  start_date: string;
  /** Date the pricing scheme is no longer in effect */
  end_date: string;
  /** Space types that this pricing scheme applies to */
  space_types?: SpaceTypeEnum[];
  /** Base fee in cents */
  base_fee_cents?: number | null;
  /** Rate in cents per loaded mile */
  loaded_mileage_rate_cents?: number | null;
  /** Rate in cents per mile for long distance trips */
  long_distance_mileage_rate_cents?: number | null;
  /** Minimum distance in miles for long distance trips */
  long_distance_mileage_start?: number | null;
  /** Number of free loaded miles */
  free_loaded_miles?: number | null;
  /** Rate in cents per unloaded mile */
  unloaded_mileage_rate_cents?: number | null;
  /** Number of free unloaded miles */
  free_unloaded_miles?: number | null;
  /** Rate in cents per wait time period */
  wait_time_rate_cents?: number | null;
  /** Wait time period in minutes */
  wait_time_period_minutes?: number | null;
  /** Number of free wait time minutes */
  wait_time_free_minutes?: number | null;
  /** Fee in cents for late cancellations */
  late_cancellation_fee_cents?: number | null;
  /** Minutes before scheduled pickup time for late cancellations */
  late_cancellation_period_minutes?: number | null;
  /** Fee in cents for no shows */
  no_show_fee_cents?: number | null;
  /** Fee in cents for stairs */
  stair_fee_cents?: number | null;
  /** Minimum number of steps for the stair fee to be applied */
  minimum_steps?: number | null;
  /** Type of stair fee, flat fee or per step fee */
  stair_fee_type?: StairFeeTypeEnum | BlankEnum;
  /** Fee in cents for each additional attendant */
  additional_attendant_rate_cents?: number | null;
  /** Fee in cents for each additional passenger */
  additional_passenger_rate_cents?: number | null;
  /** Fee in cents for bariatric passengers */
  bariatric_passenger_fee_cents?: number | null;
  /** Fee in cents for wheelchair rentals */
  wheelchair_rental_fee_cents?: number | null;
  /** Fee in cents for oxygen */
  oxygen_fee_cents?: number | null;
  /** Fee in cents for weekend trips */
  weekend_fee_cents?: number | null;
  /** Fee in cents for after hours trips */
  after_hours_fee_cents?: number | null;
  /** Time before which trips are considered after hours */
  business_hours_start_time?: string | null;
  /** Time after which trips are considered after hours */
  business_hours_end_time?: string | null;
  /** Fee in cents for holiday trips */
  holiday_fee_cents?: number | null;
  /** Holidays that this pricing scheme applies to */
  observed_holidays?: ObservedHolidaysEnum[] | null;
  /** Minimum price in cents for this pricing scheme */
  minimum_price_cents?: number | null;
  /** Type of cancellation fee, flat fee or as a percentage of the trip cost */
  late_cancellation_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Type of no show fee, flat fee or as a percentage of the trip cost */
  no_show_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  payer_ids: string[];
  /** Fee in cents for passenger with infectious disease */
  infectious_disease_fee_cents?: number | null;
  /** Fee in cents for for same day booking */
  same_day_booking_fee_cents?: number | null;
};
export type PaginatedPricingSchemeList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingScheme[];
};
export type PaginatedPricingSchemeListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingSchemeRead[];
};
export type PaginatedPricingSchemeListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingSchemeWrite[];
};
export type PricingPricingSchemesListPricingSchemePayersPayerIdErrorComponent =
  {
    attr: 'pricing_scheme_payers__payer_id';
    code: 'invalid_choice';
    detail: string;
  };
export type PricingPricingSchemesListError = {
  attr: 'pricing_scheme_payers__payer_id';
} & PricingPricingSchemesListPricingSchemePayersPayerIdErrorComponent;
export type PricingPricingSchemesListValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingSchemesListError[];
};
export type PricingPricingSchemesListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingSchemesListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PricingPricingSchemesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingSchemesCreateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingSchemesCreateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingSchemesCreateStartDateErrorComponent = {
  attr: 'start_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreateEndDateErrorComponent = {
  attr: 'end_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreateSpaceTypesErrorComponent = {
  attr: 'space_types';
  code: 'empty' | 'not_a_list' | 'null';
  detail: string;
};
export type PricingPricingSchemesCreateSpaceTypesIndexErrorComponent = {
  attr: 'space_types.INDEX';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreateBaseFeeCentsErrorComponent = {
  attr: 'base_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateLoadedMileageRateCentsErrorComponent = {
  attr: 'loaded_mileage_rate_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateLongDistanceMileageRateCentsErrorComponent =
  {
    attr: 'long_distance_mileage_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateLongDistanceMileageStartErrorComponent =
  {
    attr: 'long_distance_mileage_start';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateFreeLoadedMilesErrorComponent = {
  attr: 'free_loaded_miles';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateUnloadedMileageRateCentsErrorComponent =
  {
    attr: 'unloaded_mileage_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateFreeUnloadedMilesErrorComponent = {
  attr: 'free_unloaded_miles';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateWaitTimeRateCentsErrorComponent = {
  attr: 'wait_time_rate_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateWaitTimePeriodMinutesErrorComponent = {
  attr: 'wait_time_period_minutes';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateWaitTimeFreeMinutesErrorComponent = {
  attr: 'wait_time_free_minutes';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateLateCancellationFeeCentsErrorComponent =
  {
    attr: 'late_cancellation_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateLateCancellationPeriodMinutesErrorComponent =
  {
    attr: 'late_cancellation_period_minutes';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateNoShowFeeCentsErrorComponent = {
  attr: 'no_show_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateStairFeeCentsErrorComponent = {
  attr: 'stair_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateMinimumStepsErrorComponent = {
  attr: 'minimum_steps';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateStairFeeTypeErrorComponent = {
  attr: 'stair_fee_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PricingPricingSchemesCreateAdditionalAttendantRateCentsErrorComponent =
  {
    attr: 'additional_attendant_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateAdditionalPassengerRateCentsErrorComponent =
  {
    attr: 'additional_passenger_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateBariatricPassengerFeeCentsErrorComponent =
  {
    attr: 'bariatric_passenger_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateWheelchairRentalFeeCentsErrorComponent =
  {
    attr: 'wheelchair_rental_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateOxygenFeeCentsErrorComponent = {
  attr: 'oxygen_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateWeekendFeeCentsErrorComponent = {
  attr: 'weekend_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateAfterHoursFeeCentsErrorComponent = {
  attr: 'after_hours_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateBusinessHoursStartTimeErrorComponent = {
  attr: 'business_hours_start_time';
  code: 'invalid';
  detail: string;
};
export type PricingPricingSchemesCreateBusinessHoursEndTimeErrorComponent = {
  attr: 'business_hours_end_time';
  code: 'invalid';
  detail: string;
};
export type PricingPricingSchemesCreateHolidayFeeCentsErrorComponent = {
  attr: 'holiday_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateObservedHolidaysErrorComponent = {
  attr: 'observed_holidays';
  code: 'not_a_list';
  detail: string;
};
export type PricingPricingSchemesCreateObservedHolidaysIndexErrorComponent = {
  attr: 'observed_holidays.INDEX';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreateMinimumPriceCentsErrorComponent = {
  attr: 'minimum_price_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateLateCancellationFeeTypeErrorComponent = {
  attr: 'late_cancellation_fee_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PricingPricingSchemesCreateNoShowFeeTypeErrorComponent = {
  attr: 'no_show_fee_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PricingPricingSchemesCreatePayersIndexPricingSchemeErrorComponent =
  {
    attr: 'payers.INDEX.pricing_scheme';
    code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingSchemesCreatePayersIndexPayerErrorComponent = {
  attr: 'payers.INDEX.payer';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'empty' | 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesCreateInfectiousDiseaseFeeCentsErrorComponent =
  {
    attr: 'infectious_disease_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesCreateSameDayBookingFeeCentsErrorComponent = {
  attr: 'same_day_booking_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingSchemesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'name';
    } & PricingPricingSchemesCreateNameErrorComponent)
  | ({
      attr: 'description';
    } & PricingPricingSchemesCreateDescriptionErrorComponent)
  | ({
      attr: 'start_date';
    } & PricingPricingSchemesCreateStartDateErrorComponent)
  | ({
      attr: 'end_date';
    } & PricingPricingSchemesCreateEndDateErrorComponent)
  | ({
      attr: 'space_types';
    } & PricingPricingSchemesCreateSpaceTypesErrorComponent)
  | ({
      attr: 'space_types.INDEX';
    } & PricingPricingSchemesCreateSpaceTypesIndexErrorComponent)
  | ({
      attr: 'base_fee_cents';
    } & PricingPricingSchemesCreateBaseFeeCentsErrorComponent)
  | ({
      attr: 'loaded_mileage_rate_cents';
    } & PricingPricingSchemesCreateLoadedMileageRateCentsErrorComponent)
  | ({
      attr: 'long_distance_mileage_rate_cents';
    } & PricingPricingSchemesCreateLongDistanceMileageRateCentsErrorComponent)
  | ({
      attr: 'long_distance_mileage_start';
    } & PricingPricingSchemesCreateLongDistanceMileageStartErrorComponent)
  | ({
      attr: 'free_loaded_miles';
    } & PricingPricingSchemesCreateFreeLoadedMilesErrorComponent)
  | ({
      attr: 'unloaded_mileage_rate_cents';
    } & PricingPricingSchemesCreateUnloadedMileageRateCentsErrorComponent)
  | ({
      attr: 'free_unloaded_miles';
    } & PricingPricingSchemesCreateFreeUnloadedMilesErrorComponent)
  | ({
      attr: 'wait_time_rate_cents';
    } & PricingPricingSchemesCreateWaitTimeRateCentsErrorComponent)
  | ({
      attr: 'wait_time_period_minutes';
    } & PricingPricingSchemesCreateWaitTimePeriodMinutesErrorComponent)
  | ({
      attr: 'wait_time_free_minutes';
    } & PricingPricingSchemesCreateWaitTimeFreeMinutesErrorComponent)
  | ({
      attr: 'late_cancellation_fee_cents';
    } & PricingPricingSchemesCreateLateCancellationFeeCentsErrorComponent)
  | ({
      attr: 'late_cancellation_period_minutes';
    } & PricingPricingSchemesCreateLateCancellationPeriodMinutesErrorComponent)
  | ({
      attr: 'no_show_fee_cents';
    } & PricingPricingSchemesCreateNoShowFeeCentsErrorComponent)
  | ({
      attr: 'stair_fee_cents';
    } & PricingPricingSchemesCreateStairFeeCentsErrorComponent)
  | ({
      attr: 'minimum_steps';
    } & PricingPricingSchemesCreateMinimumStepsErrorComponent)
  | ({
      attr: 'stair_fee_type';
    } & PricingPricingSchemesCreateStairFeeTypeErrorComponent)
  | ({
      attr: 'additional_attendant_rate_cents';
    } & PricingPricingSchemesCreateAdditionalAttendantRateCentsErrorComponent)
  | ({
      attr: 'additional_passenger_rate_cents';
    } & PricingPricingSchemesCreateAdditionalPassengerRateCentsErrorComponent)
  | ({
      attr: 'bariatric_passenger_fee_cents';
    } & PricingPricingSchemesCreateBariatricPassengerFeeCentsErrorComponent)
  | ({
      attr: 'wheelchair_rental_fee_cents';
    } & PricingPricingSchemesCreateWheelchairRentalFeeCentsErrorComponent)
  | ({
      attr: 'oxygen_fee_cents';
    } & PricingPricingSchemesCreateOxygenFeeCentsErrorComponent)
  | ({
      attr: 'weekend_fee_cents';
    } & PricingPricingSchemesCreateWeekendFeeCentsErrorComponent)
  | ({
      attr: 'after_hours_fee_cents';
    } & PricingPricingSchemesCreateAfterHoursFeeCentsErrorComponent)
  | ({
      attr: 'business_hours_start_time';
    } & PricingPricingSchemesCreateBusinessHoursStartTimeErrorComponent)
  | ({
      attr: 'business_hours_end_time';
    } & PricingPricingSchemesCreateBusinessHoursEndTimeErrorComponent)
  | ({
      attr: 'holiday_fee_cents';
    } & PricingPricingSchemesCreateHolidayFeeCentsErrorComponent)
  | ({
      attr: 'observed_holidays';
    } & PricingPricingSchemesCreateObservedHolidaysErrorComponent)
  | ({
      attr: 'observed_holidays.INDEX';
    } & PricingPricingSchemesCreateObservedHolidaysIndexErrorComponent)
  | ({
      attr: 'minimum_price_cents';
    } & PricingPricingSchemesCreateMinimumPriceCentsErrorComponent)
  | ({
      attr: 'late_cancellation_fee_type';
    } & PricingPricingSchemesCreateLateCancellationFeeTypeErrorComponent)
  | ({
      attr: 'no_show_fee_type';
    } & PricingPricingSchemesCreateNoShowFeeTypeErrorComponent)
  | ({
      attr: 'payers.INDEX.pricing_scheme';
    } & PricingPricingSchemesCreatePayersIndexPricingSchemeErrorComponent)
  | ({
      attr: 'payers.INDEX.payer';
    } & PricingPricingSchemesCreatePayersIndexPayerErrorComponent)
  | ({
      attr: 'payer_ids';
    } & PricingPricingSchemesCreatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & PricingPricingSchemesCreatePayerIdsIndexErrorComponent)
  | ({
      attr: 'infectious_disease_fee_cents';
    } & PricingPricingSchemesCreateInfectiousDiseaseFeeCentsErrorComponent)
  | ({
      attr: 'same_day_booking_fee_cents';
    } & PricingPricingSchemesCreateSameDayBookingFeeCentsErrorComponent);
export type PricingPricingSchemesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingSchemesCreateError[];
};
export type PricingPricingSchemesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingSchemesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PricingPricingSchemesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingPricingSchemesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateNameErrorComponent = {
  attr: 'name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateStartDateErrorComponent = {
  attr: 'start_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateEndDateErrorComponent = {
  attr: 'end_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateSpaceTypesErrorComponent = {
  attr: 'space_types';
  code: 'empty' | 'not_a_list' | 'null';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateSpaceTypesIndexErrorComponent = {
  attr: 'space_types.INDEX';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateBaseFeeCentsErrorComponent = {
  attr: 'base_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateLoadedMileageRateCentsErrorComponent =
  {
    attr: 'loaded_mileage_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateLongDistanceMileageRateCentsErrorComponent =
  {
    attr: 'long_distance_mileage_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateLongDistanceMileageStartErrorComponent =
  {
    attr: 'long_distance_mileage_start';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateFreeLoadedMilesErrorComponent = {
  attr: 'free_loaded_miles';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateUnloadedMileageRateCentsErrorComponent =
  {
    attr: 'unloaded_mileage_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateFreeUnloadedMilesErrorComponent =
  {
    attr: 'free_unloaded_miles';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateWaitTimeRateCentsErrorComponent =
  {
    attr: 'wait_time_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateWaitTimePeriodMinutesErrorComponent =
  {
    attr: 'wait_time_period_minutes';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateWaitTimeFreeMinutesErrorComponent =
  {
    attr: 'wait_time_free_minutes';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateLateCancellationFeeCentsErrorComponent =
  {
    attr: 'late_cancellation_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateLateCancellationPeriodMinutesErrorComponent =
  {
    attr: 'late_cancellation_period_minutes';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateNoShowFeeCentsErrorComponent = {
  attr: 'no_show_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateStairFeeCentsErrorComponent = {
  attr: 'stair_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateMinimumStepsErrorComponent = {
  attr: 'minimum_steps';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateStairFeeTypeErrorComponent = {
  attr: 'stair_fee_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateAdditionalAttendantRateCentsErrorComponent =
  {
    attr: 'additional_attendant_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateAdditionalPassengerRateCentsErrorComponent =
  {
    attr: 'additional_passenger_rate_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateBariatricPassengerFeeCentsErrorComponent =
  {
    attr: 'bariatric_passenger_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateWheelchairRentalFeeCentsErrorComponent =
  {
    attr: 'wheelchair_rental_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateOxygenFeeCentsErrorComponent = {
  attr: 'oxygen_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateWeekendFeeCentsErrorComponent = {
  attr: 'weekend_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateAfterHoursFeeCentsErrorComponent =
  {
    attr: 'after_hours_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateBusinessHoursStartTimeErrorComponent =
  {
    attr: 'business_hours_start_time';
    code: 'invalid';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateBusinessHoursEndTimeErrorComponent =
  {
    attr: 'business_hours_end_time';
    code: 'invalid';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateHolidayFeeCentsErrorComponent = {
  attr: 'holiday_fee_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateObservedHolidaysErrorComponent = {
  attr: 'observed_holidays';
  code: 'not_a_list';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateObservedHolidaysIndexErrorComponent =
  {
    attr: 'observed_holidays.INDEX';
    code: 'invalid_choice' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateMinimumPriceCentsErrorComponent =
  {
    attr: 'minimum_price_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateLateCancellationFeeTypeErrorComponent =
  {
    attr: 'late_cancellation_fee_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateNoShowFeeTypeErrorComponent = {
  attr: 'no_show_fee_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type PricingPricingSchemesPartialUpdatePayersIndexPricingSchemeErrorComponent =
  {
    attr: 'payers.INDEX.pricing_scheme';
    code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdatePayersIndexPayerErrorComponent = {
  attr: 'payers.INDEX.payer';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesPartialUpdatePayerIdsErrorComponent = {
  attr: 'payer_ids';
  code: 'empty' | 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesPartialUpdatePayerIdsIndexErrorComponent = {
  attr: 'payer_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingSchemesPartialUpdateInfectiousDiseaseFeeCentsErrorComponent =
  {
    attr: 'infectious_disease_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateSameDayBookingFeeCentsErrorComponent =
  {
    attr: 'same_day_booking_fee_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type PricingPricingSchemesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingSchemesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'name';
    } & PricingPricingSchemesPartialUpdateNameErrorComponent)
  | ({
      attr: 'description';
    } & PricingPricingSchemesPartialUpdateDescriptionErrorComponent)
  | ({
      attr: 'start_date';
    } & PricingPricingSchemesPartialUpdateStartDateErrorComponent)
  | ({
      attr: 'end_date';
    } & PricingPricingSchemesPartialUpdateEndDateErrorComponent)
  | ({
      attr: 'space_types';
    } & PricingPricingSchemesPartialUpdateSpaceTypesErrorComponent)
  | ({
      attr: 'space_types.INDEX';
    } & PricingPricingSchemesPartialUpdateSpaceTypesIndexErrorComponent)
  | ({
      attr: 'base_fee_cents';
    } & PricingPricingSchemesPartialUpdateBaseFeeCentsErrorComponent)
  | ({
      attr: 'loaded_mileage_rate_cents';
    } & PricingPricingSchemesPartialUpdateLoadedMileageRateCentsErrorComponent)
  | ({
      attr: 'long_distance_mileage_rate_cents';
    } & PricingPricingSchemesPartialUpdateLongDistanceMileageRateCentsErrorComponent)
  | ({
      attr: 'long_distance_mileage_start';
    } & PricingPricingSchemesPartialUpdateLongDistanceMileageStartErrorComponent)
  | ({
      attr: 'free_loaded_miles';
    } & PricingPricingSchemesPartialUpdateFreeLoadedMilesErrorComponent)
  | ({
      attr: 'unloaded_mileage_rate_cents';
    } & PricingPricingSchemesPartialUpdateUnloadedMileageRateCentsErrorComponent)
  | ({
      attr: 'free_unloaded_miles';
    } & PricingPricingSchemesPartialUpdateFreeUnloadedMilesErrorComponent)
  | ({
      attr: 'wait_time_rate_cents';
    } & PricingPricingSchemesPartialUpdateWaitTimeRateCentsErrorComponent)
  | ({
      attr: 'wait_time_period_minutes';
    } & PricingPricingSchemesPartialUpdateWaitTimePeriodMinutesErrorComponent)
  | ({
      attr: 'wait_time_free_minutes';
    } & PricingPricingSchemesPartialUpdateWaitTimeFreeMinutesErrorComponent)
  | ({
      attr: 'late_cancellation_fee_cents';
    } & PricingPricingSchemesPartialUpdateLateCancellationFeeCentsErrorComponent)
  | ({
      attr: 'late_cancellation_period_minutes';
    } & PricingPricingSchemesPartialUpdateLateCancellationPeriodMinutesErrorComponent)
  | ({
      attr: 'no_show_fee_cents';
    } & PricingPricingSchemesPartialUpdateNoShowFeeCentsErrorComponent)
  | ({
      attr: 'stair_fee_cents';
    } & PricingPricingSchemesPartialUpdateStairFeeCentsErrorComponent)
  | ({
      attr: 'minimum_steps';
    } & PricingPricingSchemesPartialUpdateMinimumStepsErrorComponent)
  | ({
      attr: 'stair_fee_type';
    } & PricingPricingSchemesPartialUpdateStairFeeTypeErrorComponent)
  | ({
      attr: 'additional_attendant_rate_cents';
    } & PricingPricingSchemesPartialUpdateAdditionalAttendantRateCentsErrorComponent)
  | ({
      attr: 'additional_passenger_rate_cents';
    } & PricingPricingSchemesPartialUpdateAdditionalPassengerRateCentsErrorComponent)
  | ({
      attr: 'bariatric_passenger_fee_cents';
    } & PricingPricingSchemesPartialUpdateBariatricPassengerFeeCentsErrorComponent)
  | ({
      attr: 'wheelchair_rental_fee_cents';
    } & PricingPricingSchemesPartialUpdateWheelchairRentalFeeCentsErrorComponent)
  | ({
      attr: 'oxygen_fee_cents';
    } & PricingPricingSchemesPartialUpdateOxygenFeeCentsErrorComponent)
  | ({
      attr: 'weekend_fee_cents';
    } & PricingPricingSchemesPartialUpdateWeekendFeeCentsErrorComponent)
  | ({
      attr: 'after_hours_fee_cents';
    } & PricingPricingSchemesPartialUpdateAfterHoursFeeCentsErrorComponent)
  | ({
      attr: 'business_hours_start_time';
    } & PricingPricingSchemesPartialUpdateBusinessHoursStartTimeErrorComponent)
  | ({
      attr: 'business_hours_end_time';
    } & PricingPricingSchemesPartialUpdateBusinessHoursEndTimeErrorComponent)
  | ({
      attr: 'holiday_fee_cents';
    } & PricingPricingSchemesPartialUpdateHolidayFeeCentsErrorComponent)
  | ({
      attr: 'observed_holidays';
    } & PricingPricingSchemesPartialUpdateObservedHolidaysErrorComponent)
  | ({
      attr: 'observed_holidays.INDEX';
    } & PricingPricingSchemesPartialUpdateObservedHolidaysIndexErrorComponent)
  | ({
      attr: 'minimum_price_cents';
    } & PricingPricingSchemesPartialUpdateMinimumPriceCentsErrorComponent)
  | ({
      attr: 'late_cancellation_fee_type';
    } & PricingPricingSchemesPartialUpdateLateCancellationFeeTypeErrorComponent)
  | ({
      attr: 'no_show_fee_type';
    } & PricingPricingSchemesPartialUpdateNoShowFeeTypeErrorComponent)
  | ({
      attr: 'payers.INDEX.pricing_scheme';
    } & PricingPricingSchemesPartialUpdatePayersIndexPricingSchemeErrorComponent)
  | ({
      attr: 'payers.INDEX.payer';
    } & PricingPricingSchemesPartialUpdatePayersIndexPayerErrorComponent)
  | ({
      attr: 'payer_ids';
    } & PricingPricingSchemesPartialUpdatePayerIdsErrorComponent)
  | ({
      attr: 'payer_ids.INDEX';
    } & PricingPricingSchemesPartialUpdatePayerIdsIndexErrorComponent)
  | ({
      attr: 'infectious_disease_fee_cents';
    } & PricingPricingSchemesPartialUpdateInfectiousDiseaseFeeCentsErrorComponent)
  | ({
      attr: 'same_day_booking_fee_cents';
    } & PricingPricingSchemesPartialUpdateSameDayBookingFeeCentsErrorComponent);
export type PricingPricingSchemesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingSchemesPartialUpdateError[];
};
export type PricingPricingSchemesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingSchemesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPricingScheme = {
  /** Name of the pricing scheme, this should be unique */
  name?: string;
  /** Description of the pricing scheme */
  description?: string;
  /** Date the pricing scheme goes into effect */
  start_date?: string;
  /** Date the pricing scheme is no longer in effect */
  end_date?: string;
  /** Space types that this pricing scheme applies to */
  space_types?: SpaceTypeEnum[];
  /** Base fee in cents */
  base_fee_cents?: number | null;
  /** Rate in cents per loaded mile */
  loaded_mileage_rate_cents?: number | null;
  /** Rate in cents per mile for long distance trips */
  long_distance_mileage_rate_cents?: number | null;
  /** Minimum distance in miles for long distance trips */
  long_distance_mileage_start?: number | null;
  /** Number of free loaded miles */
  free_loaded_miles?: number | null;
  /** Rate in cents per unloaded mile */
  unloaded_mileage_rate_cents?: number | null;
  /** Number of free unloaded miles */
  free_unloaded_miles?: number | null;
  /** Rate in cents per wait time period */
  wait_time_rate_cents?: number | null;
  /** Wait time period in minutes */
  wait_time_period_minutes?: number | null;
  /** Number of free wait time minutes */
  wait_time_free_minutes?: number | null;
  /** Fee in cents for late cancellations */
  late_cancellation_fee_cents?: number | null;
  /** Minutes before scheduled pickup time for late cancellations */
  late_cancellation_period_minutes?: number | null;
  /** Fee in cents for no shows */
  no_show_fee_cents?: number | null;
  /** Fee in cents for stairs */
  stair_fee_cents?: number | null;
  /** Minimum number of steps for the stair fee to be applied */
  minimum_steps?: number | null;
  /** Type of stair fee, flat fee or per step fee */
  stair_fee_type?: StairFeeTypeEnum | BlankEnum;
  /** Fee in cents for each additional attendant */
  additional_attendant_rate_cents?: number | null;
  /** Fee in cents for each additional passenger */
  additional_passenger_rate_cents?: number | null;
  /** Fee in cents for bariatric passengers */
  bariatric_passenger_fee_cents?: number | null;
  /** Fee in cents for wheelchair rentals */
  wheelchair_rental_fee_cents?: number | null;
  /** Fee in cents for oxygen */
  oxygen_fee_cents?: number | null;
  /** Fee in cents for weekend trips */
  weekend_fee_cents?: number | null;
  /** Fee in cents for after hours trips */
  after_hours_fee_cents?: number | null;
  /** Time before which trips are considered after hours */
  business_hours_start_time?: string | null;
  /** Time after which trips are considered after hours */
  business_hours_end_time?: string | null;
  /** Fee in cents for holiday trips */
  holiday_fee_cents?: number | null;
  /** Holidays that this pricing scheme applies to */
  observed_holidays?: ObservedHolidaysEnum[] | null;
  /** Minimum price in cents for this pricing scheme */
  minimum_price_cents?: number | null;
  /** Type of cancellation fee, flat fee or as a percentage of the trip cost */
  late_cancellation_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Type of no show fee, flat fee or as a percentage of the trip cost */
  no_show_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Fee in cents for passenger with infectious disease */
  infectious_disease_fee_cents?: number | null;
  /** Fee in cents for for same day booking */
  same_day_booking_fee_cents?: number | null;
};
export type PatchedPricingSchemeRead = {
  id?: string;
  /** Name of the pricing scheme, this should be unique */
  name?: string;
  /** Description of the pricing scheme */
  description?: string;
  /** Date the pricing scheme goes into effect */
  start_date?: string;
  /** Date the pricing scheme is no longer in effect */
  end_date?: string;
  /** Space types that this pricing scheme applies to */
  space_types?: SpaceTypeEnum[];
  /** Base fee in cents */
  base_fee_cents?: number | null;
  /** Rate in cents per loaded mile */
  loaded_mileage_rate_cents?: number | null;
  /** Rate in cents per mile for long distance trips */
  long_distance_mileage_rate_cents?: number | null;
  /** Minimum distance in miles for long distance trips */
  long_distance_mileage_start?: number | null;
  /** Number of free loaded miles */
  free_loaded_miles?: number | null;
  /** Rate in cents per unloaded mile */
  unloaded_mileage_rate_cents?: number | null;
  /** Number of free unloaded miles */
  free_unloaded_miles?: number | null;
  /** Rate in cents per wait time period */
  wait_time_rate_cents?: number | null;
  /** Wait time period in minutes */
  wait_time_period_minutes?: number | null;
  /** Number of free wait time minutes */
  wait_time_free_minutes?: number | null;
  /** Fee in cents for late cancellations */
  late_cancellation_fee_cents?: number | null;
  /** Minutes before scheduled pickup time for late cancellations */
  late_cancellation_period_minutes?: number | null;
  /** Fee in cents for no shows */
  no_show_fee_cents?: number | null;
  /** Fee in cents for stairs */
  stair_fee_cents?: number | null;
  /** Minimum number of steps for the stair fee to be applied */
  minimum_steps?: number | null;
  /** Type of stair fee, flat fee or per step fee */
  stair_fee_type?: StairFeeTypeEnum | BlankEnum;
  /** Fee in cents for each additional attendant */
  additional_attendant_rate_cents?: number | null;
  /** Fee in cents for each additional passenger */
  additional_passenger_rate_cents?: number | null;
  /** Fee in cents for bariatric passengers */
  bariatric_passenger_fee_cents?: number | null;
  /** Fee in cents for wheelchair rentals */
  wheelchair_rental_fee_cents?: number | null;
  /** Fee in cents for oxygen */
  oxygen_fee_cents?: number | null;
  /** Fee in cents for weekend trips */
  weekend_fee_cents?: number | null;
  /** Fee in cents for after hours trips */
  after_hours_fee_cents?: number | null;
  /** Time before which trips are considered after hours */
  business_hours_start_time?: string | null;
  /** Time after which trips are considered after hours */
  business_hours_end_time?: string | null;
  /** Fee in cents for holiday trips */
  holiday_fee_cents?: number | null;
  /** Holidays that this pricing scheme applies to */
  observed_holidays?: ObservedHolidaysEnum[] | null;
  /** Minimum price in cents for this pricing scheme */
  minimum_price_cents?: number | null;
  /** Type of cancellation fee, flat fee or as a percentage of the trip cost */
  late_cancellation_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Type of no show fee, flat fee or as a percentage of the trip cost */
  no_show_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  payers?: PricingSchemePayerRead[];
  /** Fee in cents for passenger with infectious disease */
  infectious_disease_fee_cents?: number | null;
  /** Fee in cents for for same day booking */
  same_day_booking_fee_cents?: number | null;
};
export type PatchedPricingSchemeWrite = {
  /** Name of the pricing scheme, this should be unique */
  name?: string;
  /** Description of the pricing scheme */
  description?: string;
  /** Date the pricing scheme goes into effect */
  start_date?: string;
  /** Date the pricing scheme is no longer in effect */
  end_date?: string;
  /** Space types that this pricing scheme applies to */
  space_types?: SpaceTypeEnum[];
  /** Base fee in cents */
  base_fee_cents?: number | null;
  /** Rate in cents per loaded mile */
  loaded_mileage_rate_cents?: number | null;
  /** Rate in cents per mile for long distance trips */
  long_distance_mileage_rate_cents?: number | null;
  /** Minimum distance in miles for long distance trips */
  long_distance_mileage_start?: number | null;
  /** Number of free loaded miles */
  free_loaded_miles?: number | null;
  /** Rate in cents per unloaded mile */
  unloaded_mileage_rate_cents?: number | null;
  /** Number of free unloaded miles */
  free_unloaded_miles?: number | null;
  /** Rate in cents per wait time period */
  wait_time_rate_cents?: number | null;
  /** Wait time period in minutes */
  wait_time_period_minutes?: number | null;
  /** Number of free wait time minutes */
  wait_time_free_minutes?: number | null;
  /** Fee in cents for late cancellations */
  late_cancellation_fee_cents?: number | null;
  /** Minutes before scheduled pickup time for late cancellations */
  late_cancellation_period_minutes?: number | null;
  /** Fee in cents for no shows */
  no_show_fee_cents?: number | null;
  /** Fee in cents for stairs */
  stair_fee_cents?: number | null;
  /** Minimum number of steps for the stair fee to be applied */
  minimum_steps?: number | null;
  /** Type of stair fee, flat fee or per step fee */
  stair_fee_type?: StairFeeTypeEnum | BlankEnum;
  /** Fee in cents for each additional attendant */
  additional_attendant_rate_cents?: number | null;
  /** Fee in cents for each additional passenger */
  additional_passenger_rate_cents?: number | null;
  /** Fee in cents for bariatric passengers */
  bariatric_passenger_fee_cents?: number | null;
  /** Fee in cents for wheelchair rentals */
  wheelchair_rental_fee_cents?: number | null;
  /** Fee in cents for oxygen */
  oxygen_fee_cents?: number | null;
  /** Fee in cents for weekend trips */
  weekend_fee_cents?: number | null;
  /** Fee in cents for after hours trips */
  after_hours_fee_cents?: number | null;
  /** Time before which trips are considered after hours */
  business_hours_start_time?: string | null;
  /** Time after which trips are considered after hours */
  business_hours_end_time?: string | null;
  /** Fee in cents for holiday trips */
  holiday_fee_cents?: number | null;
  /** Holidays that this pricing scheme applies to */
  observed_holidays?: ObservedHolidaysEnum[] | null;
  /** Minimum price in cents for this pricing scheme */
  minimum_price_cents?: number | null;
  /** Type of cancellation fee, flat fee or as a percentage of the trip cost */
  late_cancellation_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  /** Type of no show fee, flat fee or as a percentage of the trip cost */
  no_show_fee_type?: NoShowFeeTypeEnum | BlankEnum;
  payer_ids?: string[];
  /** Fee in cents for passenger with infectious disease */
  infectious_disease_fee_cents?: number | null;
  /** Fee in cents for for same day booking */
  same_day_booking_fee_cents?: number | null;
};
export type PricingPricingSchemesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingTypeEnum =
  | 'Base'
  | 'Wait'
  | 'Stair'
  | 'Oxygen'
  | 'Loaded Mileage'
  | 'Unloaded Mileage'
  | 'Canceled';
export type RoundingEnum = 'none' | 'up' | 'down' | 'nearest';
export type UnitEnum =
  | 'Trip'
  | 'Minute'
  | 'Hour'
  | 'Quarter Hour'
  | 'Half Hour'
  | 'Mile'
  | 'Half Mile'
  | 'Quarter Mile'
  | 'Stair';
export type PricingTypeSelection = {
  pricing_type: PricingTypeEnum;
  /** Initial rate for this value in cents */
  initial_rate?: number;
  rounding: RoundingEnum;
  unit: UnitEnum;
  maximum_charge?: number | null;
};
export type PricingTypeSelectionRead = {
  id: string;
  pricing_type: PricingTypeEnum;
  /** Initial rate for this value in cents */
  initial_rate?: number;
  rounding: RoundingEnum;
  unit: UnitEnum;
  maximum_charge?: number | null;
};
export type PricingTypeSelectionWrite = {
  pricing_model_id: string;
  pricing_type: PricingTypeEnum;
  /** Initial rate for this value in cents */
  initial_rate?: number;
  rounding: RoundingEnum;
  unit: UnitEnum;
  maximum_charge?: number | null;
};
export type PaginatedPricingTypeSelectionList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingTypeSelection[];
};
export type PaginatedPricingTypeSelectionListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingTypeSelectionRead[];
};
export type PaginatedPricingTypeSelectionListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PricingTypeSelectionWrite[];
};
export type PricingPricingTypesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingPricingTypesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingTypesCreatePricingModelIdErrorComponent = {
  attr: 'pricing_model_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesCreatePricingTypeErrorComponent = {
  attr: 'pricing_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesCreateInitialRateErrorComponent = {
  attr: 'initial_rate';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type PricingPricingTypesCreateRoundingErrorComponent = {
  attr: 'rounding';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesCreateUnitErrorComponent = {
  attr: 'unit';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesCreateMaximumChargeErrorComponent = {
  attr: 'maximum_charge';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type PricingPricingTypesCreateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingTypesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'pricing_model_id';
    } & PricingPricingTypesCreatePricingModelIdErrorComponent)
  | ({
      attr: 'pricing_type';
    } & PricingPricingTypesCreatePricingTypeErrorComponent)
  | ({
      attr: 'initial_rate';
    } & PricingPricingTypesCreateInitialRateErrorComponent)
  | ({
      attr: 'rounding';
    } & PricingPricingTypesCreateRoundingErrorComponent)
  | ({
      attr: 'unit';
    } & PricingPricingTypesCreateUnitErrorComponent)
  | ({
      attr: 'maximum_charge';
    } & PricingPricingTypesCreateMaximumChargeErrorComponent);
export type PricingPricingTypesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingTypesCreateError[];
};
export type PricingPricingTypesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingTypesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PricingPricingTypesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PricingPricingTypesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type PricingPricingTypesPartialUpdatePricingModelIdErrorComponent = {
  attr: 'pricing_model_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesPartialUpdatePricingTypeErrorComponent = {
  attr: 'pricing_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesPartialUpdateInitialRateErrorComponent = {
  attr: 'initial_rate';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type PricingPricingTypesPartialUpdateRoundingErrorComponent = {
  attr: 'rounding';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesPartialUpdateUnitErrorComponent = {
  attr: 'unit';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type PricingPricingTypesPartialUpdateMaximumChargeErrorComponent = {
  attr: 'maximum_charge';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type PricingPricingTypesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & PricingPricingTypesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'pricing_model_id';
    } & PricingPricingTypesPartialUpdatePricingModelIdErrorComponent)
  | ({
      attr: 'pricing_type';
    } & PricingPricingTypesPartialUpdatePricingTypeErrorComponent)
  | ({
      attr: 'initial_rate';
    } & PricingPricingTypesPartialUpdateInitialRateErrorComponent)
  | ({
      attr: 'rounding';
    } & PricingPricingTypesPartialUpdateRoundingErrorComponent)
  | ({
      attr: 'unit';
    } & PricingPricingTypesPartialUpdateUnitErrorComponent)
  | ({
      attr: 'maximum_charge';
    } & PricingPricingTypesPartialUpdateMaximumChargeErrorComponent);
export type PricingPricingTypesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: PricingPricingTypesPartialUpdateError[];
};
export type PricingPricingTypesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & PricingPricingTypesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedPricingTypeSelection = {
  pricing_type?: PricingTypeEnum;
  /** Initial rate for this value in cents */
  initial_rate?: number;
  rounding?: RoundingEnum;
  unit?: UnitEnum;
  maximum_charge?: number | null;
};
export type PatchedPricingTypeSelectionRead = {
  id?: string;
  pricing_type?: PricingTypeEnum;
  /** Initial rate for this value in cents */
  initial_rate?: number;
  rounding?: RoundingEnum;
  unit?: UnitEnum;
  maximum_charge?: number | null;
};
export type PatchedPricingTypeSelectionWrite = {
  pricing_model_id?: string;
  pricing_type?: PricingTypeEnum;
  /** Initial rate for this value in cents */
  initial_rate?: number;
  rounding?: RoundingEnum;
  unit?: UnitEnum;
  maximum_charge?: number | null;
};
export type PricingPricingTypesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type UserProfileDriver = {
  id?: string;
  can_be_scheduled?: boolean;
};
export type UserProfileAttendant = {
  id?: string;
  can_be_scheduled?: boolean;
};
export type UserProfileMembership = {
  id?: string;
  role?: RoleEnum;
  driver?: UserProfileDriver;
  attendant?: UserProfileAttendant;
};
export type UserProfileOrganization = {
  name: string;
  id?: string;
  dispatch_phone_number?: string | null;
  dispatch_text_number?: string | null;
  /** The chat application id for this org. Probably Sendbird */
  chat_application_id?: string | null;
};
export type UserProfileOrganizationRead = {
  name: string;
  id?: string;
  dispatch_phone_number?: string | null;
  dispatch_text_number?: string | null;
  settings: OrganizationSettingsRead;
  /** The chat application id for this org. Probably Sendbird */
  chat_application_id?: string | null;
};
export type UserProfileMembershipRead = {
  id?: string;
  organization: UserProfileOrganizationRead;
  created_at: string;
  role?: RoleEnum;
  driver?: UserProfileDriver;
  attendant?: UserProfileAttendant;
};
export type UserProfile = {
  first_name?: string;
  last_name?: string;
  email: string;
  /** Contact phone number */
  phone_number?: string | null;
  date_joined?: string;
  memberships: UserProfileMembership[];
};
export type UserProfileRead = {
  id: number;
  first_name?: string;
  last_name?: string;
  email: string;
  /** Contact phone number */
  phone_number?: string | null;
  date_joined?: string;
  memberships: UserProfileMembershipRead[];
};
export type ProfileRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type RunBambiRun = {};
export type RunBambiRunRead = {
  id: string;
};
export type RunBambiRunWrite = {
  range_start: string;
  range_end: string;
  trips: string[];
  assignments: string[];
};
export type RunBambiRunCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type RunBambiRunCreateRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type RunBambiRunCreateRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type RunBambiRunCreateTripsErrorComponent = {
  attr: 'trips';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type RunBambiRunCreateTripsIndexErrorComponent = {
  attr: 'trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type RunBambiRunCreateAssignmentsErrorComponent = {
  attr: 'assignments';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type RunBambiRunCreateAssignmentsIndexErrorComponent = {
  attr: 'assignments.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type RunBambiRunCreateError =
  | ({
      attr: 'non_field_errors';
    } & RunBambiRunCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'range_start';
    } & RunBambiRunCreateRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & RunBambiRunCreateRangeEndErrorComponent)
  | ({
      attr: 'trips';
    } & RunBambiRunCreateTripsErrorComponent)
  | ({
      attr: 'trips.INDEX';
    } & RunBambiRunCreateTripsIndexErrorComponent)
  | ({
      attr: 'assignments';
    } & RunBambiRunCreateAssignmentsErrorComponent)
  | ({
      attr: 'assignments.INDEX';
    } & RunBambiRunCreateAssignmentsIndexErrorComponent);
export type RunBambiRunCreateValidationError = {
  type: ValidationErrorEnum;
  errors: RunBambiRunCreateError[];
};
export type RunBambiRunCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & RunBambiRunCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type RunBambiRunTask = {
  /** Message to display to the user */
  message?: string;
};
export type StateEnum =
  | 'PENDING'
  | 'RECEIVED'
  | 'STARTED'
  | 'SUCCESS'
  | 'FAILURE'
  | 'REVOKED'
  | 'REJECTED'
  | 'RETRY'
  | 'IGNORED';
export type RunBambiRunResult = {};
export type RunBambiRunAssignment = {};
export type RunBambiRunAssignedVehicle = {};
export type RunBambiRunAssignedVehicleRead = {
  id: string;
  nickname: string;
  category: string;
};
export type RunBambiRunAssignedDriver = {};
export type RunBambiRunAssignedDriverRead = {
  id: string;
  first_name: string;
  last_name: string;
};
export type RunBambiRunAssignmentRead = {
  id: string;
  start: string;
  end: string;
  vehicle: RunBambiRunAssignedVehicleRead;
  driver: RunBambiRunAssignedDriverRead;
};
export type RunBambiRunTrip = {};
export type RunBambiRunTripPassenger = {};
export type RunBambiRunTripPassengerRead = {
  full_name: string;
  id: string;
};
export type RunBambiRunTripRead = {
  id: string;
  pickup_address: string;
  pickup_latitude: number;
  pickup_longitude: number;
  dropoff_address: string;
  dropoff_latitude: number;
  dropoff_longitude: number;
  passenger: RunBambiRunTripPassengerRead;
  assignment: RunBambiRunAssignmentRead;
  scheduled_pickup_at: string;
  scheduled_dropoff_at: string;
  suggested_pickup_time: string;
  appointment_at: string;
  status: OverrideStatusEnum;
  space_type: SpaceTypeEnum;
  service_type: ServiceTypeEnum;
  estimated_duration_sec: number;
  is_locked: boolean;
  is_non_activated_will_call: boolean;
  wait_time_start: string | null;
  wait_time_end: string | null;
};
export type RunBambiRunReassignedTrip = {};
export type RunBambiRunReassignedTripRead = {
  id: string;
  pickup_address: string;
  pickup_latitude: number;
  pickup_longitude: number;
  dropoff_address: string;
  dropoff_latitude: number;
  dropoff_longitude: number;
  passenger: RunBambiRunTripPassengerRead;
  assignment: RunBambiRunAssignmentRead;
  scheduled_pickup_at: string;
  scheduled_dropoff_at: string;
  suggested_pickup_time: string;
  appointment_at: string;
  status: OverrideStatusEnum;
  space_type: SpaceTypeEnum;
  service_type: ServiceTypeEnum;
  estimated_duration_sec: number;
  is_locked: boolean;
  is_non_activated_will_call: boolean;
  wait_time_start: string | null;
  wait_time_end: string | null;
  old_assignment: RunBambiRunAssignmentRead;
  new_scheduled_pickup_at: string;
  new_scheduled_dropoff_at: string;
};
export type RunBambiRunResultRead = {
  assignments: RunBambiRunAssignmentRead[];
  new_trip_assignments: RunBambiRunTripRead[];
  trip_reassignments: RunBambiRunReassignedTripRead[];
  trips_not_assigned: RunBambiRunTripRead[];
  existing_trip_assignments: RunBambiRunTripRead[];
};
export type RunBambiRunTaskRead = {
  id: string;
  state: StateEnum;
  result: RunBambiRunResultRead;
  /** Message to display to the user */
  message?: string;
};
export type RunBambiRunRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type RunBambiRunApply = {};
export type RunBambiRunApplyWrite = {
  excluded_trips: string[];
};
export type RunBambiRunApplyCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type RunBambiRunApplyCreateExcludedTripsErrorComponent = {
  attr: 'excluded_trips';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type RunBambiRunApplyCreateExcludedTripsIndexErrorComponent = {
  attr: 'excluded_trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type RunBambiRunApplyCreateError =
  | ({
      attr: 'non_field_errors';
    } & RunBambiRunApplyCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'excluded_trips';
    } & RunBambiRunApplyCreateExcludedTripsErrorComponent)
  | ({
      attr: 'excluded_trips.INDEX';
    } & RunBambiRunApplyCreateExcludedTripsIndexErrorComponent);
export type RunBambiRunApplyCreateValidationError = {
  type: ValidationErrorEnum;
  errors: RunBambiRunApplyCreateError[];
};
export type RunBambiRunApplyCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & RunBambiRunApplyCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type SignatureTypeEnum =
  | 'membership'
  | 'passenger'
  | 'guardian'
  | 'facility'
  | 'unable';
export type ContentTypeEnum = 'vehicleinspectionreport' | 'triplocationdetail';
export type SignedItem = {
  content_type: ContentTypeEnum;
  object_id: string;
};
export type Signature = {
  image: string;
  /** type of signature */
  signature_type?: SignatureTypeEnum | BlankEnum;
  signed_items: SignedItem[];
};
export type SignatureRead = {
  id: string;
  organization_id: string;
  membership: string;
  first_name: string;
  last_name: string;
  longitude: number;
  latitude: number;
  location_queried_at: string | null;
  device_requested_at: string | null;
  image: string;
  /** type of signature */
  signature_type?: SignatureTypeEnum | BlankEnum;
  signed_items: SignedItem[];
  created_at: string;
  updated_at: string;
};
export type PaginatedSignatureList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Signature[];
};
export type PaginatedSignatureListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: SignatureRead[];
};
export type SignaturesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type SignaturesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type SignaturesCreateImageErrorComponent = {
  attr: 'image';
  code:
    | 'empty'
    | 'invalid'
    | 'invalid_image'
    | 'max_length'
    | 'no_name'
    | 'null'
    | 'required';
  detail: string;
};
export type SignaturesCreateSignatureTypeErrorComponent = {
  attr: 'signature_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type SignaturesCreateSignedItemsNonFieldErrorsErrorComponent = {
  attr: 'signed_items.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type SignaturesCreateSignedItemsIndexNonFieldErrorsErrorComponent = {
  attr: 'signed_items.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type SignaturesCreateSignedItemsIndexContentTypeErrorComponent = {
  attr: 'signed_items.INDEX.content_type';
  code: 'does_not_exist' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type SignaturesCreateSignedItemsIndexObjectIdErrorComponent = {
  attr: 'signed_items.INDEX.object_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type SignaturesCreateError =
  | ({
      attr: 'non_field_errors';
    } & SignaturesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'image';
    } & SignaturesCreateImageErrorComponent)
  | ({
      attr: 'signature_type';
    } & SignaturesCreateSignatureTypeErrorComponent)
  | ({
      attr: 'signed_items.non_field_errors';
    } & SignaturesCreateSignedItemsNonFieldErrorsErrorComponent)
  | ({
      attr: 'signed_items.INDEX.non_field_errors';
    } & SignaturesCreateSignedItemsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'signed_items.INDEX.content_type';
    } & SignaturesCreateSignedItemsIndexContentTypeErrorComponent)
  | ({
      attr: 'signed_items.INDEX.object_id';
    } & SignaturesCreateSignedItemsIndexObjectIdErrorComponent);
export type SignaturesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: SignaturesCreateError[];
};
export type SignaturesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & SignaturesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type SignaturesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type SignaturesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type Tag = {
  tag_name: string;
  color_hex?: string;
  /** Optional description of the tag */
  description?: string;
};
export type InternalUseEnum =
  | 'address interpolation warning'
  | 'multistop trip'
  | 'pricing confirmed'
  | 'billing complete'
  | 'pickup address interpolation warning'
  | 'dropoff address interpolation warning';
export type TagRead = {
  id: string;
  tag_name: string;
  color_hex?: string;
  internal_use: (InternalUseEnum | BlankEnum | NullEnum) | null;
  /** Optional description of the tag */
  description?: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedTagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Tag[];
};
export type PaginatedTagListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TagRead[];
};
export type TagsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TagsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TagsCreateTagNameErrorComponent = {
  attr: 'tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TagsCreateColorHexErrorComponent = {
  attr: 'color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TagsCreateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TagsCreateError =
  | ({
      attr: 'non_field_errors';
    } & TagsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'tag_name';
    } & TagsCreateTagNameErrorComponent)
  | ({
      attr: 'color_hex';
    } & TagsCreateColorHexErrorComponent)
  | ({
      attr: 'description';
    } & TagsCreateDescriptionErrorComponent);
export type TagsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TagsCreateError[];
};
export type TagsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TagsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TagsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TagsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TagsPartialUpdateTagNameErrorComponent = {
  attr: 'tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TagsPartialUpdateColorHexErrorComponent = {
  attr: 'color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TagsPartialUpdateDescriptionErrorComponent = {
  attr: 'description';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TagsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TagsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'tag_name';
    } & TagsPartialUpdateTagNameErrorComponent)
  | ({
      attr: 'color_hex';
    } & TagsPartialUpdateColorHexErrorComponent)
  | ({
      attr: 'description';
    } & TagsPartialUpdateDescriptionErrorComponent);
export type TagsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TagsPartialUpdateError[];
};
export type TagsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TagsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTag = {
  tag_name?: string;
  color_hex?: string;
  /** Optional description of the tag */
  description?: string;
};
export type PatchedTagRead = {
  id?: string;
  tag_name?: string;
  color_hex?: string;
  internal_use?: (InternalUseEnum | BlankEnum | NullEnum) | null;
  /** Optional description of the tag */
  description?: string;
  created_at?: string;
  updated_at?: string;
};
export type TagsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type BambiPayerTypeEnum = 'passenger' | 'payer_organization';
export type TilledCustomerMetaData = {
  bambi_payer_id: string;
  bambi_payer_type: BambiPayerTypeEnum;
};
export type TilledBillingDetails = {
  address?: {
    [key: string]: string;
  };
  email?: string;
  name: string;
  phone?: string;
};
export type TilledCard = {
  brand: string;
  exp_month: number;
  exp_year: number;
  holder_name: string;
  last4: string;
};
export type TilledPaymentMethodResponse = {
  id: string;
  metadata: TilledCustomerMetaData | null;
  billing_details?: TilledBillingDetails | null;
  card: TilledCard;
  chargeable: boolean;
  created_at: string;
  customer_id: string;
  expires_at: string | null;
};
export type TilledListPaymentMethodsTilledResponse = {
  has_more: boolean;
  limit: number;
  offset: number;
  total: number;
  items: TilledPaymentMethodResponse[];
};
export type TilledPaymentMethodRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TilledCustomer = {
  tilled_customer_id: string;
};
export type TilledPaymentMethodCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TilledPaymentMethodCreatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TilledPaymentMethodCreatePayerTypeErrorComponent = {
  attr: 'payer_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TilledPaymentMethodCreatePaymentMethodIdErrorComponent = {
  attr: 'payment_method_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TilledPaymentMethodCreatePaymentAccountIdErrorComponent = {
  attr: 'payment_account_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TilledPaymentMethodCreateOrganizationIdErrorComponent = {
  attr: 'organization_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TilledPaymentMethodCreateError =
  | ({
      attr: 'non_field_errors';
    } & TilledPaymentMethodCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_id';
    } & TilledPaymentMethodCreatePayerIdErrorComponent)
  | ({
      attr: 'payer_type';
    } & TilledPaymentMethodCreatePayerTypeErrorComponent)
  | ({
      attr: 'payment_method_id';
    } & TilledPaymentMethodCreatePaymentMethodIdErrorComponent)
  | ({
      attr: 'payment_account_id';
    } & TilledPaymentMethodCreatePaymentAccountIdErrorComponent)
  | ({
      attr: 'organization_id';
    } & TilledPaymentMethodCreateOrganizationIdErrorComponent);
export type TilledPaymentMethodCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TilledPaymentMethodCreateError[];
};
export type TilledPaymentMethodCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TilledPaymentMethodCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TilledPaymentMethodInput = {
  payer_id: string;
  payer_type: BambiPayerTypeEnum;
  payment_method_id: string;
};
export type TilledPaymentMethodDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type CurrencyEnum =
  | 'usd'
  | 'aud'
  | 'cad'
  | 'dkk'
  | 'eur'
  | 'hkd'
  | 'jpy'
  | 'nzd'
  | 'nok'
  | 'gbp'
  | 'zar'
  | 'sek'
  | 'chf';
export type TilledPayoutFeeDetailsTypeEnum =
  | 'platform_fee'
  | 'tilled_fee'
  | 'tax'
  | 'chargeback_fee';
export type TilledPayoutFeeDetails = {
  account_id?: string;
  amount: number;
  currency: CurrencyEnum;
  description?: string;
  type: TilledPayoutFeeDetailsTypeEnum;
};
export type FeeSubtypeEnum =
  | 'approved_verification'
  | 'declined_verification'
  | 'approved_authorization'
  | 'declined_authorization'
  | 'reversed_authorization'
  | 'completed_credit'
  | 'declined_credit'
  | 'completed_settlement'
  | 'declined_settlement'
  | 'approved_purchase_return_authorization'
  | 'declined_purchase_return_authorization'
  | 'acquirer_processing'
  | 'amex_card_not_present'
  | 'amex_dues_and_assessments'
  | 'amex_inbound'
  | 'amex_international_assessment'
  | 'discover_assessment'
  | 'discover_data_usage'
  | 'dues_and_assessments'
  | 'fixed_acquirer_network'
  | 'mastercard_acquirer_brand_volume'
  | 'mastercard_acquirer_merchant_advice_code'
  | 'mastercard_acquirer_program_support'
  | 'mastercard_annual_location'
  | 'mastercard_assessment'
  | 'mastercard_assessment_1000_plus'
  | 'mastercard_authorization'
  | 'mastercard_cross_border_scheme'
  | 'mastercard_digital_commerce_development'
  | 'mastercard_digital_enablement'
  | 'mastercard_digital_enablement_max'
  | 'mastercard_final_authorization'
  | 'mastercard_final_authorization_min'
  | 'mastercard_international_assessment'
  | 'mastercard_nominal_amount_authorization'
  | 'mastercard_processing_integrity'
  | 'mastercard_safety_net_acquirer'
  | 'misuse_of_authorization'
  | 'nabu'
  | 'transaction_integrity'
  | 'visa_acquirer_credit_voucher_international'
  | 'visa_assessment'
  | 'visa_base_ii_transmission'
  | 'visa_commercial_solution'
  | 'visa_debit_assessment'
  | 'visa_international_assessment'
  | 'visa_misuse_of_authorization'
  | 'visa_us_digital_commerce'
  | 'reversal'
  | 'discount'
  | 'transaction'
  | 'account_setup'
  | 'ach_return'
  | 'monthly_account'
  | 'monthly_minimum_processing'
  | 'card_account_update'
  | 'monthly_terminal'
  | 'retrieval'
  | 'apple_pay_balance_transfer'
  | 'goods_and_services'
  | 'harmonized_sales';
export type FeeTypeEnum =
  | 'discount'
  | 'transaction'
  | 'interchange'
  | 'pass_through'
  | 'platform'
  | 'chargeback'
  | 'administrative'
  | 'tax'
  | 'negative_rounding_adjustment'
  | 'positive_rounding_adjustment'
  | 'unknown';
export type SourceTypeEnum =
  | 'charge'
  | 'refund'
  | 'payout'
  | 'payment_method'
  | 'platform_fee'
  | 'platform_fee_refund'
  | 'dispute'
  | 'account'
  | 'outbound_transfer';
export type TilledPayoutBalanceTransactionStatusEnum = 'available' | 'pending';
export type TilledPayoutBalanceTransactionTypeEnum =
  | 'charge'
  | 'charge_failure_refund'
  | 'dispute'
  | 'dispute_won'
  | 'refund'
  | 'refund_failure'
  | 'adjustment'
  | 'commission'
  | 'payout'
  | 'payout_cancel'
  | 'payout_failure'
  | 'fee'
  | 'platform_fee'
  | 'platform_fee_refund'
  | 'outbound_transfer'
  | 'outbound_transfer_cancel'
  | 'outbound_transfer_failure'
  | 'charge_fee'
  | 'refund_fee'
  | 'account_fee'
  | 'payment_method_fee'
  | 'tilled_fee';
export type TilledPayoutBalanceTransaction = {
  account_id: string;
  amount: number;
  available_on: string;
  created_at: string;
  currency: CurrencyEnum;
  description?: string;
  fee?: number;
  fee_details?: TilledPayoutFeeDetails[];
  fee_subtype?: (FeeSubtypeEnum | NullEnum) | null;
  fee_type?: (FeeTypeEnum | NullEnum) | null;
  id: string;
  net?: number;
  payout_id?: string | null;
  source_id: string;
  source_type: SourceTypeEnum;
  status: TilledPayoutBalanceTransactionStatusEnum;
  type: TilledPayoutBalanceTransactionTypeEnum;
  updated_at: string;
};
export type FailureCodeEnum =
  | 'account_closed'
  | 'account_frozen'
  | 'bank_account_restricted'
  | 'bank_ownership_changed'
  | 'could_not_process'
  | 'debit_not_authorized'
  | 'declined'
  | 'invalid_account_number'
  | 'incorrect_account_holder_name'
  | 'incorrect_account_holder_address'
  | 'incorrect_account_holder_tax_id'
  | 'invalid_currency'
  | 'no_account';
export type TilledPayoutItemStatusEnum =
  | 'canceled'
  | 'failed'
  | 'in_transit'
  | 'paid'
  | 'pending';
export type TilledPayoutItem = {
  account_id: string;
  amount: number;
  balance_transaction?: TilledPayoutBalanceTransaction;
  created_at: string;
  currency: CurrencyEnum;
  description?: string | null;
  failure_code?: (FailureCodeEnum | NullEnum) | null;
  id: string;
  paid_at?: string;
  status: TilledPayoutItemStatusEnum;
  transaction_count?: number;
  updated_at: string;
};
export type PaginatedTilledPayoutItemList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TilledPayoutItem[];
};
export type TilledPayoutsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TilledWebHookEnvelope = {
  id: string;
  account_id: string;
  type: string;
  internal_data: {
    [key: string]: any;
  };
};
export type TilledWebhookCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TilledWebhookCreateIdErrorComponent = {
  attr: 'id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TilledWebhookCreateAccountIdErrorComponent = {
  attr: 'account_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TilledWebhookCreateTypeErrorComponent = {
  attr: 'type';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TilledWebhookCreateInternalDataErrorComponent = {
  attr: 'internal_data';
  code: 'not_a_dict' | 'null' | 'required';
  detail: string;
};
export type TilledWebhookCreateInternalDataKeyErrorComponent = {
  attr: 'internal_data.KEY';
  code: 'required';
  detail: string;
};
export type TilledWebhookCreateError =
  | ({
      attr: 'non_field_errors';
    } & TilledWebhookCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'id';
    } & TilledWebhookCreateIdErrorComponent)
  | ({
      attr: 'account_id';
    } & TilledWebhookCreateAccountIdErrorComponent)
  | ({
      attr: 'type';
    } & TilledWebhookCreateTypeErrorComponent)
  | ({
      attr: 'internal_data';
    } & TilledWebhookCreateInternalDataErrorComponent)
  | ({
      attr: 'internal_data.KEY';
    } & TilledWebhookCreateInternalDataKeyErrorComponent);
export type TilledWebhookCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TilledWebhookCreateError[];
};
export type TilledWebhookCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TilledWebhookCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type ChatTokens = {
  token: string;
  /** Unix timestamp in seconds */
  expiration: number;
};
export type TokensChatIssueRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PushTokens = {
  token: string;
};
export type PushTokensRead = {
  token: string;
  token_type: string | null;
};
export type TokensPushRegisterCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TokensPushRegisterCreateTokenErrorComponent = {
  attr: 'token';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TokensPushRegisterCreateError =
  | ({
      attr: 'non_field_errors';
    } & TokensPushRegisterCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'token';
    } & TokensPushRegisterCreateTokenErrorComponent);
export type TokensPushRegisterCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TokensPushRegisterCreateError[];
};
export type TokensPushRegisterCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TokensPushRegisterCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TokensPushRevokeCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TokensPushRevokeCreateTokenErrorComponent = {
  attr: 'token';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TokensPushRevokeCreateError =
  | ({
      attr: 'non_field_errors';
    } & TokensPushRevokeCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'token';
    } & TokensPushRevokeCreateTokenErrorComponent);
export type TokensPushRevokeCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TokensPushRevokeCreateError[];
};
export type TokensPushRevokeCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TokensPushRevokeCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripImportAsync = {};
export type TripImportAsyncRead = {
  id: string;
};
export type TripImportAsyncWrite = {
  payer_id?: string | null;
  template_key: string;
  trips_from_csv: {
    [key: string]: any;
  };
};
export type TripImportCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripImportCreatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripImportCreateTemplateKeyErrorComponent = {
  attr: 'template_key';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripImportCreateTripsFromCsvNonFieldErrorsErrorComponent = {
  attr: 'trips_from_csv.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsNonFieldErrorsErrorComponent = {
  attr: 'trips_from_csv.records.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexAssignmentStartErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.assignment.start';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexAssignmentEndErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.assignment.end';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.id';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerFirstNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.first_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerLastNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.last_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerPreferredNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.preferred_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerPronounErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.pronoun';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerGenderErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.gender';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerDobErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.dob';
    code: 'datetime' | 'invalid';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerWeightErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.weight';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerNeedsBariatricTransportErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.needs_bariatric_transport';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.phone_number';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerEmailErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.email';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerDispatcherNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPassengerAllowSmsMessageErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.allow_sms_message';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerPayerErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer.payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexPayerDisplayNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.display_name';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerAlternateNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.alternate_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerTypeErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexPayerIsActiveErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.is_active';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerCanMultiloadErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.can_multiload';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerExternalIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.external_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerWebsiteErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.website';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerEinErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexPayerEmailErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer.email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexPayerPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingEmailErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_email';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDispatcherNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerAdminNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.admin_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDeletedAtErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.deleted_at';
    code: 'date' | 'invalid' | 'make_aware' | 'overflow';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabelErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabel2ErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressTypeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabelErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabel2ErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPayerIdErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexPayerPassengerExternalIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer_passenger_external_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexExternalTripIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.external_trip_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexInputPriceCentsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.input_price_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPaymentMethodErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payment_method';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexScheduledPickupAtErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.scheduled_pickup_at';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupAddressDetailsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.address_details';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupNumStairsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.num_stairs';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupStairEquipmentErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.stair_equipment';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupContactNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.contact_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupContactPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.contact_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupDriverNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.driver_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffAddressDetailsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.address_details';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffNumStairsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.num_stairs';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffStairEquipmentErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.stair_equipment';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffContactNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.contact_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffContactPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.contact_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffDriverNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.driver_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDropoffPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexAppointmentAtErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.appointment_at';
    code: 'date' | 'invalid' | 'make_aware' | 'overflow';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexIsWillCallErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.is_will_call';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexSpaceTypeErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexMustProvideWheelchairErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.must_provide_wheelchair';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexIsTransportingScooterErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_transporting_scooter';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexServiceTypeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.service_type';
    code: 'invalid_choice' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDispatcherNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexDriverEnteredNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.driver_entered_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexIsOxygenRequiredErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_oxygen_required';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexOxygenLitersPerMinErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.oxygen_liters_per_min';
    code:
      | 'invalid'
      | 'max_decimal_places'
      | 'max_digits'
      | 'max_string_length'
      | 'max_whole_digits'
      | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexNumAttendantsNeededErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.num_attendants_needed';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexNumAccompanyingPassengersErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.num_accompanying_passengers';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.one_schema_template_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateMultiIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.one_schema_template_multi_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexCorrelationIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.correlation_id';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexBookedByNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.booked_by_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexBookedByPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.booked_by_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexBookedByPhoneExtensionErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.booked_by_phone_extension';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexLoadTimeSecondsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.load_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexUnloadTimeSecondsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.unload_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexPickupWaitTimeSecondsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup_wait_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexTagIdsErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type TripImportCreateTripsFromCsvRecordsIndexTagIdsIndexErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.tag_ids.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexTagsIndexTagNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.tags.INDEX.tag_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexTagsIndexColorHexErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.tags.INDEX.color_hex';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnOutboundErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_wait_and_return_outbound';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnInboundErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_wait_and_return_inbound';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexAuthorizationIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.authorization_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexInsurancePolicyIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.insurance_policy_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexIsPriceLockedErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_price_locked';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexLinkToPayerErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.link_to_payer';
    code: 'invalid_choice';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexConfirmationCodeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.confirmation_code';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripImportCreateTripsFromCsvRecordsIndexHasInfectiousDiseaseErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.has_infectious_disease';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripImportCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripImportCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_id';
    } & TripImportCreatePayerIdErrorComponent)
  | ({
      attr: 'template_key';
    } & TripImportCreateTemplateKeyErrorComponent)
  | ({
      attr: 'trips_from_csv.non_field_errors';
    } & TripImportCreateTripsFromCsvNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.assignment.start';
    } & TripImportCreateTripsFromCsvRecordsIndexAssignmentStartErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.assignment.end';
    } & TripImportCreateTripsFromCsvRecordsIndexAssignmentEndErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.id';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.first_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerFirstNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.last_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerLastNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.preferred_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerPreferredNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.pronoun';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerPronounErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.gender';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerGenderErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.dob';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerDobErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.weight';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerWeightErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.needs_bariatric_transport';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.phone_number';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.email';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerEmailErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.dispatcher_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerDispatcherNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.allow_sms_message';
    } & TripImportCreateTripsFromCsvRecordsIndexPassengerAllowSmsMessageErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.payer';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerPayerErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.display_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDisplayNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.alternate_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerAlternateNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.type';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.is_active';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerIsActiveErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.can_multiload';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerCanMultiloadErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.external_id';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerExternalIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.website';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerWebsiteErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.ein';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerEinErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.email';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerEmailErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.phone_number';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_phone_number';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_email';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingEmailErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.dispatcher_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDispatcherNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.admin_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerAdminNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.deleted_at';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDeletedAtErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.place_id';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.label';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabelErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.label2';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_type';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.place_id';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label2';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_type';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.INDEX';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersIndexErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer_id';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer_passenger_external_id';
    } & TripImportCreateTripsFromCsvRecordsIndexPayerPassengerExternalIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.external_trip_id';
    } & TripImportCreateTripsFromCsvRecordsIndexExternalTripIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.input_price_cents';
    } & TripImportCreateTripsFromCsvRecordsIndexInputPriceCentsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payment_method';
    } & TripImportCreateTripsFromCsvRecordsIndexPaymentMethodErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.scheduled_pickup_at';
    } & TripImportCreateTripsFromCsvRecordsIndexScheduledPickupAtErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.address_details';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupAddressDetailsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.num_stairs';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupNumStairsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.stair_equipment';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupStairEquipmentErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.contact_name';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupContactNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.contact_phone_number';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupContactPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.driver_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupDriverNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.place_id';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.non_field_errors';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.address_details';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffAddressDetailsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.num_stairs';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffNumStairsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.stair_equipment';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffStairEquipmentErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.contact_name';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffContactNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.contact_phone_number';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffContactPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.driver_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffDriverNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.place_id';
    } & TripImportCreateTripsFromCsvRecordsIndexDropoffPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.appointment_at';
    } & TripImportCreateTripsFromCsvRecordsIndexAppointmentAtErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_will_call';
    } & TripImportCreateTripsFromCsvRecordsIndexIsWillCallErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.space_type';
    } & TripImportCreateTripsFromCsvRecordsIndexSpaceTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.must_provide_wheelchair';
    } & TripImportCreateTripsFromCsvRecordsIndexMustProvideWheelchairErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_transporting_scooter';
    } & TripImportCreateTripsFromCsvRecordsIndexIsTransportingScooterErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.service_type';
    } & TripImportCreateTripsFromCsvRecordsIndexServiceTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dispatcher_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexDispatcherNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.driver_entered_notes';
    } & TripImportCreateTripsFromCsvRecordsIndexDriverEnteredNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_oxygen_required';
    } & TripImportCreateTripsFromCsvRecordsIndexIsOxygenRequiredErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.oxygen_liters_per_min';
    } & TripImportCreateTripsFromCsvRecordsIndexOxygenLitersPerMinErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.num_attendants_needed';
    } & TripImportCreateTripsFromCsvRecordsIndexNumAttendantsNeededErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.num_accompanying_passengers';
    } & TripImportCreateTripsFromCsvRecordsIndexNumAccompanyingPassengersErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.one_schema_template_id';
    } & TripImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.one_schema_template_multi_id';
    } & TripImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateMultiIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.correlation_id';
    } & TripImportCreateTripsFromCsvRecordsIndexCorrelationIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.booked_by_name';
    } & TripImportCreateTripsFromCsvRecordsIndexBookedByNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.booked_by_phone_number';
    } & TripImportCreateTripsFromCsvRecordsIndexBookedByPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.booked_by_phone_extension';
    } & TripImportCreateTripsFromCsvRecordsIndexBookedByPhoneExtensionErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.load_time_seconds';
    } & TripImportCreateTripsFromCsvRecordsIndexLoadTimeSecondsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.unload_time_seconds';
    } & TripImportCreateTripsFromCsvRecordsIndexUnloadTimeSecondsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup_wait_time_seconds';
    } & TripImportCreateTripsFromCsvRecordsIndexPickupWaitTimeSecondsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tag_ids';
    } & TripImportCreateTripsFromCsvRecordsIndexTagIdsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tag_ids.INDEX';
    } & TripImportCreateTripsFromCsvRecordsIndexTagIdsIndexErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tags.INDEX.tag_name';
    } & TripImportCreateTripsFromCsvRecordsIndexTagsIndexTagNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tags.INDEX.color_hex';
    } & TripImportCreateTripsFromCsvRecordsIndexTagsIndexColorHexErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_wait_and_return_outbound';
    } & TripImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnOutboundErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_wait_and_return_inbound';
    } & TripImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnInboundErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.authorization_id';
    } & TripImportCreateTripsFromCsvRecordsIndexAuthorizationIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.insurance_policy_id';
    } & TripImportCreateTripsFromCsvRecordsIndexInsurancePolicyIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_price_locked';
    } & TripImportCreateTripsFromCsvRecordsIndexIsPriceLockedErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.link_to_payer';
    } & TripImportCreateTripsFromCsvRecordsIndexLinkToPayerErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.confirmation_code';
    } & TripImportCreateTripsFromCsvRecordsIndexConfirmationCodeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.has_infectious_disease';
    } & TripImportCreateTripsFromCsvRecordsIndexHasInfectiousDiseaseErrorComponent);
export type TripImportCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripImportCreateError[];
};
export type TripImportCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripImportCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripPassenger = {
  id?: string;
  first_name?: string;
  last_name?: string;
  preferred_name?: string;
  pronoun?: string;
  gender?: string;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  phone_number?: string;
  email?: string;
  notes?: string;
  dispatcher_notes?: string;
  allow_sms_message?: boolean;
};
export type PatchedTripPassengerRead = {
  id?: string;
  organization_id?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  preferred_name?: string;
  pronoun?: string;
  gender?: string;
  dob?: string | null;
  weight?: number | null;
  needs_bariatric_transport?: boolean;
  phone_number?: string;
  email?: string;
  notes?: string;
  dispatcher_notes?: string;
  allow_sms_message?: boolean;
};
export type TripLocationDetail = {
  address_details?: string;
  /** The number of stairs at this location */
  num_stairs?: number;
  /** Stair equipment required for this location */
  stair_equipment?: StairEquipmentEnum | BlankEnum;
  /** Name of contact for this location, if not passenger */
  contact_name?: string;
  /** Phone number of contact for this location, if not passenger */
  contact_phone_number?: string | null;
  /** Notes visible to the driver for this location */
  driver_notes?: string;
  place_id: string;
};
export type TripLocationDetailRead = {
  id: string;
  /** Purpose of this location, pickup, dropoff, etc. */
  purpose: PurposeEnum;
  address: string;
  address_details?: string;
  latitude: number;
  longitude: number;
  /** The number of stairs at this location */
  num_stairs?: number;
  /** Stair equipment required for this location */
  stair_equipment?: StairEquipmentEnum | BlankEnum;
  /** Name of contact for this location, if not passenger */
  contact_name?: string;
  /** Phone number of contact for this location, if not passenger */
  contact_phone_number?: string | null;
  /** Notes visible to the driver for this location */
  driver_notes?: string;
  place_id: string;
};
export type LinkToPayerEnum = 'always' | 'never' | 'only_with_id';
export type Trip = {
  passenger: PatchedTripPassenger;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  scheduled_pickup_at: string;
  pickup: TripLocationDetail;
  dropoff: TripLocationDetail;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound?: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound?: boolean;
  /** The authorization ID for this trip */
  authorization_id?: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id?: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked?: boolean;
  link_to_payer?: (LinkToPayerEnum | NullEnum) | null;
  confirmation_code?: string;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
};
export type AssignmentMini = {
  start: string;
  end: string;
};
export type AssignmentMiniRead = {
  id: string;
  start: string;
  end: string;
};
export type TripPayerPassenger = {};
export type TripPayerPassengerRead = {
  id: string;
  external_id: string;
};
export type TripTiming = {};
export type TripTimingRead = {
  /** Estimated arrival at pickup location per vehicle's last location log */
  estimated_pickup_arrival_time: string | null;
  is_on_time: boolean | null;
  delayed_by_seconds: number | null;
  /** Last time this timing was calculated */
  last_calculated_at: string | null;
};
export type TripTripTag = {
  tag_name: string;
  color_hex: string;
};
export type TripTripTagRead = {
  id: string;
  tag: string;
  tag_name: string;
  color_hex: string;
  created_at: string;
  updated_at: string;
};
export type TripRead = {
  id: string;
  assignment: AssignmentMiniRead | null;
  /** Current status of this trip */
  status: OverrideStatusEnum;
  /** Locked assignment cannot be changed */
  is_locked: boolean | null;
  passenger: PatchedTripPassengerRead;
  payer: PayerOrganizationRead;
  payer_passenger: TripPayerPassengerRead | null;
  early_pickup_at: string;
  late_pickup_at: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** The initial price of the trip calculated using a pricing model */
  initial_calculated_price_cents: number | null;
  /** The final price of the trip calculated using a pricing model */
  final_calculated_price_cents: number | null;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  scheduled_pickup_at: string;
  current_task: PurposeEnum;
  current_task_at: string;
  pickup: TripLocationDetailRead;
  dropoff: TripLocationDetailRead;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** Whether the passenger has called in that they are ready to be picked up */
  is_will_call_activated: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  estimated_distance_miles: number | null;
  /** The time the trip was canceled */
  canceled_at: string | null;
  /** The main reason a trip was canceled */
  canceled_reason: CanceledReasonEnum;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  timing: TripTimingRead | null;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  tags: TripTripTagRead[] | null;
  /** The suggested pickup time based off of run_bambi_run results */
  suggested_pickup_time: string | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound?: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound?: boolean;
  /** The authorization ID for this trip */
  authorization_id?: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id?: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked?: boolean;
  link_to_payer?: (LinkToPayerEnum | NullEnum) | null;
  confirmation_code?: string;
  driver_name: string;
  invoice_numbers: string | null;
  invoice_id: string | null;
  rejection_reason: string | null;
  /** The trip ID used by an intermediary/broker, may or may not be the originator */
  intermediary_id: string;
  /** The intermediary/broker source of this trip */
  intermediary: IntermediaryEnum;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  completion_time: string | null;
  vehicle_nickname: string | null;
};
export type TripWrite = {
  passenger: PatchedTripPassenger;
  payer_id: string;
  payer_passenger_external_id?: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  scheduled_pickup_at: string;
  pickup: TripLocationDetail;
  dropoff: TripLocationDetail;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  tag_ids?: string[] | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound?: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound?: boolean;
  /** The authorization ID for this trip */
  authorization_id?: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id?: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked?: boolean;
  link_to_payer?: (LinkToPayerEnum | NullEnum) | null;
  confirmation_code?: string;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
};
export type OneSchemaDataAsync = {
  records: Trip[];
};
export type OneSchemaDataAsyncRead = {
  records: TripRead[];
};
export type OneSchemaDataAsyncWrite = {
  records: TripWrite[];
};
export type TripImportAsyncRequest = {
  payer_id?: string;
  template_key: string;
  trips_from_csv: OneSchemaDataAsync;
};
export type TripImportAsyncRequestRead = {
  payer_id?: string;
  template_key: string;
  trips_from_csv: OneSchemaDataAsyncRead;
};
export type TripImportAsyncRequestWrite = {
  payer_id?: string;
  template_key: string;
  trips_from_csv: OneSchemaDataAsyncWrite;
};
export type TripImportTask = {};
export type TripImportResult = {};
export type TripImportParserError = {};
export type TripImportParserErrorRead = {
  trip: string;
  error: string;
};
export type TripImportUploadError = {};
export type TripImportUploadErrorRead = {
  trip: string;
  errors: {
    [key: string]: string;
  };
};
export type TripImportResultRead = {
  trip_ids: string[];
  number_parsed: number;
  number_uploaded: number;
  parser_errors: TripImportParserErrorRead[];
  upload_errors: TripImportUploadErrorRead[];
};
export type TripImportTaskRead = {
  id: string;
  state: StateEnum;
  result: TripImportResultRead;
};
export type TripImportRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripTag = {
  trip: string;
  tag: string;
};
export type TripTagRead = {
  id: string;
  tag_name: string;
  color_hex: string;
  trip: string;
  tag: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedTripTagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripTag[];
};
export type PaginatedTripTagListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripTagRead[];
};
export type TripTagsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripTagBulkCreate = {
  tags: TripTag[];
};
export type TripTagBulkCreateRead = {
  tags: TripTagRead[];
};
export type TripTagsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripTagsCreateTagsNonFieldErrorsErrorComponent = {
  attr: 'tags.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripTagsCreateTagsIndexNonFieldErrorsErrorComponent = {
  attr: 'tags.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required' | 'unique';
  detail: string;
};
export type TripTagsCreateTagsIndexTripErrorComponent = {
  attr: 'tags.INDEX.trip';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type TripTagsCreateTagsIndexTagErrorComponent = {
  attr: 'tags.INDEX.tag';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type TripTagsCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripTagsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.non_field_errors';
    } & TripTagsCreateTagsNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.INDEX.non_field_errors';
    } & TripTagsCreateTagsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.INDEX.trip';
    } & TripTagsCreateTagsIndexTripErrorComponent)
  | ({
      attr: 'tags.INDEX.tag';
    } & TripTagsCreateTagsIndexTagErrorComponent);
export type TripTagsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripTagsCreateError[];
};
export type TripTagsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripTagsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripTagsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripTagsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedTripList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Trip[];
};
export type PaginatedTripListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripRead[];
};
export type PaginatedTripListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripWrite[];
};
export type TripsListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type TripsListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type TripsListPayersErrorComponent = {
  attr: 'payers';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsListStatusErrorComponent = {
  attr: 'status';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsListPassengersErrorComponent = {
  attr: 'passengers';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsListTagsErrorComponent = {
  attr: 'tags';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsListVehiclesErrorComponent = {
  attr: 'vehicles';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsListPriceErrorComponent = {
  attr: 'price';
  code: 'invalid';
  detail: string;
};
export type TripsListError =
  | ({
      attr: 'range_start';
    } & TripsListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & TripsListRangeEndErrorComponent)
  | ({
      attr: 'payers';
    } & TripsListPayersErrorComponent)
  | ({
      attr: 'status';
    } & TripsListStatusErrorComponent)
  | ({
      attr: 'passengers';
    } & TripsListPassengersErrorComponent)
  | ({
      attr: 'tags';
    } & TripsListTagsErrorComponent)
  | ({
      attr: 'vehicles';
    } & TripsListVehiclesErrorComponent)
  | ({
      attr: 'price';
    } & TripsListPriceErrorComponent);
export type TripsListValidationError = {
  type: ValidationErrorEnum;
  errors: TripsListError[];
};
export type TripsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripsBulkCreate = {
  trips: Trip[];
};
export type TripsBulkCreateRead = {
  trips: TripRead[];
};
export type TripsBulkCreateWrite = {
  trips: TripWrite[];
};
export type TripsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsCreateTripsNonFieldErrorsErrorComponent = {
  attr: 'trips.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexNonFieldErrorsErrorComponent = {
  attr: 'trips.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexAssignmentStartErrorComponent = {
  attr: 'trips.INDEX.assignment.start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexAssignmentEndErrorComponent = {
  attr: 'trips.INDEX.assignment.end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexPassengerNonFieldErrorsErrorComponent = {
  attr: 'trips.INDEX.passenger.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexPassengerIdErrorComponent = {
  attr: 'trips.INDEX.passenger.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPassengerFirstNameErrorComponent = {
  attr: 'trips.INDEX.passenger.first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerLastNameErrorComponent = {
  attr: 'trips.INDEX.passenger.last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerPreferredNameErrorComponent = {
  attr: 'trips.INDEX.passenger.preferred_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerPronounErrorComponent = {
  attr: 'trips.INDEX.passenger.pronoun';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerGenderErrorComponent = {
  attr: 'trips.INDEX.passenger.gender';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerDobErrorComponent = {
  attr: 'trips.INDEX.passenger.dob';
  code: 'datetime' | 'invalid';
  detail: string;
};
export type TripsCreateTripsIndexPassengerWeightErrorComponent = {
  attr: 'trips.INDEX.passenger.weight';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsCreateTripsIndexPassengerNeedsBariatricTransportErrorComponent =
  {
    attr: 'trips.INDEX.passenger.needs_bariatric_transport';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsCreateTripsIndexPassengerPhoneNumberErrorComponent = {
  attr: 'trips.INDEX.passenger.phone_number';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerEmailErrorComponent = {
  attr: 'trips.INDEX.passenger.email';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerNotesErrorComponent = {
  attr: 'trips.INDEX.passenger.notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerDispatcherNotesErrorComponent = {
  attr: 'trips.INDEX.passenger.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPassengerAllowSmsMessageErrorComponent = {
  attr: 'trips.INDEX.passenger.allow_sms_message';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPayerPayerErrorComponent = {
  attr: 'trips.INDEX.payer.payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type TripsCreateTripsIndexPayerDisplayNameErrorComponent = {
  attr: 'trips.INDEX.payer.display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerAlternateNameErrorComponent = {
  attr: 'trips.INDEX.payer.alternate_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerTypeErrorComponent = {
  attr: 'trips.INDEX.payer.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPayerIsActiveErrorComponent = {
  attr: 'trips.INDEX.payer.is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPayerCanMultiloadErrorComponent = {
  attr: 'trips.INDEX.payer.can_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPayerExternalIdErrorComponent = {
  attr: 'trips.INDEX.payer.external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerWebsiteErrorComponent = {
  attr: 'trips.INDEX.payer.website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerEinErrorComponent = {
  attr: 'trips.INDEX.payer.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerEmailErrorComponent = {
  attr: 'trips.INDEX.payer.email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerPhoneNumberErrorComponent = {
  attr: 'trips.INDEX.payer.phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerBillingNameErrorComponent = {
  attr: 'trips.INDEX.payer.billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerBillingPhoneNumberErrorComponent = {
  attr: 'trips.INDEX.payer.billing_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerBillingEmailErrorComponent = {
  attr: 'trips.INDEX.payer.billing_email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerDispatcherNotesErrorComponent = {
  attr: 'trips.INDEX.payer.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerAdminNotesErrorComponent = {
  attr: 'trips.INDEX.payer.admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerDeletedAtErrorComponent = {
  attr: 'trips.INDEX.payer.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsCreateTripsIndexPayerBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'trips.INDEX.payer.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsCreateTripsIndexPayerBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'trips.INDEX.payer.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsCreateTripsIndexPayerBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'trips.INDEX.payer.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsCreateTripsIndexPayerBillingAddressLabelErrorComponent = {
  attr: 'trips.INDEX.payer.billing_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerBillingAddressLabel2ErrorComponent = {
  attr: 'trips.INDEX.payer.billing_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerBillingAddressAddressNotesErrorComponent =
  {
    attr: 'trips.INDEX.payer.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsCreateTripsIndexPayerBillingAddressAddressTypeErrorComponent =
  {
    attr: 'trips.INDEX.payer.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsCreateTripsIndexPayerDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'trips.INDEX.payer.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsCreateTripsIndexPayerDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'trips.INDEX.payer.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsCreateTripsIndexPayerDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'trips.INDEX.payer.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsCreateTripsIndexPayerDefaultTripAddressLabelErrorComponent = {
  attr: 'trips.INDEX.payer.default_trip_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerDefaultTripAddressLabel2ErrorComponent = {
  attr: 'trips.INDEX.payer.default_trip_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPayerDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'trips.INDEX.payer.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsCreateTripsIndexPayerDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'trips.INDEX.payer.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsCreateTripsIndexPayerMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'trips.INDEX.payer.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type TripsCreateTripsIndexPayerMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'trips.INDEX.payer.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsCreateTripsIndexPayerIdErrorComponent = {
  attr: 'trips.INDEX.payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexPayerPassengerExternalIdErrorComponent = {
  attr: 'trips.INDEX.payer_passenger_external_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexExternalTripIdErrorComponent = {
  attr: 'trips.INDEX.external_trip_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexInputPriceCentsErrorComponent = {
  attr: 'trips.INDEX.input_price_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type TripsCreateTripsIndexPaymentMethodErrorComponent = {
  attr: 'trips.INDEX.payment_method';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexScheduledPickupAtErrorComponent = {
  attr: 'trips.INDEX.scheduled_pickup_at';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexPickupNonFieldErrorsErrorComponent = {
  attr: 'trips.INDEX.pickup.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexPickupAddressDetailsErrorComponent = {
  attr: 'trips.INDEX.pickup.address_details';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPickupNumStairsErrorComponent = {
  attr: 'trips.INDEX.pickup.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPickupStairEquipmentErrorComponent = {
  attr: 'trips.INDEX.pickup.stair_equipment';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexPickupContactNameErrorComponent = {
  attr: 'trips.INDEX.pickup.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPickupContactPhoneNumberErrorComponent = {
  attr: 'trips.INDEX.pickup.contact_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPickupDriverNotesErrorComponent = {
  attr: 'trips.INDEX.pickup.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexPickupPlaceIdErrorComponent = {
  attr: 'trips.INDEX.pickup.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexDropoffNonFieldErrorsErrorComponent = {
  attr: 'trips.INDEX.dropoff.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexDropoffAddressDetailsErrorComponent = {
  attr: 'trips.INDEX.dropoff.address_details';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexDropoffNumStairsErrorComponent = {
  attr: 'trips.INDEX.dropoff.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexDropoffStairEquipmentErrorComponent = {
  attr: 'trips.INDEX.dropoff.stair_equipment';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexDropoffContactNameErrorComponent = {
  attr: 'trips.INDEX.dropoff.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexDropoffContactPhoneNumberErrorComponent = {
  attr: 'trips.INDEX.dropoff.contact_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexDropoffDriverNotesErrorComponent = {
  attr: 'trips.INDEX.dropoff.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexDropoffPlaceIdErrorComponent = {
  attr: 'trips.INDEX.dropoff.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexAppointmentAtErrorComponent = {
  attr: 'trips.INDEX.appointment_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsCreateTripsIndexIsWillCallErrorComponent = {
  attr: 'trips.INDEX.is_will_call';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexSpaceTypeErrorComponent = {
  attr: 'trips.INDEX.space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexMustProvideWheelchairErrorComponent = {
  attr: 'trips.INDEX.must_provide_wheelchair';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexIsTransportingScooterErrorComponent = {
  attr: 'trips.INDEX.is_transporting_scooter';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexServiceTypeErrorComponent = {
  attr: 'trips.INDEX.service_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexDispatcherNotesErrorComponent = {
  attr: 'trips.INDEX.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexDriverEnteredNotesErrorComponent = {
  attr: 'trips.INDEX.driver_entered_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexIsOxygenRequiredErrorComponent = {
  attr: 'trips.INDEX.is_oxygen_required';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexOxygenLitersPerMinErrorComponent = {
  attr: 'trips.INDEX.oxygen_liters_per_min';
  code:
    | 'invalid'
    | 'max_decimal_places'
    | 'max_digits'
    | 'max_string_length'
    | 'max_whole_digits'
    | 'null';
  detail: string;
};
export type TripsCreateTripsIndexNumAttendantsNeededErrorComponent = {
  attr: 'trips.INDEX.num_attendants_needed';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexNumAccompanyingPassengersErrorComponent = {
  attr: 'trips.INDEX.num_accompanying_passengers';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexOneSchemaTemplateIdErrorComponent = {
  attr: 'trips.INDEX.one_schema_template_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexOneSchemaTemplateMultiIdErrorComponent = {
  attr: 'trips.INDEX.one_schema_template_multi_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexCorrelationIdErrorComponent = {
  attr: 'trips.INDEX.correlation_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexBookedByNameErrorComponent = {
  attr: 'trips.INDEX.booked_by_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexBookedByPhoneNumberErrorComponent = {
  attr: 'trips.INDEX.booked_by_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexBookedByPhoneExtensionErrorComponent = {
  attr: 'trips.INDEX.booked_by_phone_extension';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexLoadTimeSecondsErrorComponent = {
  attr: 'trips.INDEX.load_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsCreateTripsIndexUnloadTimeSecondsErrorComponent = {
  attr: 'trips.INDEX.unload_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsCreateTripsIndexPickupWaitTimeSecondsErrorComponent = {
  attr: 'trips.INDEX.pickup_wait_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsCreateTripsIndexTagIdsErrorComponent = {
  attr: 'trips.INDEX.tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type TripsCreateTripsIndexTagIdsIndexErrorComponent = {
  attr: 'trips.INDEX.tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCreateTripsIndexTagsIndexTagNameErrorComponent = {
  attr: 'trips.INDEX.tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexTagsIndexColorHexErrorComponent = {
  attr: 'trips.INDEX.tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexIsWaitAndReturnOutboundErrorComponent = {
  attr: 'trips.INDEX.is_wait_and_return_outbound';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexIsWaitAndReturnInboundErrorComponent = {
  attr: 'trips.INDEX.is_wait_and_return_inbound';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexAuthorizationIdErrorComponent = {
  attr: 'trips.INDEX.authorization_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexInsurancePolicyIdErrorComponent = {
  attr: 'trips.INDEX.insurance_policy_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexIsPriceLockedErrorComponent = {
  attr: 'trips.INDEX.is_price_locked';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateTripsIndexLinkToPayerErrorComponent = {
  attr: 'trips.INDEX.link_to_payer';
  code: 'invalid_choice';
  detail: string;
};
export type TripsCreateTripsIndexConfirmationCodeErrorComponent = {
  attr: 'trips.INDEX.confirmation_code';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCreateTripsIndexHasInfectiousDiseaseErrorComponent = {
  attr: 'trips.INDEX.has_infectious_disease';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.non_field_errors';
    } & TripsCreateTripsNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.non_field_errors';
    } & TripsCreateTripsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.assignment.start';
    } & TripsCreateTripsIndexAssignmentStartErrorComponent)
  | ({
      attr: 'trips.INDEX.assignment.end';
    } & TripsCreateTripsIndexAssignmentEndErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.non_field_errors';
    } & TripsCreateTripsIndexPassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.id';
    } & TripsCreateTripsIndexPassengerIdErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.first_name';
    } & TripsCreateTripsIndexPassengerFirstNameErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.last_name';
    } & TripsCreateTripsIndexPassengerLastNameErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.preferred_name';
    } & TripsCreateTripsIndexPassengerPreferredNameErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.pronoun';
    } & TripsCreateTripsIndexPassengerPronounErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.gender';
    } & TripsCreateTripsIndexPassengerGenderErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.dob';
    } & TripsCreateTripsIndexPassengerDobErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.weight';
    } & TripsCreateTripsIndexPassengerWeightErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.needs_bariatric_transport';
    } & TripsCreateTripsIndexPassengerNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.phone_number';
    } & TripsCreateTripsIndexPassengerPhoneNumberErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.email';
    } & TripsCreateTripsIndexPassengerEmailErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.notes';
    } & TripsCreateTripsIndexPassengerNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.dispatcher_notes';
    } & TripsCreateTripsIndexPassengerDispatcherNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.passenger.allow_sms_message';
    } & TripsCreateTripsIndexPassengerAllowSmsMessageErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.payer';
    } & TripsCreateTripsIndexPayerPayerErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.display_name';
    } & TripsCreateTripsIndexPayerDisplayNameErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.alternate_name';
    } & TripsCreateTripsIndexPayerAlternateNameErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.type';
    } & TripsCreateTripsIndexPayerTypeErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.is_active';
    } & TripsCreateTripsIndexPayerIsActiveErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.can_multiload';
    } & TripsCreateTripsIndexPayerCanMultiloadErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.external_id';
    } & TripsCreateTripsIndexPayerExternalIdErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.website';
    } & TripsCreateTripsIndexPayerWebsiteErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.ein';
    } & TripsCreateTripsIndexPayerEinErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.email';
    } & TripsCreateTripsIndexPayerEmailErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.phone_number';
    } & TripsCreateTripsIndexPayerPhoneNumberErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_name';
    } & TripsCreateTripsIndexPayerBillingNameErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_phone_number';
    } & TripsCreateTripsIndexPayerBillingPhoneNumberErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_email';
    } & TripsCreateTripsIndexPayerBillingEmailErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.dispatcher_notes';
    } & TripsCreateTripsIndexPayerDispatcherNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.admin_notes';
    } & TripsCreateTripsIndexPayerAdminNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.deleted_at';
    } & TripsCreateTripsIndexPayerDeletedAtErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.non_field_errors';
    } & TripsCreateTripsIndexPayerBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.location.non_field_errors';
    } & TripsCreateTripsIndexPayerBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.location.place_id';
    } & TripsCreateTripsIndexPayerBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.label';
    } & TripsCreateTripsIndexPayerBillingAddressLabelErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.label2';
    } & TripsCreateTripsIndexPayerBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.address_notes';
    } & TripsCreateTripsIndexPayerBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.billing_address.address_type';
    } & TripsCreateTripsIndexPayerBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.non_field_errors';
    } & TripsCreateTripsIndexPayerDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.location.non_field_errors';
    } & TripsCreateTripsIndexPayerDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.location.place_id';
    } & TripsCreateTripsIndexPayerDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.label';
    } & TripsCreateTripsIndexPayerDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.label2';
    } & TripsCreateTripsIndexPayerDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.address_notes';
    } & TripsCreateTripsIndexPayerDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.default_trip_address.address_type';
    } & TripsCreateTripsIndexPayerDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.multiloading_partners.non_field_errors';
    } & TripsCreateTripsIndexPayerMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.payer.multiloading_partners.INDEX';
    } & TripsCreateTripsIndexPayerMultiloadingPartnersIndexErrorComponent)
  | ({
      attr: 'trips.INDEX.payer_id';
    } & TripsCreateTripsIndexPayerIdErrorComponent)
  | ({
      attr: 'trips.INDEX.payer_passenger_external_id';
    } & TripsCreateTripsIndexPayerPassengerExternalIdErrorComponent)
  | ({
      attr: 'trips.INDEX.external_trip_id';
    } & TripsCreateTripsIndexExternalTripIdErrorComponent)
  | ({
      attr: 'trips.INDEX.input_price_cents';
    } & TripsCreateTripsIndexInputPriceCentsErrorComponent)
  | ({
      attr: 'trips.INDEX.payment_method';
    } & TripsCreateTripsIndexPaymentMethodErrorComponent)
  | ({
      attr: 'trips.INDEX.scheduled_pickup_at';
    } & TripsCreateTripsIndexScheduledPickupAtErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.non_field_errors';
    } & TripsCreateTripsIndexPickupNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.address_details';
    } & TripsCreateTripsIndexPickupAddressDetailsErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.num_stairs';
    } & TripsCreateTripsIndexPickupNumStairsErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.stair_equipment';
    } & TripsCreateTripsIndexPickupStairEquipmentErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.contact_name';
    } & TripsCreateTripsIndexPickupContactNameErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.contact_phone_number';
    } & TripsCreateTripsIndexPickupContactPhoneNumberErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.driver_notes';
    } & TripsCreateTripsIndexPickupDriverNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup.place_id';
    } & TripsCreateTripsIndexPickupPlaceIdErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.non_field_errors';
    } & TripsCreateTripsIndexDropoffNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.address_details';
    } & TripsCreateTripsIndexDropoffAddressDetailsErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.num_stairs';
    } & TripsCreateTripsIndexDropoffNumStairsErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.stair_equipment';
    } & TripsCreateTripsIndexDropoffStairEquipmentErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.contact_name';
    } & TripsCreateTripsIndexDropoffContactNameErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.contact_phone_number';
    } & TripsCreateTripsIndexDropoffContactPhoneNumberErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.driver_notes';
    } & TripsCreateTripsIndexDropoffDriverNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.dropoff.place_id';
    } & TripsCreateTripsIndexDropoffPlaceIdErrorComponent)
  | ({
      attr: 'trips.INDEX.appointment_at';
    } & TripsCreateTripsIndexAppointmentAtErrorComponent)
  | ({
      attr: 'trips.INDEX.is_will_call';
    } & TripsCreateTripsIndexIsWillCallErrorComponent)
  | ({
      attr: 'trips.INDEX.space_type';
    } & TripsCreateTripsIndexSpaceTypeErrorComponent)
  | ({
      attr: 'trips.INDEX.must_provide_wheelchair';
    } & TripsCreateTripsIndexMustProvideWheelchairErrorComponent)
  | ({
      attr: 'trips.INDEX.is_transporting_scooter';
    } & TripsCreateTripsIndexIsTransportingScooterErrorComponent)
  | ({
      attr: 'trips.INDEX.service_type';
    } & TripsCreateTripsIndexServiceTypeErrorComponent)
  | ({
      attr: 'trips.INDEX.dispatcher_notes';
    } & TripsCreateTripsIndexDispatcherNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.driver_entered_notes';
    } & TripsCreateTripsIndexDriverEnteredNotesErrorComponent)
  | ({
      attr: 'trips.INDEX.is_oxygen_required';
    } & TripsCreateTripsIndexIsOxygenRequiredErrorComponent)
  | ({
      attr: 'trips.INDEX.oxygen_liters_per_min';
    } & TripsCreateTripsIndexOxygenLitersPerMinErrorComponent)
  | ({
      attr: 'trips.INDEX.num_attendants_needed';
    } & TripsCreateTripsIndexNumAttendantsNeededErrorComponent)
  | ({
      attr: 'trips.INDEX.num_accompanying_passengers';
    } & TripsCreateTripsIndexNumAccompanyingPassengersErrorComponent)
  | ({
      attr: 'trips.INDEX.one_schema_template_id';
    } & TripsCreateTripsIndexOneSchemaTemplateIdErrorComponent)
  | ({
      attr: 'trips.INDEX.one_schema_template_multi_id';
    } & TripsCreateTripsIndexOneSchemaTemplateMultiIdErrorComponent)
  | ({
      attr: 'trips.INDEX.correlation_id';
    } & TripsCreateTripsIndexCorrelationIdErrorComponent)
  | ({
      attr: 'trips.INDEX.booked_by_name';
    } & TripsCreateTripsIndexBookedByNameErrorComponent)
  | ({
      attr: 'trips.INDEX.booked_by_phone_number';
    } & TripsCreateTripsIndexBookedByPhoneNumberErrorComponent)
  | ({
      attr: 'trips.INDEX.booked_by_phone_extension';
    } & TripsCreateTripsIndexBookedByPhoneExtensionErrorComponent)
  | ({
      attr: 'trips.INDEX.load_time_seconds';
    } & TripsCreateTripsIndexLoadTimeSecondsErrorComponent)
  | ({
      attr: 'trips.INDEX.unload_time_seconds';
    } & TripsCreateTripsIndexUnloadTimeSecondsErrorComponent)
  | ({
      attr: 'trips.INDEX.pickup_wait_time_seconds';
    } & TripsCreateTripsIndexPickupWaitTimeSecondsErrorComponent)
  | ({
      attr: 'trips.INDEX.tag_ids';
    } & TripsCreateTripsIndexTagIdsErrorComponent)
  | ({
      attr: 'trips.INDEX.tag_ids.INDEX';
    } & TripsCreateTripsIndexTagIdsIndexErrorComponent)
  | ({
      attr: 'trips.INDEX.tags.INDEX.tag_name';
    } & TripsCreateTripsIndexTagsIndexTagNameErrorComponent)
  | ({
      attr: 'trips.INDEX.tags.INDEX.color_hex';
    } & TripsCreateTripsIndexTagsIndexColorHexErrorComponent)
  | ({
      attr: 'trips.INDEX.is_wait_and_return_outbound';
    } & TripsCreateTripsIndexIsWaitAndReturnOutboundErrorComponent)
  | ({
      attr: 'trips.INDEX.is_wait_and_return_inbound';
    } & TripsCreateTripsIndexIsWaitAndReturnInboundErrorComponent)
  | ({
      attr: 'trips.INDEX.authorization_id';
    } & TripsCreateTripsIndexAuthorizationIdErrorComponent)
  | ({
      attr: 'trips.INDEX.insurance_policy_id';
    } & TripsCreateTripsIndexInsurancePolicyIdErrorComponent)
  | ({
      attr: 'trips.INDEX.is_price_locked';
    } & TripsCreateTripsIndexIsPriceLockedErrorComponent)
  | ({
      attr: 'trips.INDEX.link_to_payer';
    } & TripsCreateTripsIndexLinkToPayerErrorComponent)
  | ({
      attr: 'trips.INDEX.confirmation_code';
    } & TripsCreateTripsIndexConfirmationCodeErrorComponent)
  | ({
      attr: 'trips.INDEX.has_infectious_disease';
    } & TripsCreateTripsIndexHasInfectiousDiseaseErrorComponent);
export type TripsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsCreateError[];
};
export type TripsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsPartialUpdateAssignmentStartErrorComponent = {
  attr: 'assignment.start';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsPartialUpdateAssignmentEndErrorComponent = {
  attr: 'assignment.end';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsPartialUpdatePassengerNonFieldErrorsErrorComponent = {
  attr: 'passenger.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdatePassengerIdErrorComponent = {
  attr: 'passenger.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdatePassengerFirstNameErrorComponent = {
  attr: 'passenger.first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerLastNameErrorComponent = {
  attr: 'passenger.last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerPreferredNameErrorComponent = {
  attr: 'passenger.preferred_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerPronounErrorComponent = {
  attr: 'passenger.pronoun';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerGenderErrorComponent = {
  attr: 'passenger.gender';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerDobErrorComponent = {
  attr: 'passenger.dob';
  code: 'datetime' | 'invalid';
  detail: string;
};
export type TripsPartialUpdatePassengerWeightErrorComponent = {
  attr: 'passenger.weight';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsPartialUpdatePassengerNeedsBariatricTransportErrorComponent = {
  attr: 'passenger.needs_bariatric_transport';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdatePassengerPhoneNumberErrorComponent = {
  attr: 'passenger.phone_number';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerEmailErrorComponent = {
  attr: 'passenger.email';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerNotesErrorComponent = {
  attr: 'passenger.notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerDispatcherNotesErrorComponent = {
  attr: 'passenger.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePassengerAllowSmsMessageErrorComponent = {
  attr: 'passenger.allow_sms_message';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdatePayerPayerErrorComponent = {
  attr: 'payer.payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type TripsPartialUpdatePayerDisplayNameErrorComponent = {
  attr: 'payer.display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerAlternateNameErrorComponent = {
  attr: 'payer.alternate_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerTypeErrorComponent = {
  attr: 'payer.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsPartialUpdatePayerIsActiveErrorComponent = {
  attr: 'payer.is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdatePayerCanMultiloadErrorComponent = {
  attr: 'payer.can_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdatePayerExternalIdErrorComponent = {
  attr: 'payer.external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerWebsiteErrorComponent = {
  attr: 'payer.website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerEinErrorComponent = {
  attr: 'payer.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerEmailErrorComponent = {
  attr: 'payer.email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerPhoneNumberErrorComponent = {
  attr: 'payer.phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerBillingNameErrorComponent = {
  attr: 'payer.billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerBillingPhoneNumberErrorComponent = {
  attr: 'payer.billing_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerBillingEmailErrorComponent = {
  attr: 'payer.billing_email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerDispatcherNotesErrorComponent = {
  attr: 'payer.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerAdminNotesErrorComponent = {
  attr: 'payer.admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerDeletedAtErrorComponent = {
  attr: 'payer.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsPartialUpdatePayerBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsPartialUpdatePayerBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsPartialUpdatePayerBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsPartialUpdatePayerBillingAddressLabelErrorComponent = {
  attr: 'payer.billing_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerBillingAddressLabel2ErrorComponent = {
  attr: 'payer.billing_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerBillingAddressAddressNotesErrorComponent = {
  attr: 'payer.billing_address.address_notes';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerBillingAddressAddressTypeErrorComponent = {
  attr: 'payer.billing_address.address_type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsPartialUpdatePayerDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsPartialUpdatePayerDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsPartialUpdatePayerDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsPartialUpdatePayerDefaultTripAddressLabelErrorComponent = {
  attr: 'payer.default_trip_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerDefaultTripAddressLabel2ErrorComponent = {
  attr: 'payer.default_trip_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePayerDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'payer.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsPartialUpdatePayerDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'payer.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsPartialUpdatePayerMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'payer.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type TripsPartialUpdatePayerMultiloadingPartnersIndexErrorComponent = {
  attr: 'payer.multiloading_partners.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdatePayerPassengerExternalIdErrorComponent = {
  attr: 'payer_passenger_external_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateExternalTripIdErrorComponent = {
  attr: 'external_trip_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateInputPriceCentsErrorComponent = {
  attr: 'input_price_cents';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type TripsPartialUpdatePaymentMethodErrorComponent = {
  attr: 'payment_method';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsPartialUpdateScheduledPickupAtErrorComponent = {
  attr: 'scheduled_pickup_at';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsPartialUpdatePickupNonFieldErrorsErrorComponent = {
  attr: 'pickup.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdatePickupAddressDetailsErrorComponent = {
  attr: 'pickup.address_details';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePickupNumStairsErrorComponent = {
  attr: 'pickup.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsPartialUpdatePickupStairEquipmentErrorComponent = {
  attr: 'pickup.stair_equipment';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsPartialUpdatePickupContactNameErrorComponent = {
  attr: 'pickup.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePickupContactPhoneNumberErrorComponent = {
  attr: 'pickup.contact_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePickupDriverNotesErrorComponent = {
  attr: 'pickup.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdatePickupPlaceIdErrorComponent = {
  attr: 'pickup.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateDropoffNonFieldErrorsErrorComponent = {
  attr: 'dropoff.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdateDropoffAddressDetailsErrorComponent = {
  attr: 'dropoff.address_details';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateDropoffNumStairsErrorComponent = {
  attr: 'dropoff.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsPartialUpdateDropoffStairEquipmentErrorComponent = {
  attr: 'dropoff.stair_equipment';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsPartialUpdateDropoffContactNameErrorComponent = {
  attr: 'dropoff.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateDropoffContactPhoneNumberErrorComponent = {
  attr: 'dropoff.contact_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateDropoffDriverNotesErrorComponent = {
  attr: 'dropoff.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateDropoffPlaceIdErrorComponent = {
  attr: 'dropoff.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateAppointmentAtErrorComponent = {
  attr: 'appointment_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsPartialUpdateIsWillCallErrorComponent = {
  attr: 'is_will_call';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdateMustProvideWheelchairErrorComponent = {
  attr: 'must_provide_wheelchair';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateIsTransportingScooterErrorComponent = {
  attr: 'is_transporting_scooter';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateServiceTypeErrorComponent = {
  attr: 'service_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateDriverEnteredNotesErrorComponent = {
  attr: 'driver_entered_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateIsOxygenRequiredErrorComponent = {
  attr: 'is_oxygen_required';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateOxygenLitersPerMinErrorComponent = {
  attr: 'oxygen_liters_per_min';
  code:
    | 'invalid'
    | 'max_decimal_places'
    | 'max_digits'
    | 'max_string_length'
    | 'max_whole_digits'
    | 'null';
  detail: string;
};
export type TripsPartialUpdateNumAttendantsNeededErrorComponent = {
  attr: 'num_attendants_needed';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsPartialUpdateNumAccompanyingPassengersErrorComponent = {
  attr: 'num_accompanying_passengers';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsPartialUpdateOneSchemaTemplateIdErrorComponent = {
  attr: 'one_schema_template_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateOneSchemaTemplateMultiIdErrorComponent = {
  attr: 'one_schema_template_multi_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateCorrelationIdErrorComponent = {
  attr: 'correlation_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateBookedByNameErrorComponent = {
  attr: 'booked_by_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateBookedByPhoneNumberErrorComponent = {
  attr: 'booked_by_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateBookedByPhoneExtensionErrorComponent = {
  attr: 'booked_by_phone_extension';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateLoadTimeSecondsErrorComponent = {
  attr: 'load_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsPartialUpdateUnloadTimeSecondsErrorComponent = {
  attr: 'unload_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsPartialUpdatePickupWaitTimeSecondsErrorComponent = {
  attr: 'pickup_wait_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsPartialUpdateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type TripsPartialUpdateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsPartialUpdateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateIsWaitAndReturnOutboundErrorComponent = {
  attr: 'is_wait_and_return_outbound';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateIsWaitAndReturnInboundErrorComponent = {
  attr: 'is_wait_and_return_inbound';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateAuthorizationIdErrorComponent = {
  attr: 'authorization_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateInsurancePolicyIdErrorComponent = {
  attr: 'insurance_policy_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateIsPriceLockedErrorComponent = {
  attr: 'is_price_locked';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateLinkToPayerErrorComponent = {
  attr: 'link_to_payer';
  code: 'invalid_choice';
  detail: string;
};
export type TripsPartialUpdateConfirmationCodeErrorComponent = {
  attr: 'confirmation_code';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsPartialUpdateHasInfectiousDiseaseErrorComponent = {
  attr: 'has_infectious_disease';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment.start';
    } & TripsPartialUpdateAssignmentStartErrorComponent)
  | ({
      attr: 'assignment.end';
    } & TripsPartialUpdateAssignmentEndErrorComponent)
  | ({
      attr: 'passenger.non_field_errors';
    } & TripsPartialUpdatePassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.id';
    } & TripsPartialUpdatePassengerIdErrorComponent)
  | ({
      attr: 'passenger.first_name';
    } & TripsPartialUpdatePassengerFirstNameErrorComponent)
  | ({
      attr: 'passenger.last_name';
    } & TripsPartialUpdatePassengerLastNameErrorComponent)
  | ({
      attr: 'passenger.preferred_name';
    } & TripsPartialUpdatePassengerPreferredNameErrorComponent)
  | ({
      attr: 'passenger.pronoun';
    } & TripsPartialUpdatePassengerPronounErrorComponent)
  | ({
      attr: 'passenger.gender';
    } & TripsPartialUpdatePassengerGenderErrorComponent)
  | ({
      attr: 'passenger.dob';
    } & TripsPartialUpdatePassengerDobErrorComponent)
  | ({
      attr: 'passenger.weight';
    } & TripsPartialUpdatePassengerWeightErrorComponent)
  | ({
      attr: 'passenger.needs_bariatric_transport';
    } & TripsPartialUpdatePassengerNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'passenger.phone_number';
    } & TripsPartialUpdatePassengerPhoneNumberErrorComponent)
  | ({
      attr: 'passenger.email';
    } & TripsPartialUpdatePassengerEmailErrorComponent)
  | ({
      attr: 'passenger.notes';
    } & TripsPartialUpdatePassengerNotesErrorComponent)
  | ({
      attr: 'passenger.dispatcher_notes';
    } & TripsPartialUpdatePassengerDispatcherNotesErrorComponent)
  | ({
      attr: 'passenger.allow_sms_message';
    } & TripsPartialUpdatePassengerAllowSmsMessageErrorComponent)
  | ({
      attr: 'payer.payer';
    } & TripsPartialUpdatePayerPayerErrorComponent)
  | ({
      attr: 'payer.display_name';
    } & TripsPartialUpdatePayerDisplayNameErrorComponent)
  | ({
      attr: 'payer.alternate_name';
    } & TripsPartialUpdatePayerAlternateNameErrorComponent)
  | ({
      attr: 'payer.type';
    } & TripsPartialUpdatePayerTypeErrorComponent)
  | ({
      attr: 'payer.is_active';
    } & TripsPartialUpdatePayerIsActiveErrorComponent)
  | ({
      attr: 'payer.can_multiload';
    } & TripsPartialUpdatePayerCanMultiloadErrorComponent)
  | ({
      attr: 'payer.external_id';
    } & TripsPartialUpdatePayerExternalIdErrorComponent)
  | ({
      attr: 'payer.website';
    } & TripsPartialUpdatePayerWebsiteErrorComponent)
  | ({
      attr: 'payer.ein';
    } & TripsPartialUpdatePayerEinErrorComponent)
  | ({
      attr: 'payer.email';
    } & TripsPartialUpdatePayerEmailErrorComponent)
  | ({
      attr: 'payer.phone_number';
    } & TripsPartialUpdatePayerPhoneNumberErrorComponent)
  | ({
      attr: 'payer.billing_name';
    } & TripsPartialUpdatePayerBillingNameErrorComponent)
  | ({
      attr: 'payer.billing_phone_number';
    } & TripsPartialUpdatePayerBillingPhoneNumberErrorComponent)
  | ({
      attr: 'payer.billing_email';
    } & TripsPartialUpdatePayerBillingEmailErrorComponent)
  | ({
      attr: 'payer.dispatcher_notes';
    } & TripsPartialUpdatePayerDispatcherNotesErrorComponent)
  | ({
      attr: 'payer.admin_notes';
    } & TripsPartialUpdatePayerAdminNotesErrorComponent)
  | ({
      attr: 'payer.deleted_at';
    } & TripsPartialUpdatePayerDeletedAtErrorComponent)
  | ({
      attr: 'payer.billing_address.non_field_errors';
    } & TripsPartialUpdatePayerBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.billing_address.location.non_field_errors';
    } & TripsPartialUpdatePayerBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.billing_address.location.place_id';
    } & TripsPartialUpdatePayerBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer.billing_address.label';
    } & TripsPartialUpdatePayerBillingAddressLabelErrorComponent)
  | ({
      attr: 'payer.billing_address.label2';
    } & TripsPartialUpdatePayerBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'payer.billing_address.address_notes';
    } & TripsPartialUpdatePayerBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer.billing_address.address_type';
    } & TripsPartialUpdatePayerBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer.default_trip_address.non_field_errors';
    } & TripsPartialUpdatePayerDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.default_trip_address.location.non_field_errors';
    } & TripsPartialUpdatePayerDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.default_trip_address.location.place_id';
    } & TripsPartialUpdatePayerDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer.default_trip_address.label';
    } & TripsPartialUpdatePayerDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'payer.default_trip_address.label2';
    } & TripsPartialUpdatePayerDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'payer.default_trip_address.address_notes';
    } & TripsPartialUpdatePayerDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer.default_trip_address.address_type';
    } & TripsPartialUpdatePayerDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer.multiloading_partners.non_field_errors';
    } & TripsPartialUpdatePayerMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.multiloading_partners.INDEX';
    } & TripsPartialUpdatePayerMultiloadingPartnersIndexErrorComponent)
  | ({
      attr: 'payer_id';
    } & TripsPartialUpdatePayerIdErrorComponent)
  | ({
      attr: 'payer_passenger_external_id';
    } & TripsPartialUpdatePayerPassengerExternalIdErrorComponent)
  | ({
      attr: 'external_trip_id';
    } & TripsPartialUpdateExternalTripIdErrorComponent)
  | ({
      attr: 'input_price_cents';
    } & TripsPartialUpdateInputPriceCentsErrorComponent)
  | ({
      attr: 'payment_method';
    } & TripsPartialUpdatePaymentMethodErrorComponent)
  | ({
      attr: 'scheduled_pickup_at';
    } & TripsPartialUpdateScheduledPickupAtErrorComponent)
  | ({
      attr: 'pickup.non_field_errors';
    } & TripsPartialUpdatePickupNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickup.address_details';
    } & TripsPartialUpdatePickupAddressDetailsErrorComponent)
  | ({
      attr: 'pickup.num_stairs';
    } & TripsPartialUpdatePickupNumStairsErrorComponent)
  | ({
      attr: 'pickup.stair_equipment';
    } & TripsPartialUpdatePickupStairEquipmentErrorComponent)
  | ({
      attr: 'pickup.contact_name';
    } & TripsPartialUpdatePickupContactNameErrorComponent)
  | ({
      attr: 'pickup.contact_phone_number';
    } & TripsPartialUpdatePickupContactPhoneNumberErrorComponent)
  | ({
      attr: 'pickup.driver_notes';
    } & TripsPartialUpdatePickupDriverNotesErrorComponent)
  | ({
      attr: 'pickup.place_id';
    } & TripsPartialUpdatePickupPlaceIdErrorComponent)
  | ({
      attr: 'dropoff.non_field_errors';
    } & TripsPartialUpdateDropoffNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoff.address_details';
    } & TripsPartialUpdateDropoffAddressDetailsErrorComponent)
  | ({
      attr: 'dropoff.num_stairs';
    } & TripsPartialUpdateDropoffNumStairsErrorComponent)
  | ({
      attr: 'dropoff.stair_equipment';
    } & TripsPartialUpdateDropoffStairEquipmentErrorComponent)
  | ({
      attr: 'dropoff.contact_name';
    } & TripsPartialUpdateDropoffContactNameErrorComponent)
  | ({
      attr: 'dropoff.contact_phone_number';
    } & TripsPartialUpdateDropoffContactPhoneNumberErrorComponent)
  | ({
      attr: 'dropoff.driver_notes';
    } & TripsPartialUpdateDropoffDriverNotesErrorComponent)
  | ({
      attr: 'dropoff.place_id';
    } & TripsPartialUpdateDropoffPlaceIdErrorComponent)
  | ({
      attr: 'appointment_at';
    } & TripsPartialUpdateAppointmentAtErrorComponent)
  | ({
      attr: 'is_will_call';
    } & TripsPartialUpdateIsWillCallErrorComponent)
  | ({
      attr: 'space_type';
    } & TripsPartialUpdateSpaceTypeErrorComponent)
  | ({
      attr: 'must_provide_wheelchair';
    } & TripsPartialUpdateMustProvideWheelchairErrorComponent)
  | ({
      attr: 'is_transporting_scooter';
    } & TripsPartialUpdateIsTransportingScooterErrorComponent)
  | ({
      attr: 'service_type';
    } & TripsPartialUpdateServiceTypeErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & TripsPartialUpdateDispatcherNotesErrorComponent)
  | ({
      attr: 'driver_entered_notes';
    } & TripsPartialUpdateDriverEnteredNotesErrorComponent)
  | ({
      attr: 'is_oxygen_required';
    } & TripsPartialUpdateIsOxygenRequiredErrorComponent)
  | ({
      attr: 'oxygen_liters_per_min';
    } & TripsPartialUpdateOxygenLitersPerMinErrorComponent)
  | ({
      attr: 'num_attendants_needed';
    } & TripsPartialUpdateNumAttendantsNeededErrorComponent)
  | ({
      attr: 'num_accompanying_passengers';
    } & TripsPartialUpdateNumAccompanyingPassengersErrorComponent)
  | ({
      attr: 'one_schema_template_id';
    } & TripsPartialUpdateOneSchemaTemplateIdErrorComponent)
  | ({
      attr: 'one_schema_template_multi_id';
    } & TripsPartialUpdateOneSchemaTemplateMultiIdErrorComponent)
  | ({
      attr: 'correlation_id';
    } & TripsPartialUpdateCorrelationIdErrorComponent)
  | ({
      attr: 'booked_by_name';
    } & TripsPartialUpdateBookedByNameErrorComponent)
  | ({
      attr: 'booked_by_phone_number';
    } & TripsPartialUpdateBookedByPhoneNumberErrorComponent)
  | ({
      attr: 'booked_by_phone_extension';
    } & TripsPartialUpdateBookedByPhoneExtensionErrorComponent)
  | ({
      attr: 'load_time_seconds';
    } & TripsPartialUpdateLoadTimeSecondsErrorComponent)
  | ({
      attr: 'unload_time_seconds';
    } & TripsPartialUpdateUnloadTimeSecondsErrorComponent)
  | ({
      attr: 'pickup_wait_time_seconds';
    } & TripsPartialUpdatePickupWaitTimeSecondsErrorComponent)
  | ({
      attr: 'tag_ids';
    } & TripsPartialUpdateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & TripsPartialUpdateTagIdsIndexErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & TripsPartialUpdateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & TripsPartialUpdateTagsIndexColorHexErrorComponent)
  | ({
      attr: 'is_wait_and_return_outbound';
    } & TripsPartialUpdateIsWaitAndReturnOutboundErrorComponent)
  | ({
      attr: 'is_wait_and_return_inbound';
    } & TripsPartialUpdateIsWaitAndReturnInboundErrorComponent)
  | ({
      attr: 'authorization_id';
    } & TripsPartialUpdateAuthorizationIdErrorComponent)
  | ({
      attr: 'insurance_policy_id';
    } & TripsPartialUpdateInsurancePolicyIdErrorComponent)
  | ({
      attr: 'is_price_locked';
    } & TripsPartialUpdateIsPriceLockedErrorComponent)
  | ({
      attr: 'link_to_payer';
    } & TripsPartialUpdateLinkToPayerErrorComponent)
  | ({
      attr: 'confirmation_code';
    } & TripsPartialUpdateConfirmationCodeErrorComponent)
  | ({
      attr: 'has_infectious_disease';
    } & TripsPartialUpdateHasInfectiousDiseaseErrorComponent);
export type TripsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsPartialUpdateError[];
};
export type TripsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTrip = {
  passenger?: PatchedTripPassenger;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  scheduled_pickup_at?: string;
  pickup?: TripLocationDetail;
  dropoff?: TripLocationDetail;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type?: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type?: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound?: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound?: boolean;
  /** The authorization ID for this trip */
  authorization_id?: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id?: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked?: boolean;
  link_to_payer?: (LinkToPayerEnum | NullEnum) | null;
  confirmation_code?: string;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
};
export type PatchedTripRead = {
  id?: string;
  assignment?: AssignmentMiniRead | null;
  /** Current status of this trip */
  status?: OverrideStatusEnum;
  /** Locked assignment cannot be changed */
  is_locked?: boolean | null;
  passenger?: PatchedTripPassengerRead;
  payer?: PayerOrganizationRead;
  payer_passenger?: TripPayerPassengerRead | null;
  early_pickup_at?: string;
  late_pickup_at?: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** The initial price of the trip calculated using a pricing model */
  initial_calculated_price_cents?: number | null;
  /** The final price of the trip calculated using a pricing model */
  final_calculated_price_cents?: number | null;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  scheduled_pickup_at?: string;
  current_task?: PurposeEnum;
  current_task_at?: string;
  pickup?: TripLocationDetailRead;
  dropoff?: TripLocationDetailRead;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** Whether the passenger has called in that they are ready to be picked up */
  is_will_call_activated?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type?: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type?: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  estimated_distance_miles?: number | null;
  /** The time the trip was canceled */
  canceled_at?: string | null;
  /** The main reason a trip was canceled */
  canceled_reason?: CanceledReasonEnum;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  timing?: TripTimingRead | null;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  tags?: TripTripTagRead[] | null;
  /** The suggested pickup time based off of run_bambi_run results */
  suggested_pickup_time?: string | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound?: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound?: boolean;
  /** The authorization ID for this trip */
  authorization_id?: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id?: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked?: boolean;
  link_to_payer?: (LinkToPayerEnum | NullEnum) | null;
  confirmation_code?: string;
  driver_name?: string;
  invoice_numbers?: string | null;
  invoice_id?: string | null;
  rejection_reason?: string | null;
  /** The trip ID used by an intermediary/broker, may or may not be the originator */
  intermediary_id?: string;
  /** The intermediary/broker source of this trip */
  intermediary?: IntermediaryEnum;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  completion_time?: string | null;
  vehicle_nickname?: string | null;
};
export type PatchedTripWrite = {
  passenger?: PatchedTripPassenger;
  payer_id?: string;
  payer_passenger_external_id?: string;
  /** The trip ID used by the trip originator, (e.g. payer or original broker) */
  external_trip_id?: string;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  scheduled_pickup_at?: string;
  pickup?: TripLocationDetail;
  dropoff?: TripLocationDetail;
  /** The time of the passenger's appointment */
  appointment_at?: string | null;
  /** Whether the passenger will call when it is time to be picked up */
  is_will_call?: boolean;
  /** The type of space in the vehicle this passenger will need */
  space_type?: SpaceTypeEnum;
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  service_type?: ServiceTypeEnum;
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  /** Notes entered by the driver */
  driver_entered_notes?: string;
  is_oxygen_required?: boolean;
  oxygen_liters_per_min?: string;
  num_attendants_needed?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  one_schema_template_id?: string;
  one_schema_template_multi_id?: string;
  correlation_id?: string;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
  pickup_wait_time_seconds?: number | null;
  tag_ids?: string[] | null;
  /** Whether this is the first leg of a wait and return trip */
  is_wait_and_return_outbound?: boolean;
  /** Whether this is the second leg of a wait and return trip */
  is_wait_and_return_inbound?: boolean;
  /** The authorization ID for this trip */
  authorization_id?: string;
  /** The insurance policy ID for this trip */
  insurance_policy_id?: string;
  /** Whether this trips pricing is locked (non-editable) */
  is_price_locked?: boolean;
  link_to_payer?: (LinkToPayerEnum | NullEnum) | null;
  confirmation_code?: string;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
};
export type TripsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripAccept = {};
export type TripAcceptWrite = {
  override?: boolean;
};
export type TripsAcceptPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsAcceptPartialUpdateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsAcceptPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsAcceptPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'override';
    } & TripsAcceptPartialUpdateOverrideErrorComponent);
export type TripsAcceptPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsAcceptPartialUpdateError[];
};
export type TripsAcceptPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsAcceptPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripAccept = {};
export type PatchedTripAcceptWrite = {
  override?: boolean;
};
export type TripActivateWillCall = {};
export type TripActivateWillCallWrite = {
  pickup_at: string;
};
export type TripsActivateWillCallPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsActivateWillCallPartialUpdatePickupAtErrorComponent = {
  attr: 'pickup_at';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsActivateWillCallPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsActivateWillCallPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickup_at';
    } & TripsActivateWillCallPartialUpdatePickupAtErrorComponent);
export type TripsActivateWillCallPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsActivateWillCallPartialUpdateError[];
};
export type TripsActivateWillCallPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsActivateWillCallPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripActivateWillCall = {};
export type PatchedTripActivateWillCallWrite = {
  pickup_at?: string;
};
export type TripAssign = {};
export type TripAssignWrite = {
  assignment_id: string;
};
export type TripsAssignPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsAssignPartialUpdateAssignmentIdErrorComponent = {
  attr: 'assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsAssignPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsAssignPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment_id';
    } & TripsAssignPartialUpdateAssignmentIdErrorComponent);
export type TripsAssignPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsAssignPartialUpdateError[];
};
export type TripsAssignPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsAssignPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripAssign = {};
export type PatchedTripAssignWrite = {
  assignment_id?: string;
};
export type TripCancel = {};
export type TripCancelWrite = {
  concurrent_trips?: string[];
  canceled_reason: string;
  override?: boolean;
  /** Cancel code */
  canceled_reason_code?: number;
};
export type TripsCancelPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsCancelPartialUpdateConcurrentTripsErrorComponent = {
  attr: 'concurrent_trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type TripsCancelPartialUpdateConcurrentTripsIndexErrorComponent = {
  attr: 'concurrent_trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCancelPartialUpdateCanceledReasonErrorComponent = {
  attr: 'canceled_reason';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsCancelPartialUpdateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCancelPartialUpdateCanceledReasonCodeErrorComponent = {
  attr: 'canceled_reason_code';
  code: 'invalid' | 'max_string_length' | 'null';
  detail: string;
};
export type TripsCancelPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsCancelPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'concurrent_trips';
    } & TripsCancelPartialUpdateConcurrentTripsErrorComponent)
  | ({
      attr: 'concurrent_trips.INDEX';
    } & TripsCancelPartialUpdateConcurrentTripsIndexErrorComponent)
  | ({
      attr: 'canceled_reason';
    } & TripsCancelPartialUpdateCanceledReasonErrorComponent)
  | ({
      attr: 'override';
    } & TripsCancelPartialUpdateOverrideErrorComponent)
  | ({
      attr: 'canceled_reason_code';
    } & TripsCancelPartialUpdateCanceledReasonCodeErrorComponent);
export type TripsCancelPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsCancelPartialUpdateError[];
};
export type TripsCancelPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsCancelPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripCancel = {};
export type PatchedTripCancelWrite = {
  concurrent_trips?: string[];
  canceled_reason?: string;
  override?: boolean;
  /** Cancel code */
  canceled_reason_code?: number;
};
export type TripCancelReasonCode = {};
export type TripCancelReasonCodeRead = {
  /** Map of cancellation reasons as keys to their integer values */
  cancel_codes: {
    [key: string]: string;
  } | null;
};
export type TripsCancelCodesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripCancelReasons = {};
export type TripCancelReason = {
  /** The reason for the cancellation to be shown to the user */
  label: string;
  /** The value of the reason for dropdowns, the cancel endpoint.  */
  value?: string;
};
export type TripCancelReasonsRead = {
  /** A list of valid reasons for canceling the trip. */
  cancel_reasons: TripCancelReason[];
};
export type TripsCancelReasonsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripComplete = {};
export type TripCompleteWrite = {
  concurrent_trips?: string[];
  override?: boolean;
};
export type TripsCompletePartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsCompletePartialUpdateConcurrentTripsErrorComponent = {
  attr: 'concurrent_trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type TripsCompletePartialUpdateConcurrentTripsIndexErrorComponent = {
  attr: 'concurrent_trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsCompletePartialUpdateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsCompletePartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsCompletePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'concurrent_trips';
    } & TripsCompletePartialUpdateConcurrentTripsErrorComponent)
  | ({
      attr: 'concurrent_trips.INDEX';
    } & TripsCompletePartialUpdateConcurrentTripsIndexErrorComponent)
  | ({
      attr: 'override';
    } & TripsCompletePartialUpdateOverrideErrorComponent);
export type TripsCompletePartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsCompletePartialUpdateError[];
};
export type TripsCompletePartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsCompletePartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripComplete = {};
export type PatchedTripCompleteWrite = {
  concurrent_trips?: string[];
  override?: boolean;
};
export type TripsCorrelatedListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripCreate = {};
export type TripCreateTripData = {};
export type TripCreateTripDataRead = {
  passenger_name: string | null;
  pickup: string | null;
  dropoff: string | null;
  /** The time of the passenger's appointment */
  appointment_at: string | null;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  service_type: ServiceTypeEnum;
  /** The manually inputted price of the trip */
  input_price_cents: number | null;
  scheduled_pickup_at: string | null;
  /** template used to create this trip, if uploaded via csv */
  one_schema_template_id: string | null;
  /** template used to create this trip, if backfilled via csv */
  one_schema_template_multi_id: string | null;
  created_at: string;
  person_name: string | null;
  payer_display_name: string | null;
  /** The trip ID used by an intermediary/broker, may or may not be the originator */
  intermediary_id: string;
};
export type TripCreateRead = {
  id: number;
  parsed_trip: TripCreateTripDataRead | null;
};
export type PaginatedTripCreateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripCreate[];
};
export type PaginatedTripCreateListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripCreateRead[];
};
export type TripsCreatesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripUpdateDriverEnteredNotes = {};
export type TripUpdateDriverEnteredNotesWrite = {
  driver_entered_notes: string;
};
export type TripsDriverEnteredNotesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsDriverEnteredNotesPartialUpdateDriverEnteredNotesErrorComponent =
  {
    attr: 'driver_entered_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsDriverEnteredNotesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsDriverEnteredNotesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'driver_entered_notes';
    } & TripsDriverEnteredNotesPartialUpdateDriverEnteredNotesErrorComponent);
export type TripsDriverEnteredNotesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsDriverEnteredNotesPartialUpdateError[];
};
export type TripsDriverEnteredNotesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsDriverEnteredNotesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripUpdateDriverEnteredNotes = {};
export type PatchedTripUpdateDriverEnteredNotesWrite = {
  driver_entered_notes?: string;
};
export type TripEvent = {};
export type TripEventEventTypeEnum =
  | 'status_en_route'
  | 'status_at_pickup'
  | 'status_transporting'
  | 'status_at_dropoff'
  | 'completion'
  | 'uncompletion'
  | 'cancellation'
  | 'uncancellation'
  | 'accept'
  | 'assign'
  | 'unassign'
  | 'lock'
  | 'unlock'
  | 'rejection'
  | 'unrejection'
  | 'activate_will_call'
  | 'update_pickup_at'
  | 'push_needs_at_pickup'
  | 'push_needs_transporting'
  | 'push_needs_dropoff'
  | 'push_needs_completed'
  | 'enter_pickup_geofence'
  | 'exit_pickup_geofence'
  | 'enter_dropoff_geofence'
  | 'exit_dropoff_geofence';
export type TripEventData = {
  assignment_id?: string | null;
  status?: string | null;
  previous_status?: string | null;
  canceled_reason?: string | null;
};
export type TripEventRead = {
  id: string;
  longitude: number | null;
  latitude: number | null;
  event_type: TripEventEventTypeEnum;
  occurred_at: string;
  person_name: string;
  data: TripEventData | null;
  raw_json_data: {
    [key: string]: any;
  } | null;
  assignment_id: string | null;
  vehicle: string | null;
  driver: string | null;
  attendants: string[] | null;
};
export type PaginatedTripEventList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripEvent[];
};
export type PaginatedTripEventListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripEventRead[];
};
export type TripsEventsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type LocationLogEntryMinimalReadOnly = {};
export type LocationLogEntryMinimalReadOnlyRead = {
  /** ISO-8601 UTC datetime provided by the native location API. */
  device_timestamp: string;
  longitude: number;
  latitude: number;
};
export type PaginatedLocationLogEntryMinimalReadOnlyList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: LocationLogEntryMinimalReadOnly[];
};
export type PaginatedLocationLogEntryMinimalReadOnlyListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: LocationLogEntryMinimalReadOnlyRead[];
};
export type TripsLocationsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsLockPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsLockPartialUpdateError = {
  attr: 'non_field_errors';
} & TripsLockPartialUpdateNonFieldErrorsErrorComponent;
export type TripsLockPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsLockPartialUpdateError[];
};
export type TripsLockPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsLockPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripOverrideStatusChange = {};
export type TripOverrideStatusChangeWrite = {
  concurrent_trips?: string[];
  override_status: OverrideStatusEnum;
};
export type TripsOverrideStatusPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsOverrideStatusPartialUpdateConcurrentTripsErrorComponent = {
  attr: 'concurrent_trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type TripsOverrideStatusPartialUpdateConcurrentTripsIndexErrorComponent =
  {
    attr: 'concurrent_trips.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsOverrideStatusPartialUpdateOverrideStatusErrorComponent = {
  attr: 'override_status';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsOverrideStatusPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsOverrideStatusPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'concurrent_trips';
    } & TripsOverrideStatusPartialUpdateConcurrentTripsErrorComponent)
  | ({
      attr: 'concurrent_trips.INDEX';
    } & TripsOverrideStatusPartialUpdateConcurrentTripsIndexErrorComponent)
  | ({
      attr: 'override_status';
    } & TripsOverrideStatusPartialUpdateOverrideStatusErrorComponent);
export type TripsOverrideStatusPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsOverrideStatusPartialUpdateError[];
};
export type TripsOverrideStatusPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsOverrideStatusPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripOverrideStatusChange = {};
export type PatchedTripOverrideStatusChangeWrite = {
  concurrent_trips?: string[];
  override_status?: OverrideStatusEnum;
};
export type TripPriceSummary = {};
export type TripPriceSummaryRead = {
  /** - This class represents the summary of all fees that are calculated
    and the associated subtotals.
    - The fields are all optional because they will only be calculated
    if the relevant fees are present on the PricingScheme model. */
  price_summary: {
    name: string;
    base_fee_summary: {
      sub_total: number;
    } | null;
    loaded_mileage_fee_summary: {
      miles: number;
      free_miles: number;
      rate: number;
      sub_total: number;
    } | null;
    long_distance_loaded_mileage_fee_summary: {
      miles: number;
      free_miles: number;
      rate: number;
      sub_total: number;
    } | null;
    unloaded_mileage_fee_summary: {
      miles: number;
      free_miles: number;
      rate: number;
      sub_total: number;
    } | null;
    wait_time_fee_summary: {
      minutes: number;
      free_minutes: number;
      period: number;
      rate: number;
      sub_total: number;
    } | null;
    no_show_fee_summary: {
      sub_total: number;
      cancellation_reason: string;
      canceled_at: string;
      scheduled_pickup_at: string;
      minutes_before_pickup: number;
      no_show_fee_type: string;
    } | null;
    late_cancellation_fee_summary: {
      sub_total: number;
      canceled_at: string;
      scheduled_pickup_at: string;
      minutes_before_pickup: number;
      late_cancellation_fee_type: string;
    } | null;
    pickup_stair_fee_summary: {
      sub_total: number;
      steps: number;
      minimum_steps: number;
      fee_type: string;
      rate: number;
    } | null;
    dropoff_stair_fee_summary: {
      sub_total: number;
      steps: number;
      minimum_steps: number;
      fee_type: string;
      rate: number;
    } | null;
    additional_attendants_fee_summary: {
      sub_total: number;
      additional_attendants: number;
      rate: number;
    } | null;
    additional_passengers_fee_summary: {
      sub_total: number;
      additional_passengers: number;
      rate: number;
    } | null;
    bariatric_fee_summary: {
      sub_total: number;
      is_bariatric: boolean;
    } | null;
    wheelchair_rental_fee_summary: {
      sub_total: number;
      is_wheelchair_provided: boolean;
    } | null;
    oxygen_fee_summary: {
      sub_total: number;
      is_oxygen_provided: boolean;
    } | null;
    after_hours_fee_summary: {
      sub_total: number;
      pickup_time: string;
      business_hours_start: string | null;
      business_hours_end: string | null;
    } | null;
    weekend_fee_summary: {
      sub_total: number;
      day_of_week: string;
    } | null;
    holiday_fee_summary: {
      sub_total: number;
      holiday: string | null;
      date: string;
    } | null;
    minimum_price_cents: number | null;
    infectious_disease_fee_summary: {
      sub_total: number;
      has_infectious_disease: boolean;
    } | null;
    same_day_booking_fee_summary: {
      sub_total: number;
    } | null;
    total: number;
  } | null;
};
export type TripsPriceSummaryRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripReassign = {};
export type TripReassignWrite = {
  assignment_id: string;
};
export type TripsReassignPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsReassignPartialUpdateAssignmentIdErrorComponent = {
  attr: 'assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsReassignPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsReassignPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment_id';
    } & TripsReassignPartialUpdateAssignmentIdErrorComponent);
export type TripsReassignPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsReassignPartialUpdateError[];
};
export type TripsReassignPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsReassignPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripReassign = {};
export type PatchedTripReassignWrite = {
  assignment_id?: string;
};
export type TripReject = {};
export type TripRejectWrite = {
  override?: boolean;
  rejected_reason: string;
};
export type TripsRejectPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsRejectPartialUpdateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsRejectPartialUpdateRejectedReasonErrorComponent = {
  attr: 'rejected_reason';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsRejectPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsRejectPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'override';
    } & TripsRejectPartialUpdateOverrideErrorComponent)
  | ({
      attr: 'rejected_reason';
    } & TripsRejectPartialUpdateRejectedReasonErrorComponent);
export type TripsRejectPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsRejectPartialUpdateError[];
};
export type TripsRejectPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsRejectPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripReject = {};
export type PatchedTripRejectWrite = {
  override?: boolean;
  rejected_reason?: string;
};
export type TripRejectionReasons = {};
export type TripRejectionReasonsRead = {
  /** Map of rejection reasons as keys to their integer/database values */
  rejection_reasons: {
    [key: string]: string;
  } | null;
};
export type TripsRejectCodesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripRejectReasons = {};
export type TripRejectReason = {
  /** The reason for the rejection to be shown to the user */
  label: string;
  /** The value of the reason for dropdowns, the rejection endpoint.  */
  value?: string;
};
export type TripRejectReasonsRead = {
  /** A list of valid reasons for rejecting the trip. */
  reject_reasons: TripRejectReason[];
};
export type TripsRejectReasonsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsRelatedListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsSignaturesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripStatusChange = {};
export type NewStatusEnum =
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff';
export type TripStatusChangeWrite = {
  concurrent_trips?: string[];
  new_status: NewStatusEnum;
};
export type TripsStatusPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsStatusPartialUpdateConcurrentTripsErrorComponent = {
  attr: 'concurrent_trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type TripsStatusPartialUpdateConcurrentTripsIndexErrorComponent = {
  attr: 'concurrent_trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsStatusPartialUpdateNewStatusErrorComponent = {
  attr: 'new_status';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsStatusPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsStatusPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'concurrent_trips';
    } & TripsStatusPartialUpdateConcurrentTripsErrorComponent)
  | ({
      attr: 'concurrent_trips.INDEX';
    } & TripsStatusPartialUpdateConcurrentTripsIndexErrorComponent)
  | ({
      attr: 'new_status';
    } & TripsStatusPartialUpdateNewStatusErrorComponent);
export type TripsStatusPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsStatusPartialUpdateError[];
};
export type TripsStatusPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsStatusPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripStatusChange = {};
export type PatchedTripStatusChangeWrite = {
  concurrent_trips?: string[];
  new_status?: NewStatusEnum;
};
export type TripSummary = {
  scheduled_pickup_at: string;
  actual_en_route_at: string;
  actual_at_pickup_at: string;
  actual_transporting_at: string;
  actual_at_dropoff_at: string;
  actual_completed_at: string;
  at_pickup_to_complete_duration_sec: number;
};
export type TripSummaryRead = {
  id: string;
  /** The assignment that this trip belongs to (optional) */
  assignment: string | null;
  passenger: PassengerMinimalReadOnlyRead;
  pickup: TripLocationDetailRead;
  dropoff: TripLocationDetailRead;
  scheduled_pickup_at: string;
  actual_en_route_at: string;
  actual_at_pickup_at: string;
  actual_transporting_at: string;
  actual_at_dropoff_at: string;
  actual_completed_at: string;
  at_pickup_to_complete_duration_sec: number;
  location_log_entries: LocationLogEntryRead;
};
export type TripsSummaryRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripTurnbackReason = {};
export type TripTurnbackReasonRead = {
  /** Map of turnback reasons as keys to their integer values (maybe) */
  turnback_reasons: {
    [key: string]: string;
  } | null;
};
export type TripsTurnbackReasonsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsUnassignPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsUnassignPartialUpdateError = {
  attr: 'non_field_errors';
} & TripsUnassignPartialUpdateNonFieldErrorsErrorComponent;
export type TripsUnassignPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsUnassignPartialUpdateError[];
};
export type TripsUnassignPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsUnassignPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripUncancel = {};
export type TripUncancelWrite = {
  concurrent_trips?: string[];
  override?: boolean;
};
export type TripsUncancelPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsUncancelPartialUpdateConcurrentTripsErrorComponent = {
  attr: 'concurrent_trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type TripsUncancelPartialUpdateConcurrentTripsIndexErrorComponent = {
  attr: 'concurrent_trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsUncancelPartialUpdateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsUncancelPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsUncancelPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'concurrent_trips';
    } & TripsUncancelPartialUpdateConcurrentTripsErrorComponent)
  | ({
      attr: 'concurrent_trips.INDEX';
    } & TripsUncancelPartialUpdateConcurrentTripsIndexErrorComponent)
  | ({
      attr: 'override';
    } & TripsUncancelPartialUpdateOverrideErrorComponent);
export type TripsUncancelPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsUncancelPartialUpdateError[];
};
export type TripsUncancelPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsUncancelPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripUncancel = {};
export type PatchedTripUncancelWrite = {
  concurrent_trips?: string[];
  override?: boolean;
};
export type TripsUnlockPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsUnlockPartialUpdateError = {
  attr: 'non_field_errors';
} & TripsUnlockPartialUpdateNonFieldErrorsErrorComponent;
export type TripsUnlockPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsUnlockPartialUpdateError[];
};
export type TripsUnlockPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsUnlockPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripUnreject = {};
export type TripUnrejectWrite = {
  override?: boolean;
};
export type TripsUnrejectPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsUnrejectPartialUpdateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsUnrejectPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsUnrejectPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'override';
    } & TripsUnrejectPartialUpdateOverrideErrorComponent);
export type TripsUnrejectPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsUnrejectPartialUpdateError[];
};
export type TripsUnrejectPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsUnrejectPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripUnreject = {};
export type PatchedTripUnrejectWrite = {
  override?: boolean;
};
export type TripUpdatePickupAt = {};
export type TripUpdatePickupAtWrite = {
  pickup_at: string;
};
export type TripsUpdatePickupAtPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsUpdatePickupAtPartialUpdatePickupAtErrorComponent = {
  attr: 'pickup_at';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsUpdatePickupAtPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsUpdatePickupAtPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickup_at';
    } & TripsUpdatePickupAtPartialUpdatePickupAtErrorComponent);
export type TripsUpdatePickupAtPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsUpdatePickupAtPartialUpdateError[];
};
export type TripsUpdatePickupAtPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsUpdatePickupAtPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripUpdatePickupAt = {};
export type PatchedTripUpdatePickupAtWrite = {
  pickup_at?: string;
};
export type TripUpdate = {};
export type TripUpdateRead = {
  id: number;
  raw_diff: {
    [key: string]: any;
  } | null;
  values_changed: {
    [key: string]: {
      old_value: string | null;
      new_value: string | null;
    };
  } | null;
  value_keys: string[] | null;
  person_name: string | null;
  created_at: string;
};
export type PaginatedTripUpdateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripUpdate[];
};
export type PaginatedTripUpdateListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripUpdateRead[];
};
export type TripsUpdatesListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripValidate = {};
export type TripValidateRead = {
  explanation: string[];
  can_assign: boolean;
};
export type TripValidateWrite = {
  assignment_id: string;
};
export type TripsValidatePartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsValidatePartialUpdateAssignmentIdErrorComponent = {
  attr: 'assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsValidatePartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsValidatePartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment_id';
    } & TripsValidatePartialUpdateAssignmentIdErrorComponent);
export type TripsValidatePartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsValidatePartialUpdateError[];
};
export type TripsValidatePartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsValidatePartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripValidate = {};
export type PatchedTripValidateRead = {
  explanation?: string[];
  can_assign?: boolean;
};
export type PatchedTripValidateWrite = {
  assignment_id?: string;
};
export type TripsAllListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type TripsAllListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type TripsAllListPayersErrorComponent = {
  attr: 'payers';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsAllListStatusErrorComponent = {
  attr: 'status';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsAllListPassengersErrorComponent = {
  attr: 'passengers';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsAllListTagsErrorComponent = {
  attr: 'tags';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsAllListVehiclesErrorComponent = {
  attr: 'vehicles';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsAllListPriceErrorComponent = {
  attr: 'price';
  code: 'invalid';
  detail: string;
};
export type TripsAllListError =
  | ({
      attr: 'range_start';
    } & TripsAllListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & TripsAllListRangeEndErrorComponent)
  | ({
      attr: 'payers';
    } & TripsAllListPayersErrorComponent)
  | ({
      attr: 'status';
    } & TripsAllListStatusErrorComponent)
  | ({
      attr: 'passengers';
    } & TripsAllListPassengersErrorComponent)
  | ({
      attr: 'tags';
    } & TripsAllListTagsErrorComponent)
  | ({
      attr: 'vehicles';
    } & TripsAllListVehiclesErrorComponent)
  | ({
      attr: 'price';
    } & TripsAllListPriceErrorComponent);
export type TripsAllListValidationError = {
  type: ValidationErrorEnum;
  errors: TripsAllListError[];
};
export type TripsAllListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsAllListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkTripError = {
  trip_id: string;
  error: string;
};
export type BulkTripAcceptResults = {
  trips_accepted: string[];
  trips_not_accepted: string[];
  errors?: BulkTripError[] | null;
};
export type TripsBulkAcceptCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkAcceptCreateTripIdsErrorComponent = {
  attr: 'trip_ids';
  code: 'min_length' | 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsBulkAcceptCreateTripIdsIndexErrorComponent = {
  attr: 'trip_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkAcceptCreateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsBulkAcceptCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkAcceptCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip_ids';
    } & TripsBulkAcceptCreateTripIdsErrorComponent)
  | ({
      attr: 'trip_ids.INDEX';
    } & TripsBulkAcceptCreateTripIdsIndexErrorComponent)
  | ({
      attr: 'override';
    } & TripsBulkAcceptCreateOverrideErrorComponent);
export type TripsBulkAcceptCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkAcceptCreateError[];
};
export type TripsBulkAcceptCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkAcceptCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkTripAccept = {
  trip_ids: string[];
};
export type BulkTripAcceptWrite = {
  trip_ids: string[];
  override?: boolean;
};
export type BulkTripAssign = {};
export type BulkTripAssignWrite = {
  assignment_id: string;
  trip_ids: string[];
};
export type TripsBulkAssignCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkAssignCreateAssignmentIdErrorComponent = {
  attr: 'assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkAssignCreateTripIdsErrorComponent = {
  attr: 'trip_ids';
  code: 'min_length' | 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsBulkAssignCreateTripIdsIndexErrorComponent = {
  attr: 'trip_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkAssignCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkAssignCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment_id';
    } & TripsBulkAssignCreateAssignmentIdErrorComponent)
  | ({
      attr: 'trip_ids';
    } & TripsBulkAssignCreateTripIdsErrorComponent)
  | ({
      attr: 'trip_ids.INDEX';
    } & TripsBulkAssignCreateTripIdsIndexErrorComponent);
export type TripsBulkAssignCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkAssignCreateError[];
};
export type TripsBulkAssignCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkAssignCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkTripCancelResults = {
  trips_canceled: string[];
  trips_not_canceled: string[];
  errors?: BulkTripError[] | null;
};
export type TripsBulkCancelCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkCancelCreateTripReasonsErrorComponent = {
  attr: 'trip_reasons';
  code: 'min_length' | 'not_a_list';
  detail: string;
};
export type TripsBulkCancelCreateTripReasonsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'trip_reasons.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsBulkCancelCreateTripReasonsIndexTripIdErrorComponent = {
  attr: 'trip_reasons.INDEX.trip_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkCancelCreateTripReasonsIndexCanceledReasonErrorComponent =
  {
    attr: 'trip_reasons.INDEX.canceled_reason';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsBulkCancelCreateTripIdsErrorComponent = {
  attr: 'trip_ids';
  code: 'min_length' | 'not_a_list';
  detail: string;
};
export type TripsBulkCancelCreateTripIdsIndexErrorComponent = {
  attr: 'trip_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkCancelCreateCanceledReasonErrorComponent = {
  attr: 'canceled_reason';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsBulkCancelCreateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsBulkCancelCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkCancelCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip_reasons';
    } & TripsBulkCancelCreateTripReasonsErrorComponent)
  | ({
      attr: 'trip_reasons.INDEX.non_field_errors';
    } & TripsBulkCancelCreateTripReasonsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip_reasons.INDEX.trip_id';
    } & TripsBulkCancelCreateTripReasonsIndexTripIdErrorComponent)
  | ({
      attr: 'trip_reasons.INDEX.canceled_reason';
    } & TripsBulkCancelCreateTripReasonsIndexCanceledReasonErrorComponent)
  | ({
      attr: 'trip_ids';
    } & TripsBulkCancelCreateTripIdsErrorComponent)
  | ({
      attr: 'trip_ids.INDEX';
    } & TripsBulkCancelCreateTripIdsIndexErrorComponent)
  | ({
      attr: 'canceled_reason';
    } & TripsBulkCancelCreateCanceledReasonErrorComponent)
  | ({
      attr: 'override';
    } & TripsBulkCancelCreateOverrideErrorComponent);
export type TripsBulkCancelCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkCancelCreateError[];
};
export type TripsBulkCancelCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkCancelCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripCanceledReason = {
  trip_id: string;
  canceled_reason: string;
};
export type BulkTripCancel = {
  trip_reasons?: TripCanceledReason[] | null;
  trip_ids?: string[] | null;
};
export type BulkTripCancelWrite = {
  trip_reasons?: TripCanceledReason[] | null;
  trip_ids?: string[] | null;
  canceled_reason?: CanceledReasonEnum;
  override?: boolean;
};
export type BulkTripCancelReasons = {};
export type BulkTripCancelReason = {};
export type BulkTripCancelReasonRead = {
  trip_id: string;
  cancel_reasons: TripCancelReason[];
};
export type BulkTripCancelReasonsRead = {
  results: BulkTripCancelReasonRead[];
};
export type BulkTripCancelReasonsWrite = {
  trip_ids: string[];
};
export type TripsBulkCancelReasonsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkCancelReasonsCreateTripIdsErrorComponent = {
  attr: 'trip_ids';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsBulkCancelReasonsCreateTripIdsIndexErrorComponent = {
  attr: 'trip_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkCancelReasonsCreateResultsIndexCancelReasonsIndexLabelErrorComponent =
  {
    attr: 'results.INDEX.cancel_reasons.INDEX.label';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsBulkCancelReasonsCreateResultsIndexCancelReasonsIndexValueErrorComponent =
  {
    attr: 'results.INDEX.cancel_reasons.INDEX.value';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsBulkCancelReasonsCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkCancelReasonsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip_ids';
    } & TripsBulkCancelReasonsCreateTripIdsErrorComponent)
  | ({
      attr: 'trip_ids.INDEX';
    } & TripsBulkCancelReasonsCreateTripIdsIndexErrorComponent)
  | ({
      attr: 'results.INDEX.cancel_reasons.INDEX.label';
    } & TripsBulkCancelReasonsCreateResultsIndexCancelReasonsIndexLabelErrorComponent)
  | ({
      attr: 'results.INDEX.cancel_reasons.INDEX.value';
    } & TripsBulkCancelReasonsCreateResultsIndexCancelReasonsIndexValueErrorComponent);
export type TripsBulkCancelReasonsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkCancelReasonsCreateError[];
};
export type TripsBulkCancelReasonsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkCancelReasonsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkTripRejectResults = {
  trips_rejected: string[];
  trips_not_rejected: string[];
  errors?: BulkTripError[] | null;
};
export type TripsBulkRejectCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkRejectCreateTripReasonsErrorComponent = {
  attr: 'trip_reasons';
  code: 'min_length' | 'not_a_list';
  detail: string;
};
export type TripsBulkRejectCreateTripReasonsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'trip_reasons.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsBulkRejectCreateTripReasonsIndexTripIdErrorComponent = {
  attr: 'trip_reasons.INDEX.trip_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkRejectCreateTripReasonsIndexRejectedReasonErrorComponent =
  {
    attr: 'trip_reasons.INDEX.rejected_reason';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsBulkRejectCreateTripIdsErrorComponent = {
  attr: 'trip_ids';
  code: 'min_length' | 'not_a_list';
  detail: string;
};
export type TripsBulkRejectCreateTripIdsIndexErrorComponent = {
  attr: 'trip_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkRejectCreateRejectedReasonErrorComponent = {
  attr: 'rejected_reason';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsBulkRejectCreateOverrideErrorComponent = {
  attr: 'override';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsBulkRejectCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkRejectCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip_reasons';
    } & TripsBulkRejectCreateTripReasonsErrorComponent)
  | ({
      attr: 'trip_reasons.INDEX.non_field_errors';
    } & TripsBulkRejectCreateTripReasonsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'trip_reasons.INDEX.trip_id';
    } & TripsBulkRejectCreateTripReasonsIndexTripIdErrorComponent)
  | ({
      attr: 'trip_reasons.INDEX.rejected_reason';
    } & TripsBulkRejectCreateTripReasonsIndexRejectedReasonErrorComponent)
  | ({
      attr: 'trip_ids';
    } & TripsBulkRejectCreateTripIdsErrorComponent)
  | ({
      attr: 'trip_ids.INDEX';
    } & TripsBulkRejectCreateTripIdsIndexErrorComponent)
  | ({
      attr: 'rejected_reason';
    } & TripsBulkRejectCreateRejectedReasonErrorComponent)
  | ({
      attr: 'override';
    } & TripsBulkRejectCreateOverrideErrorComponent);
export type TripsBulkRejectCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkRejectCreateError[];
};
export type TripsBulkRejectCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkRejectCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripRejectedReason = {
  trip_id: string;
  rejected_reason: string;
};
export type BulkTripReject = {
  trip_reasons?: TripRejectedReason[] | null;
  trip_ids?: string[] | null;
};
export type BulkTripRejectWrite = {
  trip_reasons?: TripRejectedReason[] | null;
  trip_ids?: string[] | null;
  rejected_reason?: string;
  override?: boolean;
};
export type BulkTripUnassignResults = {
  affected_trips: string[];
  unaffected_trips_locked: string[];
  unaffected_trips_status: string[];
};
export type TripsBulkUnassignCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkUnassignCreateAssignmentIdErrorComponent = {
  attr: 'assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkUnassignCreateUnassignLockedTripsErrorComponent = {
  attr: 'unassign_locked_trips';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsBulkUnassignCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkUnassignCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment_id';
    } & TripsBulkUnassignCreateAssignmentIdErrorComponent)
  | ({
      attr: 'unassign_locked_trips';
    } & TripsBulkUnassignCreateUnassignLockedTripsErrorComponent);
export type TripsBulkUnassignCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkUnassignCreateError[];
};
export type TripsBulkUnassignCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkUnassignCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkTripUnassign = {};
export type BulkTripUnassignWrite = {
  assignment_id: string;
  unassign_locked_trips?: boolean;
};
export type BulkInvalidTrip = {
  trip_id: string;
  explanation?: string[];
};
export type BulkTripValidateResults = {
  assignment_id: string;
  valid_trips: string[];
  invalid_trips?: BulkInvalidTrip[];
};
export type TripsBulkValidateCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsBulkValidateCreateAssignmentIdErrorComponent = {
  attr: 'assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkValidateCreateTripIdsErrorComponent = {
  attr: 'trip_ids';
  code: 'min_length' | 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsBulkValidateCreateTripIdsIndexErrorComponent = {
  attr: 'trip_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkValidateCreateIsMultiloadErrorComponent = {
  attr: 'is_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsBulkValidateCreateOrderedTripLocationDetailIdsErrorComponent =
  {
    attr: 'ordered_trip_location_detail_ids';
    code: 'min_length' | 'not_a_list';
    detail: string;
  };
export type TripsBulkValidateCreateOrderedTripLocationDetailIdsIndexErrorComponent =
  {
    attr: 'ordered_trip_location_detail_ids.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsBulkValidateCreateResultsAssignmentIdErrorComponent = {
  attr: 'results.assignment_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkValidateCreateResultsValidTripsErrorComponent = {
  attr: 'results.valid_trips';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsBulkValidateCreateResultsValidTripsIndexErrorComponent = {
  attr: 'results.valid_trips.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsBulkValidateCreateResultsInvalidTripsErrorComponent = {
  attr: 'results.invalid_trips';
  code: 'not_a_list' | 'null';
  detail: string;
};
export type TripsBulkValidateCreateResultsInvalidTripsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'results.invalid_trips.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsBulkValidateCreateResultsInvalidTripsIndexTripIdErrorComponent =
  {
    attr: 'results.invalid_trips.INDEX.trip_id';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsBulkValidateCreateResultsInvalidTripsIndexExplanationErrorComponent =
  {
    attr: 'results.invalid_trips.INDEX.explanation';
    code: 'not_a_list' | 'null';
    detail: string;
  };
export type TripsBulkValidateCreateResultsInvalidTripsIndexExplanationIndexErrorComponent =
  {
    attr: 'results.invalid_trips.INDEX.explanation.INDEX';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsBulkValidateCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsBulkValidateCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'assignment_id';
    } & TripsBulkValidateCreateAssignmentIdErrorComponent)
  | ({
      attr: 'trip_ids';
    } & TripsBulkValidateCreateTripIdsErrorComponent)
  | ({
      attr: 'trip_ids.INDEX';
    } & TripsBulkValidateCreateTripIdsIndexErrorComponent)
  | ({
      attr: 'is_multiload';
    } & TripsBulkValidateCreateIsMultiloadErrorComponent)
  | ({
      attr: 'ordered_trip_location_detail_ids';
    } & TripsBulkValidateCreateOrderedTripLocationDetailIdsErrorComponent)
  | ({
      attr: 'ordered_trip_location_detail_ids.INDEX';
    } & TripsBulkValidateCreateOrderedTripLocationDetailIdsIndexErrorComponent)
  | ({
      attr: 'results.assignment_id';
    } & TripsBulkValidateCreateResultsAssignmentIdErrorComponent)
  | ({
      attr: 'results.valid_trips';
    } & TripsBulkValidateCreateResultsValidTripsErrorComponent)
  | ({
      attr: 'results.valid_trips.INDEX';
    } & TripsBulkValidateCreateResultsValidTripsIndexErrorComponent)
  | ({
      attr: 'results.invalid_trips';
    } & TripsBulkValidateCreateResultsInvalidTripsErrorComponent)
  | ({
      attr: 'results.invalid_trips.INDEX.non_field_errors';
    } & TripsBulkValidateCreateResultsInvalidTripsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'results.invalid_trips.INDEX.trip_id';
    } & TripsBulkValidateCreateResultsInvalidTripsIndexTripIdErrorComponent)
  | ({
      attr: 'results.invalid_trips.INDEX.explanation';
    } & TripsBulkValidateCreateResultsInvalidTripsIndexExplanationErrorComponent)
  | ({
      attr: 'results.invalid_trips.INDEX.explanation.INDEX';
    } & TripsBulkValidateCreateResultsInvalidTripsIndexExplanationIndexErrorComponent);
export type TripsBulkValidateCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsBulkValidateCreateError[];
};
export type TripsBulkValidateCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsBulkValidateCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type BulkTripValidate = {};
export type BulkTripValidateRead = {
  results: BulkTripValidateResults;
};
export type BulkTripValidateWrite = {
  assignment_id: string;
  trip_ids: string[];
  is_multiload?: boolean;
  ordered_trip_location_detail_ids?: string[] | null;
};
export type TripImport = {
  trips: Trip[];
};
export type TripImportRead = {
  trips: TripRead[];
};
export type TripImportWrite = {
  trips: TripWrite[];
};
export type TripsImportCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsImportCreatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsImportCreateTemplateKeyErrorComponent = {
  attr: 'template_key';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsImportCreateTripsFromCsvNonFieldErrorsErrorComponent = {
  attr: 'trips_from_csv.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsNonFieldErrorsErrorComponent = {
  attr: 'trips_from_csv.records.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexAssignmentStartErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.assignment.start';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexAssignmentEndErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.assignment.end';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.id';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerFirstNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.first_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerLastNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.last_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerPreferredNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.preferred_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerPronounErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.pronoun';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerGenderErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.gender';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerDobErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.dob';
    code: 'datetime' | 'invalid';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerWeightErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.weight';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerNeedsBariatricTransportErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.needs_bariatric_transport';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.phone_number';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerEmailErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.email';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerDispatcherNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPassengerAllowSmsMessageErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.passenger.allow_sms_message';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerPayerErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.payer';
    code: 'does_not_exist' | 'incorrect_type';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDisplayNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.display_name';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerAlternateNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.alternate_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerTypeErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsIndexPayerIsActiveErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.is_active';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerCanMultiloadErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.can_multiload';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerExternalIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.external_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerWebsiteErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.website';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerEinErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsIndexPayerEmailErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.email';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingEmailErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_email';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDispatcherNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerAdminNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.admin_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDeletedAtErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.deleted_at';
    code: 'date' | 'invalid' | 'make_aware' | 'overflow';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabelErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabel2ErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressTypeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabelErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabel2ErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPayerIdErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsIndexPayerPassengerExternalIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payer_passenger_external_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexExternalTripIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.external_trip_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexInputPriceCentsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.input_price_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPaymentMethodErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.payment_method';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexScheduledPickupAtErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.scheduled_pickup_at';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupAddressDetailsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.address_details';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupNumStairsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.num_stairs';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupStairEquipmentErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.stair_equipment';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupContactNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.contact_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupContactPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.contact_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupDriverNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.driver_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffNonFieldErrorsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffAddressDetailsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.address_details';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffNumStairsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.num_stairs';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffStairEquipmentErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.stair_equipment';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffContactNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.contact_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffContactPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.contact_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffDriverNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.driver_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDropoffPlaceIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dropoff.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexAppointmentAtErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.appointment_at';
    code: 'date' | 'invalid' | 'make_aware' | 'overflow';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexIsWillCallErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_will_call';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexSpaceTypeErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsIndexMustProvideWheelchairErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.must_provide_wheelchair';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexIsTransportingScooterErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_transporting_scooter';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexServiceTypeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.service_type';
    code: 'invalid_choice' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDispatcherNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexDriverEnteredNotesErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.driver_entered_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexIsOxygenRequiredErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_oxygen_required';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexOxygenLitersPerMinErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.oxygen_liters_per_min';
    code:
      | 'invalid'
      | 'max_decimal_places'
      | 'max_digits'
      | 'max_string_length'
      | 'max_whole_digits'
      | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexNumAttendantsNeededErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.num_attendants_needed';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexNumAccompanyingPassengersErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.num_accompanying_passengers';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.one_schema_template_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateMultiIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.one_schema_template_multi_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexCorrelationIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.correlation_id';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexBookedByNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.booked_by_name';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexBookedByPhoneNumberErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.booked_by_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexBookedByPhoneExtensionErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.booked_by_phone_extension';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexLoadTimeSecondsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.load_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexUnloadTimeSecondsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.unload_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexPickupWaitTimeSecondsErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.pickup_wait_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexTagIdsErrorComponent = {
  attr: 'trips_from_csv.records.INDEX.tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type TripsImportCreateTripsFromCsvRecordsIndexTagIdsIndexErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.tag_ids.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexTagsIndexTagNameErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.tags.INDEX.tag_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexTagsIndexColorHexErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.tags.INDEX.color_hex';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnOutboundErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_wait_and_return_outbound';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnInboundErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_wait_and_return_inbound';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexAuthorizationIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.authorization_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexInsurancePolicyIdErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.insurance_policy_id';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexIsPriceLockedErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.is_price_locked';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexLinkToPayerErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.link_to_payer';
    code: 'invalid_choice';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexConfirmationCodeErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.confirmation_code';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsImportCreateTripsFromCsvRecordsIndexHasInfectiousDiseaseErrorComponent =
  {
    attr: 'trips_from_csv.records.INDEX.has_infectious_disease';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsImportCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsImportCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer_id';
    } & TripsImportCreatePayerIdErrorComponent)
  | ({
      attr: 'template_key';
    } & TripsImportCreateTemplateKeyErrorComponent)
  | ({
      attr: 'trips_from_csv.non_field_errors';
    } & TripsImportCreateTripsFromCsvNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.assignment.start';
    } & TripsImportCreateTripsFromCsvRecordsIndexAssignmentStartErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.assignment.end';
    } & TripsImportCreateTripsFromCsvRecordsIndexAssignmentEndErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.first_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerFirstNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.last_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerLastNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.preferred_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerPreferredNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.pronoun';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerPronounErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.gender';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerGenderErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.dob';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerDobErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.weight';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerWeightErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.needs_bariatric_transport';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.phone_number';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.email';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerEmailErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.dispatcher_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerDispatcherNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.passenger.allow_sms_message';
    } & TripsImportCreateTripsFromCsvRecordsIndexPassengerAllowSmsMessageErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.payer';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerPayerErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.display_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDisplayNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.alternate_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerAlternateNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.type';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.is_active';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerIsActiveErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.can_multiload';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerCanMultiloadErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.external_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerExternalIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.website';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerWebsiteErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.ein';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerEinErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.email';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerEmailErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.phone_number';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_phone_number';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_email';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingEmailErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.dispatcher_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDispatcherNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.admin_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerAdminNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.deleted_at';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDeletedAtErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.location.place_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.label';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabelErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.label2';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.billing_address.address_type';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.location.place_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.label2';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.default_trip_address.address_type';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer.multiloading_partners.INDEX';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerMultiloadingPartnersIndexErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payer_passenger_external_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPayerPassengerExternalIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.external_trip_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexExternalTripIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.input_price_cents';
    } & TripsImportCreateTripsFromCsvRecordsIndexInputPriceCentsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.payment_method';
    } & TripsImportCreateTripsFromCsvRecordsIndexPaymentMethodErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.scheduled_pickup_at';
    } & TripsImportCreateTripsFromCsvRecordsIndexScheduledPickupAtErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.address_details';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupAddressDetailsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.num_stairs';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupNumStairsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.stair_equipment';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupStairEquipmentErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.contact_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupContactNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.contact_phone_number';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupContactPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.driver_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupDriverNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup.place_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.non_field_errors';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffNonFieldErrorsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.address_details';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffAddressDetailsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.num_stairs';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffNumStairsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.stair_equipment';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffStairEquipmentErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.contact_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffContactNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.contact_phone_number';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffContactPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.driver_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffDriverNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dropoff.place_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexDropoffPlaceIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.appointment_at';
    } & TripsImportCreateTripsFromCsvRecordsIndexAppointmentAtErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_will_call';
    } & TripsImportCreateTripsFromCsvRecordsIndexIsWillCallErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.space_type';
    } & TripsImportCreateTripsFromCsvRecordsIndexSpaceTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.must_provide_wheelchair';
    } & TripsImportCreateTripsFromCsvRecordsIndexMustProvideWheelchairErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_transporting_scooter';
    } & TripsImportCreateTripsFromCsvRecordsIndexIsTransportingScooterErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.service_type';
    } & TripsImportCreateTripsFromCsvRecordsIndexServiceTypeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.dispatcher_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexDispatcherNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.driver_entered_notes';
    } & TripsImportCreateTripsFromCsvRecordsIndexDriverEnteredNotesErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_oxygen_required';
    } & TripsImportCreateTripsFromCsvRecordsIndexIsOxygenRequiredErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.oxygen_liters_per_min';
    } & TripsImportCreateTripsFromCsvRecordsIndexOxygenLitersPerMinErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.num_attendants_needed';
    } & TripsImportCreateTripsFromCsvRecordsIndexNumAttendantsNeededErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.num_accompanying_passengers';
    } & TripsImportCreateTripsFromCsvRecordsIndexNumAccompanyingPassengersErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.one_schema_template_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.one_schema_template_multi_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexOneSchemaTemplateMultiIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.correlation_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexCorrelationIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.booked_by_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexBookedByNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.booked_by_phone_number';
    } & TripsImportCreateTripsFromCsvRecordsIndexBookedByPhoneNumberErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.booked_by_phone_extension';
    } & TripsImportCreateTripsFromCsvRecordsIndexBookedByPhoneExtensionErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.load_time_seconds';
    } & TripsImportCreateTripsFromCsvRecordsIndexLoadTimeSecondsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.unload_time_seconds';
    } & TripsImportCreateTripsFromCsvRecordsIndexUnloadTimeSecondsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.pickup_wait_time_seconds';
    } & TripsImportCreateTripsFromCsvRecordsIndexPickupWaitTimeSecondsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tag_ids';
    } & TripsImportCreateTripsFromCsvRecordsIndexTagIdsErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tag_ids.INDEX';
    } & TripsImportCreateTripsFromCsvRecordsIndexTagIdsIndexErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tags.INDEX.tag_name';
    } & TripsImportCreateTripsFromCsvRecordsIndexTagsIndexTagNameErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.tags.INDEX.color_hex';
    } & TripsImportCreateTripsFromCsvRecordsIndexTagsIndexColorHexErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_wait_and_return_outbound';
    } & TripsImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnOutboundErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_wait_and_return_inbound';
    } & TripsImportCreateTripsFromCsvRecordsIndexIsWaitAndReturnInboundErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.authorization_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexAuthorizationIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.insurance_policy_id';
    } & TripsImportCreateTripsFromCsvRecordsIndexInsurancePolicyIdErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.is_price_locked';
    } & TripsImportCreateTripsFromCsvRecordsIndexIsPriceLockedErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.link_to_payer';
    } & TripsImportCreateTripsFromCsvRecordsIndexLinkToPayerErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.confirmation_code';
    } & TripsImportCreateTripsFromCsvRecordsIndexConfirmationCodeErrorComponent)
  | ({
      attr: 'trips_from_csv.records.INDEX.has_infectious_disease';
    } & TripsImportCreateTripsFromCsvRecordsIndexHasInfectiousDiseaseErrorComponent);
export type TripsImportCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsImportCreateError[];
};
export type TripsImportCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsImportCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type OneSchemaData = {
  records: Trip[];
};
export type OneSchemaDataRead = {
  records: TripRead[];
};
export type OneSchemaDataWrite = {
  records: TripWrite[];
};
export type TripImportViewSet = {
  payer_id?: string;
  template_key: string;
  trips_from_csv: OneSchemaData;
};
export type TripImportViewSetRead = {
  payer_id?: string;
  template_key: string;
  trips_from_csv: OneSchemaDataRead;
};
export type TripImportViewSetWrite = {
  payer_id?: string;
  template_key: string;
  trips_from_csv: OneSchemaDataWrite;
};
export type OrderedMultiloadTripLocationDetail = {
  trip_location_detail_id: string;
  order: number;
};
export type Multiload = {
  /** Notes about this multiload */
  notes?: string;
  multiload_trip_location_details: OrderedMultiloadTripLocationDetail[];
  deleted_at?: string | null;
};
export type MultiloadRead = {
  id: string;
  organization_id: string;
  /** Notes about this multiload */
  notes?: string;
  multiload_trip_location_details: OrderedMultiloadTripLocationDetail[];
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
};
export type PaginatedMultiloadList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Multiload[];
};
export type PaginatedMultiloadListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MultiloadRead[];
};
export type TripsMultiloadListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsMultiloadCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsMultiloadCreateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsMultiloadCreateMultiloadTripLocationDetailsNonFieldErrorsErrorComponent =
  {
    attr: 'multiload_trip_location_details.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type TripsMultiloadCreateMultiloadTripLocationDetailsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'multiload_trip_location_details.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsMultiloadCreateMultiloadTripLocationDetailsIndexTripLocationDetailIdErrorComponent =
  {
    attr: 'multiload_trip_location_details.INDEX.trip_location_detail_id';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsMultiloadCreateMultiloadTripLocationDetailsIndexOrderErrorComponent =
  {
    attr: 'multiload_trip_location_details.INDEX.order';
    code:
      | 'invalid'
      | 'max_string_length'
      | 'max_value'
      | 'min_value'
      | 'null'
      | 'required';
    detail: string;
  };
export type TripsMultiloadCreateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsMultiloadCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsMultiloadCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'notes';
    } & TripsMultiloadCreateNotesErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.non_field_errors';
    } & TripsMultiloadCreateMultiloadTripLocationDetailsNonFieldErrorsErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.INDEX.non_field_errors';
    } & TripsMultiloadCreateMultiloadTripLocationDetailsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.INDEX.trip_location_detail_id';
    } & TripsMultiloadCreateMultiloadTripLocationDetailsIndexTripLocationDetailIdErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.INDEX.order';
    } & TripsMultiloadCreateMultiloadTripLocationDetailsIndexOrderErrorComponent)
  | ({
      attr: 'deleted_at';
    } & TripsMultiloadCreateDeletedAtErrorComponent);
export type TripsMultiloadCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsMultiloadCreateError[];
};
export type TripsMultiloadCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsMultiloadCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripsMultiloadRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripsMultiloadPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsMultiloadPartialUpdateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsMultiloadPartialUpdateMultiloadTripLocationDetailsNonFieldErrorsErrorComponent =
  {
    attr: 'multiload_trip_location_details.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type TripsMultiloadPartialUpdateMultiloadTripLocationDetailsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'multiload_trip_location_details.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsMultiloadPartialUpdateMultiloadTripLocationDetailsIndexTripLocationDetailIdErrorComponent =
  {
    attr: 'multiload_trip_location_details.INDEX.trip_location_detail_id';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsMultiloadPartialUpdateMultiloadTripLocationDetailsIndexOrderErrorComponent =
  {
    attr: 'multiload_trip_location_details.INDEX.order';
    code:
      | 'invalid'
      | 'max_string_length'
      | 'max_value'
      | 'min_value'
      | 'null'
      | 'required';
    detail: string;
  };
export type TripsMultiloadPartialUpdateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsMultiloadPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsMultiloadPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'notes';
    } & TripsMultiloadPartialUpdateNotesErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.non_field_errors';
    } & TripsMultiloadPartialUpdateMultiloadTripLocationDetailsNonFieldErrorsErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.INDEX.non_field_errors';
    } & TripsMultiloadPartialUpdateMultiloadTripLocationDetailsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.INDEX.trip_location_detail_id';
    } & TripsMultiloadPartialUpdateMultiloadTripLocationDetailsIndexTripLocationDetailIdErrorComponent)
  | ({
      attr: 'multiload_trip_location_details.INDEX.order';
    } & TripsMultiloadPartialUpdateMultiloadTripLocationDetailsIndexOrderErrorComponent)
  | ({
      attr: 'deleted_at';
    } & TripsMultiloadPartialUpdateDeletedAtErrorComponent);
export type TripsMultiloadPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsMultiloadPartialUpdateError[];
};
export type TripsMultiloadPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsMultiloadPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedMultiload = {
  /** Notes about this multiload */
  notes?: string;
  multiload_trip_location_details?: OrderedMultiloadTripLocationDetail[];
  deleted_at?: string | null;
};
export type PatchedMultiloadRead = {
  id?: string;
  organization_id?: string;
  /** Notes about this multiload */
  notes?: string;
  multiload_trip_location_details?: OrderedMultiloadTripLocationDetail[];
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
};
export type TripsMultiloadDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type MultiloadMobile = {};
export type MultiloadPassengerMobile = {};
export type MultiloadPassengerMobileRead = {
  id: string;
  first_name: string;
  last_name: string;
};
export type MultiloadStepMobile = {
  trip_id: string;
  passenger: MultiloadPassengerMobile;
  location_detail: AssignmentTripLocationDetail;
};
export type MultiloadStepMobileRead = {
  order: number;
  trip_id: string;
  trip_status: OverrideStatusEnum;
  passenger: MultiloadPassengerMobileRead;
  location_detail: AssignmentTripLocationDetailRead;
};
export type MultiloadMobileRead = {
  id: string;
  /** Notes about this multiload */
  notes: string;
  steps: MultiloadStepMobileRead[];
};
export type TripsMultiloadMobileRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripRequestedCount = {
  count: number;
};
export type TripsRequestedCountRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripRecurrence = {
  is_round_trip?: boolean;
  is_will_call?: boolean;
  /** The rrule (RFC5545) */
  rrule: string;
  /** The manually inputted price of the trip */
  input_price_cents?: number | null;
  scheduled_pickup_time: string;
  appointment_time?: string;
};
export type TripSubscription = {
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  dropoff: TripLocationDetail;
  estimated_distance_miles?: number | null;
  estimated_load_seconds?: number | null;
  estimated_unload_seconds?: number | null;
  /** When the recurrences stop applying (exclusive) */
  expires_at: string;
  is_oxygen_required?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  load_time_seconds?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  num_attendants_needed?: number;
  oxygen_liters_per_min?: string;
  passenger: PatchedTripPassenger;
  payment_method?: PaymentMethodEnum | BlankEnum;
  pickup: TripLocationDetail;
  pickup_wait_time_seconds?: number;
  recurrences: TripRecurrence[];
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  service_type: ServiceTypeEnum;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** When the recurrences start applying (inclusive) */
  start_date: string;
  unload_time_seconds?: number;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
};
export type TripSubscriptionTrip = {
  passenger_initials: string;
  pickup_datetime: string;
  from_address: string;
  to_address: string;
  key: string;
};
export type TripTripSubscriptionTag = {
  tag_name: string;
  color_hex: string;
};
export type TripTripSubscriptionTagRead = {
  id: string;
  tag: string;
  tag_name: string;
  color_hex: string;
  created_at: string;
  updated_at: string;
};
export type TripSubscriptionRead = {
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  dropoff: TripLocationDetailRead;
  estimated_distance_miles?: number | null;
  estimated_load_seconds?: number | null;
  estimated_unload_seconds?: number | null;
  /** When the recurrences stop applying (exclusive) */
  expires_at: string;
  id: string;
  is_oxygen_required?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  load_time_seconds?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  num_attendants_needed?: number;
  oxygen_liters_per_min?: string;
  passenger: PatchedTripPassengerRead;
  payer: PayerOrganizationRead;
  payer_passenger: TripPayerPassengerRead;
  payment_method?: PaymentMethodEnum | BlankEnum;
  pickup: TripLocationDetailRead;
  pickup_wait_time_seconds?: number;
  recurrences: TripRecurrence[];
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  service_type: ServiceTypeEnum;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** When the recurrences start applying (inclusive) */
  start_date: string;
  unload_time_seconds?: number;
  old_trips: TripSubscriptionTrip[];
  new_trips: TripSubscriptionTrip[];
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  tags: TripTripSubscriptionTagRead[] | null;
};
export type TripSubscriptionWrite = {
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  dropoff: TripLocationDetail;
  estimated_distance_miles?: number | null;
  estimated_load_seconds?: number | null;
  estimated_unload_seconds?: number | null;
  /** When the recurrences stop applying (exclusive) */
  expires_at: string;
  is_oxygen_required?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  load_time_seconds?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  num_attendants_needed?: number;
  oxygen_liters_per_min?: string;
  passenger: PatchedTripPassenger;
  payer_id: string;
  payer_passenger_external_id?: string;
  payment_method?: PaymentMethodEnum | BlankEnum;
  pickup: TripLocationDetail;
  pickup_wait_time_seconds?: number;
  recurrences: TripRecurrence[];
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  service_type: ServiceTypeEnum;
  /** The type of space in the vehicle this passenger will need */
  space_type: SpaceTypeEnum;
  /** When the recurrences start applying (inclusive) */
  start_date: string;
  unload_time_seconds?: number;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  tag_ids?: string[] | null;
};
export type PaginatedTripSubscriptionList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripSubscription[];
};
export type PaginatedTripSubscriptionListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripSubscriptionRead[];
};
export type PaginatedTripSubscriptionListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: TripSubscriptionWrite[];
};
export type TripsSubscriptionsListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type TripsSubscriptionsListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type TripsSubscriptionsListPayersErrorComponent = {
  attr: 'payers';
  code: 'null_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsListError =
  | ({
      attr: 'range_start';
    } & TripsSubscriptionsListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & TripsSubscriptionsListRangeEndErrorComponent)
  | ({
      attr: 'payers';
    } & TripsSubscriptionsListPayersErrorComponent);
export type TripsSubscriptionsListValidationError = {
  type: ValidationErrorEnum;
  errors: TripsSubscriptionsListError[];
};
export type TripsSubscriptionsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsSubscriptionsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripsSubscriptionsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsSubscriptionsCreateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffNonFieldErrorsErrorComponent = {
  attr: 'dropoff.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffAddressDetailsErrorComponent = {
  attr: 'dropoff.address_details';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffNumStairsErrorComponent = {
  attr: 'dropoff.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffStairEquipmentErrorComponent = {
  attr: 'dropoff.stair_equipment';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffContactNameErrorComponent = {
  attr: 'dropoff.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffContactPhoneNumberErrorComponent = {
  attr: 'dropoff.contact_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffDriverNotesErrorComponent = {
  attr: 'dropoff.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateDropoffPlaceIdErrorComponent = {
  attr: 'dropoff.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateEstimatedDistanceMilesErrorComponent = {
  attr: 'estimated_distance_miles';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type TripsSubscriptionsCreateEstimatedLoadSecondsErrorComponent = {
  attr: 'estimated_load_seconds';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type TripsSubscriptionsCreateEstimatedUnloadSecondsErrorComponent = {
  attr: 'estimated_unload_seconds';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type TripsSubscriptionsCreateExpiresAtErrorComponent = {
  attr: 'expires_at';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateIsOxygenRequiredErrorComponent = {
  attr: 'is_oxygen_required';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateIsTransportingScooterErrorComponent = {
  attr: 'is_transporting_scooter';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateLoadTimeSecondsErrorComponent = {
  attr: 'load_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateNumAccompanyingPassengersErrorComponent = {
  attr: 'num_accompanying_passengers';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateNumAttendantsNeededErrorComponent = {
  attr: 'num_attendants_needed';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateOxygenLitersPerMinErrorComponent = {
  attr: 'oxygen_liters_per_min';
  code:
    | 'invalid'
    | 'max_decimal_places'
    | 'max_digits'
    | 'max_string_length'
    | 'max_whole_digits'
    | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerNonFieldErrorsErrorComponent = {
  attr: 'passenger.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerIdErrorComponent = {
  attr: 'passenger.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerFirstNameErrorComponent = {
  attr: 'passenger.first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerLastNameErrorComponent = {
  attr: 'passenger.last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerPreferredNameErrorComponent = {
  attr: 'passenger.preferred_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerPronounErrorComponent = {
  attr: 'passenger.pronoun';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerGenderErrorComponent = {
  attr: 'passenger.gender';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerDobErrorComponent = {
  attr: 'passenger.dob';
  code: 'datetime' | 'invalid';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerWeightErrorComponent = {
  attr: 'passenger.weight';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerNeedsBariatricTransportErrorComponent =
  {
    attr: 'passenger.needs_bariatric_transport';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreatePassengerPhoneNumberErrorComponent = {
  attr: 'passenger.phone_number';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerEmailErrorComponent = {
  attr: 'passenger.email';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerNotesErrorComponent = {
  attr: 'passenger.notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerDispatcherNotesErrorComponent = {
  attr: 'passenger.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePassengerAllowSmsMessageErrorComponent = {
  attr: 'passenger.allow_sms_message';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePayerPayerErrorComponent = {
  attr: 'payer.payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type TripsSubscriptionsCreatePayerDisplayNameErrorComponent = {
  attr: 'payer.display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerAlternateNameErrorComponent = {
  attr: 'payer.alternate_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerTypeErrorComponent = {
  attr: 'payer.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePayerIsActiveErrorComponent = {
  attr: 'payer.is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePayerCanMultiloadErrorComponent = {
  attr: 'payer.can_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePayerExternalIdErrorComponent = {
  attr: 'payer.external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerWebsiteErrorComponent = {
  attr: 'payer.website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerEinErrorComponent = {
  attr: 'payer.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerEmailErrorComponent = {
  attr: 'payer.email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerPhoneNumberErrorComponent = {
  attr: 'payer.phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerBillingNameErrorComponent = {
  attr: 'payer.billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerBillingPhoneNumberErrorComponent = {
  attr: 'payer.billing_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerBillingEmailErrorComponent = {
  attr: 'payer.billing_email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerDispatcherNotesErrorComponent = {
  attr: 'payer.dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerAdminNotesErrorComponent = {
  attr: 'payer.admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerDeletedAtErrorComponent = {
  attr: 'payer.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsSubscriptionsCreatePayerBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerBillingAddressLabelErrorComponent = {
  attr: 'payer.billing_address.label';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerBillingAddressLabel2ErrorComponent = {
  attr: 'payer.billing_address.label2';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePayerBillingAddressAddressNotesErrorComponent =
  {
    attr: 'payer.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerBillingAddressAddressTypeErrorComponent =
  {
    attr: 'payer.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressLabelErrorComponent =
  {
    attr: 'payer.default_trip_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressLabel2ErrorComponent =
  {
    attr: 'payer.default_trip_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'payer.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'payer.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'payer.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'payer.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreatePayerPassengerExternalIdErrorComponent = {
  attr: 'payer_passenger_external_id';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePaymentMethodErrorComponent = {
  attr: 'payment_method';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePickupNonFieldErrorsErrorComponent = {
  attr: 'pickup.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreatePickupAddressDetailsErrorComponent = {
  attr: 'pickup.address_details';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePickupNumStairsErrorComponent = {
  attr: 'pickup.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePickupStairEquipmentErrorComponent = {
  attr: 'pickup.stair_equipment';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreatePickupContactNameErrorComponent = {
  attr: 'pickup.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePickupContactPhoneNumberErrorComponent = {
  attr: 'pickup.contact_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePickupDriverNotesErrorComponent = {
  attr: 'pickup.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePickupPlaceIdErrorComponent = {
  attr: 'pickup.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreatePickupWaitTimeSecondsErrorComponent = {
  attr: 'pickup_wait_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateRecurrencesNonFieldErrorsErrorComponent = {
  attr: 'recurrences.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateRecurrencesIndexNonFieldErrorsErrorComponent =
  {
    attr: 'recurrences.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreateRecurrencesIndexIsRoundTripErrorComponent =
  {
    attr: 'recurrences.INDEX.is_round_trip';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreateRecurrencesIndexIsWillCallErrorComponent = {
  attr: 'recurrences.INDEX.is_will_call';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateRecurrencesIndexRruleErrorComponent = {
  attr: 'recurrences.INDEX.rrule';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateRecurrencesIndexInputPriceCentsErrorComponent =
  {
    attr: 'recurrences.INDEX.input_price_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type TripsSubscriptionsCreateRecurrencesIndexScheduledPickupTimeErrorComponent =
  {
    attr: 'recurrences.INDEX.scheduled_pickup_time';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreateRecurrencesIndexAppointmentTimeErrorComponent =
  {
    attr: 'recurrences.INDEX.appointment_time';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsCreateMustProvideWheelchairErrorComponent = {
  attr: 'must_provide_wheelchair';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateServiceTypeErrorComponent = {
  attr: 'service_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateStartDateErrorComponent = {
  attr: 'start_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateUnloadTimeSecondsErrorComponent = {
  attr: 'unload_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateOldTripsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'old_trips.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreateOldTripsIndexPassengerInitialsErrorComponent =
  {
    attr: 'old_trips.INDEX.passenger_initials';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreateOldTripsIndexPickupDatetimeErrorComponent =
  {
    attr: 'old_trips.INDEX.pickup_datetime';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreateOldTripsIndexFromAddressErrorComponent = {
  attr: 'old_trips.INDEX.from_address';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateOldTripsIndexToAddressErrorComponent = {
  attr: 'old_trips.INDEX.to_address';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateOldTripsIndexKeyErrorComponent = {
  attr: 'old_trips.INDEX.key';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateNewTripsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'new_trips.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreateNewTripsIndexPassengerInitialsErrorComponent =
  {
    attr: 'new_trips.INDEX.passenger_initials';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsCreateNewTripsIndexPickupDatetimeErrorComponent =
  {
    attr: 'new_trips.INDEX.pickup_datetime';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsSubscriptionsCreateNewTripsIndexFromAddressErrorComponent = {
  attr: 'new_trips.INDEX.from_address';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateNewTripsIndexToAddressErrorComponent = {
  attr: 'new_trips.INDEX.to_address';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateNewTripsIndexKeyErrorComponent = {
  attr: 'new_trips.INDEX.key';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateHasInfectiousDiseaseErrorComponent = {
  attr: 'has_infectious_disease';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsCreateBookedByNameErrorComponent = {
  attr: 'booked_by_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateBookedByPhoneNumberErrorComponent = {
  attr: 'booked_by_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateBookedByPhoneExtensionErrorComponent = {
  attr: 'booked_by_phone_extension';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type TripsSubscriptionsCreateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsCreateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsSubscriptionsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & TripsSubscriptionsCreateDispatcherNotesErrorComponent)
  | ({
      attr: 'dropoff.non_field_errors';
    } & TripsSubscriptionsCreateDropoffNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoff.address_details';
    } & TripsSubscriptionsCreateDropoffAddressDetailsErrorComponent)
  | ({
      attr: 'dropoff.num_stairs';
    } & TripsSubscriptionsCreateDropoffNumStairsErrorComponent)
  | ({
      attr: 'dropoff.stair_equipment';
    } & TripsSubscriptionsCreateDropoffStairEquipmentErrorComponent)
  | ({
      attr: 'dropoff.contact_name';
    } & TripsSubscriptionsCreateDropoffContactNameErrorComponent)
  | ({
      attr: 'dropoff.contact_phone_number';
    } & TripsSubscriptionsCreateDropoffContactPhoneNumberErrorComponent)
  | ({
      attr: 'dropoff.driver_notes';
    } & TripsSubscriptionsCreateDropoffDriverNotesErrorComponent)
  | ({
      attr: 'dropoff.place_id';
    } & TripsSubscriptionsCreateDropoffPlaceIdErrorComponent)
  | ({
      attr: 'estimated_distance_miles';
    } & TripsSubscriptionsCreateEstimatedDistanceMilesErrorComponent)
  | ({
      attr: 'estimated_load_seconds';
    } & TripsSubscriptionsCreateEstimatedLoadSecondsErrorComponent)
  | ({
      attr: 'estimated_unload_seconds';
    } & TripsSubscriptionsCreateEstimatedUnloadSecondsErrorComponent)
  | ({
      attr: 'expires_at';
    } & TripsSubscriptionsCreateExpiresAtErrorComponent)
  | ({
      attr: 'is_oxygen_required';
    } & TripsSubscriptionsCreateIsOxygenRequiredErrorComponent)
  | ({
      attr: 'is_transporting_scooter';
    } & TripsSubscriptionsCreateIsTransportingScooterErrorComponent)
  | ({
      attr: 'load_time_seconds';
    } & TripsSubscriptionsCreateLoadTimeSecondsErrorComponent)
  | ({
      attr: 'num_accompanying_passengers';
    } & TripsSubscriptionsCreateNumAccompanyingPassengersErrorComponent)
  | ({
      attr: 'num_attendants_needed';
    } & TripsSubscriptionsCreateNumAttendantsNeededErrorComponent)
  | ({
      attr: 'oxygen_liters_per_min';
    } & TripsSubscriptionsCreateOxygenLitersPerMinErrorComponent)
  | ({
      attr: 'passenger.non_field_errors';
    } & TripsSubscriptionsCreatePassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.id';
    } & TripsSubscriptionsCreatePassengerIdErrorComponent)
  | ({
      attr: 'passenger.first_name';
    } & TripsSubscriptionsCreatePassengerFirstNameErrorComponent)
  | ({
      attr: 'passenger.last_name';
    } & TripsSubscriptionsCreatePassengerLastNameErrorComponent)
  | ({
      attr: 'passenger.preferred_name';
    } & TripsSubscriptionsCreatePassengerPreferredNameErrorComponent)
  | ({
      attr: 'passenger.pronoun';
    } & TripsSubscriptionsCreatePassengerPronounErrorComponent)
  | ({
      attr: 'passenger.gender';
    } & TripsSubscriptionsCreatePassengerGenderErrorComponent)
  | ({
      attr: 'passenger.dob';
    } & TripsSubscriptionsCreatePassengerDobErrorComponent)
  | ({
      attr: 'passenger.weight';
    } & TripsSubscriptionsCreatePassengerWeightErrorComponent)
  | ({
      attr: 'passenger.needs_bariatric_transport';
    } & TripsSubscriptionsCreatePassengerNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'passenger.phone_number';
    } & TripsSubscriptionsCreatePassengerPhoneNumberErrorComponent)
  | ({
      attr: 'passenger.email';
    } & TripsSubscriptionsCreatePassengerEmailErrorComponent)
  | ({
      attr: 'passenger.notes';
    } & TripsSubscriptionsCreatePassengerNotesErrorComponent)
  | ({
      attr: 'passenger.dispatcher_notes';
    } & TripsSubscriptionsCreatePassengerDispatcherNotesErrorComponent)
  | ({
      attr: 'passenger.allow_sms_message';
    } & TripsSubscriptionsCreatePassengerAllowSmsMessageErrorComponent)
  | ({
      attr: 'payer.payer';
    } & TripsSubscriptionsCreatePayerPayerErrorComponent)
  | ({
      attr: 'payer.display_name';
    } & TripsSubscriptionsCreatePayerDisplayNameErrorComponent)
  | ({
      attr: 'payer.alternate_name';
    } & TripsSubscriptionsCreatePayerAlternateNameErrorComponent)
  | ({
      attr: 'payer.type';
    } & TripsSubscriptionsCreatePayerTypeErrorComponent)
  | ({
      attr: 'payer.is_active';
    } & TripsSubscriptionsCreatePayerIsActiveErrorComponent)
  | ({
      attr: 'payer.can_multiload';
    } & TripsSubscriptionsCreatePayerCanMultiloadErrorComponent)
  | ({
      attr: 'payer.external_id';
    } & TripsSubscriptionsCreatePayerExternalIdErrorComponent)
  | ({
      attr: 'payer.website';
    } & TripsSubscriptionsCreatePayerWebsiteErrorComponent)
  | ({
      attr: 'payer.ein';
    } & TripsSubscriptionsCreatePayerEinErrorComponent)
  | ({
      attr: 'payer.email';
    } & TripsSubscriptionsCreatePayerEmailErrorComponent)
  | ({
      attr: 'payer.phone_number';
    } & TripsSubscriptionsCreatePayerPhoneNumberErrorComponent)
  | ({
      attr: 'payer.billing_name';
    } & TripsSubscriptionsCreatePayerBillingNameErrorComponent)
  | ({
      attr: 'payer.billing_phone_number';
    } & TripsSubscriptionsCreatePayerBillingPhoneNumberErrorComponent)
  | ({
      attr: 'payer.billing_email';
    } & TripsSubscriptionsCreatePayerBillingEmailErrorComponent)
  | ({
      attr: 'payer.dispatcher_notes';
    } & TripsSubscriptionsCreatePayerDispatcherNotesErrorComponent)
  | ({
      attr: 'payer.admin_notes';
    } & TripsSubscriptionsCreatePayerAdminNotesErrorComponent)
  | ({
      attr: 'payer.deleted_at';
    } & TripsSubscriptionsCreatePayerDeletedAtErrorComponent)
  | ({
      attr: 'payer.billing_address.non_field_errors';
    } & TripsSubscriptionsCreatePayerBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.billing_address.location.non_field_errors';
    } & TripsSubscriptionsCreatePayerBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.billing_address.location.place_id';
    } & TripsSubscriptionsCreatePayerBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer.billing_address.label';
    } & TripsSubscriptionsCreatePayerBillingAddressLabelErrorComponent)
  | ({
      attr: 'payer.billing_address.label2';
    } & TripsSubscriptionsCreatePayerBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'payer.billing_address.address_notes';
    } & TripsSubscriptionsCreatePayerBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer.billing_address.address_type';
    } & TripsSubscriptionsCreatePayerBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer.default_trip_address.non_field_errors';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.default_trip_address.location.non_field_errors';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.default_trip_address.location.place_id';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer.default_trip_address.label';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'payer.default_trip_address.label2';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'payer.default_trip_address.address_notes';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer.default_trip_address.address_type';
    } & TripsSubscriptionsCreatePayerDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer.multiloading_partners.non_field_errors';
    } & TripsSubscriptionsCreatePayerMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.multiloading_partners.INDEX';
    } & TripsSubscriptionsCreatePayerMultiloadingPartnersIndexErrorComponent)
  | ({
      attr: 'payer_id';
    } & TripsSubscriptionsCreatePayerIdErrorComponent)
  | ({
      attr: 'payer_passenger_external_id';
    } & TripsSubscriptionsCreatePayerPassengerExternalIdErrorComponent)
  | ({
      attr: 'payment_method';
    } & TripsSubscriptionsCreatePaymentMethodErrorComponent)
  | ({
      attr: 'pickup.non_field_errors';
    } & TripsSubscriptionsCreatePickupNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickup.address_details';
    } & TripsSubscriptionsCreatePickupAddressDetailsErrorComponent)
  | ({
      attr: 'pickup.num_stairs';
    } & TripsSubscriptionsCreatePickupNumStairsErrorComponent)
  | ({
      attr: 'pickup.stair_equipment';
    } & TripsSubscriptionsCreatePickupStairEquipmentErrorComponent)
  | ({
      attr: 'pickup.contact_name';
    } & TripsSubscriptionsCreatePickupContactNameErrorComponent)
  | ({
      attr: 'pickup.contact_phone_number';
    } & TripsSubscriptionsCreatePickupContactPhoneNumberErrorComponent)
  | ({
      attr: 'pickup.driver_notes';
    } & TripsSubscriptionsCreatePickupDriverNotesErrorComponent)
  | ({
      attr: 'pickup.place_id';
    } & TripsSubscriptionsCreatePickupPlaceIdErrorComponent)
  | ({
      attr: 'pickup_wait_time_seconds';
    } & TripsSubscriptionsCreatePickupWaitTimeSecondsErrorComponent)
  | ({
      attr: 'recurrences.non_field_errors';
    } & TripsSubscriptionsCreateRecurrencesNonFieldErrorsErrorComponent)
  | ({
      attr: 'recurrences.INDEX.non_field_errors';
    } & TripsSubscriptionsCreateRecurrencesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'recurrences.INDEX.is_round_trip';
    } & TripsSubscriptionsCreateRecurrencesIndexIsRoundTripErrorComponent)
  | ({
      attr: 'recurrences.INDEX.is_will_call';
    } & TripsSubscriptionsCreateRecurrencesIndexIsWillCallErrorComponent)
  | ({
      attr: 'recurrences.INDEX.rrule';
    } & TripsSubscriptionsCreateRecurrencesIndexRruleErrorComponent)
  | ({
      attr: 'recurrences.INDEX.input_price_cents';
    } & TripsSubscriptionsCreateRecurrencesIndexInputPriceCentsErrorComponent)
  | ({
      attr: 'recurrences.INDEX.scheduled_pickup_time';
    } & TripsSubscriptionsCreateRecurrencesIndexScheduledPickupTimeErrorComponent)
  | ({
      attr: 'recurrences.INDEX.appointment_time';
    } & TripsSubscriptionsCreateRecurrencesIndexAppointmentTimeErrorComponent)
  | ({
      attr: 'must_provide_wheelchair';
    } & TripsSubscriptionsCreateMustProvideWheelchairErrorComponent)
  | ({
      attr: 'service_type';
    } & TripsSubscriptionsCreateServiceTypeErrorComponent)
  | ({
      attr: 'space_type';
    } & TripsSubscriptionsCreateSpaceTypeErrorComponent)
  | ({
      attr: 'start_date';
    } & TripsSubscriptionsCreateStartDateErrorComponent)
  | ({
      attr: 'unload_time_seconds';
    } & TripsSubscriptionsCreateUnloadTimeSecondsErrorComponent)
  | ({
      attr: 'old_trips.INDEX.non_field_errors';
    } & TripsSubscriptionsCreateOldTripsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'old_trips.INDEX.passenger_initials';
    } & TripsSubscriptionsCreateOldTripsIndexPassengerInitialsErrorComponent)
  | ({
      attr: 'old_trips.INDEX.pickup_datetime';
    } & TripsSubscriptionsCreateOldTripsIndexPickupDatetimeErrorComponent)
  | ({
      attr: 'old_trips.INDEX.from_address';
    } & TripsSubscriptionsCreateOldTripsIndexFromAddressErrorComponent)
  | ({
      attr: 'old_trips.INDEX.to_address';
    } & TripsSubscriptionsCreateOldTripsIndexToAddressErrorComponent)
  | ({
      attr: 'old_trips.INDEX.key';
    } & TripsSubscriptionsCreateOldTripsIndexKeyErrorComponent)
  | ({
      attr: 'new_trips.INDEX.non_field_errors';
    } & TripsSubscriptionsCreateNewTripsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'new_trips.INDEX.passenger_initials';
    } & TripsSubscriptionsCreateNewTripsIndexPassengerInitialsErrorComponent)
  | ({
      attr: 'new_trips.INDEX.pickup_datetime';
    } & TripsSubscriptionsCreateNewTripsIndexPickupDatetimeErrorComponent)
  | ({
      attr: 'new_trips.INDEX.from_address';
    } & TripsSubscriptionsCreateNewTripsIndexFromAddressErrorComponent)
  | ({
      attr: 'new_trips.INDEX.to_address';
    } & TripsSubscriptionsCreateNewTripsIndexToAddressErrorComponent)
  | ({
      attr: 'new_trips.INDEX.key';
    } & TripsSubscriptionsCreateNewTripsIndexKeyErrorComponent)
  | ({
      attr: 'has_infectious_disease';
    } & TripsSubscriptionsCreateHasInfectiousDiseaseErrorComponent)
  | ({
      attr: 'booked_by_name';
    } & TripsSubscriptionsCreateBookedByNameErrorComponent)
  | ({
      attr: 'booked_by_phone_number';
    } & TripsSubscriptionsCreateBookedByPhoneNumberErrorComponent)
  | ({
      attr: 'booked_by_phone_extension';
    } & TripsSubscriptionsCreateBookedByPhoneExtensionErrorComponent)
  | ({
      attr: 'tag_ids';
    } & TripsSubscriptionsCreateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & TripsSubscriptionsCreateTagIdsIndexErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & TripsSubscriptionsCreateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & TripsSubscriptionsCreateTagsIndexColorHexErrorComponent);
export type TripsSubscriptionsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsSubscriptionsCreateError[];
};
export type TripsSubscriptionsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsSubscriptionsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type TripsSubscriptionsPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateDispatcherNotesErrorComponent = {
  attr: 'dispatcher_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateDropoffNonFieldErrorsErrorComponent =
  {
    attr: 'dropoff.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateDropoffAddressDetailsErrorComponent =
  {
    attr: 'dropoff.address_details';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateDropoffNumStairsErrorComponent = {
  attr: 'dropoff.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateDropoffStairEquipmentErrorComponent =
  {
    attr: 'dropoff.stair_equipment';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateDropoffContactNameErrorComponent = {
  attr: 'dropoff.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateDropoffContactPhoneNumberErrorComponent =
  {
    attr: 'dropoff.contact_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateDropoffDriverNotesErrorComponent = {
  attr: 'dropoff.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateDropoffPlaceIdErrorComponent = {
  attr: 'dropoff.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateEstimatedDistanceMilesErrorComponent =
  {
    attr: 'estimated_distance_miles';
    code: 'invalid' | 'max_string_length';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateEstimatedLoadSecondsErrorComponent =
  {
    attr: 'estimated_load_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateEstimatedUnloadSecondsErrorComponent =
  {
    attr: 'estimated_unload_seconds';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateExpiresAtErrorComponent = {
  attr: 'expires_at';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateIsOxygenRequiredErrorComponent = {
  attr: 'is_oxygen_required';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateIsTransportingScooterErrorComponent =
  {
    attr: 'is_transporting_scooter';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateLoadTimeSecondsErrorComponent = {
  attr: 'load_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateNumAccompanyingPassengersErrorComponent =
  {
    attr: 'num_accompanying_passengers';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateNumAttendantsNeededErrorComponent = {
  attr: 'num_attendants_needed';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateOxygenLitersPerMinErrorComponent = {
  attr: 'oxygen_liters_per_min';
  code:
    | 'invalid'
    | 'max_decimal_places'
    | 'max_digits'
    | 'max_string_length'
    | 'max_whole_digits'
    | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerNonFieldErrorsErrorComponent =
  {
    attr: 'passenger.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePassengerIdErrorComponent = {
  attr: 'passenger.id';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerFirstNameErrorComponent = {
  attr: 'passenger.first_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerLastNameErrorComponent = {
  attr: 'passenger.last_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerPreferredNameErrorComponent =
  {
    attr: 'passenger.preferred_name';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePassengerPronounErrorComponent = {
  attr: 'passenger.pronoun';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerGenderErrorComponent = {
  attr: 'passenger.gender';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerDobErrorComponent = {
  attr: 'passenger.dob';
  code: 'datetime' | 'invalid';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerWeightErrorComponent = {
  attr: 'passenger.weight';
  code: 'invalid' | 'max_string_length' | 'min_value';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerNeedsBariatricTransportErrorComponent =
  {
    attr: 'passenger.needs_bariatric_transport';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePassengerPhoneNumberErrorComponent =
  {
    attr: 'passenger.phone_number';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePassengerEmailErrorComponent = {
  attr: 'passenger.email';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerNotesErrorComponent = {
  attr: 'passenger.notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePassengerDispatcherNotesErrorComponent =
  {
    attr: 'passenger.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePassengerAllowSmsMessageErrorComponent =
  {
    attr: 'passenger.allow_sms_message';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerPayerErrorComponent = {
  attr: 'payer.payer';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerDisplayNameErrorComponent = {
  attr: 'payer.display_name';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerAlternateNameErrorComponent = {
  attr: 'payer.alternate_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerTypeErrorComponent = {
  attr: 'payer.type';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerIsActiveErrorComponent = {
  attr: 'payer.is_active';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerCanMultiloadErrorComponent = {
  attr: 'payer.can_multiload';
  code: 'invalid' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerExternalIdErrorComponent = {
  attr: 'payer.external_id';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerWebsiteErrorComponent = {
  attr: 'payer.website';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerEinErrorComponent = {
  attr: 'payer.ein';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerEmailErrorComponent = {
  attr: 'payer.email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerPhoneNumberErrorComponent = {
  attr: 'payer.phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerBillingNameErrorComponent = {
  attr: 'payer.billing_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerBillingPhoneNumberErrorComponent =
  {
    attr: 'payer.billing_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingEmailErrorComponent = {
  attr: 'payer.billing_email';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerDispatcherNotesErrorComponent =
  {
    attr: 'payer.dispatcher_notes';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerAdminNotesErrorComponent = {
  attr: 'payer.admin_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerDeletedAtErrorComponent = {
  attr: 'payer.deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerBillingAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer.billing_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer.billing_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer.billing_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingAddressLabelErrorComponent =
  {
    attr: 'payer.billing_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingAddressLabel2ErrorComponent =
  {
    attr: 'payer.billing_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingAddressAddressNotesErrorComponent =
  {
    attr: 'payer.billing_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerBillingAddressAddressTypeErrorComponent =
  {
    attr: 'payer.billing_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressNonFieldErrorsErrorComponent =
  {
    attr: 'payer.default_trip_address.non_field_errors';
    code: 'invalid';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLocationNonFieldErrorsErrorComponent =
  {
    attr: 'payer.default_trip_address.location.non_field_errors';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLocationPlaceIdErrorComponent =
  {
    attr: 'payer.default_trip_address.location.place_id';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLabelErrorComponent =
  {
    attr: 'payer.default_trip_address.label';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLabel2ErrorComponent =
  {
    attr: 'payer.default_trip_address.label2';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressAddressNotesErrorComponent =
  {
    attr: 'payer.default_trip_address.address_notes';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerDefaultTripAddressAddressTypeErrorComponent =
  {
    attr: 'payer.default_trip_address.address_type';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerMultiloadingPartnersNonFieldErrorsErrorComponent =
  {
    attr: 'payer.multiloading_partners.non_field_errors';
    code: 'not_a_list';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerMultiloadingPartnersIndexErrorComponent =
  {
    attr: 'payer.multiloading_partners.INDEX';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePayerIdErrorComponent = {
  attr: 'payer_id';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePayerPassengerExternalIdErrorComponent =
  {
    attr: 'payer_passenger_external_id';
    code:
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePaymentMethodErrorComponent = {
  attr: 'payment_method';
  code: 'invalid_choice' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePickupNonFieldErrorsErrorComponent =
  {
    attr: 'pickup.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePickupAddressDetailsErrorComponent =
  {
    attr: 'pickup.address_details';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePickupNumStairsErrorComponent = {
  attr: 'pickup.num_stairs';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePickupStairEquipmentErrorComponent =
  {
    attr: 'pickup.stair_equipment';
    code: 'invalid_choice' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePickupContactNameErrorComponent = {
  attr: 'pickup.contact_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePickupContactPhoneNumberErrorComponent =
  {
    attr: 'pickup.contact_phone_number';
    code:
      | 'invalid'
      | 'max_length'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdatePickupDriverNotesErrorComponent = {
  attr: 'pickup.driver_notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePickupPlaceIdErrorComponent = {
  attr: 'pickup.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdatePickupWaitTimeSecondsErrorComponent =
  {
    attr: 'pickup_wait_time_seconds';
    code: 'invalid' | 'max_string_length' | 'min_value' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesNonFieldErrorsErrorComponent =
  {
    attr: 'recurrences.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexNonFieldErrorsErrorComponent =
  {
    attr: 'recurrences.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexIsRoundTripErrorComponent =
  {
    attr: 'recurrences.INDEX.is_round_trip';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexIsWillCallErrorComponent =
  {
    attr: 'recurrences.INDEX.is_will_call';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexRruleErrorComponent =
  {
    attr: 'recurrences.INDEX.rrule';
    code:
      | 'blank'
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexInputPriceCentsErrorComponent =
  {
    attr: 'recurrences.INDEX.input_price_cents';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexScheduledPickupTimeErrorComponent =
  {
    attr: 'recurrences.INDEX.scheduled_pickup_time';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateRecurrencesIndexAppointmentTimeErrorComponent =
  {
    attr: 'recurrences.INDEX.appointment_time';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateMustProvideWheelchairErrorComponent =
  {
    attr: 'must_provide_wheelchair';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateServiceTypeErrorComponent = {
  attr: 'service_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateStartDateErrorComponent = {
  attr: 'start_date';
  code: 'datetime' | 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateUnloadTimeSecondsErrorComponent = {
  attr: 'unload_time_seconds';
  code: 'invalid' | 'max_string_length' | 'min_value' | 'null';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateOldTripsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'old_trips.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateOldTripsIndexPassengerInitialsErrorComponent =
  {
    attr: 'old_trips.INDEX.passenger_initials';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateOldTripsIndexPickupDatetimeErrorComponent =
  {
    attr: 'old_trips.INDEX.pickup_datetime';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateOldTripsIndexFromAddressErrorComponent =
  {
    attr: 'old_trips.INDEX.from_address';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateOldTripsIndexToAddressErrorComponent =
  {
    attr: 'old_trips.INDEX.to_address';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateOldTripsIndexKeyErrorComponent = {
  attr: 'old_trips.INDEX.key';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateNewTripsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'new_trips.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateNewTripsIndexPassengerInitialsErrorComponent =
  {
    attr: 'new_trips.INDEX.passenger_initials';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateNewTripsIndexPickupDatetimeErrorComponent =
  {
    attr: 'new_trips.INDEX.pickup_datetime';
    code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateNewTripsIndexFromAddressErrorComponent =
  {
    attr: 'new_trips.INDEX.from_address';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateNewTripsIndexToAddressErrorComponent =
  {
    attr: 'new_trips.INDEX.to_address';
    code:
      | 'blank'
      | 'invalid'
      | 'null'
      | 'null_characters_not_allowed'
      | 'required'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateNewTripsIndexKeyErrorComponent = {
  attr: 'new_trips.INDEX.key';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateHasInfectiousDiseaseErrorComponent =
  {
    attr: 'has_infectious_disease';
    code: 'invalid' | 'null';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateBookedByNameErrorComponent = {
  attr: 'booked_by_name';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateBookedByPhoneNumberErrorComponent = {
  attr: 'booked_by_phone_number';
  code:
    | 'invalid'
    | 'max_length'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateBookedByPhoneExtensionErrorComponent =
  {
    attr: 'booked_by_phone_extension';
    code:
      | 'invalid'
      | 'max_length'
      | 'null'
      | 'null_characters_not_allowed'
      | 'surrogate_characters_not_allowed';
    detail: string;
  };
export type TripsSubscriptionsPartialUpdateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSubscriptionsPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & TripsSubscriptionsPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'dispatcher_notes';
    } & TripsSubscriptionsPartialUpdateDispatcherNotesErrorComponent)
  | ({
      attr: 'dropoff.non_field_errors';
    } & TripsSubscriptionsPartialUpdateDropoffNonFieldErrorsErrorComponent)
  | ({
      attr: 'dropoff.address_details';
    } & TripsSubscriptionsPartialUpdateDropoffAddressDetailsErrorComponent)
  | ({
      attr: 'dropoff.num_stairs';
    } & TripsSubscriptionsPartialUpdateDropoffNumStairsErrorComponent)
  | ({
      attr: 'dropoff.stair_equipment';
    } & TripsSubscriptionsPartialUpdateDropoffStairEquipmentErrorComponent)
  | ({
      attr: 'dropoff.contact_name';
    } & TripsSubscriptionsPartialUpdateDropoffContactNameErrorComponent)
  | ({
      attr: 'dropoff.contact_phone_number';
    } & TripsSubscriptionsPartialUpdateDropoffContactPhoneNumberErrorComponent)
  | ({
      attr: 'dropoff.driver_notes';
    } & TripsSubscriptionsPartialUpdateDropoffDriverNotesErrorComponent)
  | ({
      attr: 'dropoff.place_id';
    } & TripsSubscriptionsPartialUpdateDropoffPlaceIdErrorComponent)
  | ({
      attr: 'estimated_distance_miles';
    } & TripsSubscriptionsPartialUpdateEstimatedDistanceMilesErrorComponent)
  | ({
      attr: 'estimated_load_seconds';
    } & TripsSubscriptionsPartialUpdateEstimatedLoadSecondsErrorComponent)
  | ({
      attr: 'estimated_unload_seconds';
    } & TripsSubscriptionsPartialUpdateEstimatedUnloadSecondsErrorComponent)
  | ({
      attr: 'expires_at';
    } & TripsSubscriptionsPartialUpdateExpiresAtErrorComponent)
  | ({
      attr: 'is_oxygen_required';
    } & TripsSubscriptionsPartialUpdateIsOxygenRequiredErrorComponent)
  | ({
      attr: 'is_transporting_scooter';
    } & TripsSubscriptionsPartialUpdateIsTransportingScooterErrorComponent)
  | ({
      attr: 'load_time_seconds';
    } & TripsSubscriptionsPartialUpdateLoadTimeSecondsErrorComponent)
  | ({
      attr: 'num_accompanying_passengers';
    } & TripsSubscriptionsPartialUpdateNumAccompanyingPassengersErrorComponent)
  | ({
      attr: 'num_attendants_needed';
    } & TripsSubscriptionsPartialUpdateNumAttendantsNeededErrorComponent)
  | ({
      attr: 'oxygen_liters_per_min';
    } & TripsSubscriptionsPartialUpdateOxygenLitersPerMinErrorComponent)
  | ({
      attr: 'passenger.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePassengerNonFieldErrorsErrorComponent)
  | ({
      attr: 'passenger.id';
    } & TripsSubscriptionsPartialUpdatePassengerIdErrorComponent)
  | ({
      attr: 'passenger.first_name';
    } & TripsSubscriptionsPartialUpdatePassengerFirstNameErrorComponent)
  | ({
      attr: 'passenger.last_name';
    } & TripsSubscriptionsPartialUpdatePassengerLastNameErrorComponent)
  | ({
      attr: 'passenger.preferred_name';
    } & TripsSubscriptionsPartialUpdatePassengerPreferredNameErrorComponent)
  | ({
      attr: 'passenger.pronoun';
    } & TripsSubscriptionsPartialUpdatePassengerPronounErrorComponent)
  | ({
      attr: 'passenger.gender';
    } & TripsSubscriptionsPartialUpdatePassengerGenderErrorComponent)
  | ({
      attr: 'passenger.dob';
    } & TripsSubscriptionsPartialUpdatePassengerDobErrorComponent)
  | ({
      attr: 'passenger.weight';
    } & TripsSubscriptionsPartialUpdatePassengerWeightErrorComponent)
  | ({
      attr: 'passenger.needs_bariatric_transport';
    } & TripsSubscriptionsPartialUpdatePassengerNeedsBariatricTransportErrorComponent)
  | ({
      attr: 'passenger.phone_number';
    } & TripsSubscriptionsPartialUpdatePassengerPhoneNumberErrorComponent)
  | ({
      attr: 'passenger.email';
    } & TripsSubscriptionsPartialUpdatePassengerEmailErrorComponent)
  | ({
      attr: 'passenger.notes';
    } & TripsSubscriptionsPartialUpdatePassengerNotesErrorComponent)
  | ({
      attr: 'passenger.dispatcher_notes';
    } & TripsSubscriptionsPartialUpdatePassengerDispatcherNotesErrorComponent)
  | ({
      attr: 'passenger.allow_sms_message';
    } & TripsSubscriptionsPartialUpdatePassengerAllowSmsMessageErrorComponent)
  | ({
      attr: 'payer.payer';
    } & TripsSubscriptionsPartialUpdatePayerPayerErrorComponent)
  | ({
      attr: 'payer.display_name';
    } & TripsSubscriptionsPartialUpdatePayerDisplayNameErrorComponent)
  | ({
      attr: 'payer.alternate_name';
    } & TripsSubscriptionsPartialUpdatePayerAlternateNameErrorComponent)
  | ({
      attr: 'payer.type';
    } & TripsSubscriptionsPartialUpdatePayerTypeErrorComponent)
  | ({
      attr: 'payer.is_active';
    } & TripsSubscriptionsPartialUpdatePayerIsActiveErrorComponent)
  | ({
      attr: 'payer.can_multiload';
    } & TripsSubscriptionsPartialUpdatePayerCanMultiloadErrorComponent)
  | ({
      attr: 'payer.external_id';
    } & TripsSubscriptionsPartialUpdatePayerExternalIdErrorComponent)
  | ({
      attr: 'payer.website';
    } & TripsSubscriptionsPartialUpdatePayerWebsiteErrorComponent)
  | ({
      attr: 'payer.ein';
    } & TripsSubscriptionsPartialUpdatePayerEinErrorComponent)
  | ({
      attr: 'payer.email';
    } & TripsSubscriptionsPartialUpdatePayerEmailErrorComponent)
  | ({
      attr: 'payer.phone_number';
    } & TripsSubscriptionsPartialUpdatePayerPhoneNumberErrorComponent)
  | ({
      attr: 'payer.billing_name';
    } & TripsSubscriptionsPartialUpdatePayerBillingNameErrorComponent)
  | ({
      attr: 'payer.billing_phone_number';
    } & TripsSubscriptionsPartialUpdatePayerBillingPhoneNumberErrorComponent)
  | ({
      attr: 'payer.billing_email';
    } & TripsSubscriptionsPartialUpdatePayerBillingEmailErrorComponent)
  | ({
      attr: 'payer.dispatcher_notes';
    } & TripsSubscriptionsPartialUpdatePayerDispatcherNotesErrorComponent)
  | ({
      attr: 'payer.admin_notes';
    } & TripsSubscriptionsPartialUpdatePayerAdminNotesErrorComponent)
  | ({
      attr: 'payer.deleted_at';
    } & TripsSubscriptionsPartialUpdatePayerDeletedAtErrorComponent)
  | ({
      attr: 'payer.billing_address.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.billing_address.location.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.billing_address.location.place_id';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer.billing_address.label';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressLabelErrorComponent)
  | ({
      attr: 'payer.billing_address.label2';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressLabel2ErrorComponent)
  | ({
      attr: 'payer.billing_address.address_notes';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer.billing_address.address_type';
    } & TripsSubscriptionsPartialUpdatePayerBillingAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer.default_trip_address.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.default_trip_address.location.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.default_trip_address.location.place_id';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLocationPlaceIdErrorComponent)
  | ({
      attr: 'payer.default_trip_address.label';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLabelErrorComponent)
  | ({
      attr: 'payer.default_trip_address.label2';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressLabel2ErrorComponent)
  | ({
      attr: 'payer.default_trip_address.address_notes';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressAddressNotesErrorComponent)
  | ({
      attr: 'payer.default_trip_address.address_type';
    } & TripsSubscriptionsPartialUpdatePayerDefaultTripAddressAddressTypeErrorComponent)
  | ({
      attr: 'payer.multiloading_partners.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePayerMultiloadingPartnersNonFieldErrorsErrorComponent)
  | ({
      attr: 'payer.multiloading_partners.INDEX';
    } & TripsSubscriptionsPartialUpdatePayerMultiloadingPartnersIndexErrorComponent)
  | ({
      attr: 'payer_id';
    } & TripsSubscriptionsPartialUpdatePayerIdErrorComponent)
  | ({
      attr: 'payer_passenger_external_id';
    } & TripsSubscriptionsPartialUpdatePayerPassengerExternalIdErrorComponent)
  | ({
      attr: 'payment_method';
    } & TripsSubscriptionsPartialUpdatePaymentMethodErrorComponent)
  | ({
      attr: 'pickup.non_field_errors';
    } & TripsSubscriptionsPartialUpdatePickupNonFieldErrorsErrorComponent)
  | ({
      attr: 'pickup.address_details';
    } & TripsSubscriptionsPartialUpdatePickupAddressDetailsErrorComponent)
  | ({
      attr: 'pickup.num_stairs';
    } & TripsSubscriptionsPartialUpdatePickupNumStairsErrorComponent)
  | ({
      attr: 'pickup.stair_equipment';
    } & TripsSubscriptionsPartialUpdatePickupStairEquipmentErrorComponent)
  | ({
      attr: 'pickup.contact_name';
    } & TripsSubscriptionsPartialUpdatePickupContactNameErrorComponent)
  | ({
      attr: 'pickup.contact_phone_number';
    } & TripsSubscriptionsPartialUpdatePickupContactPhoneNumberErrorComponent)
  | ({
      attr: 'pickup.driver_notes';
    } & TripsSubscriptionsPartialUpdatePickupDriverNotesErrorComponent)
  | ({
      attr: 'pickup.place_id';
    } & TripsSubscriptionsPartialUpdatePickupPlaceIdErrorComponent)
  | ({
      attr: 'pickup_wait_time_seconds';
    } & TripsSubscriptionsPartialUpdatePickupWaitTimeSecondsErrorComponent)
  | ({
      attr: 'recurrences.non_field_errors';
    } & TripsSubscriptionsPartialUpdateRecurrencesNonFieldErrorsErrorComponent)
  | ({
      attr: 'recurrences.INDEX.non_field_errors';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'recurrences.INDEX.is_round_trip';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexIsRoundTripErrorComponent)
  | ({
      attr: 'recurrences.INDEX.is_will_call';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexIsWillCallErrorComponent)
  | ({
      attr: 'recurrences.INDEX.rrule';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexRruleErrorComponent)
  | ({
      attr: 'recurrences.INDEX.input_price_cents';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexInputPriceCentsErrorComponent)
  | ({
      attr: 'recurrences.INDEX.scheduled_pickup_time';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexScheduledPickupTimeErrorComponent)
  | ({
      attr: 'recurrences.INDEX.appointment_time';
    } & TripsSubscriptionsPartialUpdateRecurrencesIndexAppointmentTimeErrorComponent)
  | ({
      attr: 'must_provide_wheelchair';
    } & TripsSubscriptionsPartialUpdateMustProvideWheelchairErrorComponent)
  | ({
      attr: 'service_type';
    } & TripsSubscriptionsPartialUpdateServiceTypeErrorComponent)
  | ({
      attr: 'space_type';
    } & TripsSubscriptionsPartialUpdateSpaceTypeErrorComponent)
  | ({
      attr: 'start_date';
    } & TripsSubscriptionsPartialUpdateStartDateErrorComponent)
  | ({
      attr: 'unload_time_seconds';
    } & TripsSubscriptionsPartialUpdateUnloadTimeSecondsErrorComponent)
  | ({
      attr: 'old_trips.INDEX.non_field_errors';
    } & TripsSubscriptionsPartialUpdateOldTripsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'old_trips.INDEX.passenger_initials';
    } & TripsSubscriptionsPartialUpdateOldTripsIndexPassengerInitialsErrorComponent)
  | ({
      attr: 'old_trips.INDEX.pickup_datetime';
    } & TripsSubscriptionsPartialUpdateOldTripsIndexPickupDatetimeErrorComponent)
  | ({
      attr: 'old_trips.INDEX.from_address';
    } & TripsSubscriptionsPartialUpdateOldTripsIndexFromAddressErrorComponent)
  | ({
      attr: 'old_trips.INDEX.to_address';
    } & TripsSubscriptionsPartialUpdateOldTripsIndexToAddressErrorComponent)
  | ({
      attr: 'old_trips.INDEX.key';
    } & TripsSubscriptionsPartialUpdateOldTripsIndexKeyErrorComponent)
  | ({
      attr: 'new_trips.INDEX.non_field_errors';
    } & TripsSubscriptionsPartialUpdateNewTripsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'new_trips.INDEX.passenger_initials';
    } & TripsSubscriptionsPartialUpdateNewTripsIndexPassengerInitialsErrorComponent)
  | ({
      attr: 'new_trips.INDEX.pickup_datetime';
    } & TripsSubscriptionsPartialUpdateNewTripsIndexPickupDatetimeErrorComponent)
  | ({
      attr: 'new_trips.INDEX.from_address';
    } & TripsSubscriptionsPartialUpdateNewTripsIndexFromAddressErrorComponent)
  | ({
      attr: 'new_trips.INDEX.to_address';
    } & TripsSubscriptionsPartialUpdateNewTripsIndexToAddressErrorComponent)
  | ({
      attr: 'new_trips.INDEX.key';
    } & TripsSubscriptionsPartialUpdateNewTripsIndexKeyErrorComponent)
  | ({
      attr: 'has_infectious_disease';
    } & TripsSubscriptionsPartialUpdateHasInfectiousDiseaseErrorComponent)
  | ({
      attr: 'booked_by_name';
    } & TripsSubscriptionsPartialUpdateBookedByNameErrorComponent)
  | ({
      attr: 'booked_by_phone_number';
    } & TripsSubscriptionsPartialUpdateBookedByPhoneNumberErrorComponent)
  | ({
      attr: 'booked_by_phone_extension';
    } & TripsSubscriptionsPartialUpdateBookedByPhoneExtensionErrorComponent)
  | ({
      attr: 'tag_ids';
    } & TripsSubscriptionsPartialUpdateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & TripsSubscriptionsPartialUpdateTagIdsIndexErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & TripsSubscriptionsPartialUpdateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & TripsSubscriptionsPartialUpdateTagsIndexColorHexErrorComponent);
export type TripsSubscriptionsPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsSubscriptionsPartialUpdateError[];
};
export type TripsSubscriptionsPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsSubscriptionsPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedTripSubscription = {
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  dropoff?: TripLocationDetail;
  estimated_distance_miles?: number | null;
  estimated_load_seconds?: number | null;
  estimated_unload_seconds?: number | null;
  /** When the recurrences stop applying (exclusive) */
  expires_at?: string;
  is_oxygen_required?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  load_time_seconds?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  num_attendants_needed?: number;
  oxygen_liters_per_min?: string;
  passenger?: PatchedTripPassenger;
  payment_method?: PaymentMethodEnum | BlankEnum;
  pickup?: TripLocationDetail;
  pickup_wait_time_seconds?: number;
  recurrences?: TripRecurrence[];
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  service_type?: ServiceTypeEnum;
  /** The type of space in the vehicle this passenger will need */
  space_type?: SpaceTypeEnum;
  /** When the recurrences start applying (inclusive) */
  start_date?: string;
  unload_time_seconds?: number;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
};
export type PatchedTripSubscriptionRead = {
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  dropoff?: TripLocationDetailRead;
  estimated_distance_miles?: number | null;
  estimated_load_seconds?: number | null;
  estimated_unload_seconds?: number | null;
  /** When the recurrences stop applying (exclusive) */
  expires_at?: string;
  id?: string;
  is_oxygen_required?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  load_time_seconds?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  num_attendants_needed?: number;
  oxygen_liters_per_min?: string;
  passenger?: PatchedTripPassengerRead;
  payer?: PayerOrganizationRead;
  payer_passenger?: TripPayerPassengerRead;
  payment_method?: PaymentMethodEnum | BlankEnum;
  pickup?: TripLocationDetailRead;
  pickup_wait_time_seconds?: number;
  recurrences?: TripRecurrence[];
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  service_type?: ServiceTypeEnum;
  /** The type of space in the vehicle this passenger will need */
  space_type?: SpaceTypeEnum;
  /** When the recurrences start applying (inclusive) */
  start_date?: string;
  unload_time_seconds?: number;
  old_trips?: TripSubscriptionTrip[];
  new_trips?: TripSubscriptionTrip[];
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  tags?: TripTripSubscriptionTagRead[] | null;
};
export type PatchedTripSubscriptionWrite = {
  /** Notes only visible to the dispatcher */
  dispatcher_notes?: string;
  dropoff?: TripLocationDetail;
  estimated_distance_miles?: number | null;
  estimated_load_seconds?: number | null;
  estimated_unload_seconds?: number | null;
  /** When the recurrences stop applying (exclusive) */
  expires_at?: string;
  is_oxygen_required?: boolean;
  /** Whether the passenger's scooter will need to be transported */
  is_transporting_scooter?: boolean;
  load_time_seconds?: number;
  /** How many additional people will be accompanying the primary passenger */
  num_accompanying_passengers?: number;
  num_attendants_needed?: number;
  oxygen_liters_per_min?: string;
  passenger?: PatchedTripPassenger;
  payer_id?: string;
  payer_passenger_external_id?: string;
  payment_method?: PaymentMethodEnum | BlankEnum;
  pickup?: TripLocationDetail;
  pickup_wait_time_seconds?: number;
  recurrences?: TripRecurrence[];
  /** Whether the passenger will supply their own wheelchair or not */
  must_provide_wheelchair?: boolean;
  service_type?: ServiceTypeEnum;
  /** The type of space in the vehicle this passenger will need */
  space_type?: SpaceTypeEnum;
  /** When the recurrences start applying (inclusive) */
  start_date?: string;
  unload_time_seconds?: number;
  /** Whether the passenger has an infectious disease */
  has_infectious_disease?: boolean;
  /** Who booked this trip, if not passenger */
  booked_by_name?: string;
  /** Phone number of who booked this trip, if not passenger */
  booked_by_phone_number?: string | null;
  /** Phone number extension of who booked this trip, if not passenger */
  booked_by_phone_extension?: string;
  tag_ids?: string[] | null;
};
export type TripsSubscriptionsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type TripSuggestedPickupTime = {};
export type TripSuggestedPickupTimeRead = {
  pickup_at: string | null;
};
export type TripSuggestedPickupTimeWrite = {
  appointment_at: string;
  pickup_aws_place_id: string;
  dropoff_aws_place_id: string;
  space_type: SpaceTypeEnum;
  load_time_seconds?: number | null;
  unload_time_seconds?: number | null;
};
export type TripsSuggestedPickupAtCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type TripsSuggestedPickupAtCreateAppointmentAtErrorComponent = {
  attr: 'appointment_at';
  code: 'date' | 'invalid' | 'make_aware' | 'null' | 'overflow' | 'required';
  detail: string;
};
export type TripsSuggestedPickupAtCreatePickupAwsPlaceIdErrorComponent = {
  attr: 'pickup_aws_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSuggestedPickupAtCreateDropoffAwsPlaceIdErrorComponent = {
  attr: 'dropoff_aws_place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type TripsSuggestedPickupAtCreateSpaceTypeErrorComponent = {
  attr: 'space_type';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type TripsSuggestedPickupAtCreateLoadTimeSecondsErrorComponent = {
  attr: 'load_time_seconds';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type TripsSuggestedPickupAtCreateUnloadTimeSecondsErrorComponent = {
  attr: 'unload_time_seconds';
  code: 'invalid' | 'max_string_length';
  detail: string;
};
export type TripsSuggestedPickupAtCreateError =
  | ({
      attr: 'non_field_errors';
    } & TripsSuggestedPickupAtCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'appointment_at';
    } & TripsSuggestedPickupAtCreateAppointmentAtErrorComponent)
  | ({
      attr: 'pickup_aws_place_id';
    } & TripsSuggestedPickupAtCreatePickupAwsPlaceIdErrorComponent)
  | ({
      attr: 'dropoff_aws_place_id';
    } & TripsSuggestedPickupAtCreateDropoffAwsPlaceIdErrorComponent)
  | ({
      attr: 'space_type';
    } & TripsSuggestedPickupAtCreateSpaceTypeErrorComponent)
  | ({
      attr: 'load_time_seconds';
    } & TripsSuggestedPickupAtCreateLoadTimeSecondsErrorComponent)
  | ({
      attr: 'unload_time_seconds';
    } & TripsSuggestedPickupAtCreateUnloadTimeSecondsErrorComponent);
export type TripsSuggestedPickupAtCreateValidationError = {
  type: ValidationErrorEnum;
  errors: TripsSuggestedPickupAtCreateError[];
};
export type TripsSuggestedPickupAtCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & TripsSuggestedPickupAtCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type VehicleTag = {
  vehicle: string;
  tag: string;
};
export type VehicleTagRead = {
  id: string;
  tag_name: string;
  color_hex: string;
  vehicle: string;
  tag: string;
  created_at: string;
  updated_at: string;
};
export type PaginatedVehicleTagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleTag[];
};
export type PaginatedVehicleTagListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleTagRead[];
};
export type VehicleTagsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type VehicleTagBulkCreate = {
  tags: VehicleTag[];
};
export type VehicleTagBulkCreateRead = {
  tags: VehicleTagRead[];
};
export type VehicleTagsCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type VehicleTagsCreateTagsNonFieldErrorsErrorComponent = {
  attr: 'tags.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type VehicleTagsCreateTagsIndexNonFieldErrorsErrorComponent = {
  attr: 'tags.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required' | 'unique';
  detail: string;
};
export type VehicleTagsCreateTagsIndexVehicleErrorComponent = {
  attr: 'tags.INDEX.vehicle';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type VehicleTagsCreateTagsIndexTagErrorComponent = {
  attr: 'tags.INDEX.tag';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type VehicleTagsCreateError =
  | ({
      attr: 'non_field_errors';
    } & VehicleTagsCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.non_field_errors';
    } & VehicleTagsCreateTagsNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.INDEX.non_field_errors';
    } & VehicleTagsCreateTagsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'tags.INDEX.vehicle';
    } & VehicleTagsCreateTagsIndexVehicleErrorComponent)
  | ({
      attr: 'tags.INDEX.tag';
    } & VehicleTagsCreateTagsIndexTagErrorComponent);
export type VehicleTagsCreateValidationError = {
  type: ValidationErrorEnum;
  errors: VehicleTagsCreateError[];
};
export type VehicleTagsCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & VehicleTagsCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type VehicleTagsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type VehicleTagsDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type PaginatedVehicleList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Vehicle[];
};
export type PaginatedVehicleListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleRead[];
};
export type PaginatedVehicleListWrite = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleWrite[];
};
export type VehiclesListCategoryErrorComponent = {
  attr: 'category';
  code: 'invalid_choice';
  detail: string;
};
export type VehiclesListError = {
  attr: 'category';
} & VehiclesListCategoryErrorComponent;
export type VehiclesListValidationError = {
  type: ValidationErrorEnum;
  errors: VehiclesListError[];
};
export type VehiclesListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & VehiclesListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type VehiclesCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type VehiclesCreateNicknameErrorComponent = {
  attr: 'nickname';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateVinErrorComponent = {
  attr: 'vin';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateMakeErrorComponent = {
  attr: 'make';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateModelErrorComponent = {
  attr: 'model';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateColorErrorComponent = {
  attr: 'color';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateYearErrorComponent = {
  attr: 'year';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type VehiclesCreateCanBeScheduledErrorComponent = {
  attr: 'can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type VehiclesCreateLicensePlateErrorComponent = {
  attr: 'license_plate';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateLicensePlateStateErrorComponent = {
  attr: 'license_plate_state';
  code: 'invalid_choice';
  detail: string;
};
export type VehiclesCreateCategoryErrorComponent = {
  attr: 'category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type VehiclesCreateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateStorageLocationNonFieldErrorsErrorComponent = {
  attr: 'storage_location.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type VehiclesCreateStorageLocationPlaceIdErrorComponent = {
  attr: 'storage_location.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateCapacityConfigurationsNonFieldErrorsErrorComponent = {
  attr: 'capacity_configurations.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type VehiclesCreateCapacityConfigurationsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexWheelchairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.wheelchair_xl_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexBrodaChairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.broda_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexGeriChairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.geri_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexStretcherCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.stretcher_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.ambulatory_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.equipment_wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesCreateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type VehiclesCreateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesCreateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type VehiclesCreateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type VehiclesCreateError =
  | ({
      attr: 'non_field_errors';
    } & VehiclesCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'nickname';
    } & VehiclesCreateNicknameErrorComponent)
  | ({
      attr: 'vin';
    } & VehiclesCreateVinErrorComponent)
  | ({
      attr: 'make';
    } & VehiclesCreateMakeErrorComponent)
  | ({
      attr: 'model';
    } & VehiclesCreateModelErrorComponent)
  | ({
      attr: 'color';
    } & VehiclesCreateColorErrorComponent)
  | ({
      attr: 'year';
    } & VehiclesCreateYearErrorComponent)
  | ({
      attr: 'can_be_scheduled';
    } & VehiclesCreateCanBeScheduledErrorComponent)
  | ({
      attr: 'license_plate';
    } & VehiclesCreateLicensePlateErrorComponent)
  | ({
      attr: 'license_plate_state';
    } & VehiclesCreateLicensePlateStateErrorComponent)
  | ({
      attr: 'category';
    } & VehiclesCreateCategoryErrorComponent)
  | ({
      attr: 'notes';
    } & VehiclesCreateNotesErrorComponent)
  | ({
      attr: 'storage_location.non_field_errors';
    } & VehiclesCreateStorageLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'storage_location.place_id';
    } & VehiclesCreateStorageLocationPlaceIdErrorComponent)
  | ({
      attr: 'capacity_configurations.non_field_errors';
    } & VehiclesCreateCapacityConfigurationsNonFieldErrorsErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.non_field_errors';
    } & VehiclesCreateCapacityConfigurationsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.wheelchair_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexWheelchairCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.wheelchair_xl_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.broda_chair_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexBrodaChairCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.geri_chair_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexGeriChairCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.stretcher_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexStretcherCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.ambulatory_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.equipment_wheelchair_capacity';
    } & VehiclesCreateCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent)
  | ({
      attr: 'deleted_at';
    } & VehiclesCreateDeletedAtErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & VehiclesCreateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & VehiclesCreateTagsIndexColorHexErrorComponent)
  | ({
      attr: 'tag_ids';
    } & VehiclesCreateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & VehiclesCreateTagIdsIndexErrorComponent);
export type VehiclesCreateValidationError = {
  type: ValidationErrorEnum;
  errors: VehiclesCreateError[];
};
export type VehiclesCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & VehiclesCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type VehiclesRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type VehiclesPartialUpdateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type VehiclesPartialUpdateNicknameErrorComponent = {
  attr: 'nickname';
  code:
    | 'blank'
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateVinErrorComponent = {
  attr: 'vin';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateMakeErrorComponent = {
  attr: 'make';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateModelErrorComponent = {
  attr: 'model';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateColorErrorComponent = {
  attr: 'color';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateYearErrorComponent = {
  attr: 'year';
  code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value';
  detail: string;
};
export type VehiclesPartialUpdateCanBeScheduledErrorComponent = {
  attr: 'can_be_scheduled';
  code: 'invalid' | 'null';
  detail: string;
};
export type VehiclesPartialUpdateLicensePlateErrorComponent = {
  attr: 'license_plate';
  code:
    | 'invalid'
    | 'max_length'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateLicensePlateStateErrorComponent = {
  attr: 'license_plate_state';
  code: 'invalid_choice';
  detail: string;
};
export type VehiclesPartialUpdateCategoryErrorComponent = {
  attr: 'category';
  code: 'invalid_choice' | 'null' | 'required';
  detail: string;
};
export type VehiclesPartialUpdateNotesErrorComponent = {
  attr: 'notes';
  code:
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateStorageLocationNonFieldErrorsErrorComponent = {
  attr: 'storage_location.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type VehiclesPartialUpdateStorageLocationPlaceIdErrorComponent = {
  attr: 'storage_location.place_id';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateCapacityConfigurationsNonFieldErrorsErrorComponent =
  {
    attr: 'capacity_configurations.non_field_errors';
    code: 'not_a_list' | 'null' | 'required';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexNonFieldErrorsErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.non_field_errors';
    code: 'invalid' | 'null' | 'required';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexWheelchairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.wheelchair_xl_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexBrodaChairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.broda_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexGeriChairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.geri_chair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexStretcherCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.stretcher_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.ambulatory_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent =
  {
    attr: 'capacity_configurations.INDEX.equipment_wheelchair_capacity';
    code: 'invalid' | 'max_string_length' | 'max_value' | 'min_value' | 'null';
    detail: string;
  };
export type VehiclesPartialUpdateDeletedAtErrorComponent = {
  attr: 'deleted_at';
  code: 'date' | 'invalid' | 'make_aware' | 'overflow';
  detail: string;
};
export type VehiclesPartialUpdateTagsIndexTagNameErrorComponent = {
  attr: 'tags.INDEX.tag_name';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateTagsIndexColorHexErrorComponent = {
  attr: 'tags.INDEX.color_hex';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesPartialUpdateTagIdsErrorComponent = {
  attr: 'tag_ids';
  code: 'not_a_list';
  detail: string;
};
export type VehiclesPartialUpdateTagIdsIndexErrorComponent = {
  attr: 'tag_ids.INDEX';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type VehiclesPartialUpdateError =
  | ({
      attr: 'non_field_errors';
    } & VehiclesPartialUpdateNonFieldErrorsErrorComponent)
  | ({
      attr: 'nickname';
    } & VehiclesPartialUpdateNicknameErrorComponent)
  | ({
      attr: 'vin';
    } & VehiclesPartialUpdateVinErrorComponent)
  | ({
      attr: 'make';
    } & VehiclesPartialUpdateMakeErrorComponent)
  | ({
      attr: 'model';
    } & VehiclesPartialUpdateModelErrorComponent)
  | ({
      attr: 'color';
    } & VehiclesPartialUpdateColorErrorComponent)
  | ({
      attr: 'year';
    } & VehiclesPartialUpdateYearErrorComponent)
  | ({
      attr: 'can_be_scheduled';
    } & VehiclesPartialUpdateCanBeScheduledErrorComponent)
  | ({
      attr: 'license_plate';
    } & VehiclesPartialUpdateLicensePlateErrorComponent)
  | ({
      attr: 'license_plate_state';
    } & VehiclesPartialUpdateLicensePlateStateErrorComponent)
  | ({
      attr: 'category';
    } & VehiclesPartialUpdateCategoryErrorComponent)
  | ({
      attr: 'notes';
    } & VehiclesPartialUpdateNotesErrorComponent)
  | ({
      attr: 'storage_location.non_field_errors';
    } & VehiclesPartialUpdateStorageLocationNonFieldErrorsErrorComponent)
  | ({
      attr: 'storage_location.place_id';
    } & VehiclesPartialUpdateStorageLocationPlaceIdErrorComponent)
  | ({
      attr: 'capacity_configurations.non_field_errors';
    } & VehiclesPartialUpdateCapacityConfigurationsNonFieldErrorsErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.non_field_errors';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.wheelchair_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexWheelchairCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.wheelchair_xl_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexWheelchairXlCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.broda_chair_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexBrodaChairCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.geri_chair_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexGeriChairCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.stretcher_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexStretcherCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.ambulatory_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexAmbulatoryCapacityErrorComponent)
  | ({
      attr: 'capacity_configurations.INDEX.equipment_wheelchair_capacity';
    } & VehiclesPartialUpdateCapacityConfigurationsIndexEquipmentWheelchairCapacityErrorComponent)
  | ({
      attr: 'deleted_at';
    } & VehiclesPartialUpdateDeletedAtErrorComponent)
  | ({
      attr: 'tags.INDEX.tag_name';
    } & VehiclesPartialUpdateTagsIndexTagNameErrorComponent)
  | ({
      attr: 'tags.INDEX.color_hex';
    } & VehiclesPartialUpdateTagsIndexColorHexErrorComponent)
  | ({
      attr: 'tag_ids';
    } & VehiclesPartialUpdateTagIdsErrorComponent)
  | ({
      attr: 'tag_ids.INDEX';
    } & VehiclesPartialUpdateTagIdsIndexErrorComponent);
export type VehiclesPartialUpdateValidationError = {
  type: ValidationErrorEnum;
  errors: VehiclesPartialUpdateError[];
};
export type VehiclesPartialUpdateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & VehiclesPartialUpdateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type PatchedVehicle = {
  nickname?: string;
  vin?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number | null;
  can_be_scheduled?: boolean;
  license_plate?: string;
  license_plate_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  category?: VehicleCategoryEnum;
  notes?: string;
  storage_location?: VehicleStorageLocation;
  capacity_configurations?: VehicleCapacityConfiguration[];
  deleted_at?: string | null;
};
export type PatchedVehicleRead = {
  id?: string;
  nickname?: string;
  vin?: string;
  organization_id?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number | null;
  can_be_scheduled?: boolean;
  license_plate?: string;
  license_plate_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  category?: VehicleCategoryEnum;
  notes?: string;
  storage_location?: VehicleStorageLocationRead;
  capacity_configurations?: VehicleCapacityConfiguration[];
  updated_at?: string;
  created_at?: string;
  deleted_at?: string | null;
  tags?: VehicleVehicleTagRead[] | null;
};
export type PatchedVehicleWrite = {
  nickname?: string;
  vin?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number | null;
  can_be_scheduled?: boolean;
  license_plate?: string;
  license_plate_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  category?: VehicleCategoryEnum;
  notes?: string;
  storage_location?: VehicleStorageLocation;
  capacity_configurations?: VehicleCapacityConfiguration[];
  deleted_at?: string | null;
  tag_ids?: string[] | null;
};
export type VehiclesDestroyErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type VehicleInspectionReport = {
  membership: string;
  vehicle: string;
  assignment?: string | null;
};
export type VehicleInspectionReportRead = {
  id: string;
  membership: string;
  vehicle: string;
  assignment?: string | null;
  created_at: string;
  updated_at: string;
};
export type VehicleInspectionFieldSubmit = {
  title: string;
  value: string;
  comment?: string;
};
export type VehicleInspectionReportWrite = {
  membership: string;
  vehicle: string;
  assignment?: string | null;
  report: VehicleInspectionFieldSubmit[];
};
export type VehiclesInspectionCreateNonFieldErrorsErrorComponent = {
  attr: 'non_field_errors';
  code: 'invalid';
  detail: string;
};
export type VehiclesInspectionCreateMembershipErrorComponent = {
  attr: 'membership';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type VehiclesInspectionCreateVehicleErrorComponent = {
  attr: 'vehicle';
  code: 'does_not_exist' | 'incorrect_type' | 'null' | 'required';
  detail: string;
};
export type VehiclesInspectionCreateAssignmentErrorComponent = {
  attr: 'assignment';
  code: 'does_not_exist' | 'incorrect_type';
  detail: string;
};
export type VehiclesInspectionCreateReportNonFieldErrorsErrorComponent = {
  attr: 'report.non_field_errors';
  code: 'not_a_list' | 'null' | 'required';
  detail: string;
};
export type VehiclesInspectionCreateReportIndexNonFieldErrorsErrorComponent = {
  attr: 'report.INDEX.non_field_errors';
  code: 'invalid' | 'null' | 'required';
  detail: string;
};
export type VehiclesInspectionCreateReportIndexTitleErrorComponent = {
  attr: 'report.INDEX.title';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesInspectionCreateReportIndexValueErrorComponent = {
  attr: 'report.INDEX.value';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'required'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesInspectionCreateReportIndexCommentErrorComponent = {
  attr: 'report.INDEX.comment';
  code:
    | 'blank'
    | 'invalid'
    | 'null'
    | 'null_characters_not_allowed'
    | 'surrogate_characters_not_allowed';
  detail: string;
};
export type VehiclesInspectionCreateError =
  | ({
      attr: 'non_field_errors';
    } & VehiclesInspectionCreateNonFieldErrorsErrorComponent)
  | ({
      attr: 'membership';
    } & VehiclesInspectionCreateMembershipErrorComponent)
  | ({
      attr: 'vehicle';
    } & VehiclesInspectionCreateVehicleErrorComponent)
  | ({
      attr: 'assignment';
    } & VehiclesInspectionCreateAssignmentErrorComponent)
  | ({
      attr: 'report.non_field_errors';
    } & VehiclesInspectionCreateReportNonFieldErrorsErrorComponent)
  | ({
      attr: 'report.INDEX.non_field_errors';
    } & VehiclesInspectionCreateReportIndexNonFieldErrorsErrorComponent)
  | ({
      attr: 'report.INDEX.title';
    } & VehiclesInspectionCreateReportIndexTitleErrorComponent)
  | ({
      attr: 'report.INDEX.value';
    } & VehiclesInspectionCreateReportIndexValueErrorComponent)
  | ({
      attr: 'report.INDEX.comment';
    } & VehiclesInspectionCreateReportIndexCommentErrorComponent);
export type VehiclesInspectionCreateValidationError = {
  type: ValidationErrorEnum;
  errors: VehiclesInspectionCreateError[];
};
export type VehiclesInspectionCreateErrorResponse400 =
  | ({
      type: 'validation_error';
    } & VehiclesInspectionCreateValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type VehicleInspectionField = {
  title: string;
  description: string;
  field_type: string;
  required?: boolean;
};
export type PaginatedVehicleInspectionFieldList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleInspectionField[];
};
export type VehiclesInspectionFieldsListErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export type VehicleInspectionReportDetails = {};
export type ReportMembership = {
  id?: string;
  first_name: string;
  last_name: string;
};
export type ReportVehicle = {
  id?: string;
  nickname: string;
};
export type ReportAssignment = {
  id?: string;
  start: string;
  end: string;
};
export type VehicleInspectionReportDetailsRead = {
  id: string;
  membership: ReportMembership;
  vehicle: ReportVehicle;
  assignment: ReportAssignment;
  report_json: {
    [key: string]: any;
  };
  created_at: string;
  updated_at: string;
};
export type PaginatedVehicleInspectionReportDetailsList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleInspectionReportDetails[];
};
export type PaginatedVehicleInspectionReportDetailsListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: VehicleInspectionReportDetailsRead[];
};
export type VehiclesInspectionsListVehicleErrorComponent = {
  attr: 'vehicle';
  code: 'invalid_choice';
  detail: string;
};
export type VehiclesInspectionsListAssignmentErrorComponent = {
  attr: 'assignment';
  code: 'invalid_choice';
  detail: string;
};
export type VehiclesInspectionsListMembershipErrorComponent = {
  attr: 'membership';
  code: 'invalid_choice';
  detail: string;
};
export type VehiclesInspectionsListCreatedAtErrorComponent = {
  attr: 'created_at';
  code: 'invalid';
  detail: string;
};
export type VehiclesInspectionsListRangeStartErrorComponent = {
  attr: 'range_start';
  code: 'invalid';
  detail: string;
};
export type VehiclesInspectionsListRangeEndErrorComponent = {
  attr: 'range_end';
  code: 'invalid';
  detail: string;
};
export type VehiclesInspectionsListError =
  | ({
      attr: 'vehicle';
    } & VehiclesInspectionsListVehicleErrorComponent)
  | ({
      attr: 'assignment';
    } & VehiclesInspectionsListAssignmentErrorComponent)
  | ({
      attr: 'membership';
    } & VehiclesInspectionsListMembershipErrorComponent)
  | ({
      attr: 'created_at';
    } & VehiclesInspectionsListCreatedAtErrorComponent)
  | ({
      attr: 'range_start';
    } & VehiclesInspectionsListRangeStartErrorComponent)
  | ({
      attr: 'range_end';
    } & VehiclesInspectionsListRangeEndErrorComponent);
export type VehiclesInspectionsListValidationError = {
  type: ValidationErrorEnum;
  errors: VehiclesInspectionsListError[];
};
export type VehiclesInspectionsListErrorResponse400 =
  | ({
      type: 'validation_error';
    } & VehiclesInspectionsListValidationError)
  | ({
      type: 'client_error';
    } & ParseErrorResponse);
export type VehiclesInspectionsRetrieveErrorResponse400 = {
  type: 'client_error';
} & ParseErrorResponse;
export const {
  useAssignmentsListQuery,
  useLazyAssignmentsListQuery,
  useAssignmentsCreateMutation,
  useAssignmentsRetrieveQuery,
  useLazyAssignmentsRetrieveQuery,
  useAssignmentsPartialUpdateMutation,
  useAssignmentsDestroyMutation,
  useAssignmentsBreaksListQuery,
  useLazyAssignmentsBreaksListQuery,
  useAssignmentsBreaksCreateMutation,
  useAssignmentsBreaksRetrieveQuery,
  useLazyAssignmentsBreaksRetrieveQuery,
  useAssignmentsBreaksPartialUpdateMutation,
  useAssignmentsBreaksDestroyMutation,
  useAssignmentsBreaksActiveListQuery,
  useLazyAssignmentsBreaksActiveListQuery,
  useAssignmentsDriverCallRequestsCreateMutation,
  useAssignmentsDriverCallRequestsCancelPartialUpdateMutation,
  useAssignmentsDriverCallRequestsResolvePartialUpdateMutation,
  useAssignmentsEventsListQuery,
  useLazyAssignmentsEventsListQuery,
  useAssignmentsEventsCreateMutation,
  useAssignmentsTripsListQuery,
  useLazyAssignmentsTripsListQuery,
  useAssignmentsTripsActiveListQuery,
  useLazyAssignmentsTripsActiveListQuery,
  useAssignmentsAssignedMembershipsPartialUpdateMutation,
  useAssignmentsBreaksEventsListQuery,
  useLazyAssignmentsBreaksEventsListQuery,
  useAssignmentsBreaksEventsCreateMutation,
  useAssignmentsBulkDefaultVehicleDriverListQuery,
  useLazyAssignmentsBulkDefaultVehicleDriverListQuery,
  useAssignmentsDriverRetrieveQuery,
  useLazyAssignmentsDriverRetrieveQuery,
  useAssignmentsDriverListListQuery,
  useLazyAssignmentsDriverListListQuery,
  useAssignmentsExportListQuery,
  useLazyAssignmentsExportListQuery,
  useAssignmentsLocationsListQuery,
  useLazyAssignmentsLocationsListQuery,
  useAssignmentsMemberListListQuery,
  useLazyAssignmentsMemberListListQuery,
  useAssignmentsTimeRangeCreateMutation,
  useAttendantsListQuery,
  useLazyAttendantsListQuery,
  useAttendantsCreateMutation,
  useAttendantsRetrieveQuery,
  useLazyAttendantsRetrieveQuery,
  useAttendantsPartialUpdateMutation,
  useAttendantsDestroyMutation,
  useAttendantsSkillsCreateMutation,
  useAttendantsSkillsRetrieveQuery,
  useLazyAttendantsSkillsRetrieveQuery,
  useAttendantsSkillsUpdateMutation,
  useAttendantsSkillsPartialUpdateMutation,
  useAttendantsSkillsDestroyMutation,
  useBillingBillingSettingsRetrieveQuery,
  useLazyBillingBillingSettingsRetrieveQuery,
  useBillingBillingSettingsPartialUpdateMutation,
  useBillingBillingSettingsNextInvoiceNumberRetrieveQuery,
  useLazyBillingBillingSettingsNextInvoiceNumberRetrieveQuery,
  useBillingTripsBillingListQuery,
  useLazyBillingTripsBillingListQuery,
  useBrokersAliviOauth2TokenCreateMutation,
  useBrokersAliviRideCancelCreateMutation,
  useBrokersAliviRideGetStatusCreateMutation,
  useBrokersAliviRideOfferCreateMutation,
  useBrokersAliviRideUpdateCreateMutation,
  useBrokersExampleHelloWorldRetrieveQuery,
  useLazyBrokersExampleHelloWorldRetrieveQuery,
  useBrokersKinetikOauth2TokenCreateMutation,
  useBrokersKinetikV1CancelRideCreateMutation,
  useBrokersKinetikV1CreateRidesCreateMutation,
  useBrokersKinetikV1GetDriverCreateMutation,
  useBrokersKinetikV1GetRideCreateMutation,
  useBrokersKinetikV1GetVehicleCreateMutation,
  useBrokersKinetikV1UpdateRidesUpdateMutation,
  useBrokersMtmOauth2TokenCreateMutation,
  useBrokersMtmWebhookCreateMutation,
  useCredentialsListQuery,
  useLazyCredentialsListQuery,
  useCredentialsCreateMutation,
  useCredentialsRetrieveQuery,
  useLazyCredentialsRetrieveQuery,
  useCredentialsPartialUpdateMutation,
  useCredentialsDestroyMutation,
  useCredentialsTypesListQuery,
  useLazyCredentialsTypesListQuery,
  useCredentialsTypesCreateMutation,
  useCredentialsTypesRetrieveQuery,
  useLazyCredentialsTypesRetrieveQuery,
  useCredentialsTypesPartialUpdateMutation,
  useCredentialsTypesDestroyMutation,
  useDieCreateMutation,
  useDispatchRetrieveQuery,
  useLazyDispatchRetrieveQuery,
  useDriverTagsListQuery,
  useLazyDriverTagsListQuery,
  useDriverTagsCreateMutation,
  useDriverTagsRetrieveQuery,
  useLazyDriverTagsRetrieveQuery,
  useDriverTagsDestroyMutation,
  useDriversListQuery,
  useLazyDriversListQuery,
  useDriversCreateMutation,
  useDriversRetrieveQuery,
  useLazyDriversRetrieveQuery,
  useDriversPartialUpdateMutation,
  useDriversDestroyMutation,
  useFilterGroupsListQuery,
  useLazyFilterGroupsListQuery,
  useFilterGroupsCreateMutation,
  useFilterGroupsRetrieveQuery,
  useLazyFilterGroupsRetrieveQuery,
  useFilterGroupsPartialUpdateMutation,
  useFilterGroupsDestroyMutation,
  useFilterGroupsCreateExamplesCreateMutation,
  useFlagsFeatureValuesListQuery,
  useLazyFlagsFeatureValuesListQuery,
  useFlagsFeatureValuesMobileListQuery,
  useLazyFlagsFeatureValuesMobileListQuery,
  useFlagsFeatureValuesWebListQuery,
  useLazyFlagsFeatureValuesWebListQuery,
  useFlagsFeaturesListQuery,
  useLazyFlagsFeaturesListQuery,
  useInvoicesExportListQuery,
  useLazyInvoicesExportListQuery,
  useInvoicesInvoicesListQuery,
  useLazyInvoicesInvoicesListQuery,
  useInvoicesInvoicesCreateMutation,
  useInvoicesInvoicesRetrieveQuery,
  useLazyInvoicesInvoicesRetrieveQuery,
  useInvoicesInvoicesPartialUpdateMutation,
  useInvoicesInvoicesDestroyMutation,
  useInvoicesLineItemsListQuery,
  useLazyInvoicesLineItemsListQuery,
  useInvoicesLineItemsCreateMutation,
  useInvoicesLineItemsRetrieveQuery,
  useLazyInvoicesLineItemsRetrieveQuery,
  useInvoicesLineItemsPartialUpdateMutation,
  useInvoicesLineItemsDestroyMutation,
  useInvoicesPaymentsListQuery,
  useLazyInvoicesPaymentsListQuery,
  useInvoicesPaymentsCreateMutation,
  useInvoicesPaymentsRetrieveQuery,
  useLazyInvoicesPaymentsRetrieveQuery,
  useInvoicesRefundsListQuery,
  useLazyInvoicesRefundsListQuery,
  useInvoicesRefundsCreateMutation,
  useInvoicesRefundsRetrieveQuery,
  useLazyInvoicesRefundsRetrieveQuery,
  useLocationsAddressesListQuery,
  useLazyLocationsAddressesListQuery,
  useLocationsAddressesRetrieveQuery,
  useLazyLocationsAddressesRetrieveQuery,
  useLocationsLogCreateMutation,
  useLocationsMunicipalitiesRetrieveQuery,
  useLazyLocationsMunicipalitiesRetrieveQuery,
  useLocationsPassengerAddressesListQuery,
  useLazyLocationsPassengerAddressesListQuery,
  useLocationsPassengerAddressesRetrieveQuery,
  useLazyLocationsPassengerAddressesRetrieveQuery,
  useMembersListQuery,
  useLazyMembersListQuery,
  useMembersCreateMutation,
  useMembersRetrieveQuery,
  useLazyMembersRetrieveQuery,
  useMembersPartialUpdateMutation,
  useMembersDestroyMutation,
  useMembersUndeleteCreateMutation,
  useMessagesListQuery,
  useLazyMessagesListQuery,
  useMessagesCreateMutation,
  useMessagesRetrieveQuery,
  useLazyMessagesRetrieveQuery,
  useMessagesTripMessagesListQuery,
  useLazyMessagesTripMessagesListQuery,
  useMessagesTripMessagesCreateMutation,
  useMessagesTripMessagesRetrieveQuery,
  useLazyMessagesTripMessagesRetrieveQuery,
  useMessagesWebHookMessageUpdateCreateMutation,
  useMobileAppSettingsRetrieveQuery,
  useLazyMobileAppSettingsRetrieveQuery,
  useMobileAppSettingsPartialUpdateMutation,
  useOrganizationBillingStatusRetrieveQuery,
  useLazyOrganizationBillingStatusRetrieveQuery,
  useOrganizationSettingsRetrieveQuery,
  useLazyOrganizationSettingsRetrieveQuery,
  useOrganizationSettingsPartialUpdateMutation,
  useOrganizationSettingsConnectMerchantAccountCreateMutation,
  usePassengersListQuery,
  useLazyPassengersListQuery,
  usePassengersCreateMutation,
  usePassengersRetrieveQuery,
  useLazyPassengersRetrieveQuery,
  usePassengersPartialUpdateMutation,
  usePassengersDestroyMutation,
  usePassengersRecentAddressesListQuery,
  useLazyPassengersRecentAddressesListQuery,
  usePassengersRecentPayersListQuery,
  useLazyPassengersRecentPayersListQuery,
  usePassengersDuplicatesListQuery,
  useLazyPassengersDuplicatesListQuery,
  usePassengersMergeCreateMutation,
  usePayerSettingsListQuery,
  useLazyPayerSettingsListQuery,
  usePayerSettingsRetrieveQuery,
  useLazyPayerSettingsRetrieveQuery,
  usePayerSettingsPartialUpdateMutation,
  usePayerinfoListQuery,
  useLazyPayerinfoListQuery,
  usePayerinfoRetrieveQuery,
  useLazyPayerinfoRetrieveQuery,
  usePayerinfooneschematemplateListQuery,
  useLazyPayerinfooneschematemplateListQuery,
  usePayersListQuery,
  useLazyPayersListQuery,
  usePayersCreateMutation,
  usePayersPassengersRetrieveQuery,
  useLazyPayersPassengersRetrieveQuery,
  usePayersPassengersCreateMutation,
  usePayersPassengersPartialUpdateMutation,
  usePayersPassengersDestroyMutation,
  usePayersSettingsRetrieveQuery,
  useLazyPayersSettingsRetrieveQuery,
  usePayersSettingsPartialUpdateMutation,
  usePayersRetrieveQuery,
  useLazyPayersRetrieveQuery,
  usePayersPartialUpdateMutation,
  usePayersDestroyMutation,
  usePayersListSelectedQuery,
  useLazyPayersListSelectedQuery,
  usePayersandtemplatesRetrieveQuery,
  useLazyPayersandtemplatesRetrieveQuery,
  usePayerstemplatesListQuery,
  useLazyPayerstemplatesListQuery,
  usePayerstemplatesRetrieveQuery,
  useLazyPayerstemplatesRetrieveQuery,
  usePricingPriceEstimateCreateMutation,
  usePricingPriceQuoteCreateMutation,
  usePricingPricingModelSelectionCreateMutation,
  usePricingPricingModelsListQuery,
  useLazyPricingPricingModelsListQuery,
  usePricingPricingModelsCreateMutation,
  usePricingPricingModelsRetrieveQuery,
  useLazyPricingPricingModelsRetrieveQuery,
  usePricingPricingModelsPartialUpdateMutation,
  usePricingPricingModelsDestroyMutation,
  usePricingPricingRulesPartialUpdateMutation,
  usePricingPricingRulesDestroyMutation,
  usePricingPricingSchemeConflictsCreateMutation,
  usePricingPricingSchemesListQuery,
  useLazyPricingPricingSchemesListQuery,
  usePricingPricingSchemesCreateMutation,
  usePricingPricingSchemesRetrieveQuery,
  useLazyPricingPricingSchemesRetrieveQuery,
  usePricingPricingSchemesPartialUpdateMutation,
  usePricingPricingSchemesDestroyMutation,
  usePricingPricingTypesListQuery,
  useLazyPricingPricingTypesListQuery,
  usePricingPricingTypesCreateMutation,
  usePricingPricingTypesRetrieveQuery,
  useLazyPricingPricingTypesRetrieveQuery,
  usePricingPricingTypesPartialUpdateMutation,
  usePricingPricingTypesDestroyMutation,
  useProfileRetrieveQuery,
  useLazyProfileRetrieveQuery,
  useRunBambiRunCreateMutation,
  useRunBambiRunRetrieveQuery,
  useLazyRunBambiRunRetrieveQuery,
  useRunBambiRunApplyCreateMutation,
  useSignaturesListQuery,
  useLazySignaturesListQuery,
  useSignaturesCreateMutation,
  useSignaturesRetrieveQuery,
  useLazySignaturesRetrieveQuery,
  useSignaturesDestroyMutation,
  useTagsListQuery,
  useLazyTagsListQuery,
  useTagsCreateMutation,
  useTagsRetrieveQuery,
  useLazyTagsRetrieveQuery,
  useTagsPartialUpdateMutation,
  useTagsDestroyMutation,
  useTilledPaymentMethodRetrieveQuery,
  useLazyTilledPaymentMethodRetrieveQuery,
  useTilledPaymentMethodCreateMutation,
  useTilledPaymentMethodDestroyMutation,
  useTilledPayoutsListQuery,
  useLazyTilledPayoutsListQuery,
  useTilledWebhookCreateMutation,
  useTokensChatIssueRetrieveQuery,
  useLazyTokensChatIssueRetrieveQuery,
  useTokensPushRegisterCreateMutation,
  useTokensPushRevokeCreateMutation,
  useTripImportCreateMutation,
  useTripImportRetrieveQuery,
  useLazyTripImportRetrieveQuery,
  useTripTagsListQuery,
  useLazyTripTagsListQuery,
  useTripTagsCreateMutation,
  useTripTagsRetrieveQuery,
  useLazyTripTagsRetrieveQuery,
  useTripTagsDestroyMutation,
  useTripsListQuery,
  useLazyTripsListQuery,
  useTripsCreateMutation,
  useTripsRetrieveQuery,
  useLazyTripsRetrieveQuery,
  useTripsPartialUpdateMutation,
  useTripsDestroyMutation,
  useTripsAcceptPartialUpdateMutation,
  useTripsActivateWillCallPartialUpdateMutation,
  useTripsAssignPartialUpdateMutation,
  useTripsCancelPartialUpdateMutation,
  useTripsCancelCodesRetrieveQuery,
  useLazyTripsCancelCodesRetrieveQuery,
  useTripsCancelReasonsRetrieveQuery,
  useLazyTripsCancelReasonsRetrieveQuery,
  useTripsCompletePartialUpdateMutation,
  useTripsCorrelatedListQuery,
  useLazyTripsCorrelatedListQuery,
  useTripsCreatesListQuery,
  useLazyTripsCreatesListQuery,
  useTripsDriverEnteredNotesPartialUpdateMutation,
  useTripsEventsListQuery,
  useLazyTripsEventsListQuery,
  useTripsLocationsListQuery,
  useLazyTripsLocationsListQuery,
  useTripsLockPartialUpdateMutation,
  useTripsOverrideStatusPartialUpdateMutation,
  useTripsPriceSummaryRetrieveQuery,
  useLazyTripsPriceSummaryRetrieveQuery,
  useTripsReassignPartialUpdateMutation,
  useTripsRejectPartialUpdateMutation,
  useTripsRejectCodesRetrieveQuery,
  useLazyTripsRejectCodesRetrieveQuery,
  useTripsRejectReasonsRetrieveQuery,
  useLazyTripsRejectReasonsRetrieveQuery,
  useTripsRelatedListQuery,
  useLazyTripsRelatedListQuery,
  useTripsSignaturesListQuery,
  useLazyTripsSignaturesListQuery,
  useTripsStatusPartialUpdateMutation,
  useTripsSummaryRetrieveQuery,
  useLazyTripsSummaryRetrieveQuery,
  useTripsTurnbackReasonsRetrieveQuery,
  useLazyTripsTurnbackReasonsRetrieveQuery,
  useTripsUnassignPartialUpdateMutation,
  useTripsUncancelPartialUpdateMutation,
  useTripsUnlockPartialUpdateMutation,
  useTripsUnrejectPartialUpdateMutation,
  useTripsUpdatePickupAtPartialUpdateMutation,
  useTripsUpdatesListQuery,
  useLazyTripsUpdatesListQuery,
  useTripsValidatePartialUpdateMutation,
  useTripsAllListQuery,
  useLazyTripsAllListQuery,
  useTripsBulkAcceptCreateMutation,
  useTripsBulkAssignCreateMutation,
  useTripsBulkCancelCreateMutation,
  useTripsBulkCancelReasonsCreateQuery,
  useLazyTripsBulkCancelReasonsCreateQuery,
  useTripsBulkRejectCreateMutation,
  useTripsBulkUnassignCreateMutation,
  useTripsBulkValidateCreateMutation,
  useTripsExportListQuery,
  useLazyTripsExportListQuery,
  useTripsImportCreateMutation,
  useTripsMultiloadListQuery,
  useLazyTripsMultiloadListQuery,
  useTripsMultiloadCreateMutation,
  useTripsMultiloadRetrieveQuery,
  useLazyTripsMultiloadRetrieveQuery,
  useTripsMultiloadPartialUpdateMutation,
  useTripsMultiloadDestroyMutation,
  useTripsMultiloadMobileRetrieveQuery,
  useLazyTripsMultiloadMobileRetrieveQuery,
  useTripsRequestedCountRetrieveQuery,
  useLazyTripsRequestedCountRetrieveQuery,
  useTripsSubscriptionsListQuery,
  useLazyTripsSubscriptionsListQuery,
  useTripsSubscriptionsCreateMutation,
  useTripsSubscriptionsPartialUpdateMutation,
  useTripsSubscriptionsDestroyMutation,
  useTripsSuggestedPickupAtCreateMutation,
  useVehicleTagsListQuery,
  useLazyVehicleTagsListQuery,
  useVehicleTagsCreateMutation,
  useVehicleTagsRetrieveQuery,
  useLazyVehicleTagsRetrieveQuery,
  useVehicleTagsDestroyMutation,
  useVehiclesListQuery,
  useLazyVehiclesListQuery,
  useVehiclesCreateMutation,
  useVehiclesRetrieveQuery,
  useLazyVehiclesRetrieveQuery,
  useVehiclesPartialUpdateMutation,
  useVehiclesDestroyMutation,
  useVehiclesInspectionCreateMutation,
  useVehiclesInspectionFieldsListQuery,
  useLazyVehiclesInspectionFieldsListQuery,
  useVehiclesInspectionsListQuery,
  useLazyVehiclesInspectionsListQuery,
  useVehiclesInspectionsRetrieveQuery,
  useLazyVehiclesInspectionsRetrieveQuery,
} = injectedRtkApi;
