import { Cross1Icon } from '@radix-ui/react-icons';

import { isSelectOptionWithColor } from '../SelectOption/isSelectOptionWithColor';
import {
  SelectOption,
  SelectOptionWithColor,
} from '../SelectOption/SelectOption';

type SelectedMultiSelectOptionsProps = {
  option: SelectOption | SelectOptionWithColor;
  onRemove: () => void;
};

export function SelectedMultiSelectOptions({
  option,
  onRemove,
}: SelectedMultiSelectOptionsProps) {
  return (
    <div
      className="flex items-center gap-1 truncate rounded-md border border-gray-300 px-2 py-1 text-sm"
      key={option.value}
      onClick={onRemove}
      data-testid="multi-select-selected-option"
    >
      <Cross1Icon
        data-testid={`multi-select-selected-option-icon-remove-${option.value}`}
        scale=".7"
      />{' '}
      {option.label}{' '}
      {isSelectOptionWithColor(option) && (
        <span
          className="h-3 w-3 rounded-full"
          style={{ backgroundColor: option.color }}
        ></span>
      )}
    </div>
  );
}
