import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import {
  SelectOption,
  SelectOptionWithColor,
} from 'src/common/primitives/Select';

import { MultiSelectActions } from './MultiSelectActions';
import { MultiSelectOptionsDropdown } from './MultiSelectOptionsDropdown';
import { SelectedMultiSelectOptions } from './SelectedMultiSelectOption';

interface MultiSelectProps {
  placeholder?: string;
  options: SelectOption[] | SelectOptionWithColor[];
  onChange?: (value: SelectOption[] | SelectOptionWithColor[]) => void;
  selected?: SelectOption[] | SelectOptionWithColor[];
  disabled?: boolean;
  query?: string;
  setQuery?: (query: string) => void;
  searchable?: boolean;
  onSelectAll?: () => void;
}

export function MultiSelect({
  placeholder,
  options,
  onChange,
  selected = [],
  disabled,
  query,
  setQuery,
  searchable = false,
  onSelectAll,
}: MultiSelectProps) {
  return (
    <Listbox
      value={selected}
      onChange={onChange}
      multiple
      disabled={disabled}
      by="value"
    >
      <div className="relative w-full">
        <div className="relative w-full cursor-default">
          {onSelectAll && (
            <MultiSelectActions onSelectAll={onSelectAll} disabled={disabled} />
          )}
          <Listbox.Button
            placeholder={placeholder}
            className="focus:border-indigo-500 focus-visible:border-indigo-500 relative flex w-full max-w-full cursor-default flex-wrap items-center gap-1 overflow-x-auto rounded-md border border-gray-300 bg-white py-2 px-3 pr-[2em] text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 disabled:cursor-not-allowed disabled:text-gray-300 sm:text-sm"
            data-testid="multi-select-button"
          >
            {selected.length > 0 ? (
              selected.map((option) => (
                <SelectedMultiSelectOptions
                  key={option.value}
                  onRemove={() => {
                    onChange?.(
                      selected.filter((o) => o.value !== option.value)
                    );
                  }}
                  option={option}
                />
              ))
            ) : (
              <span>{placeholder || 'Select options'}</span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-4 w-4 text-primary-700" />
            </span>
          </Listbox.Button>
        </div>

        <MultiSelectOptionsDropdown
          options={options}
          searchable={searchable}
          setQuery={setQuery}
          query={query}
        />
      </div>
    </Listbox>
  );
}
