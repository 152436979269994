import { Listbox } from '@headlessui/react';

import { StyledOption } from 'src/common/primitives/ComboBox/StyledOption';
import { SearchTypeahead } from 'src/common/SearchTypeahead';

import { isSelectOptionWithColor } from '../SelectOption/isSelectOptionWithColor';
import {
  SelectOption,
  SelectOptionWithColor,
} from '../SelectOption/SelectOption';

type MultiSelectOptionsDropdownProps = {
  options: SelectOption[] | SelectOptionWithColor[];
  searchable: boolean;
  setQuery?: (query: string) => void;
  query?: string;
};

export function MultiSelectOptionsDropdown({
  options,
  searchable,
  setQuery,
  query,
}: MultiSelectOptionsDropdownProps) {
  return (
    <Listbox.Options
      className={`focus:border-indigo-500 focus:ring-indigo-500 absolute z-10 mt-1 w-full flex-wrap rounded-md border-gray-300 bg-white py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
    >
      {searchable && typeof setQuery === 'function' && (
        <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
          <SearchTypeahead
            value={query}
            onChange={setQuery}
            placeholder="Search..."
            hint="Search for options"
            dataTestId="multi-select-search"
          />
        </div>
      )}
      <div className="max-h-60 overflow-auto">
        {options.map((option) => (
          <Listbox.Option
            key={option.value}
            value={option}
            className={({ active, selected }) =>
              `relative cursor-default select-none ${
                selected || active ? 'bg-mint text-white' : 'text-gray-900'
              }`
            }
          >
            {({ selected, active }) => (
              <StyledOption
                startAddornment={
                  isSelectOptionWithColor(option) && (
                    <span
                      className="h-3 w-3 rounded-full"
                      style={{ backgroundColor: option.color }}
                    ></span>
                  )
                }
                selected={selected}
                active={active}
                option={option}
              />
            )}
          </Listbox.Option>
        ))}
      </div>
    </Listbox.Options>
  );
}
